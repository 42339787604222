.interior-header {
  @include container($padding: true);
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 12px;
  &--graphicNovel {
    padding-top: 12px;
    padding-bottom: 32px;
    @include media-notSmall {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__container {
    display: flex;
    margin-left: 0;
    width: 100%;
  }
  &__title {
    display: flex;
    flex: 1;
    flex-direction: row;
    
    &__favoritingIcon{
      display: flex;
      gap: 10px;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &__pagetitle {
        font-weight: unset !important;
        font-size: unset !important;
      }
    }
    &__umbrella-label {
      font-size: $text-base;
      font-weight: $fw-chubby;
      line-height: $lh-tight;
      color: $color-brandBlue2020;
      &--noFear {
        color: $color-brandBlue2020;
      }
      &--graphicNovel {
        color: $color-a11yGray;
      }
    }
    &__pagetitle {
      font-size: $text-big;
      font-weight: $fw-fat;
      line-height: $lh-tight;
      color: $sn-primary-header;
      &--ctQuizzes {
        color: $plus-green-dark;
      }
      &--graphicNovel {
        color: $plus-green-dark;
      }
      &__secondaryPagetitle {
        font-size: $text-big-ish;
        font-weight: $fw-chubby;
        line-height: $lh-tight;
        color: $sn-primary-header;
      
        @media (max-width: $mobile-bp) {
          font-size: $text-big-ish;
        }
      }
    }
    &__icon {
      @include interior-page-icon;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $mobile-bp) {
        height: 56px;
        width: 56px;
      }
      &--noFear {
        border-color: $color-brandBlue2020;
      }
      &--graphicNovel {
        border-color: #008262;
      }
      &--ctQuizzes {
        border-color: #008262;
      }
      &--infographic {
        border-color: #008262;
      }
    }
  }

  &__pageFavoritingIcon {
    margin-top: 2px;
  }

  &__pageFavoritingIcon__noFear {
    margin-top: 8px;
  }

  &__pageFavoritingIcon__noFearXML {
    margin-top: -8px;
  }

  &__pageFavoritingIcon input[type="checkbox"] {
    @include media-notSmall {
      opacity: 0;
    }
    @include media-small {
      display: none;
    }
  }

  &__myPageInput {
    display: inline-block;
    width: 26px;
    height: 26px;
    cursor: pointer;
    background: url(/images/flashcard/bookmark.svg) no-repeat;
    @include media-notSmall {
      margin-left: -17px;
    }

    @include media-notSmall {
      &:hover {
        background-position: -30px 0;
      }
    }
  }

  &__hovertext {
    font-family: Raleway;
    font-size: $text-tiny;
    letter-spacing: 0.05px;
    color: #008262;
    margin: 6px 30px;
    position: absolute;
    display: none;
    pointer-events: none;
    cursor: not-allowed;
  }

  @include media-notSmall {
    &__myPageInput:hover &__hovertext {
      font-family: Raleway;
      font-size: $text-tiny;
      letter-spacing: 0.05px;
      color: #008262;
      margin: 6px 30px;
      position: absolute;
      display: inline;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}

.pageSolidStar input + label {
  background-position: -60px 0;
}

@include media-small {
  .longTitleAlingText input + label {
    display: flex;
    flex-direction: column-reverse;
  }
  
  .longTitleRemoveText {
    margin-top: 5px;
    margin-left: -30px;
  }
  
  .longTitleSaveText {
    margin-top: 5px;
    margin-left: -15px;
  }
}

.starClickTextVisibility {
  display: inline;
}

.nofear_PageFavorite {
  margin: unset;
  padding-top: 5px;
}

.commonStyle_PageFavorite {
  padding-left: 6px;
  .interior-header__myPageInput {
    background-size: 86px 22px;
    @include media-notSmall {
      &:hover {
        background-position: -26px 0;
      }
    }
    .interior-header__hovertext {
      margin: 4px 30px;
    }
  }
}

.studyGuide_PageFavorite {
  .interior-header__myPageInput {
    background: url(/images/flashcard/studyguide_dark_sticky_bookmark.svg) no-repeat;
    background-size: 86px 22px;
    @include media-notSmall {
      &:hover {
        background-position: -26px 0;
      }
    }
  }
}

.studyGuide_PageFavorite.pageSolidStar input+label {
  background-position: -60px 0;
}

.commonStyle_PageFavorite.pageSolidStar input+label {
  background-position: -52px 0;
}

.pageFavorite_withPagination {
  padding: 12px 0 12px 6px !important;
}

.fulltext-subnav-container {
  flex-direction: column;
  align-items: unset;
  padding-top: unset;
}

.fulltext-icon {
  display: none;
}

.fulltext-title {
  display: none;
}

.fulltext {
  display: flex;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
  background-color: $white;
  z-index: 3;
  &__subnav {
    max-width: 1164px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 10px;
    @include media-small {
      padding-left: unset;
      padding-right: unset;
      padding-bottom: 12px;
    }
    @media (min-width: 700px) and (max-width: 980px) {
      padding-left: 20px;
    }
      &__container {
        display: flex;
        padding-top: 8px;
        padding-bottom: 13px;
        position: relative;
        &__lists {
          display: block;
          padding: 7px 28px 2px 0px;
          &__subnav-activelist {
            padding-bottom: 4px;
            font-family: Raleway;
            font-size: $text-base;
            font-weight: $fw-chubby;
            font-stretch: normal;
            font-style: normal;
            line-height: $lh-base;
            letter-spacing: normal;
            text-align: left;
            color: $sn-primary-header;
            border-bottom: 2px solid $sn-primary-header;
            &:hover, &:focus {
              color: #004371;
              border-bottom: 2px solid #004371;
            }
          }
          &__subnav-deactivelist {
            padding-bottom: 4px;
            font-family: Raleway;
            font-size: $text-base;
            font-weight: $fw-chubby;
            font-stretch: normal;
            font-style: normal;
            line-height: $lh-base;
            letter-spacing: normal;
            text-align: left;
            color: $color-a11yGray;
            &:hover, &:focus {
              color: #004371;
            }
            @include media-small {
              color: rgba(118, 118, 118, 0.75);
            }
          }
        }
        &__hiddenlists {
          padding-bottom: 4px;
        }
        // &__hiddenlists:not(:first-child) {
        //   padding-left: 10px;
        // }
        // &__more {
        //   padding: 4px 16px 2px 0px;
        //   &__text {
        //     display: flex;
        //     align-items: center;
        //     font-family: Raleway;
        //     font-size: $text-small;
        //     font-weight: $fw-chubby;
        //     font-stretch: normal;
        //     font-style: normal;
        //     line-height: $lh-base;
        //     letter-spacing: normal;
        //     text-align: left;
        //     color: #0066c4;
        //     cursor: pointer;
        //     &:hover, &:focus {
        //       color: #004371;
        //     }
        //   }
        // }
      }
  }
}

.subnav-left-arrow {
  display: flex;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
  &:hover, &:focus {
    path {
      fill: #004371;
    }
  }
}

.container-height {
  height: 56px;
}

.subnav-partition {
  display: flex;
  white-space: nowrap;
}

// .fulltext .menu-container {
//   display: flex;
//   align-items: center;
//   overflow-x: auto;
// }

// .fulltext__subnav__container__more__text:hover svg {
//   path {
//     fill: #004371;
//   }
// }

// .fulltext__subnav__container__more__text:focus svg {
//   path {
//     fill: #004371;
//   }
// }

// .fulltext .owl-carousel .item {
//   height: 10rem;
//   background: #4DC7A0;
//   padding: 1rem;
// }

// .fulltext .owl-prev {
//   top: 22%;
//   background-color: white;
//   left: 0%;
//   width: unset;
//   background-image: unset;
//   background-position: unset;
//   font-weight: 600;
//   @include media-small {
//     background-color: rgb(255 255 255 / 50%);
//     top: 13%;
//     width: 50px;
//     left: 0px;
//     height: unset;
//   }
//   img {
//     @include media-small {
//       padding: 12px 15px;
//     }
//   }
// }

// .fulltext .owl-left-arrow:hover {
//   filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
// }

// .owl-container {
//   height: 56px;
//   @include media-small {
//     height: 50px;
//   }
// }

.fulltext__subnav .left_fade {
  position: absolute;
  z-index: 4;
  width: 70px;
  height: 10px;
  top: 0;
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  background-repeat: repeat-x;
  padding: 25px 0;
  display: none;

}
// .left_opacity {
//   display: block;
// }

.fulltext__subnav .right_fade {
  position: absolute;
  z-index: 2;
  width: 70px;
  top: 0;
  height: 10px;
  float: right;
  right: 45px;
  background-image: -webkit-linear-gradient(right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  background-repeat: repeat-x;
  padding: 25px 0;

  @include media-small {
    right: 11px;
  }
}

// .fulltext .owl-next {
//   display: flex;
//   align-items: center;
//   font-weight: 600;
//   top: 18%;
//   right: -0.5%;
//   color: #0066c4 !important;
//   font-size: 14px !important;
//   padding: 6px !important;
//   background-color: $white;
//   background-image: unset;
//   margin: unset !important;
//   background-position: unset;
//   width: unset;
//   height: unset;
//   &:hover {
//     color: #004371 !important;
//   }
//   @include media-small {
//     background-color: rgb(255 255 255 / 70%);
//     top: 13%;
//     width: 50px;
//     height: unset;
//     padding: unset !important;
//   }
//   img {
//     @include media-small {
//       padding: 12px 15px;
//     }
//   }
// }

// .fulltext .owl-next .owl-right-arrow:hover {
//   color: #004371 !important;
//   filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
// }

// .fulltext .owl-next:hover .owl-right-arrow {
//   color: #004371 !important;
//   filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
// }

// .fulltext .owl-theme .owl-nav .disabled {
//   opacity: 0 !important;
//   display: none;
// }

.fulltext .fulltext-slick-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  @include media-small {
    padding: 8px 12px 13px 20px;
  }
  padding: 8px 0px 13px 0px;
}

// .fulltext .hide-more-text {
//   display: block;
//   @include media-small {
//     display: none;
//   }
// }

.fulltext__subnav .interior-header__title__favoritingIcon {
  @include media-small {
    padding: 0px 20px 0px 20px;
  }
}

// .fulltext .owl-item:first-child .fulltext__subnav__container__hiddenlists {
//   @include media-small {  
//     padding-left: 20px;
//   }
// }

// .fulltext .owl-item:last-child .fulltext__subnav__container__hiddenlists {
//   @include media-small {
//     padding-right: 20px;
//   }
// }

.fulltext .interior-header__title__favoritingIcon {
  gap: 13px;
  align-items: center;
}

.fulltext .interior-header__title__pagetitle {
  font-weight: $fw-chubby;
  font-size: $text-big-ish;
  color: $sn-primary-paragraph;
}

.fullText-sticky-font {
  font-size: $text-slightly-big !important;
}

.fulltext__subnav .slide-arrow.prev-arrow {
  position: absolute;
  border: none;
  left: -17px;
  top: 3px;
  font-size: 20px;
  color: #0066c4;
  background: $white;
  cursor: pointer;
  img {
      width: 19px;
      height: 19px;
  }
  &:hover {
      color: #004371;
      img {
          color: #004371!important;
          filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
      }
  }
  @include media-small {
      left: -15px;
      top: 3px;
  }
}

.fulltext__subnav .slide-arrow.next-arrow {
  display: flex;
  align-items: center;
  font-weight: $fw-chubby;
  position: absolute;
  top: -1px;
  right: -8px;
  color: #0066c4;
  font-size: $text-small;
  padding: 3.93px 0px 9px 0;
  background-color: $white;
  background-image: unset;
  margin: unset;
  background-position: unset;
  width: unset;
  height: unset;
  cursor: pointer;
  
  @media (min-width: 480px) and (max-width: 900px){
      span {
        padding-bottom: 2px;
      }
  }
  @include media-small {
      padding: 5px 0 5px 0;
      span {
          display: none;
      }
  }
  img {
      padding-top: 1.26px;
      width: 19px;
      height: 19px;
  }
  &:hover {
      color: #004371;
      img {
          color: #004371!important;
          filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
      }
  }
}

.fulltext .fulltext_slick {
  display: flex !important; 
  z-index: 2;
  visibility: hidden;
}

.fulltext .slick-slide {
  width: max-content;
  margin-right: 28px;
  user-select : none;
  @include media-small {
      margin-right: 16px;
  }
}

.fulltext__subnav__container__lists__subnav-activelist.is_title_active {
  border-bottom: 2px solid #484848;
  color: #484848;
  padding-bottom: 4px;
  font-weight: 600;
}

.fulltext__subnav {
  .slick-slider .slick-track, .slick-slider .slick-list {
    display: flex;
  }
  
  .next-arrow.slick-arrow.slick-disabled {
    display: none !important;
  }

  .prev-arrow.slick-arrow.slick-disabled {
    display: none !important;
  }
}

.new-interior-header {
  @include media-notSmall {
    padding-top: unset;
    padding-bottom: 39px;
    padding-left: unset;
  }
}