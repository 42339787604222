.other-subjects-hub{
  &__contents{
    padding-top: 12px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 60px 36px;
    h3{
      font-size: $text-big;
      font-weight: $fw-fat;
      margin-bottom: 0.75em;
      column-span: all;
    }
    h4{
      padding: 20px 0;
      line-height: $lh-tight;
      font-size: $text-slightly-big;
      font-weight: $fw-fat;      
    }
    a{
      @include softGrayCardLink;
      display: block;
      padding: 20px 16px;
      margin-bottom: 12px;
      font-weight: $fw-chubby;
      line-height: $lh-tight;
    }
  }
}
