.termsConditions {
    h1 {
        padding-bottom: 1rem;
    }
    .termsContacts {
        padding-bottom: 0.5rem;
    }
    ol, ul {
        list-style: revert;
        li {
            list-style-type: revert;
            font-size: 1rem;
            line-height: 1.8;
        }
    }
}

.legal-pages-navigation {
    list-style-type: revert;
    font-size: 1rem;
    line-height: 1.8;
}

.plus-discounts-container {
    h1 {
        padding-bottom: 1rem;
    }
}

.termsUsePage {
    @media only screen and (min-width: 900px) {
        width: 800px;
    }
}