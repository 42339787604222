.other-subjects-list{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: 16px;
  &__link{
    display: flex;
    flex-direction: column;
    @include softGrayCardLink;
    padding: 16px;
    &__icon{
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
      &__img{
        display: block;
        max-width: 100%;
      }
    }
    &__title{
      color: inherit;
      font-size: $text-big-ish;
      line-height: $lh-tight;
      margin-bottom: 12px;
    }
    &__chip{
      color: $sn-primary-header;
      font-size: $text-small;
      margin-top: auto;
      color: $color-a11yGray;
    }
    &:hover,&:focus {
      color: #004371 !important;
      box-shadow: 0 8px 16px 0 rgba(0,0,0,.2), inset 0 3px 0 0 #004371 !important;
    }
  }
}