.life-and-times__promo-banner{
  background-color: $color-teacherSoftGray;
  border-radius: $radius-big;
  margin-top: 48px;
  overflow: hidden;
  display: flex;
  @include media-small{
      flex-direction: column;
      background-color: #6b2762;
  }
  &__text{
    flex: 0 2 auto;
    align-self: center;
    display: flex;
    flex-direction: column;
    @include media-notSmall{
      max-width: 63%;
      padding: 36px 46px;
      background-color: #6b2762;
    }
    @media (max-width: 821px) { 
      padding: 36px;
    }
    @include media-small{
      padding: 20px;
      text-align: left;
    }
  }
  &__heading{
    display: inline-block;
    font-weight: $fw-slim;
    font-size: $text-very-big;
    @media (max-width: 821px) { 
      font-size: $text-big-ish;
    }
    @include media-small{
      font-size: $text-big-ish;
      display: flex;
      flex-direction: column;
    }
    line-height: $lh-tight;
    margin-bottom: 12px;
    &__name{
        font-weight: $fw-fat;
        color: $white;
    }
    span{
      font-weight: $fw-fat;
      color: $white;
      font-family: Raleway;
    }
  }
  &__description{
    margin-bottom: 19px;
    color: $white;
    line-height: $lh-base;
    font-size: $text-base;
    @include media-small{
      font-size: $text-tiny;
      line-height: 1.72;
      margin-bottom: 13px;
    }
  }
  &__link{
    display: inline-block;
    padding: 12px 16px;
    align-self: flex-start;
    @include media-small{
      align-self: center;
    }
    text-align: center;
    background-color: #6b2762;
    color: $white;
    border-radius: $radius-base;
    line-height: $lh-tight;
    font-weight: $fw-chubby;
    font-size: $text-base;
    border: 1px solid transparent;
    &:hover,
    &:focus{
      background-color: #6b2762;
      color: $white;
      box-shadow: 0 0 4px 0 rgb(255 255 255 / 40%);
      border: solid 1px rgba(255, 255, 255, 0.2);
    }
    &:after {
      content: "";
      background: url(/images/icon_arrow_right-24.svg) center no-repeat;
      width: 12px;
      height: 12px;
      display: inline;
      padding: 0 15px;
    }
  }
  &__image-container{
    @include media-notSmall{
        flex: none;
        width: 386px;
        position: relative;
    }
    @include media-small{
        width: auto;
        order: -1;
        align-self: center;
        margin-top: -24px;
        display: none;
    }
    // image relies on mix-blend-mode.
    // Edge 18 doesn't support it so we just hide the image
    @supports not(mix-blend-mode: multiply) {
      display: none;
    }
  }
  &__image{
      @include media-notSmall{
          position: absolute;
          display: block;
          top: 0;
          width: 120%;
          height: 140%;
          object-fit: cover;
      }
      @include media-small{
          max-width: 240px;
      }
      mix-blend-mode: multiply; // check browser support (need a fallback for IE18)
      filter: saturate(1.5);
  }
}