.join-membership {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #e1faef;
    &__cat-container {
        display: flex;
        justify-content: center; 
        margin-top: 92px;
        &__img {
            width: 335px;
            height: 140px;
        }
        @include media-small {
            display: none;
        }
    }
    &__header {
        display: flex;
        justify-content: center;
        &__join-modal {
            min-width: 576px;
            @include media-small {
                max-width: 576px;
                min-width: unset;
            }
            &__logo {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #008262;
                height: 120px;
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
                &__img {
                    width: 250px;
                    height: 45px;
                }
            }
            &__details {
                background-color: $white;
                margin-bottom: 100px;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                padding: 32px 40px 50px 40px;
                @include media-small {
                    padding: 32px 20px 50px 20px;
                }
                &__group {
                    font-family: Raleway;
                    font-size: $text-big;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: $lh-base;
                    letter-spacing: normal;
                    text-align: center;
                    color: #008262;
                }
                &__field {
                    margin-top: 30px;
                }
                &__joinplus-membership {
                    width: 300px;
                    height: 46px;
                    background-color: #0066c4;
                    color: $white;
                    font-size: $text-base;
                    font-weight: $fw-chubby;
                    box-shadow: unset;
                    &:hover, &:focus {
                        background-color: #004371;
                        color: $white;
                        box-shadow: unset;
                    }

                    &:disabled {
                        opacity: 0.5;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}

.joinplusmembership {
    opacity: 0.5;
}

.jm-on-focus:focus {
    background-color: #f5f1f5;
    cursor: no-drop;
    box-shadow: unset;
}

.jm-on-focus:hover {
    background-color: #f5f1f5;
    cursor: no-drop;
    box-shadow: unset;
}