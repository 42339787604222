@keyframes modalFadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes modalFadeOut {
  from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes modalGrowIn {
from {
  transform: scale(0.5);
  opacity: .1;
}
to {
  transform: scale(1);
  opacity: 1;
}
}

@keyframes modalSlideUpIn {
from {
  transform: translateY(100vh);
  // opacity: .4;
}
to {
  transform: translateY(0);
  // opacity: 1;
}
}

@keyframes modalSlideDownOut {
from {
  transform: translateY(0);
  // opacity: 1;
}
to {
  transform: translateY(100vh);
  // opacity: .4;
}
}

@keyframes modalGrowOut {
  from { 
    transform: scale(1);
    opacity: 1;
  }
  to { 
    transform: scale(1.05); 
    opacity: 0;
  }
}


@keyframes loader-animation {
  0% { transform: scaleX(0) translateX(0); }
  50% { transform: scaleX(0.5) translateX(50%); }
  100% { transform: scaleX(1) translateX(100%); }
}