.interior-pagination-short {
  display: flex;
  overflow-x: auto;
  max-width: 100vw;
  // individual tabs
  &__link {
    flex: none;
    display: block;
    flex-direction: column;
    padding: 16px;
    position: relative;
    color: $color-brandBlue2020;
    @include media-small {
      font-size: $text-small;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: transparent;
    }
    &:hover,
    &:focus {
      color: $color-linkHover;
      background-color: $color-teacherSoftGray;
      &:after {
        background-color: $color-linkHover;
      }
    }
    // currently viewed page tab styles
    &--active {
      pointer-events: none;
      color: $sn-primary-header;
      &:after {
        background-color: $sn-primary-header;
      }
    }
  }
}
