// variable for setting max width of jumpNav__subitem
$jumpNav__subitem-width-max: $sn-max-width - ($pageMargin * 2);
$jumpNav__height: 56px;

.jumpNav {
  $background: white;
  $shadow: #bbb;
  background: linear-gradient(to right, $background 24px, rgba($background, 0) 72px),
    linear-gradient(to left, $background 24px, rgba($background, 0) 72px) 0 100%,
    linear-gradient(to right, $shadow 1px, rgba($shadow, 0) 16px),
    linear-gradient(to left, $shadow 1px, rgba($shadow, 0) 16px);
  background-attachment: local, local, scroll, scroll;
  background-color: $white;
  border-bottom: 1px solid $sn-border-gray;
  position: sticky;
  top: 0;
  z-index: 2;
  transition: box-shadow 0.2s, border 0.2s;
  height: $jumpNav__height;
  overflow-x: auto;
  overflow-y: hidden;
  @include media-mobile {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    &.list-expanded {
      background: $sn-border-gray;
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &__items {
    mix-blend-mode: multiply;
    display: flex;
    @include container($padding: true);
  }
  &__item {
    flex: none;
    &--jump-label {
      @include media-notSmall {
        display: flex;
      }
      @include media-small {
        display: none;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      color: $color-a11yGray;
      line-height: $lh-tight;
      padding: $s-px * 2;
      font-weight: $fw-base;
      position: relative;
      height: $jumpNav__height - 1;
      @include media-small {
        font-size: $text-small;
        padding: $s-px * 1;
      }
      &:after {
        position: absolute;
        content: "";
        display: block;
        height: 2px;
        right: 0;
        bottom: 0;
        left: 0;
      }
      &:hover,
      &:focus {
        color: #004371;
        &:after {
          background-color: #004371;
        }
      }
      &--active {
        color: #004371;
        &:after {
          background-color: #004371;
        }
      }
    }
  }
}
.jump-nav__jump-label {
  color: $sn-primary-header;
  display: flex;
  align-items: center;
  line-height: $lh-tight;
  padding: $s-px * 2 $s-px * 2 $s-px * 2 0;
}
.tabactive {
  border-color: #b2b2b2;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.jumpNav__hook {
  $jump-nav-height: 56px;
  position: absolute;
  height: $jump-nav-height;
  width: 1px;
  top: -$jump-nav-height;
  &__container {
    position: relative;
  }
}
