.homepage_whats_new{

  // default color for cards - each card updates this variable in the HTML
  --cardColor: #{$white};
  --cardTextColor: #{$white};
  --lozengeBgColor: #{$white};
  --lozengeHoverColor: #{$white};
  
  $l-media-small: 600px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  grid-template-areas: "left topRight" "left bottonRight";
  @include media-under-1020{
    grid-template-areas: "left left" "topRight bottonRight";
  }
  @include media-small{
    grid-template-columns: 1fr;
    grid-template-areas: "left" "topRight" "bottonRight";

  }
  &__card{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--cardColor);
    border-radius: $radius-big;
    overflow: hidden;
    &--primary{
      grid-area: left;
      @include media-under-1020{
        @include media-notSmall{
          flex-direction: row;
        }
      }
    }

    &__image{
      overflow: hidden;
      position : relative;
      @include media-small{
      }
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #50927A;
      @include media-under-1020{
        @include media-notSmall{
          flex : 1;
        }
      }
    }
    &__img{
      transform: rotate(12deg);
      width: 60%;
      @include media-under-1020{
        @include media-notSmall{
          width: 100%;
        }
      }
      box-shadow: 0 0 44px 0 rgba(0,0,0,0.2);
    }

    &__new-img{
      @media screen and (max-width: 1199px) {
        height: auto;
        width: 100%;
      }
    }

    &__new-secondary-img{
      position: absolute;
      width: 20%;
      top: 0px;
      left: 0px;
    }

    &__text{
      padding: 36px;
      @include media-small{
        padding: 20px;
      }
      @include media-under-1020{
        @include media-notSmall{
          flex : 1;
        }
      }
    }

    &__title{
      color: var(--cardTextColor);
      font-size: $text-very-big;
      font-weight: $fw-fat;
      line-height: $lh-extra-tight;
      margin-bottom: 12px;
    }
    &__description{
      color: var(--cardTextColor);
      line-height: $lh-base;
      margin-bottom: 24px;
    }

    &__link-list{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -12px;
    }

    &__link-link{
      background-color: var(--lozengeBgColor);
      padding: 4px 12px;
      font-size: $text-base;
      line-height: $lh-tight;
      color: $white;
      border-radius: $radius-base;
      display: block;
      margin-right: 12px;
      margin-bottom: 12px;
      &:hover,
      &:focus{
        background-color: var(--lozengeHoverColor);
        outline: none;
        color: $white;
      }
    }
    &__button-links{
      display: flex;
    }
    &__button-link{
      flex: 1 1 50%;
      padding: 12px;
      text-align: center;
      background-color: $color-brandBlue2020;
      color: $white;
      border-radius: $radius-base;
      line-height: $lh-tight;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover,
      &:focus{
        background-color: $color-linkHover;
      }
      &:not(:first-child){
        margin-left: 12px;
      }
    }
    &__button-link--secondary{
      padding: 12px;
      text-align: center;
      box-shadow: inset 0 0 0 1px $color-brandBlue2020;
      color: $color-brandBlue2020;
      border-radius: $radius-base;
      line-height: $lh-tight;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover,
      &:focus{
        background-color: $color-linkHover;
        box-shadow: inset 0 0 0 1px $color-linkHover;
        color: $white;
      }
    }
  }
}
