  .notetaking-addnote-button{
    @include media-small {
      width: 100%;
    }
  }
  .notetakingmodal__addnote-button{
    height: 46px;
    width: 373px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px;
    border-radius: 4px;
    border: none;
    background-color: #007acd;

    @include media-small {
      width: 100%;
    }

    &:hover,&:focus {
      background-color: #004371;
    }

    .add-note {
      flex-grow: 0;
      font-family: Raleway;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.13px;
      text-align: center;
      color: #fff;
    }
    .plus-color-text {
      color: $white;
    }
  }
  .notetakingmodal-overlay{
    @include media-small {
      box-shadow: unset !important;
    }
  }
  .notetakingmodal__overlay {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 16px 16px 24px;
    border-top: 1px solid #c4c4c4;
    background-color: #e5e5e5;

    @include media-small {
      width: 100%;
      height: auto;
      padding-left: unset !important;
      padding-right: unset !important;
      border-radius: 12px !important;
      box-shadow: 0 12px 84px 0 rgba(0, 0, 0, 0.33);
    }

    .ql-toolbar.ql-snow{
        width: 24px;
        height: 24px;
        padding: 0px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        border: none;
        @include media-small {
          display: none;
        }
    }

    .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button{
            width: 28px;
            height: 28px;
            &:focus-visible {
              outline: auto !important;
            }
    }
  }

  .notetakingmodal__container {
    width: 544px;
    height: auto;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    @include media-small {
      width: 100%;
      height: auto;
    }
   }

  .notetakingmodal__title {
    height: 65px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    padding: 8px 16px;
    background-color: #00cc9a;
    @include media-small {
      height: 64px;
    }
  }

  .notetakingmodal__titlesection {
    background-color: #d9ebf7;
    display: block;
  }
  .notetakingmodal__notetitlesection {
    display: block;
    background-color: #d9ebf7;
    @include media-small{
      background-color: #d9ebf7;
    }
  }
  .notetakingmodal__highlightednotetitlesection{
    display: -webkit-box;
    background-color: #00cc9a;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    
    @include media-small{
      background-color: #00cc9a;
    }
  }
  .title_one {
    font-weight: 600 !important;
  }
  .noteitem {
    font-family: Raleway;
    font-size: 16px;
  }

  .notetakingmodal__title-highlighted-text {
    height: 52px;
    flex-grow: 1;
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #292c2e;

    @include media-small {
      height: 26px;
      width: 328px;
    }
  }

  .notetakingmodal__note-copy {
    height: 170px !important;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 8px 16px;
    background-color: #fff;
    padding-left: 0px !important;

    @include media-small {
      height: 260px !important;
    }
       .ql-snow{
        height: 154px;
        flex-grow: 1;
        font-family: Raleway;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #292c2e;
        }
        .ql-editor{
          width: 100%;
        }
    }

   .notetakingmodal__character {
        padding-top: 2px;
        width: 82px;
        height: 23px;
        flex-grow: 0;
        font-family: Raleway;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #292c2e;

        @include media-small {
          margin: 8px 20px 0 249px;
        }

        &__text-counter {
          font-weight: bold;
        }
    }

    .notetakingmodal__character-mobile {
      display: none;
      @include media-small {
        display: block;
        padding-top: 8px;
        padding-left: 12px;
        width: 82px;
        height: 23px;
        flex-grow: 0;
        font-family: Raleway;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #292c2e;

        &__text-counter {
          font-weight: bold;
        }
      }
    }

  .notetakingmodal__character_CTAs {
    height: 46px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    @include media-small{
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .notetakingmodal__CTAs {
    width: 220px;
    height: 46px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    gap : 32px;
  }

  .notetakingmodal__cancel-save{
      margin-left: 16px;
  }
  .notetakingmodalsave__overlay-color {
    mix-blend-mode: overlay;
    visibility: visible;
    pointer-events: none;
  }
  .notetakingmodal__overlay-color {
    mix-blend-mode: overlay;
    visibility: visible;
    pointer-events: none;
  }
  
  .notetakingmodal__cancel-button{
    height: 46px;
    width: 84px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px;
    border-radius: 4px;
    border: solid 2px #484848;

    .notetakingmodal__cancel {
        width: 54px;
        height: 19px;
        flex-grow: 0;
        font-family: Raleway;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.13px;
        text-align: center;
        color: #484848;
      }
  }

  .notetakingmodal__cancel-button:hover {
    border: solid 2px #00adef;
  }

  .notetakingmodal__save-button{
    height: 46px;
    width: 84px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px;
    border-radius: 4px;
    border: none;
    background-color: #007acd;

    .notetakingmodal__save {
        width: 37px;
        height: 19px;
        flex-grow: 0;
        font-family: Raleway;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.13px;
        text-align: center;
        color: #fff;
      }
  }

  .notetakingmodal__delete {
    content: url("/images/notetaking/icon-trashcan.svg");
    margin-right: 12px;
    &[hidden]{
      display: none;
    }
    
    @include media-small{
      width: 24px;
      height: 24px;
      // margin: 12.5px 27px 11px -10px;
    }
  }

  .notetakingmodal__delete:hover{
    cursor: pointer;
  }
  
  .notetakingmodal__save-button:hover {
    background-color: #00adef;
  }
  .note-taking-popup-modal {
    .snplusmodal__container {
      @include media-small {
        width: 360px;
        height: 375px;
        border-radius: 12px;
        box-shadow: 0 -10px 30px 0 rgb(0 0 0 / 20%);
        align-self: center;
        overflow: hidden;
      }
    }
  }
  .note-taking-popup-modal {
    .snplusmodal__header {
      @include media-small {
        height: 110px;
      }
    }
  }
  .notetakingModalPopup__overlay{
    height: 277px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $text-big;
    padding: 26px 42px 30px;
    background-color: #fff;
    border-top: 1px solid #c4c4c4;
    @include media-small{
      width: 360px;
      height: 265px;
    }

    &__container {
    width: 496px;
    height: 138px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    padding: 0;

     @include media-small{
       width: 360px;
       height: 147px;
       gap: 24px;
       margin-left: -12px;
      align-items: flex-start;
      }
    }
  }
  .notetakingModalPopup__heading {
  height: 39px;
  align-self: stretch;
  flex-grow: 0;
  font-family: Raleway;
  font-size: $text-big;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.0;
  letter-spacing: normal;
  text-align: center;
  color: #008262;

  @include media-small{
    height: 34px;
    font-size: 18px;
    align-self: unset;
  }

  &__text {
    height: 52px;
    align-self: stretch;
    flex-grow: 0;
    font-family: Raleway;
    font-size: $lh-base;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: $lh-base;
    letter-spacing: normal;
    text-align: left;
    color: $sn-primary-paragraph;

    @include media-small{
      height: 77px;
      width: 312px;
      font-size: 16px;
    }
  }
}
  .notetakingModalPopup__myNotes-button {
  width: 222px;
  height: 46px;
  flex-grow: 0;
  border-radius: $radius-base;
  background-color: $color-brandBlue2020;
  border: none;
    @include media-small{
      width: 300px;
    }
  }
  .notetakingModalPopup__button-text
  {
  width: 134px;
  height: 19px;
  font-family: Raleway;
  font-size: $text-base;
  font-weight: $fw-chubby;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.13px;
  text-align: center;
  color: $white;
  }
  .notetakingModalPopup__button-free-text {
    font-weight: $fw-fat;
  }

  .warning-msg-box{
    height: 46px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 5px;
    border-radius: 4px;
    border: solid 2px #00cc9a;
    background-color: #e1faef;
    &[hidden]{
      display: none;
        @include media-mobile {
            display: none;
        }
    }
  }

  //Highlight Overlap

.highlightoverlap{

  &__bottom{
    width: 576px;
    height: 323px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 32px 40px 48px;
    background-color: #fff;
  }

  &__notes__covered {
    height: 39px;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.6;
    text-align: center;
    color: #008262;
  }

  &__Copy_CTAs {
    width: 496px;
    height: 184px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  &__frame {
    width: 496px;
    height: 98px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__message {
    height: 52px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    color: #292c2e;
  }

  &__checkbox {
    width: 414px;
    height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
  }

  &__updates{
    width: 22px;
    height: 22px;
    border-radius: 2px;
    border: solid 1px #767676;
    background-color: #f5f1f5;
  }

  &__warning{
    width: 385px;
    height: 19px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    color: #292c2e;
  }

  &__btn-back{
    width: 222px;
    height: 46px;
    padding: 13px 44px 13px 43px;
    border-radius: 4px;
    border: none;
    background-color: #007acd
  }

  &__btn-back:hover {
    background-color: #00adef;
  }

  &__back {
    width: 134px;
    height: 19px;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.13px;
    text-align: center;
    color: #fff;
  }
}

.desktop_cancel-save {
  @include media-small{
    display:none
  }
}
.mobile_cancel-save{
  display: none;
  @include media-small{
    display: flex;
  }
}

.notetaking-text-container {
  display: flex;
}

.notetakingmodal__policy-container {
  @include media-small {
    padding: 0px 15px 0px 15px;
  }
  &__terms-text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: $sn-black;
    &__links {
      color: $color-brandBlue2020;
      &:hover {
        color: $color-brandBlue2020;
      }
    }
  }
}

.margin-forsmaller-screen {
  top: 10% !important;
}

.notetakingModal_container_gap {
  gap: 32px !important;
  @include media-small {
    gap: 24px !important;
  }
}

.notetakingmodal__addnote-button.with-plus {
  &:hover,&:focus {
    background-color: $color-brandBlue2020;
  }
}