.joining-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #e1faef;
    &__cat-container {
        display: flex;
        justify-content: center; 
        margin-top: 92px;
        &__img {
            width: 335px;
            height: 140px;
        }
        @include media-small {
            display: none;
        }
    }
    &__header {
        display: flex;
        justify-content: center;
        &__join-modal {
            max-width: 576px;
            &__logo {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #008262;
                height: 120px;
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
                &__img {
                    width: 250px;
                    height: 45px;
                }
            }
            &__details {
                background-color: $white;
                margin-bottom: 100px;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                padding: 32px 40px 50px 40px;
                @include media-small {
                    padding: 24px 24px 50px 24px;
                }
                &__group {
                    font-family: Raleway;
                    font-size: $text-big;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: $lh-base;
                    letter-spacing: normal;
                    text-align: center;
                    color: #008262;
                }
                &__signin {
                    font-family: Raleway;
                    font-size: $text-base;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: $lh-base;
                    letter-spacing: normal;
                    text-align: left;
                    color: $sn-primary-paragraph;
                    margin-top: 20px;
                    cursor: pointer;
                    &__signup {
                        color: #0066c4;
                        text-decoration: underline;
                        &:focus, &:hover {
                            color: #004371;
                        }
                    }
                }
            }
        }
    }
}

// styling for groupdiscount signin and signup modal

.groupdiscount__tabbed {
    margin-top: -1px;
    &__tabs {
        background: #008262;
        display: flex;
        justify-content: center;
        padding: 0 60px;
        box-shadow: 0 $radius-big 0 0 #008262;
        @include media-small {
            padding: 0 10px;
        }
        &__tab {
            font-size: $text-small;
            flex: 1 1 50%;
            max-width: 200px;
            text-align: center;
            padding: 12px 23px;
            display: block;
            background-color: rgba($white,.35);
            color: $white;
            border-radius: $radius-big $radius-big 0 0;
            margin:0 6px;
            line-height: $lh-tight;

            &[aria-selected] {
                background-color: $white;
                color: $plus-green-dark;
                font-weight: $fw-chubby;
            }
            &:not([aria-selected]):hover{
                background-color: rgba($white, 0.85);
                color: $color-brandBlue2020;
                box-shadow: 0 -5px 7px -2px rgba(0,0,0,0.3);
            }
            @include media-small {
                min-width: 150px;
            }
        }    
    }
    &__content{
        background-color: $white;
        z-index: 1;
        padding: 32px 40px;
        border-radius: $radius-big $radius-big 0 0;
        @include media-small {
          border-radius: unset;
        }
        position: relative;
        &:focus{
          outline: none;
        }
        &[hidden]{
          display: none;
        }
        &:before{
          content: '';
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          border-radius: $radius-big $radius-big 0 0;
          width: 100%;
          height: $radius-big;
          background-color: $white;
        }
      }
}

.groupdiscount-cont, .gd-cont {
    display: flex;
    flex-direction: column;
}
.gd-cont {
    margin-top: 0px !important;
}
.gd-login {
    font-weight: $fw-chubby;
    font-size: $text-slightly-big;
    color: $plus-green-dark;
    padding: 5px 5px 5px 0px;
    margin-bottom: 5px;
}
.gd-info {
    font-size: $text-base;
    line-height: $lh-base;
    padding: 5px 5px 5px 0px;
    margin-bottom: 10px;
    color: $sn-primary-paragraph;
}
.groupdiscount-signupbtn {
    button {
        background-color: #0066c4;
        width: 300px!important;
        box-shadow: none!important;
        &:hover, &:focus {
            background-color: #004371;
            box-shadow: unset !important;
        }
        &:disabled {
            opacity: 0.5;
        }
    }
}
.gd-checkbox-cont-termsprivacy, .gd-checkbox-cont-newsletter {
    margin-top: 16px;
}
.gd-checkbox {
    &:hover {
        .gd-checkbox-text {
            color: unset !important;
        }
    }
}
.gd-checkbox-text {
    font-size: $text-tiny;
    align-items: center;
    color: $sn-primary-paragraph;
    a {
        color: unset;
        text-decoration: underline;
        &:hover {
            color: #004371;
        }
    }
}
.gd-login-link, .gd-signup-link {
    color: #0066c4;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        color: #004371;
    }
}
.gd-bottom-login-link, .gd-bottom-signup-link {
    text-decoration: none !important;
    font-weight: $fw-fat;
}
.form--groupdiscountSignup, .form--groupdiscountSignin {
    .fe-field {
        label {
            .fe-label-text {
                color: $sn-primary-paragraph;
            }
            .fe-label-text[aria-invalid=true] {
                color: #c51c00;
            }
        }
    }
}
.groupdiscount-signinbtn {
    align-items: center;
    flex-direction: column;
    button:first-child {
        background-color: #0066c4;
        box-shadow: unset !important;
        width: 300px !important;
        &:hover, &:focus {
            background-color: #004371;
            box-shadow: unset !important;
        }
        &:disabled {
            opacity: 0.5;
        }
    }
    button:last-child {
        color: #0066c4;
        width: 300px !important;
        box-shadow: none!important;
        background-color: unset;
        &:hover, &:focus {
            color: #004371;
            background-color: unset !important;
            box-shadow: unset !important;
        }
    }
}
.gd-agree {
    color: $sn-primary-paragraph;
}
.please-text {
    @include media-small {
        display: none;
    }
}


//joining welcome and existing success screen
.join-success {
    &__thumb {
        position: relative;
        display: flex;
        justify-content: center;
        background-color: #008262;
        &::before {
            position: absolute;
            display: block;
            content: "";
            width: 100%;
            height: 60%;
            top: 41%;
            background-color: $white;
        }
        &__icon {
            animation: content .3s;
            width: 140px;
            height: 140px;
            border-radius: 50%;
            background-color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
        }
    }
    &__content {
        animation: content .3s;
        background-color: $white;
        padding: 10px 40px 56px 40px;
        &__welGroup {
            font-family: Raleway;
            font-size: $text-big;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: $lh-base;
            letter-spacing: normal;
            text-align: center;
            color: #008262;
        }
        &__message {
            font-family: Raleway;
            font-size: $text-base;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $lh-base;
            letter-spacing: normal;
            text-align: left;
            color: $sn-primary-paragraph;
            padding-top: 25px;
        }
        &__existing_message {
            font-family: Raleway;
            font-size: 16px;
            font-weight: $fw-chubby;
            font-stretch: normal;
            font-style: normal;
            line-height: $lh-base;
            letter-spacing: normal;
            text-align: left;
            color: $sn-primary-paragraph;
            padding-top: 25px;
        }
        &__go-to-dashboard {
            width: 300px;
            height: 46px;
            background-color: #0066c4;
            color: $white;
            font-size: $text-base;
            font-weight: $fw-chubby;
            box-shadow: unset;
            &:hover, &:focus {
                background-color: #004371;
                color: $white;
                box-shadow: unset;
            }
        }
        &__back-to-sparknotes {
            border: 2px solid #0066c4;
            color: #0066c4;
            width: 300px;
            height: 46px;
            font-size: $text-base;
            font-weight: $fw-chubby;
            margin-top: 20px;
            &:hover, &:focus {
                background-color: #004371;
                color: $white;
                box-shadow: unset;
                border: 2px solid #004371;
            }
          }
    }
}

.join .snplusmodal__title img {
    @include media-small {
        width: 100%;
    }
}

.join .snplusmodal__header__content {
    @include media-small {
        padding-left: 40px;
    }
}