@mixin l-media-bp1 {
  @media (max-width: 899px) {
    @content;
  }
}
@mixin l-media-bp2 {
  @media (max-width: 699px) {
    @content;
  }
}
@mixin l-media-bp3 {
  @media (min-width: 700px) and (max-width: 772px) {
    @content;
  }
}
@mixin l-media-bp4 {
  @media (min-width: 900px) and (max-width: 999px) {
    @content;
  }
}
@mixin l-media-bp5 {
  @media (min-width: 773px) and (max-width: 815px) {
    @content;
  }
}
.sparkteach-homepage__hero {
  max-width : 1164px;
  margin-left : auto;
  margin-right : auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: "all";
  max-height: 800px;
  position: relative;
  overflow: hidden;
  @include l-media-bp2{
    grid-template-rows: auto auto;
    grid-template-areas: "top" "bottom";
    overflow: initial;
  }
  &:after{
    content:'';
    position: absolute;
    display: block;
    width: 100%;
    bottom: -2px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 11vw;
    max-height: 200px;
    @include l-media-bp1{
      display: none;
    }
  }
  &__image{
    grid-area: all;
    grid-column: 2;
    &__img{
      display: block;
      float: right;
      width: auto;
      &__mobile{
        display: none;
      @include media-small{
        display: block; 
        margin-top: 5px;
        width: 100%;
      }
    }
    &__desktop{
      display: block;
      height: 100%;
      margin: 0 70px;
      width: 85%;
    @include media-small{
      display: none;
    }
    @include l-media-bp1{
      margin-top: 50px;
    }
    @include l-media-bp3{
      margin-top: 80px;
    }
    @include l-media-bp4{
      margin-top: 34px;
    }
    @include l-media-bp5{
      margin-top: 52px;
    }
  }
    }
    @include media-small{
      grid-area: bottom;
    }
  }
  &__image-mask{
    grid-area: all;
    align-self: flex-end;
    display: block;
    width: 100%;
  }
  &__text{
    grid-area: all;
    grid-column: 1;
    align-self: flex-start;
    justify-self: center;
    max-width: 400px;
    width: 100%;
    @include l-media-bp1{
      padding-bottom: 0;
    }
    @include media-small{
      grid-area: top;
      margin-top: 17px;
      padding-left: $pageMargin;
      padding-right: $pageMargin;
      padding-bottom: 36px;
      width: 100%;
    }
    &__container{
      width: 100%;
      @include l-media-bp2{
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; 
        margin-left: 0;       
      }
      @include media-small{
        margin-left: 0;
      }
      .header-logo{
            margin-bottom: 24px;
            margin-top: 40px;
            @include media-small {
                margin-bottom: 0;
                margin-top: -15px;
            }
            svg {
              height: 68px;
              width: 26.5vw;
              @include media-small{
                  height: auto;
                  margin-top: 40px;
                  margin-bottom: 24px;
                  width: 220px;
              }
          }

          &__image {
            display : flex;
          }
      }
    }
  }
  &__title{
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    align-items: center;
    &__intro{
      line-height: $lh-tight;
      font-size: $text-big-ish;
      font-weight: $fw-chubby;
      letter-spacing: 0.1em;
      @include media-under-1020{
        font-size: $text-slightly-big;
      }
      @include l-media-bp2{
        font-size: $text-base;
      }
    }
    &__main{
      display: flex;
      font-size: 80px;
      line-height: 1;
      margin-top: 12px;
      margin-bottom: 12px;
      @include media-under-1020{
        font-size: 60px;
      }
      @include l-media-bp2{
        font-size: 11vw;
      }
    }
    &__spark{
      letter-spacing: 0.035em;
      font-weight: $fw-fat;
      margin-right: 0.075em;
    }
    &__teach{
      letter-spacing: 0.05em;
      font-weight: $fw-slim;
      color: $color-teacher;
    }
  }
  &__strap{
    font-size: $text-slightly-big;
    line-height: $lh-base;
    color: $white;
    font-weight: $fw-base;
    @include media-small{
      color: $color-teacherSoftGray;
      font-size: $text-slightly-big !important;
      text-align: center;
    }
    @include media-notSmall {
      padding-left: 11%;
    }

    @include l-media-bp1{
      margin-top: 0;
      padding-bottom: 20px;
    }
    @include l-media-bp3{
      margin-top: 0;
      padding-bottom: 20px;
    }
    @include l-media-bp4{
      margin-top: 0;
      padding-bottom: 20px;
    }
    strong{
      font-weight: $fw-fat;
    }
  }
  &__ctas{
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-self: stretch;
  }
  &__cta{
    @include removeBtnStyles;
    padding: 12px 36px;
    border-radius: 50px;
    font-weight: $fw-chubby;
    box-shadow: 0 7px 9px -5px rgba(0,0,0,0.25);
    line-height: $lh-tight;
    font-size: $text-slightly-big;
    @include l-media-bp1{
      padding: 12px 16px;
      font-size: $text-base;
    }
    @include l-media-bp2{
      flex: 1;
      justify-content: center;
      max-width: 400px;
    }
    transition: transform 0.1s, box-shadow 0.1s;
    transform-origin: 50% 100%;
    &:hover,
    &:focus{
      transform: scale(1.1);
      box-shadow: 0 20px 14px -12px rgba(0,0,0,0.25);
    }
    &--watch-video{
      background-color: $white;
      color: $color-teacher;
      @include l-media-bp2{
        color: $white;
        background-color: $color-teacher;
      }
      text-transform: uppercase;
      display: flex;
      align-items: center;
      &:before{
        content:'';
        width: 24px;
        height: 24px;
        background-image: url('/images/SparkTeach-homepage-header-play-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 12px;
      }
    }
  }
}

.sparkteach-hero-header {
  background-color: $color-teacher;
  padding: 0 12px;
}