// @include media-under-1020
.bn-purchase-module {
  position: relative;
  padding-top: 12px;
  padding-bottom: 80px;
  padding-top: 40px;
  @include media-small {
    padding-top: 32px;
    padding-bottom: 40px;
  }
  &__umbrella__header {
    display: flex;
    @include media-small {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    &__icon {
      background-image: url(/images/icon-cart-72-purple.svg);
      width: 80px;
      height: 80px;
      flex: none;
      border: 2px solid #7a72cc;
      border-radius: 50%;
      margin-right: 12px;
      background-position: 50% 50%;
    }
  }
  &__title {
    font-size: $text-big;
    font-weight: $fw-chubby;
    line-height: $lh-tight;
    @include media-small {
      font-size: $text-big-ish;
      line-height: $lh-base;
    }
  }
  &__blurb {
    font-weight: $fw-base;
    font-size: $text-base;
    line-height: $lh-loose;
    color: $sn-primary-paragraph;
    @include media-small {
      line-height: $lh-tight;
    }
  }
  &__links {
    display: flex;
    padding-top: 24px;
    @include media-small {
      flex-direction: column;
      grid-gap: 16px;
      padding-top: 16px;
      display : grid;
    }
  }
  &__item {
    .bn-purchase-module & {
      padding: 0;
    }
    width: 193px;
    display: flex;
    align-items: stretch;
    @include media-small {
      width: 100%;
    }
  }
  &__link {
    height: 250px;
    width: 193px;
    position: relative;
    background-image: url("/images/buyon-background.png");
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    @include media-small {
      width: 100%;
      flex: 1;
      height: auto;
    }
    @include media-notSmall {
      margin-right : 24px;
    }
    &:focus,
    &:hover {
      box-shadow: 0 2px 20px 0 rgba($color: #000, $alpha: 0.21);
      cursor: pointer;
      -webkit-transform: scale(1);
      transform: scale(1);

      .bn-purchase-module & {
        text-decoration: none;
      } //overriding some too broad css. Won't be needed soon once other code is cleaned up.
    }
    &__book {
      width: 80px;
      margin-right: 12px;
      align-self: flex-start;
      flex: none;
      &__img {
        width: 100%;
        display: block;
      }
    }
    &__text {
      color: $white;
      line-height: $lh-tight;
      display: block;
      width: 100%;
      text-align: center;
      height: 74px;
      @include media-small {
        position : relative;
        display: flex;
        align-items: center;
        padding: 16px 0px 16px 10px;
        text-align: left;
        height: auto;
      }
      &__cartIcon {
        @include media-small {
          background-image: url(/images/icon-cart-32-purple.svg);
          width: 32px;
          height: 32px;
          display: block;
          float: right;
          background-color: white;
          border-radius: 50%;
          background-repeat: no-repeat;
          position : absolute;
          right : 20px;
        }
      }
    }
    &__title {
      .bn-purchase-module & {
        padding-bottom: 0;
      } //overriding some too broad css. Won't be needed soon once other code is cleaned up.
      color: $white;
      font-size: $text-base;
      font-weight: $fw-chubby;
      line-height: $lh-tight;
      @include media-small {
        display: flex;
        align-items: center;
        margin-right: 55px;
      }
    }
    &__descriptor {
      font-size: $text-base;
      font-weight: $fw-chubby;
      line-height: $lh-tight;
      .bn-purchase-module & {
        padding-bottom: 0;
      } //overriding some too broad css. Won't be needed soon once other code is cleaned up.
      color: inherit;
      @include media-small {
        display: flex;
        align-items: center;
        margin-right: 55px;
      }
    }
    &__pseudo-button {
      height: 40px;
      width: 100%;
      text-align: center;
      font-weight: $fw-fat;
      line-height: 3;
      color: $white;
      background: #0066c4;
      font-size: $text-tiny;
      border-radius: 0px 0px 5px 5px;
      text-transform: uppercase;
      &:hover {
        background:#004371;
      }
      @include media-small {
        display: none;
      }
    }
  }
  &__redirect {
    color: $sn-primary-header;
    border: 1px solid $sn-primary-header;
    border-radius: 5px;
    flex-wrap: wrap;
    height: 250px;
    width: 193px;
    display: flex;
    text-align: center;
    @include media-small {
      align-items: center;
      height: auto;
      width: 100%;
      position : relative;
    }
    &:hover {
      @include media-notSmall { 
        color: $sn-brand-blue;
        cursor: pointer;
        border: 1px solid $sn-brand-blue;
        svg {
          path {
            fill: $sn-brand-blue;
          }
        }
      }
    }
    &__text {
      height: 35px;
      margin: 100px auto 0 auto;
      font-size: $text-small;
      font-weight: $fw-base;
      line-height: $lh-tight;
      @include media-small {
        text-align: left;
        margin: 0;
        height: auto;
        padding: 16px 12px;
        font-size: $text-base;
        font-weight: $fw-chubby;
      }
    }
    &__icon {
      margin: 0 auto;
      width: 28px;
      height: 28px;
      margin-top: -30px;
      @include media-small {
        border-radius: 50%;
        border: 1px solid $sn-primary-header;
        position : absolute;
        right : 20px;
        margin-top: 0px;
      }
      &:hover {
        border: 1px solid $sn-brand-blue;
      }
      svg {
        @include media-small {
          margin-top: 0;
        }
      }
    }
  }
  &__container {
    padding: 8px 0 16px 0;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 0;
    @include media-small {
      display: none;
    }
    &__custom--image {
      padding-top: 0;
      background-image: var(--img);
      background-size: 100%;
      background-repeat: no-repeat;
      width: 193px;
      height: 120px;
      margin: 0;
      z-index: 0;
      position: relative;
      margin-top : -9px;
    }
    &__banner--image {
      background-image: var(--img);
      background-repeat: no-repeat;
      width: 176px;
      height: 96px;
      position: absolute;
      bottom: -10px;
      left: 9px;
      background-size: 100%;
    }
    &__image {
      background-image: var(--img);
      background-size: 75px 100%;
      background-repeat: no-repeat;
      width: 75px;
      height: 112px;
      z-index: 1;
      box-shadow: 0 10px 30px 0 rgba($color: #000000, $alpha: 0.5);
    }
    &__left--image {
      position: absolute;
      background-image: var(--img);
      background-size: 69px 100%;
      background-repeat: no-repeat;
      width: 69px;
      height: 103px;
      top: 12px;
      left: 12px;
      z-index: 0;
      box-shadow: 0 10px 30px 0 rgba($color: #000000, $alpha: 0.5);
    }
    &__right--image {
      position: absolute;
      background-image: var(--img);
      background-size: 69px 100%;
      background-repeat: no-repeat;
      width: 69px;
      height: 103px;
      top: 12px;
      right: 12px;
      z-index: 0;
      box-shadow: 0 10px 30px 0 rgba($color: #000000, $alpha: 0.5);
    }
  }
}
