// START: TEMP variables and other CSS
  // this section can be reomoved once rework title nav is merged
$color-brandBlue-2020: #007ACD;
$color-a11yGray: #767676;
$color-linkHover: #00ADEF;
html{
  scroll-behavior: smooth;
}
// END: TEMP variables and other CSS

$l-color-soft-bg: #F8F9FB;

@mixin teacherHome-sectionHeading($center: false){
  color: $color-teacher;
  font-weight: $fw-base;
  font-size: $text-extra-big;
  line-height: $lh-extra-tight;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  
  text-align: left;
  @if $center == true {
    align-items: center;  
    text-align: center;
  }
  @media (max-width: 640px) {
    font-size: $text-big;
    font-weight: $fw-chubby;
  }  
}

@import "hero.scss";
@import "title-tiles.scss";
@import "preorder-titles.scss";
@import "coming-soon.scss";
@import "email-form.scss";
@import "included-lens.scss";
@import "print-promo.scss";