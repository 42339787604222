@import 'headings/right-rail-heading';
.most-viewed-pages{
  &__heading{
    @include right-rail-heading;
  }

  &__link{
    display: flex;
    align-items: center;
    padding: $s-px * 2;
    border-radius: $radius-base;
    &:hover{
      background-color:$color-teacherSoftGray;

    }    
    &__icon{
      $size: $s-px * 5;
      width: $size;
      height: $size;
      border-radius: 50%;
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: $s-px;
      border: 1px solid $color-brandBlue2020;
      .most-viewed-pages__link:hover &{
        border: 1px solid #004371;
        -webkit-filter: invert(41%) sepia(78%) saturate(3853%) hue-rotate(182deg) brightness(45%) contrast(107%);
        filter: invert(41%) sepia(78%) saturate(3853%) hue-rotate(182deg) brightness(45%) contrast(107%);
        path{
          fill: $color-linkHover;
        }
      }
    }
    &__title{
      font-weight:$fw-chubby;
      line-height:$lh-tight;
      color: $color-brandBlue2020;
      .most-viewed-pages__link:hover &{
        color: #004371;
      }
    }
    &__label{
      display: block;
      font-size:$text-tiny;
      font-weight:$fw-chubby;
      color:$color-a11yGray;
      margin-top: 4px;
    }
    &__name{
    }
  }
}