.slide-up-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  z-index: 11; // one more than mobile slideout (reduce this when we refactor that slideout)
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($sn-primary-header, 0.75);
    z-index: -1;
  }
}

.slide-up-modal__container {
  width: 100%;
  border-top-left-radius: $radius-big;
  border-top-right-radius: $radius-big;
  max-height: calc(100vh - 64px);
  box-shadow: 0 -10px 30px 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.slide-up-modal__header {
  display: flex;
  flex: none;
  border-bottom: 1px solid $sn-border-gray;
  &__container {
    justify-content: center;
    @include container($padding: true);
    display: flex;
  }
  &__content {
    flex: 1;
    display: flex;
    align-items: center;
    max-width: calc(#{$sn-max-width} - 60px);
  }
  &--loading-bar {
    position: relative;
    &:after {
      animation: loader-animation 2s infinite;
      transform-origin: left;
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 6px;
      background: $color-linkHover;
      bottom: -1px;
      left: 0;
    }
  }
}

.slide-up-modal__title {
  font-weight: $fw-fat;
  font-size: $text-base;
  line-height: $lh-tight;
  margin-right: 12px;
}

.slide-up-modal__close {
  @include removeBtnStyles;
  width: 36px;
  height: 36px;
  margin: 12px 0 12px 12px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  path {
    fill: $sn-primary-header;
  }
  &:hover,
  &:focus {
    path {
      fill: $color-linkHover;
    }
  }
}

.slide-up-modal__content {
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  &__container {
    @include container;
    @include scrollShadows;
  }
}

.slide-up-modal__step {
  display: flex;
  align-items: center;
  font-weight: $fw-chubby;
  color: $sn-primary-header;
  padding: 8px;
  &--prev {
    svg {
      @include media-notSmall {
        margin-right: 8px;
      }
    }
    &[hidden] {
      display: none;
    }
  }
  &--next {
    @include media-notSmall {
      margin-left: 24px;
    }
    svg {
      transform: rotate(180deg);
      @include media-notSmall {
        margin-left: 8px;
      }
    }
    &[hidden] {
      display: none;
    }
  }
  &--inactive {
    opacity: 0.5;
    pointer-events: none;
    &:hover,
    &:focus {
      color: $sn-primary-header !important;
      path {
        fill: $sn-primary-header !important;
      }
    }
  }
  &:hover,
  &:focus {
    color: $color-linkHover;
    path {
      fill: $color-linkHover;
    }
  }
  &__text {
    @include media-small {
      @include visuallyhidden;
    }
  }
}

.slide-up-modal {
  display: none;
}
.slide-up-modal.is-open {
  display: block;
}

.slide-up-modal .slide-up-modal__container,
.slide-up-modal .slide-up-modal__overlay {
  will-change: transform;
}

.slide-up-modal[aria-hidden="false"]:not(.loads-open) .slide-up-modal__overlay:after {
  animation: modalFadeIn 0.12s cubic-bezier(0, 0, 0.2, 1);
}

.slide-up-modal[aria-hidden="false"]:not(.loads-open) .slide-up-modal__container {
  @include media-small {
    animation: modalSlideUpIn 0.4s ease-out;
  }
  @include media-notSmall {
    animation: modalSlideUpIn 0.4s ease-out;
  }
}

.slide-up-modal[aria-hidden="true"] .slide-up-modal__overlay:after {
  animation: modalFadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.slide-up-modal[aria-hidden="true"] .slide-up-modal__container {
  @include media-small {
    animation: modalSlideDownOut 0.4s ease-in;
  }
  @include media-notSmall {
    animation: modalSlideDownOut 0.4s ease-in;
  }
}
