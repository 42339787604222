.hub-AZ-list{
  &__section{
    @include media-small{
      &:first-child {
        padding-top: unset
      }
    }
    @at-root :root{
      --atozHeight: 48px;
    }
    padding-top: 48px;
    padding-top: var(--atozHeight);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @include media-small{
      margin-left: -$pageMargin;
      margin-right: -$pageMargin; 
    }
    &:before{
      content: '';
      position: absolute;
      display: block;
      height: 32px;
      background-color: $white;
      border-radius: 12px 12px 0 0;
      box-shadow: 0 -6px 5px rgba(0,0,0, 0.05);
      width: 100%;
      top: calc( var(--atozHeight) + 24px );
      z-index: -1;
    }
    &:target{
      .hub-AZ-list__section__title{
        background-color: $color-brandBlue2020;
      }
    }
    &__redesign {
      &::before {
        content: unset;
      }
    }
    &--sentinel{
      height: 1px;
    }
    &__title{
      width: 48px;
      height: 48px;
      background-color: $sn-primary-header;
      color: $white;
      border-radius: 50%;
      font-size: $text-big-ish;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      &__redesign {
        width: 100%;
        background-color: #cce4f5;
        border-radius: 10px;
        color: $sn-primary-header;
        margin-bottom: 16px;
      }
    }
    &__list{
      flex: 1;
      align-self: stretch;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
      grid-gap: 24px;
      padding: 0 12px;
      &--byAuthor{
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      }
      &__redesign {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex: unset;
        align-self: stretch;
        padding: unset;
        column-gap: 20px;
        row-gap: 16px;
        @include media-small {
          column-gap: 12px !important;
          justify-content: center;
        }

      }
    }
  }
  &__card{
    background-color: $color-teacherSoftGray;
    padding: 12px;
    border-radius: $radius-base;
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    position: relative;
    will-change: transform;
    &--byTitle:hover{
      box-shadow: 0 8px 16px 0 rgba(0,0,0,.2), inset 0 3px 0 0 $color-linkHover;
      transform: translateY(-2px);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &__title{
      font-size: $text-base;
      font-weight: $fw-chubby;
      line-height: $lh-tight;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      &__redesign {
        flex: unset;
        padding: 24px 12px 0;
      }
      &__link{
        display: block;
        &__redesign {
          font-size: $text-big-ish;
          font-weight: $fw-chubby;
          line-height: $lh-base;
          color: $white !important;
        }
        &--full-card-link{
          color: $color-brandBlue2020-onSoftGray;
          flex: 1;
          &:after{
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
          &:hover{
            color: $color-linkHover;
          }
        }
        &--author{
          color: $white;
          background-color: $color-brandBlue2020;
          line-height: $lh-tight;
          text-decoration: none;
          padding: 2px 16px;
          border-radius: 50px;
          &:hover{
            background-color: $color-linkHover;
          }
        }
      }
    }
    &__secondary{
      margin-top: 4px;
      color: $color-a11yGray-onSoftGray;
      font-size: $text-small;
      align-self: start;
      // background-color: #fff;
      &--link{
        margin-top: 8px;
        z-index: 1;
        display: block;
        background-color: $white;
        color: $color-brandBlue2020;
        line-height: $lh-tight;
        text-decoration: none;
        padding: 2px 8px;
        border-radius: 50px;
        // transition: background .2s;
        &:hover,
        &:focus{
            background-color: $color-brandBlue2020;
            color: $white;
        }

      }
      &__redesign {
        padding: 0 12px;
        background-color: unset;
        color: $white;
        font-size: $text-small;
        line-height: $lh-base;
        &:hover{
          background-color: unset;
        }
      }
    }
    &__header{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      &__icon{
        display: block;
        width: 80px;
        height: 80px;
        border: 2px solid $color-brandBlue2020;
        border-radius: 50%;
        margin-bottom: 12px;
        overflow: hidden;
        background-image: url(/images/icon-author-72-brandBlue.svg);
        background-size: 56px;
        background-repeat: no-repeat;
        background-position: 50%;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &__content-list{
      &__link{
        margin-top: 8px;
        z-index: 1;
        display: block;
        color: $color-brandBlue2020-onSoftGray;
        line-height: $lh-tight;
        text-decoration: none;
        padding: 8px;
        border-radius: $radius-base;
        text-align: center;
        &:hover{
        color: $white;
        background-color: $color-brandBlue2020;
        }
      }
    }
    &__redesign {
      height: 231px;
      width: 165px;
      padding: unset;   
      &:nth-child(3n+1) {
        background-color: #0066c4;
      }
      &:nth-child(3n+2) {
        background-color: #0057a7;
      }
      &:nth-child(3n+3) {
        background-color: #004789;
      }
      &:hover {
        background-color: #004371;
        box-shadow: 0 0 20px 0 rgba(0, 67, 113, 0.4);
        transform: unset;
      }
      &__image {
        width: 83px;
        height: 113.1px;
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0.1;
        z-index: -1;
      }
      &__nofear-audio {
        &__container {
          position: absolute;
          height: 48px;
          width: 48px;
          overflow: hidden;
          background-color: transparent;
          bottom: 0;
          right: 0;

          &:before {
            content: '';
            position: absolute;
            left: 20%;
            width: 100%; 
            height: 200%;
            background-color: #e1faef;
            top: 0;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        &__image {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}

.is-docked.pw-additional-top {
  @include media-small {
    margin-top: 31% !important;
  }
}

.hideSection {
  display: none;
}

.hub_plus_content {
  position: absolute;
  top: -2px;
  left: -5px;
}

.titleRedesignHub {
  &__topTitlesGuide {
    padding-top: 12px;

    &__header {
      font-size: $text-big;
      line-height: $lh-base;
      font-weight: $fw-chubby;
      color: $sn-primary-header;
    }

    &__containerDesktop {
      display: block;
      padding-top: 24px;
      overflow-y: scroll;
      height: 311px;
      @include media-small {
        display: none;
      }
    }

    &__containerMobile {
      display: none;
      padding-top: 24px;
      @include media-small {
        display: block;
      }
    }
  }
  &__helpfulResources {
    max-width: 723px;
    margin: 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    padding: 0;

    &__header {
      font-size: $text-big;
      font-weight: $fw-chubby;
      line-height: $lh-base;
      text-align: left;
      color: $sn-primary-header;
    }

    &__container {
      max-width: 723px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex: unset;
      align-self: stretch;
      padding: unset;
      column-gap: 20px;
      row-gap: 16px;
      align-items: flex-start;
      gap: 16px;

      @media only screen and (min-width: 700px) and (max-width: 800px) {
        justify-content: space-around;
      }
      @media only screen and (min-width: 700px) and (max-width: 730px) {
        flex-wrap: wrap;
      }
      
      @include media-small {
        flex-direction: column;
      }
    }

    &__content {
      display: flex;

      &:hover {
        .titleRedesignHub__helpfulResources__content__image__container {
          border-color:#004371;
        }

        .titleRedesignHub__helpfulResources__content__text {
          background-color: #004371;
          color: $white;
        }
      }

      @include media-small {
        max-width: 699px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__image {
        width: 80px;
        height: 80px;
        border-radius: 12px 0 0 12px;

        &__container {
          display: flex;
          border-right: 0;
          border: solid 1px #cce4f5;
          border-radius: 12px 0 0 12px;
        }
      }

      &__text {
        padding: 8px 12px;
        background-color: #cce5f5;
        color: $sn-primary-header;
        font-weight: $fw-chubby;
        line-height: $lh-base;
        display: flex;
        align-items: center;
        width: 268px;
        border-radius: 0 12px 12px 0;

          @include media-small {
            width: 100%;
           height: 80px;

            &:focus-within {
              background-color: #004371;
              color: #fff;
             }
          }
      }
    }
  }

  &__backToTop {
    &__container {
      width: 162px;
      height: 46px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: solid 2px $color-brandBlue2020;
      background-color: $white;
      float: right;
      cursor: pointer;
      margin: 32px 0 30px 0;
      @include media-small {
        margin: 24px 0 23px 0;
      }
    }

    &__text {
      font-size: $text-base;
      font-weight: $fw-chubby;
      letter-spacing: 0.13px;
      text-align: center;
      color: $color-brandBlue2020;
    }
  }
}

.titleRedesignHub-hub-container {
  .filter-section {
    @include media-small {
      display: none;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__sort-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      &__sort-text {
        font-family: Raleway;
        font-size: $text-slightly-big;
        font-weight: $fw-chubby;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        text-align: left;
        color: $sn-primary-header;
      }
      &__title-list {
        font-family: Raleway;
        font-size: $text-base;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        text-align: left;
        color: $sn-primary-header;
        padding-left: 6px;
        padding-top: 1px;
      }
      &__sort-dropdown {
        padding-left: 6px;
        padding-top: 1px;
      }
    }
  }

  .show-sort {
    width: 147px;
    display: none;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: $white;
    position: absolute;
    right: 0px;
    top: 30px;
    z-index: 1;
    padding: 16px 14px 16px 14px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0px 0px 12px 12px;
    padding-top: 20px;
    &__active-list {
      display: flex;
      width: 100%;
      font-family: Raleway;
      font-size: $text-base;
      font-weight: $fw-chubby;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.59;
      letter-spacing: normal;
      text-align: left;
      color: $sn-primary-header;
      padding: 4px 2px 4px 2px;
      &:hover, &:focus {
        background-color: #eeeeee;
      }
      &__img {
        display: flex;
        align-items: center;
        padding-right: 8px;
      }
    }
    &__unactive-list {
      display: flex;
      width: 100%;
      font-family: Raleway;
      font-size: $text-base;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.59;
      letter-spacing: normal;
      text-align: left;
      color: $sn-primary-header;
      padding: 4px 2px 4px 2px;
      cursor: pointer;
      &:hover, &:focus {
        background-color: #eeeeee;
      }
      &__img {
        display: flex;
        align-items: center;
        padding-right: 8px;
        svg {
          path{
            fill: none;
          }
        }
      }
    }
  }

  .hubPage__section__heading {
    display: none;
  }

  .count-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
    @include media-small {
      margin-bottom: 24px; 
    }
    &__text {
      font-family: Raleway;
      font-size: $text-small;
      font-weight: $fw-chubby;
      font-stretch: normal;
      font-style: normal;
      line-height: $lh-base;
      letter-spacing: normal;
      text-align: center;
      color: $sn-primary-paragraph;
    }
  }
}

.showMoreSortList {
  display: flex !important;
}

.rotateSVG {
  svg {
    transform: rotate(180deg);
  }
}

//redesign title hub update for filter for genre (Subject) type
.filter-section__subject-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  &__subject-text {
    font-size: $text-slightly-big;
    font-weight: $fw-chubby;
    color: $sn-primary-header;
    line-height: $lh-base;
  }
  
  &__title-list{
    font-size: $text-base;
    padding-left: 6px;
    padding-right: 6px;
    line-height: $lh-base;
  }

  &__subject-dropdown {
    padding-top: 2px;
  }
}

.show-subject {
  display: none;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  position: absolute;
  gap: 2rem;
  z-index: 10;
  left: 0;
  padding: 20px 16px;
  transform: translateY(10px);
  box-shadow: 0 4px 4px 0 rgba(0,0,0,.2);
  border-radius: 0 0 12px 12px;
  margin-top: 12px;
}

.showMoreSubjectList {
  display: flex !important;
}

.rotateSVG2 {
  svg {
    transform: rotate(180deg);
  }
}

.show-subject__unactive-list__section {
  font-size: $text-slightly-big;
  font-weight: $fw-chubby;
  color: $sn-primary-header;
}

.show-subject__unactive-list__img {
  font-size: $text-base;
  color: $sn-primary-header;
}

.show-subject__unactive-list {
  padding: 8px 19px 8px 0px;
  cursor: pointer;

      &:hover, &:focus {
        background-color: #eeeeee;
      }

      &__img {
        svg {
          path{
            fill: none;
          }
        }
      }
}

.show-subject__unactive-list-bold-text {
  padding: 12px 0px 12px;
  font-size: $text-slightly-big;
  font-weight: $fw-chubby;
}

.show-subject__active-list__text {
  font-size: $text-base;
  font-weight: $fw-chubby;
  line-height: $lh-base;
  color: $sn-primary-header;
}

.show-subject__active-list__img {
  font-size: $text-base;
  color: $sn-primary-header;
}

.show-subject__active-list {
  padding: 8px 19px 8px 0px;

  &:hover {
    background-color: #eeeeee;
  }
}

//redesign title hub update for filter
.all_guides {
  margin: 0px 0 8px 0;
  &__title {
      color: $sn-primary-header;
      font-size: $text-big;
      font-weight: $fw-chubby;
      line-height: $lh-base;
      margin-bottom: 24px;
  }

  &__title-new{
    margin-bottom: 16px;
    font-size: $text-big;
  }

  &__description{
    margin-bottom: 30px !important;
    font-size: $text-slightly-big;
    line-height: $lh-base;
    color: $sn-primary-header;
    max-width: 723px;
  }

  &__count {
    margin-bottom: 24px;
    padding-top: 30px;
    &__text {
      font-size: $text-small;
      font-weight: 700;
      line-height: $lh-base;
      color: $sn-primary-paragraph;
    }
  }
  
  .show_all_guides {
    color: #5e57ad;
    border: 1px solid #5e57ad;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .show-shakespeare {
    width: 156px;
  }

  .show_lit { 
    width: 132px;
  }

  .show-shakespeare,.show_lit {
    color: #5e57ad;
    border: 1px solid #5e57ad;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__content-types {
    @include media-small {
      display: none;
    }
    display: flex;
    flex-direction: row;
    padding-right: 89px;
    flex-wrap: wrap;

    &__name {
      width: auto;
      margin-right: 18px;
      padding: 7px 12px 7px 8px;
      height: 40px;
      border-radius: 10px;
      border: 1px solid #0066c4;
      margin-bottom: 16px;
      line-height: $lh-base;
      font-size: $text-base;
      font-weight: $fw-chubby;
      color: #0066c4;
      cursor: pointer;

      &:hover {
        background: #004371 !important;
        border-color: #004371 !important;
        color: $white !important;
        .all_guides__content-types__hover-img {
          display: inline;
        }
        .all_guides__content-types__img {
          display: none;
          height: 32px;
          width: 32px;
        }
      }
    }
    &__img {
      margin-right: 3px;
    }
    &__hover-img {
      margin-right: 3px;
      display: none;
    }
    .show_lit {
      &__hover-img { 
        width: 32px !important;
        height: 32px !important;
      }
    }
  }

  &__sub_content-types {
    @include media-small {
      display: none;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &__name {
      width: auto;
      margin-right: 16px;
      padding: 4px 12px;
      height: 34px;
      border-radius: 10px;
      border: 1px solid #0066c4;
      margin-bottom: 16px;
      line-height: $lh-base;
      font-size: $text-base;
      font-weight: $fw-chubby;
      color: #0066c4;
      cursor: pointer;
      &:hover {
        background: #004371;
        border-color: #004371;
        color: $white;
      }
    }
    &__img {
      margin-right: 3px;
    }
  }
  
}

.activeGuide {
  background: #5e57ad !important;
  color: $white !important;
  border: 1px solid #5e57ad !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  .all_guides__content-types__hover-img {
    margin-right: 3px;
    display: inline;
  }
  .all_guides__content-types__img {
    margin-right: 3px;
    display: none;
  }
}

li.active_content_type {
  background: #0066c4 !important;
  color: $white !important;
  border: 1px solid #0066c4 !important;
}

.hideParentTitle {
  display: none;
}

.z-a-data {
  display: flex;
  flex-direction: column-reverse;
}

li.disabled_chip {
  border: 1px solid rgba(0, 102, 196, 0.5)!important;
  background: $white !important;
  color: rgba(0, 102, 196, 0.5) !important;
  user-select: none;
  pointer-events: none;
}

.disabled_genre {
  background: $white !important;
  color: #c5b6b6 !important;
  user-select: none;
  pointer-events: none;
  @include media-small {
    border: 1px solid rgba(0, 102, 196, 0.5) !important;
    color: rgba(0, 102, 196, 0.5) !important;
  }
}

.disabledtab {
  pointer-events: none;
  color: #c5b6b6 !important;
}

.show-subject-unactive-list-shakespere:first-child {
  pointer-events: all;
}

.titleRedesignHub__topTitlesGuide__containerMobile {
  
  .owl-carousel {
    z-index: unset !important;
  }
  .owl-carousel .owl-item img {
    display: unset;
    width: unset;
  }

  .owl-carousel .hub-AZ-list__section__list__redesign {
    flex-direction: unset;
    justify-content: unset;
    flex-wrap: unset;
    flex: unset;
    align-self: unset;
    padding: unset;
    grid-gap: unset;
  }

  .owl-dots {
    text-align: center;
    padding-top: 16px;
  }

  button.owl-dot {
    display: inline-block;
    background: none;
    color: inherit;
    border: none;
    padding-right: 8px !important;
    padding-block: unset;
    padding-inline: unset;
  }

  button.owl-dot span {
    width: 8px;
    height: 8px;
    background: $sn-border-gray;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms;
    border-radius: 30px;
  }
  
  button.owl-dot.active span {
    background-color: #0066c4;
  }

  .owl-item:last-child {
    margin-right: unset !important;
  }

  .owl-item:nth-child(3n+1) .hub-AZ-list__card__redesign {
    background-color: #0066c4;
  }

  .owl-item:nth-child(3n+2) .hub-AZ-list__card__redesign {
    background-color: #0057a7;
  }

  .owl-item:nth-child(3n+3) .hub-AZ-list__card__redesign {
    background-color: #004789;
  }

}

.mobile-all-guides {
  @include media-notSmall {
    display: none;
  }
  @include media-small {
    // display: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px 4px 8px;
    border-radius: 10px;
    border: solid 1px #5e57ad;
    background-color: $white;
    margin-bottom: 24px;
    &__hidden-icon {
      svg {
        path {
          fill: none;
        }
      }
    }
    &__filter {
      display: flex;
      justify-content: center;
      align-items: center;
      &__text {
        font-family: Raleway;
        font-size: $text-base;
        font-weight: $fw-chubby;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        text-align: center;
        color: #5e57ad;
        padding-bottom: 2px;
      }
    }
  }
}

.stickyBackToTopCTA {
  position: sticky !important;
  z-index: 3;
  right: 0;
  margin-right: 4px;
  bottom: 110px;
  @include media-small {
    bottom: 69px;
  }
  &__signedIn {
    bottom: 16px;
  }
}

.show-sorting-guides-overlay {
  @include media-small {
    top: 0;
    left: 0!important;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: block !important;
    z-index: 9;
    background-color: $white;
  }
}

.hubRedesign__mobile__sortFilterContainer {
  display: none;
}

@include media-small {
  .hubRedesign {
    &__mobile {
      &__sortFilterContainer {
        padding: 0 10px;
        
        &__clearAllCTA {
          font-size: $text-base;
          font-weight: $fw-chubby;
          line-height: $lh-base;
          color: #0066c4;
          border: unset;
          background-color: unset;
        }
        
        &__top {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0 16px;
        }
      }
  
      &__all-guides {
        &__content-types {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
  
          &__name {
            width: auto;
            margin: 0 12px 12px 0;
            padding: 7px 12px 7px 8px;
            height: 40px;
            border-radius: 10px;
            line-height: $lh-base;
            font-size: $text-base;
            font-weight: $fw-chubby;
  
            img {
              margin-right: 4px;
              &:last-child {
                display: none;
              }
            }
          }
        }
  
        &__sub_content-types {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 12px;
  
          &__name {
            width: auto;
            margin: 0 12px 12px 0;
            padding: 4px 12px;
            height: 34px;
            border-radius: 10px;
            border: 1px solid #0066c4 !important;
            line-height: $lh-base;
            font-size: $text-base;
            font-weight: $fw-chubby;
            color: #0066c4 !important;
          }
        }
      }

      &__subjectContainer {
        margin-top: 24px;

        &__header {
          font-size: $text-slightly-big;
          line-height: $lh-base;
          font-weight: $fw-chubby;
          color: $sn-primary-header;
        }

        &__subjectTypes {
          margin: 24px 0;

          &__all {
            width: 45px;
            padding: 4px 12px;
            height: 34px;
            border-radius: 10px;
            border: 1px solid #0066c4;
            line-height: $lh-base;
            font-size: $text-base;
            font-weight: $fw-chubby;
            color: #0066c4;
          }

          &__lit {
            &__header {
              color: $sn-primary-paragraph;
              font-size: $text-base;
              line-height: $lh-base;
              font-weight: $fw-chubby;
              margin-bottom: 16px;
            }

            &__content {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;

              &__types {
                width: auto;
                margin: 0 12px 12px 0;
                padding: 4px 12px;
                height: 34px;
                border-radius: 10px;
                border: 1px solid #0066c4;
                line-height: $lh-base;
                font-size: $text-base;
                font-weight: $fw-chubby;
                color: #0066c4;
              }
            }
          }

          &__shakespeare {
            &__header {
              color: $sn-primary-paragraph;
              font-size: $text-base;
              line-height: $lh-base;
              font-weight: $fw-chubby;
              margin-bottom: 16px;
            }

            &__content {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;

              &__types {
                width: auto;
                margin: 0 12px 12px 0;
                padding: 4px 12px;
                height: 34px;
                border-radius: 10px;
                border: 1px solid #0066c4;
                line-height: $lh-base;
                font-size: $text-base;
                font-weight: $fw-chubby;
                color: #0066c4;
              }
            }
          }

          &__sort {
            &__header {
              color: $sn-primary-header;
              font-size: $text-slightly-big;
              line-height: $lh-base;
              font-weight: $fw-chubby;
              margin: 24px 0 16px 0;
            }

            &__contentByTitle, &__contentByAuthor {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;

              &__types {
                width: auto;
                margin: 0 12px 16px 0;
                padding: 4px 12px;
                height: 34px;
                border-radius: 10px;
                border: 1px solid #0066c4;
                line-height: $lh-base;
                font-size: $text-base;
                font-weight: $fw-chubby;
                color: #0066c4;
              }
            }
          }
        }
      }

      &__applyCTA {
        position: sticky;
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        bottom: 16px;
        box-shadow: 10px 7px 10px 0px rgba(0,122,205,.35);
        height: 46px;
        padding: 16px 20px;
        border-radius: 4px;
        background-color: $color-brandBlue2020;
        margin: 15px 0px 16px;
        width: 97%;
        border: unset;

        &__text {
          font-size: $text-base;
          font-weight: $fw-chubby;
          letter-spacing: 0.13px;
          color: $white;
        }
      }
    }
  }
}

.show_all_guides_mobile, .show-shakespeare_mobile, .show_lit_mobile {
  @include media-small {
    color: #5e57ad;
    border: 1px solid #5e57ad;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.mobileActiveGuide, .mobileActiveSubjectFilter , .mobileActiveSortFilter {
  @include media-small {
    background: #5e57ad!important;
    color: $white!important;
    border: 1px solid #5e57ad!important;
    img {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: unset;
      }
    }
  }
}

.mobileActiveSubjectFilter, .mobileActiveSortFilter , .mobileActiveSortFilter{
  @include media-small {
    background: #0066c4!important;
    color: $white!important;
    border: 1px solid #0066c4!important;
  }
}

@include media-small {
  .hubRedesign__mobile__subjectContainer__subjectTypes__lit__content.disabledtab ,
  .hubRedesign__mobile__subjectContainer__subjectTypes__shakespeare__content.disabledtab {
    li {
      color: rgba(0, 102, 196, 0.5) !important;
      pointer-events: none;
      border-color: rgba(0, 102, 196, 0.5);
    }
  }
}

//related to chips css for guide section
.chips-container {
  display: none;
  @include media-small {
    display: none;
    align-items: center;
    padding-bottom: 16px;
    &__clear-all {
      padding-right: 14px;
      white-space: nowrap;
      &__text {
        font-family: Raleway;
        font-size: $text-base;
        font-weight: $fw-chubby;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        text-align: left;
        color: #0066c4;
      }
    }
    &__list {
      overflow-x: auto;
      margin-right: -9px;
      &::-webkit-scrollbar {
        display: none;
      }
      &__guide-list {
        display: flex;
        white-space: nowrap;
        &__guide-select {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 12px 4px 8px;
          border-radius: 10px;
          border: solid 1px #5e57ad;
          background-color: #5e57ad;
          color: $white;
          font-family: Raleway;
          font-size: $text-base;
          font-weight: $fw-chubby;
          font-stretch: normal;
          font-style: normal;
          line-height: $lh-base;
          letter-spacing: normal;
          text-align: left;
          &__guide-img{
            padding-right: 5px;
          }
          &__close-img {
            padding-left: 8px;
            padding-top: 1px;
          }
        }
        &__filter-select {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 12px 4px 13px;
          border-radius: 10px;
          border: solid 1px #0066c4;
          background-color: #0066c4;
          color: $white;
          font-family: Raleway;
          font-size: $text-base;
          font-weight: $fw-chubby;
          font-stretch: normal;
          font-style: normal;
          line-height: $lh-base;
          letter-spacing: normal;
          text-align: left;
          margin-left: 12px;
          &:first-child {
            margin-left: unset;
          }
          &__close-img {
            padding-left: 8px;
            padding-top: 1px;
          }
        }
      }
    }
  }
}

.show-chips {
  display: none;
  @include media-small {
    display: flex;
  }
}

#show-count-chips {
  font-family: Raleway;
  font-size: $text-base;
  font-weight: $fw-chubby;
  font-stretch: normal;
  font-style: normal;
  line-height: $lh-base;
  letter-spacing: normal;
  text-align: center;
  color: #5e57ad;
  padding-bottom: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.show-count-activeChips {
  @include media-small {
    display: block;
  }
}

.adjust-top-sort {
  margin-top: 4px;
  margin-bottom: 4px;
}

.adjust_margin_backToTop {
  @include media-small {
    margin-bottom: 25px!important;
    visibility: hidden !important;
  }
}

.content_types_filter {
  display: none;
}

.showHideAlphabetSeperation {
  display: block !important;
}

.hub-AZ-list.remove_section_alphabet .hub-AZ-list__section {
  display: none;
}

.remove_section_alphabet {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex: unset;
    align-self: stretch;
    padding: unset;
    -moz-column-gap: 20px;
    column-gap: 20px;
    row-gap: 16px;
    padding-top: var(--atozHeight);
    @include media-small {
      column-gap: 12px;
      justify-content: center;
      padding-top: 0;
    }
}

.titleRedesignHub-hub-container__signedin {
  .remove_section_alphabet {
    @include media-notSmall {
      column-gap: 14.7px;
    }
  }
}

.hide-bottomStickyAd {
  display: none;
}

.redesign-top-titles {
  @include media-notSmall {
    display: grid;  
    grid-template-columns: repeat(5, 1fr);
    padding: unset;
    column-gap: 14.7px;
    row-gap: 16px;
    align-items: stretch;
  }
}

//shakespeare hub redesign css//
.hub_description {
    font-weight: $fw-base;
    font-size: $text-slightly-big !important;
    line-height: $lh-base !important;
    color: $sn-primary-header !important;
    margin-bottom: 60px;
}

.guest-container {
  max-width: 723px;
}

.signedIn_container {
  max-width: 884px;
  // .looking_all_shakespeare {
  //   gap: 168px;
  // }
}

.no-fear-shakespeare-thumb.signedIn_container_thumb {
  column-gap: 14.7px;
  @media only screen and (max-width: 768px) {  
    column-gap: 13px;
    margin-left: -4px;
    margin-right: -3px;
    justify-content: center;
  }
}

.signedIn_container .no-fear-shakespeare-thumb {
  column-gap: 14.7px;
}

.titleRedesignHub-hub-container-shakespeare {
  margin-top: 48px !important;
  padding-top: 0;
}

.looking_all_shakespeare {
  height: 62px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
  padding: 20px 24px;
  border-radius: 20px;
  box-shadow: 0 0 7px 0 rgba(0, 102, 196, 0.25);
  background-color: rgba(222, 240, 245, 0.65);
  margin-bottom: 60px;

  @media only screen and (max-width: 768px) {  
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 12px 24px;
  }

  &__description {
    font-size: $text-base;
    font-weight: $fw-chubby;
    line-height: $lh-base;
    color: $sn-black;
    @media only screen and (max-width: 768px) {  
      font-size: 16px;
      text-align: center;
    }
  }

  &__anchor {
    border: 2px solid #0066c4;
    padding: 4.5px 19px;
    color: #0066c4;
    font-size: $text-base;
    height: 38px;
    font-weight: $fw-chubby;
    line-height: $lh-base;
    background: $white;
    width: 164px;
    border-radius: 4px;
    &:hover,&:focus {
      background-color: #004371;
      color: $white;
      border: 2px solid #004371;
      border-radius: 4px;
    }

    @media only screen and (max-width: 768px) {  
      //padding: 4.5px 12px;
      font-size: 15px;
      padding-left: 23px;
    }
  }
}

.no-fear-shakespeare-thumb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: unset;
  align-self: stretch;
  padding: unset;
  -moz-column-gap: 20px;
  column-gap: 20px;
  row-gap: 16px;
  margin-top: 10px;

  @include media-small {
    margin-top: 24px;
  }

  @media only screen and (max-width: 768px) {  
    column-gap: 13px;
    margin-left: -4px;
    margin-right: -3px;
    justify-content: center;
  }
  
  &__thumblist {
    &:hover,&:focus {
      box-shadow: 0 0 20px 0 rgba(0, 67, 113, 0.4);
      border-radius : 4px;
      .no-fear-shakespeare-thumb__text {
        background-color: #004371;
      }
    }
  }

  &__thumblist {
    height: 231px;
    width: 165px;
    padding: unset;
    position: relative;
  }

  &__section {
    display: flex;
    flex-direction: column;
  }

  &__text {
    height: 91px;
    background-color: #008262;
    color: $white;
    padding: 8px 12px 8px 12px;
    line-height: $lh-base;
    font-size: $text-slightly-big;
    font-weight: $fw-chubby;
    border-radius: 4px 4px 0px 0px;
  }

  &__thumb{
    &__image {
      height: 140px;
      width: 165px;
      border-radius: 0 0 4px 4px;
    }
  }

  .audio-triangle {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 184px !important;
    right: -1px;
  }
}

.hub-header--shakespeare.ShakespeareRedesignHubHeader {
  background-color: #008262 !important;
  .hub-header__image__img {
    height: 254px;
  }   
}

.all_shakespeare_guide_link {
  margin-top: 73.5px;
  text-align: center;
  &__lit-hub {
    border: 2px solid #0066c4;
    padding: 13.5px 20px;
    font-size: $text-base;
    font-weight: $fw-chubby;
    line-height: $lh-base;
    border-radius: 4px;
    &:hover,&:focus {
      color: $white;
      background: #004371;
      border: 2px solid #004371;
    }
  }
}

.shakespeare-filter-section {
  display: flex;
  justify-content: space-between;
  position: relative;

  &__subject-container{
    display: flex;
    align-items: center;
    cursor: pointer;

    &__subject-text {
      font-size: $text-slightly-big;
      font-weight: $fw-chubby;
      line-height: $lh-base;
      letter-spacing: normal;
      color:$sn-primary-header;
    }

    &__title-list {
      padding-left: 6px;
    }
  }

  @include media-small {
    display: none;
  }

}

.shakespeare-subject-section-expend {
  display: none;
  position: absolute;
  margin:  10px 0px 63px 0px;
  padding: 16px;
  z-index: 5;
  top: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.shakespeare-show-subject__active-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 4px 19px 4px 0;
  font-size: $text-base;
  font-weight:$fw-chubby;
  line-height: 1.59;
  color: $sn-primary-header;
}

.shakespeare-show-subject__unactive-list{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 4px 19px 4px 0;
  line-height: 1.59;
  font-size: $text-base;

  &__img {
    svg {
      path {
        fill: none;
      }
    }
  }
}

.shakespeare-show-subject__unactive-list:hover{
  padding: 4px 19px 4px 0;
  background-color: #eeeeee;
  cursor: pointer;
}

.shakespeare-show-subject__active-list:hover{
  padding: 4px 19px 4px 0;
  background-color: #eeeeee;
}

.shakespeare-sort-section-expend {
  display: none;
  position: absolute;
  margin-top: 10px;
  padding: 16px;
  z-index: 5;
  right: 0;
  top: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.shakespeare-filter-section__sort-container{
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

    &__sort-text{
      font-size: $text-slightly-big;
      font-weight: $fw-chubby;
      line-height: $lh-base;
      letter-spacing: normal;
      color:$sn-primary-header;
    }

    &__title-list {
      padding-left: 6px;
    }

    &__sort-dropdown {
      display: flex;
      align-items: center;
      padding-left: 6px;
    }
}

.shakespeare-show-sort__active-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 4px 19px 4px 0;
  font-size: $text-base;
  font-weight: $fw-chubby;
  line-height: 1.59;
  color: $sn-primary-header;
}

.shakespeare-show-sort__unactive-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 4px 19px 4px 0;
  line-height: 1.59;
  font-size:$text-base;
  &__img {
    svg {
      path {
        fill: none;
      }
    }
  }
}

.shakespeare-show-sort__unactive-list:hover {
  padding: 4px 19px 4px 0;
  background-color: #eeeeee;
  cursor: pointer;
}

.shakespeare-show-sort__active-list:hover {
  padding: 4px 19px 4px 0;
  background-color: #eeeeee;
}

.showShakespeareSubject {
  display: block
}

.showShakespeareSort {
  display: block;
}

.desktop-slogan {
  @include media-small{
    display: none;
  }
}

.mobile-slogan {
  @include media-notSmall{
    display: none;
  }
}

.hub-header__image__img-mobile {
  @media only screen and (max-width: 600px) {  
    height: unset !important;
    display: flex;
  }
}

@media only screen and (max-width: 599px) {  
  .hub-header__image__img-desktop {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {  
  .hub-header__image__img-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 599px) {  
  .all_shakespeare_guide_link {
    border: 2px solid #0066c4;
    padding: 13.5px 0;
    border-radius: 4px;
  }
  .all_shakespeare_guide_link:hover {
    color: $white;
    background: #004371;
    border: 2px solid #004371;
  }
  .all_shakespeare_guide_link__lit-hub {
    line-height: normal;
    padding: 0;
    letter-spacing: 0.13px;
    border: none;
  }
}

//mobile modal css for sorting of shakespeare hub
.mobile-all-shakespeare-guides {
  @include media-notSmall {
    display: none;
  }
  @include media-small {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px 4px 8px;
    border-radius: 10px;
    border: solid 1px #5e57ad;
    background-color: $white;
    cursor: pointer;

    &__hidden-icon {
      svg {
        path {
          fill: none;
        }
      }
    }
    &__filter {
      display: flex;
      justify-content: center;
      align-items: center;
      &__text {
        font-size: $text-base;
        font-weight: $fw-chubby;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        text-align: center;
        color: #5e57ad;
        padding-bottom: 3px;
        padding-top: 2px;
      }
    }
  }
}

.hubRedesign_shakespeare__mobile__sortFilterContainer {
  display: none;
}

@include media-small {
  .hubRedesign_shakespeare {
    &__mobile {
      &__sortFilterContainer {
        padding: 0 10px;
        
        &__clearAllCTA {
          font-size: $text-base;
          font-weight: $fw-chubby;
          line-height: $lh-base;
          color: #0066c4;
          border: unset;
          background-color: unset;
          padding-left : 0;
          &:hover,&:focus {
            color: #004371;
          }
        }
        
        &__top {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0 16px;
        }

        &__close {
          circle {
            fill: $sn-primary-header;
          }
          &:hover,&:focus {
            circle {
              fill: $white;
            }
            path {
              fill: $sn-primary-header;
            }
          }
        }
      }
  
      &__all-guides {
        &__content-types {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
  
          &__name {
            width: auto;
            margin: 0 12px 12px 0;
            padding: 7px 12px 7px 8px;
            height: 40px;
            border-radius: 10px;
            line-height: $lh-base;
            font-size: $text-base;
            font-weight: $fw-chubby;
  
            img {
              margin-right: 4px;
              &:last-child {
                display: none;
              }
            }
          }
        }
  
        &__sub_content-types {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 12px;
  
          &__name {
            width: auto;
            margin: 0 12px 12px 0;
            padding: 4px 12px;
            height: 34px;
            border-radius: 10px;
            border: 1px solid #0066c4 !important;
            line-height: $lh-base;
            font-size: $text-base;
            font-weight: $fw-chubby;
            color: #0066c4 !important;
          }
        }
      }

      &__subjectContainer {
        margin-top: 24px;

        &__header {
          font-size: $text-slightly-big;
          line-height: $lh-base;
          font-weight: $fw-chubby;
          color: $sn-primary-header;
        }

        &__subjectTypes {
          margin: 24px 0;

          &__all {
            width: 45px;
            padding: 4px 12px;
            height: 34px;
            border-radius: 10px;
            border: 1px solid #0066c4;
            line-height: $lh-base;
            font-size: $text-base;
            font-weight: $fw-chubby;
            color: #0066c4;
            cursor: pointer;
            &:hover,&:focus {
              color: $white;
              border: 1px solid #004371;
              background-color: #004371;
            }
          }

          &__lit {
            &__header {
              color: $sn-primary-paragraph;
              font-size: $text-base;
              line-height: $lh-base;
              font-weight: $fw-chubby;
              margin-bottom: 16px;
            }

            &__content {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;

              &__types {
                width: auto;
                margin: 0 12px 12px 0;
                padding: 4px 12px;
                height: 34px;
                border-radius: 10px;
                border: 1px solid #0066c4;
                line-height: $lh-base;
                font-size: $text-base;
                font-weight: $fw-chubby;
                color: #0066c4;
              }
            }
          }

          &__shakespeare {
            &__header {
              color: $sn-primary-paragraph;
              font-size: $text-base;
              line-height: $lh-base;
              font-weight: $fw-chubby;
              margin-bottom: 16px;
            }

            &__content {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;

              &__types {
                width: auto;
                margin: 0 12px 12px 0;
                padding: 4px 12px;
                height: 34px;
                border-radius: 10px;
                border: 1px solid #0066c4;
                line-height: $lh-base;
                font-size: $text-base;
                font-weight: $fw-chubby;
                color: #0066c4;
                cursor: pointer;
                &:hover,&:focus {
                  color: $white;
                  border: 1px solid #004371;
                  background-color: #004371;
                }
              }
            }
          }

          &__sort {
            &__header {
              color: $sn-primary-header;
              font-size: $text-slightly-big;
              line-height: $lh-base;
              font-weight: $fw-chubby;
              margin: 24px 0 16px 0;
            }

            &__contentByTitle{
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;

              &__types {
                width: auto;
                margin: 0 12px 16px 0;
                padding: 4px 12px;
                height: 34px;
                border-radius: 10px;
                border: 1px solid #0066c4;
                line-height: $lh-base;
                font-size: $text-base;
                font-weight: $fw-chubby;
                color: #0066c4;
                &:hover,&:focus {
                  color: $white;
                  border: 1px solid #004371;
                  background-color: #004371;
                }
              }
            }
          }
        }
      }

      &__applyCTA {
        position: sticky;
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        bottom: 16px;
        box-shadow: none;
        height: 46px;
        padding: 16px 20px;
        border-radius: 4px;
        background-color: $color-brandBlue2020;
        margin-top: calc(50vh - 100px);
        margin-bottom: 16px;
        width: 100%;
        border: unset;

        &__text {
          font-size: $text-base;
          font-weight: $fw-chubby;
          letter-spacing: 0.13px;
          color: $white;
        }
        &:hover,&:focus {
          background: #004371 !important;
          color: $white !important;
        }
      }
    }
  }
}

//nofear shakespeare chips css for guide section
.ns-chips-container {
  display: none;
  @include media-small {
    display: none;
    align-items: center;
    margin-top: 16px;
    &__clear-all {
      padding-right: 14px;
      white-space: nowrap;
      &__text {
        font-family: Raleway;
        font-size: $text-base;
        font-weight: $fw-chubby;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        text-align: left;
        color: #0066c4;
        cursor: pointer;
      }
    }
    &__list {
      overflow-x: auto;
      margin-right: -11px;
      &::-webkit-scrollbar {
        display: none;
      }
      &__guide-list {
        display: flex;
        white-space: nowrap;
        &__guide-select {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 12px 4px 8px;
          border-radius: 10px;
          border: solid 1px #5e57ad;
          background-color: #5e57ad;
          color: $white;
          font-family: Raleway;
          font-size: $text-base;
          font-weight: $fw-chubby;
          font-stretch: normal;
          font-style: normal;
          line-height: $lh-base;
          letter-spacing: normal;
          text-align: left;
          &__guide-img{
            padding-right: 5px;
          }
          &__close-img {
            padding-left: 8px;
            padding-top: 1px;
          }
        }
        &__filter-select {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 12px 4px 13px;
          border-radius: 10px;
          border: solid 1px #0066c4;
          background-color: #0066c4;
          color: $white;
          font-family: Raleway;
          font-size: $text-base;
          font-weight: $fw-chubby;
          font-stretch: normal;
          font-style: normal;
          line-height: $lh-base;
          letter-spacing: normal;
          text-align: left;
          margin-left: 12px;
          &:first-child {
            margin-left: unset;
          }
          &__close-img {
            padding-left: 8px;
            padding-bottom: 1px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@include media-small {
  .ns-show-chips {
    display: flex;
  }
}

@include media-small {
  #ns-show-count-chips {
    font-family: Raleway;
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: #5e57ad;
    padding-bottom: 3px;
    padding-top: 2px;
  }
}

.hub-header__image__img-mobile {
  @media only screen and (max-width: 600px) {  
    height: unset !important;
    display: flex;
  }
}

@media only screen and (max-width: 599px) {  
  .hub-header__image__img-desktop {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {  
  .hub-header__image__img-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 599px) {  
  .all_shakespeare_guide_link {
    border: 2px solid #0066c4;
    padding: 13.5px 0;
    border-radius: 4px;
  }
  .all_shakespeare_guide_link:hover {
    color: #fff;
    background: #004371;
    border: 2px solid #004371;
  }
  .all_shakespeare_guide_link__lit-hub {
    line-height: normal;
    padding: 0;
    letter-spacing: 0.13px;
    border: none;
  }
}

.desktop_shakespeare_btn {
  @include media-small {
    display: none;
  }
}

.mobile_shakespeare_btn {
  @include media-notSmall {
    display: none;
  }
}

// for single sear result disable sort css
.filter-section__sort-container.disableSortSection {
  pointer-events: none;
  .filter-section__sort-container__sort-text {
    opacity: 0.39;
  }
  .filter-section__sort-container__title-list {
    opacity: 0.39;
  }
  svg {
    opacity: 0.39;
  }
}

// to show showing results for data 
.showing-Search-result {
  display: none;
  align-items: center;
  gap: 5px;
  margin-bottom: 24px;

  p {
    font-weight: $fw-base;
    font-size: $text-small;
    line-height: $lh-base;
    color: $sn-primary-paragraph;
    text-align: left;
  }
  
  .result_data {
    font-weight: 700;
    font-size: $text-small;
    line-height: $lh-base;
    text-transform: capitalize;
    color: $sn-primary-paragraph;
    text-align: left;
  }
 
}

.all_guides__count.add_space {
  margin-bottom: 8px;
}

.showing-Search-result.show_result_for {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

//no search results SCSS
.nosearchresult {
  padding-bottom: 48px;
  &__results {
    margin-bottom: 24px;

    &__text {
      font-family: Raleway;
      font-size: $text-big;
      font-weight: 700;
      line-height: $lh-base;
      text-align: left;
      color: $sn-primary-header;
    }
  }

  &__info {
    margin-bottom: 24px;

    &__text {
      font-size: $text-slightly-big;
      font-weight: 700;
      line-height: $lh-base;
      text-align: left;
      color: $sn-primary-header;
      margin-bottom: 8px;
    }

    &__container {
      padding-left: 13px;

      &__list {
        display: flex;
        font-size: $text-slightly-big;
        font-weight: normal;
        line-height: $lh-base;
        text-align: left;
        color: #292c2e;
        &__bullet {
          padding-right: 7px;
        }

        &__bold {
          font-weight: 700;
        }
      }
    }
  }

  &__toptitles {
    margin-bottom: 10px;

    &__text {
      font-size: $text-slightly-big;
      font-weight: 700;
      line-height: $lh-base;
      text-align: left;
      color: $sn-primary-header;
      padding-bottom: 8px;
    }

    &__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;

      &__title {
        padding: 12px 16px;
        border-radius: 100px;
        color: $white;
        background-color: #0066c4;
        font-size: $text-base;
        font-weight: $fw-chubby;
        line-height: 1;
        text-align: left;
        &:hover, &:focus {  
          background-color: #004371;
          color: $white;
        }
      }
    }
  }
  
  &__backToTop {
    &__container {
      width: 162px;
      height: 46px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: solid 2px #007acd;
      background-color: $white;
      float: right;
      cursor: pointer;
      margin-right: 7px;
      margin-bottom: 24px;
    }

    &__text {
      font-size: $text-base;
      font-weight: $fw-chubby;
      letter-spacing: .13px;
      text-align: center;
      color: #007acd;
    }
  }

}

#link-other-subject {
  color: #0066c4;
  text-decoration: underline;
  &:hover, &:focus {
    color: #004371;
  }
}

// auto suggest lit hub
.hiddenUl {
  display: none !important;
}

.auto_suggest_list {
  
  .auto_result_link {
    display: flex;
    line-height: $lh-base;
    align-items: center;
    color: $sn-primary-paragraph;
    cursor: pointer;
    font-weight: 700;
    font-size: $text-base;
    padding: 11px 16px;

    &:hover {
      background-color: $color-teacherSoftGray;
      color: #004371;
      text-decoration: underline;
      text-underline-offset: 2px;
      h4 {
        background-color: $color-teacherSoftGray;
        color: #004371;
        text-decoration: underline;
        text-underline-offset: 2px;
      }
      span {
        background-color: $color-teacherSoftGray;
        color: #004371;
        text-decoration: underline;
        text-underline-offset: 2px;
      }
      color: #004371;
    }

    .nolink_title{
      font-weight: 700;
      font-size: $text-base;
    }
    
    .check_author_title {
      color: $sn-primary-paragraph;
      font-size: $text-base;
      font-weight: $fw-base;
      font-style: italic;
    }

    .highlight {
      font-weight: $fw-base;
    }

    .auto_result_heading {
      padding: 0;
    }
    
  }

  .auto_result_heading {
    display: flex;
    line-height: $lh-base;
    align-items: center;
    color: $sn-primary-paragraph;
    font-weight: 700;
    padding: 11px 16px;

    .nolink_title{
      font-weight: 700;
      font-size: $text-base;
    }
    
    .check_author_title {
      color: $sn-primary-paragraph;
      font-size: $text-base;
      font-weight: $fw-base;
      font-style: italic;
    }

    .highlight {
      font-weight: $fw-base;
    }
  }

  .title_text {
    color: $sn-primary-paragraph;
    font-size: $text-base;
    font-weight: 700;
    font-style: normal;
  }

  .hide_shakespeare_author {
    display: none;
  }
}


.nosearchresult__backToTop__container {
  @include media-notSmall {
    display: none;
  }
}
// clear all text and cross icon for mobile for lit hub css
.clear_text {
  @include media-small {
    display: none;
  }
}

.cross_icon {
  display: none;
  @include media-small {
    display: block;
  }
}

// mobile search box design for lit hub css
@include media-small {
  .hub-container.lit-container.lit-container-hubsearch {
    padding-top: 24px;

    .titleRedesignHub__topTitlesGuide {
      padding-top: 0;
    }

    .all_guides__title-new {
      margin-bottom: 4px;
      font-size: $text-big;
      line-height: $lh-base;
    }

    .all_guides__description {
      margin-bottom: 16px !important;
      font-size: $text-slightly-big;
      line-height: $lh-base;
    }

    .all_guides__count {
      margin-bottom: 24px;
      padding-top: 16px;
    }

    .all_guides__count.add_space {
      margin-bottom: 8px !important;
    }
    
  }

  .showClearAll+.lit_search_icon {
    display: none;
  }
}


.nosearchresult__description__text {
    font-size: $text-slightly-big;
    font-weight: 400;
    line-height: $lh-base;
    text-align: left;
    color: $sn-primary-paragraph;
}

.hubRedesign__mobile__subjectContainer__subjectTypes__sort__contentByTitle.disableSortSection li,
.hubRedesign__mobile__subjectContainer__subjectTypes__sort__contentByAuthor.disableSortSection li {
  border: 1px solid rgba(0, 102, 196, .5) !important;
  color: rgba(0, 102, 196, .5) !important;
  background: $white !important;
}

//hide separator  for one search result
.hideSeparator {
  .hub-AZ-list__section__title.hub-AZ-list__section__title__redesign {
    display: none;
  }
  .hub-AZ-list__section {
    padding-top: 34px !important;
  }
}

.title_text.subject {
  .highlight {
    font-weight: $fw-base;
  }
}

.hideBackToTopButton {
  display: none;
}
.shakespear_hub__helpful_resources {
  &__header {
    font-size: $text-base;
    font-weight: bold;
    line-height: $lh-base;
    text-align: left;
    color: $sn-primary-paragraph;
  }
  &__cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    margin: 16px 0 30px 0;

    @include media-small {
      margin: 16px 0 32px 0;
    }

    @include media-small {
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
      justify-content: center;
    }

    &__life , &__glossary {

      border-radius: 8px;
      border: solid 1px #bfdef2;
      background-color: $white;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;

      @include media-small {
        width: 100%;
      }

      &:hover {
        background-color: #004371;
        border: solid 1px #004371;

        .shakespear_hub__helpful_resources__cards__life__header, .shakespear_hub__helpful_resources__cards__glossary__header {
          color: $white;
        }
      }

      &__image {
        height: 48px;
        width: 48px;
      }

      &__header {
        font-size: $text-small;
        font-weight: $fw-chubby;
        line-height: $lh-base;
        text-align: left;
        color: $sn-primary-header;
        margin: 12.5px 12px;
      }
    }
  }
}

// css for refactor shakespeare hub with all shakespeare title//
.refactorShakespeareHub-hub-container-shakespeare {
  margin: 48px 0 20px 0 !important;

  @include media-small {
    margin: 24px 0 20px 0 !important;
  }

  .filter-section {
    @include media-small {
      display: none;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__sort-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      &__sort-text {
        font-size: $text-slightly-big;
        font-weight: $fw-chubby;
        line-height: $lh-base;
        text-align: left;
        color: $sn-primary-header;
      }
      &__title-list {
        font-size: $text-base;
        line-height: $lh-base;
        text-align: left;
        color: $sn-primary-header;
        padding-left: 6px;
        padding-top: 1px;
      }
      &__sort-dropdown {
        padding-left: 6px;
        padding-top: 1px;
      }
    }
  }

  .show-sort {
    width: 147px;
    display: none;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: $white;
    position: absolute;
    right: 0px;
    top: 30px;
    z-index: 1;
    padding: 16px 14px 16px 14px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0px 0px 12px 12px;
    padding-top: 20px;
    &__active-list {
      display: flex;
      width: 100%;
      font-size: $text-base;
      font-weight: $fw-chubby;
      line-height: 1.59;
      text-align: left;
      color: $sn-primary-header;
      padding: 4px 2px 4px 2px;
      &:hover, &:focus {
        background-color: #eeeeee;
      }
      &__img {
        display: flex;
        align-items: center;
        padding-right: 8px;
      }
    }
    &__unactive-list {
      display: flex;
      width: 100%;
      font-size: $text-base;
      line-height: 1.59;
      text-align: left;
      color: $sn-primary-header;
      padding: 4px 2px 4px 2px;
      cursor: pointer;
      &:hover, &:focus {
        background-color: #eeeeee;
      }
      &__img {
        display: flex;
        align-items: center;
        padding-right: 8px;
        svg {
          path{
            fill: none;
          }
        }
      }
    }
  }

  .hubPage__section__heading {
    margin-bottom: 18px;
    font-weight: $fw-chubby;
  }

  .hub_description {
    margin-bottom: 24px;
  }

  .count-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
    @include media-small {
      margin-bottom: 24px; 
    }
    &__text {
      font-size: $text-small;
      font-weight: $fw-chubby;
      line-height: $lh-base;
      text-align: center;
      color: $sn-primary-paragraph;
    }
  }

  .all_guides__sub_content-types__name {
    margin-bottom: 20px !important;
  }

  .all_guides {
    margin: 0 0 14px 0;
  }

  .remove_section_alphabet {
    padding-top: 14px;
  }

  .titleRedesignHub__backToTop__container {
    margin: -45px 0 30px 0;
    @include media-small {
      margin: 59px 0 -20px 0;
    }
  }

  @include media-small {
    .hubRedesign__mobile {
      &__subjectContainer {
        &__subjectTypes {
          &__all {
            margin: 0 12px 12px 0;
          }
        }
        &__header {
          margin-bottom: 24px;
        }
      }

      &__applyCTA {
        &:focus {
          background-color: #004371;
        }

        margin: 137px 0 16px;
        width: 99%;
      }

      &__sortFilterContainer{
        &__clearAllCTA {
          &:focus {
            color: #004371;
          }
        }

        &__top {
          margin: 10px 0 26px;
        }
      }

    }
  }
}

.refactorShakespeareHub-hub-container-shakespeare__signedin {
  .remove_section_alphabet {
    @include media-notSmall {
      column-gap: 14.7px;
    }
  }
}

.hub-header.hub-header--shakespeare.refator {
  background-color: #0066c4 !important;
  .hub-header {
    &__slogan {
      margin-top: 10px;
      color: $white;
      line-height: 0.96;
      font-size: $text-very-big;
      font-weight: $fw-base;
      @include media-small {
        margin: 2px 0 24px 0;
        line-height: 1.22;
        font-size: $text-big-ish;
      }
    }

    &__text {
      margin: 67px 0 117.21px 0;
      
      @include media-small {
        margin: 24px 0 25px 0;
      }
    }

    &__container {
      padding-left: 14px;
      padding-right: 12px;
    }

    &__title {
      font-weight: $fw-fat;
      color: $white;
      font-size: $text-very-big;
      line-height: $lh-base;
    }
  }
}

@include media-small {
  .refactorShakespeare-hub-container {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }
}