.bn-pod-right-rail {
  @include media-under-1020 {
    display: none;
  }
  display: block;
  margin-top: 8px;
  &:focus,
  &:hover {
    background-color: transparent;
    -webkit-transform: scale(1.00);
    transform: scale(1.00);
  }
  @media (max-width: 980px) {
    border-radius: 12px;
    margin-bottom: 40px;
    margin: 40px auto;
  }
  &__wrap {
    width: 405px;
    position: relative;
    background-image: url("/images/buyon-background.png");
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 12px;
    z-index: -2;
    .bn-pod-right-rail:hover & {
      background-image: url("/images/buyon-background.png");
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    padding: 12px 32px;
  }
  &__title {
    color: $white;
    font-weight: $fw-chubby;
    font-size: $text-slightly-big;
    line-height: $lh-extra-tight;
    text-align: center;
  }
  &__type {
    color: $white;
    font-weight: $fw-base;
    font-size: $text-tiny;
    line-height: $lh-tight;
    text-align: center;
  }
  &__blurb {
    margin-top: 4px;
    color: $white;
    font-weight: $fw-base;
    font-size: $text-small;
    line-height: $lh-base;
    text-align: center;
  }
  &__pseudo-button {
    width: 100%;
    text-align: center;
    font-weight: $fw-fat;
    line-height: $lh-base;
    color: $white;
    background: $color-brandBlue2020;
    font-size: $text-slightly-big;
    border-radius: 0px 0px 12px 12px;
    padding: 12px 0px;
    &:hover {
      background: $color-linkHover;
    }
  }

  &__container {
    position: relative;
    padding-top: 14px;
    display: flex;
    justify-content: center;
    &__custom--image {
      padding-top: 0;
      background-image: var(--img);
      background-size: 100%;
      background-repeat: no-repeat;
      width: 100%;
      height: 214px;
      margin : 0px 5px;
      z-index: 0;
      position: relative;
      margin-top: -15px;
    }
    &__banner--image {
      background-image: var(--img);
      background-repeat: no-repeat;
      width: 364px;
      height: 115px;
      position: absolute;
      bottom: 30px;
      left: 16px;
      background-size: 100%;
    }
    &__image {
      background-image: var(--img);
      background-size: 133px 100%;
      background-repeat: no-repeat;
      width: 133px;
      height: 200px;
      z-index: 0;
      box-shadow: 0 10px 30px 0 rgba($color: #000000, $alpha: 0.5);
    }
    &__left--image {
      position: absolute;
      background-image: var(--img);
      background-size: 113px 100%;
      background-repeat: no-repeat;
      width: 113px;
      height: 170px;
      top: 30px;
      left: 32px;
      z-index: 0;
      box-shadow: 0 10px 30px 0 rgba($color: #000000, $alpha: 0.5);
    }
    &__right--image {
      position: absolute;
      background-image: var(--img);
      background-size: 113px 100%;
      background-repeat: no-repeat;
      width: 113px;
      height: 170px;
      top: 30px;
      right: 32px;
      z-index: -1;
      box-shadow: 0 10px 30px 0 rgba($color: #000000, $alpha: 0.5);
    }
  }
}
