// This file will eventually contain all the homepage specific code. Right now it is also defined in _core and possibly other sass files.

@mixin media-set1 {
  @media (min-width: 980px) and (max-width: 1110px) {
    @content;
  }
}
.homepage {
  // contains all the main content except the hero search:
  &__container {
    @include container($padding: true);
  }
  &__section {
    margin-top: 48px;
    margin-bottom: 48px;
    @include media-small {
      margin-top: 20px;
    }
    &.carouselSec {
      margin-bottom: 0;
      padding-top: 75px;
      background-color: $color-teacherSoftGray;
    }
    &.carousel {
      width: 85%;
      margin: 0 auto;
    }
    &--whats-on {
      display: flex;
      @include media-small {
        flex-direction: column;
      }
    }
  }

  // The "From the SparkNotes Blog" heading
  &__heading {
    font-weight: $fw-fat;
    margin-bottom: $s-px * 2;
  }
  &__header {
    background-color: $color-brandBlue2020;
    overflow: hidden;
    &__container {
      @include container($padding: true);
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
      }
    }
    &__title {
      color: $white;
      font-size: $text-very-big;
      font-weight: $fw-base;
      margin-top: 48px;
      margin-right: 32px;
      margin-bottom: 48px;
      @media screen and (max-width: 840px) {
        font-size: $text-big-ish;
        margin-top: 12px;
        margin-bottom: 24px;
      }
      @media screen and (max-width: 600px) {
        margin-right: 0;
      }
      &__logo {
        display: block;
        @media screen and (max-width: 600px) {
          padding-left: 32px;
          padding-right: 32px;
        }
        svg {
          width: 400px;
          max-width: 100%;
          @media screen and (min-width: 601px) {
            max-width: 40vw;
          }
          height: auto;
          display: block;
        }
        path {
          fill: $white;
        }
      }
      &__text {
        @include visuallyhidden;
      }
      &__slogan {
        display: block;
        margin-top: 12px;
        margin-left: 10%;
        @media screen and (max-width: 600px) {
          margin-left: 0;
          text-align: center;
          font-size: $text-base;
        }
      }
    }
    &__image {
      display: block;
      align-self: flex-end;
      flex: 0 1 auto;
      @media screen and (max-width: 600px) {
        overflow: hidden;
        display: flex;
        align-items: center;
      }
      &__img {
        display: block;
        @media screen and (min-width: 601px) and (max-width: 1215px) {
          height: auto;
          width: 110%;
        }
        @media screen and (max-width: 600px) {
          height: auto;
          width: 100%;
          transform: scale(1.2);
          margin-left: 2%;
        }
      }
    }
  }
}

// the UL that inlcudes the 4 links to main site sections
.homepage-ctas {
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(144px, 1fr));
  grid-gap: 16px;
}
// the inndividual cta <li> elements, excluding the ad
.homepage-cta {
  display: flex;
  // the anchor that wraps the other content
  &__link {
    flex: 1;
    border-radius: $radius-big;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    text-align: center;
    &:hover,
    &:focus {
      box-shadow: 0 16px 20px 0 rgba(0, 0, 0, 0.1), 0 12px 24px -16px rgba($color-brandBlue2020, 0.3);
    }
  }
  // the icon
  &__icon {
    $size: $s-px * 12;
    width: $size;
    height: $size;
    background-size: contain;
    &--shakespeare {
      background-image: url("/images/icon-shakespeare-72-brandBlue.svg");
    }
    &--literature {
      background-image: url("/images/icon-literature-72-brandBlue.svg");
    }
    &--otherSubjects {
      background-image: url("/images/icon-otherSubjects-72-brandBlue.svg");
    }
    &--blog {
      background-image: url("/images/icon-blog-72-brandBlue.svg");
    }
  }
  // the title
  &__title {
    line-height: $lh-extra-tight;
    margin-top: 12px;
    @include media-notSmall {
      margin-bottom: 20px;
      font-size: $text-big-ish;
    }
    @include media-small {
      font-size: $text-slightly-big;
    }
    color: $color-brandBlue2020;
    font-weight: $fw-fat;
  }
}

// the ad space that sits inside the homepage ctas
.homepage-cta--rect {
  @include playwire-gray-box;
  min-width: 300px;
  min-height: 280px;
  flex: none;
  justify-content: center;
  @include media-notSmall {
    margin-left: 24px;
  }
  @include media-small {
    margin-top: 48px;
    align-self: center;
    width: 100%;
  }
  //hack to remove margin added to all these playwire ads in _core.scss
  [data-pw-desk],
  [data-pw-mobi] {
    margin-bottom: 0;
  }
}

.homepage__blog-links {
  padding-bottom: 65px;
  @include media-tablet-and-desktop {
    padding-left: 0;
    padding-right: 0;
  }

  // use display flex on owl-carousel items to allow all items to be the same height.
  .owl-stage-outer {
    padding: 20px 0 0 0;
  }
  .owl-stage {
    display: flex;
    height: 370px;
  }
  .owl-item {
    display: flex;
    height: 360px;
    &:focus,
    &:hover {
      background-color: $white;
      border-radius: $radius-big;
    }
  }

  .owl-prev,
  .owl-next {
    // overwrite the main site owl-carousel buttons. Will roll this out wider eventually.
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    $size: $s-px * 4;
    width: 48px;
    height: 48px;
    position: absolute;
    transform: translateY(-50%);
    border-radius: 50%;
    opacity: 0.7;
    transition: opacity 0.2s;
    background-image: url("/images/icon-arrow-active.svg");
    &:hover {
      background-image: url("/images/icon-arrow-active.svg") !important;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
    }
    &:focus,
    &:active {
      background-image: url("/images/icon-arrow-click.svg");
      outline: none;
    }
    &.disabled {
      background-color: $sn-border-gray !important;
      background-image: url("/images/icon-arrow-inactive.svg");
      background-color: $sn-border-gray;
      pointer-events: none;
    }
  }
  .owl-prev {
    left: -65px;
    top: 38%;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .owl-next {
    right: -64px;
    top: 44%;
  }
}

//probably move to a generic blog-post section

// the individual link that wraps the visible content
.blog-link {
  //expand to fill
  flex: 1;
  display: flex;
  flex-direction: column;
  &:hover,
  &:focus {
    border-top: 3px solid #e42c90;
    outline: none;
  }
  // a div that holds the img:
  &__thumb {
    img {
      border-radius: $radius-big $radius-big 0 0;
      height: auto;
    }
    // the img itself:
    .blog-link__img {
      width: auto;
      max-width: 100%;
      height: 180px;
    }
  }
  // the blog post title:
  &__title {
    font-weight: $fw-chubby;
    color: $sn-primary-header;
    line-height: $lh-tight;
    padding: 12px 16px 28px 16px;
    @include media-set1 {
      padding: 10px 16px;
    }
  }
  // the date format:
  &__date {
    margin-top: auto;
    padding: 8px 16px 12px 16px;
    border-top: 1px solid $sn-border-gray;
    font-size: $text-small;
    font-weight: $fw-base;
    color: $sn-primary-header;
    line-height: $lh-loose;
  }
}
.blog-link:hover .blog-link__thumb img {
  border-radius: 0;
}

.homepage-ad-container {
  height: 500px;
  display: flex;
  margin-top: 40px;
  @include media-small {
    height: auto;
    flex-direction: column-reverse;
    margin-top: 20px;
  }
  &__image-area {
    // height: -webkit-fill-available;
    height: 100%;
    @include media-small {
      display: none;
      max-width: 100%;
      max-height: 100%;
      height: unset;
    }
    img {
      border-radius: 12px 0px 0px 12px;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__explore {
    position: relative;
    background-color: #008262 !important;
    border-radius: 0px 12px 12px 0px !important;
    padding: 40px 35px;
    @include media-under-1020 {
      padding: 40px 30px;
    }
    @include media-small {
      width: auto;
      padding: 10px;
      padding-bottom: 20px;
      border-radius: 12px 12px 0px 0px !important;
    }

    &__new-text-desktop {
      position: absolute;
      max-height: 20%;
      max-width: 15%;
      @include media-small {
        display: none;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &__new-text {
      display: none;
      @include media-small {
        display: unset;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &__logo {
      padding-top: 10px;
      height: 47px;
      max-width: 260px;
      margin-bottom: 50px;
      @include media-under-1020 {
        margin-bottom: 30px;
      }
      @include media-small {
        height: 37px;
        max-width: unset;
        margin-bottom: 24px;
        text-align: center;
      }
      img{
        max-width: 100%;
        @include media-small {
          max-width: 200px;
        }
      }
    }

    &__content {
      // max-width: 477px;
      display: flex;
      flex-direction: column;
      @include media-small {
        width: auto;
        text-align: center;
        margin-top: 28px;
        padding-left: unset !important;
      }
      @include media-under-1020 {
        padding-left: 15px;
      }

      &__title {
        font-size: $text-extra-big;
        font-weight: $fw-fat;
        line-height: normal;
        color: $white;
        @include media-under-1020 {
          font-size: $text-big;
        }
        @include media-small {
          font-size: $text-big-ish;
          font-weight: $fw-chubby;
          line-height: $lh-base;
          padding: 0px 12px;
        }
      }

      &__text {
        margin-top: 16px;
        line-height: $lh-base;
        color: white;
        font-size: $text-base;
        height: auto;
        font-weight: $fw-base;
        @include media-small {
          margin-top: 12px;
          padding: 0px 6px;
        }
      }

      &__explorebtn {
        text-align: center;
        color: #0066c4;
        justify-content: center;
        margin-top: 51px;
        width: 221px;
        min-height: 46px;
        background-color: $white;
        line-height: $lh-base;
        font-size: $text-base;
        border-radius: 6px;
        padding: 10px;
        cursor:pointer;
        &:hover{
          background-color: #004371;
          color: $white;
        }
        @include media-under-1020 {
          margin-top: 25px;
        }
        @include media-small {
          margin-left: auto;
          margin-right: auto;
          max-width: 265px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.homepage-btncontainer {
  display: flex;
  flex-direction: row;
  @include media-under-1020 {
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
  }
  @include media-small {
    flex-direction: column;
    text-align: center;
    justify-self: center;
    padding-right: unset;
    margin-top: 12px;
  }
  &__freetrial {
    margin-right: 12px;
    color: #0066c4 !important;
    border: none;
    padding: unset;
    @include media-under-1020 {
      margin-right: 0;
    }
    &:hover{
      background-color: #004371 !important;
      color: $white !important;
    }
    @include media-small {
      margin-right: auto;
      margin-bottom: 0px;
    }
  }
  &__renew {
    margin-right: 12px;
    color: #0066c4 !important;
    border: none;
    padding: unset;
    @include media-under-1020 {
      margin-right: 0;
    }
    &:hover{
      background-color:#004371 !important;
      color: $white !important;
    }
    @include media-small {
      margin-right: auto;
      margin-bottom: 0px;
    }
  }
  &__exploreplus {
    border: 2px solid $white;
    @include media-small {
      border: 1px solid $white;
    }
    color: $white;
    background: transparent;
    &:hover{
      background-color: #004371;
      color: $white;
      border: 2px solid #004371;
      @include media-small {
        border: 1px solid #004371;
      }
    }
  }
  &__haveaccount {
    color: $white;
    font-size: $text-small;
    margin-top: 19px;
    display: flex;
    @include media-small {
      margin-top: 5px;
      justify-content: center;
      margin-bottom: 20px;
      padding-right: unset !important;
    }
    @include media-under-1020 {
      justify-content: center;
      padding-right: 20px;
    }
  }
  &__signinopt {
    color: $white;
    font-weight: $fw-chubby;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: unset;
    &:hover, &:focus-visible{
      text-decoration: underline;
    }
  }
}

.free-text {
  font-weight:$fw-fat;
}

.signedin-title-logo{
  svg{
    path:first-child {
      fill: #41FFAA !important;
    }
  }
}

.homepage-mobile-image {
  display: none;
  @include media-small {
    display: unset;
    img {
      border-radius: 0px 0px 12px 12px;
      width: 100%;
    }
  }
}

.whats-new-additionalstyle {
  @include media-small {
    flex-direction: column-reverse;
    display: flex !important;
    grid-gap: unset !important;
  }
}

.whats-new-additionalstyle .campanion-text {
  @include media-small {
    margin-top: 48px;
  }
}

.whats-new-additionalstyle .full-text {
  @include media-small {
    margin-top: 20px;
  }
}

.promoad-container {
  grid-gap: 0px !important;
  margin-top: 40px;
  @include media-small {
    display: flex!important;
    flex-direction: column-reverse;
  }
}
.promo-image-cont {
  border-radius: 12px 0px 0px 12px !important;
  @include media-small {
    border-radius: 0px 0px 12px 12px!important;
    position: relative;
    top: -1px;
  }
}

.clicking {
  div:focus{
    outline: none !important;
  }
}  