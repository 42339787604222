.titleHeader--howTo{
  background: #2a99cd;
  background-color: #98348b;
  background-image: radial-gradient(circle at 50% 170%, #98348b, rgba(42, 153, 205, 0) 70%), linear-gradient(154deg, #2a99cd 37%, #98348b 100%);     
  background-size: 70%, 100%;
  background-repeat: no-repeat;
  background-position: 100% 100%, 0 0;
  @include media-notSmall{
    padding-top: 24px;
    padding-bottom: 24px;
  }
  @include media-small{
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &__container {
    @include container($padding: true);
  }
  &__title {
    color: $white;
    font-weight: $fw-huge;
    font-size: $text-extra-big;
    line-height: $lh-tight;
    @include media-small{
      text-align: center;
    }
  }   
}