@include premiumFlag{
    .account-error-message{
      @keyframes bg {
        from{
          background: rgba($color-error,0);
        }
      }
      @keyframes content {
        from{
          opacity: 0;
          transform: translateY(48px);
        }
        to{
          opacity: 1;
          transform: translateY(0);
        }
      }
      background: #fbefed;
      padding: 16px 20px;
      align-items: center;
      animation: bg 0.4s;
      overflow: hidden;
      border: solid 2px $color-form-error;
      border-radius: 4px;
      &:not([hidden]){
        display: flex;
        margin: -20px -20px 20px -30px;
        @include media-mobile {
          margin: 0px 0px 20px 0px;
        }
      }
      &__icon{
        animation: content 0.4s;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        img{
          transform: translateY(-2px);
        }
      }
      &__text{
        animation: content 0.4s;
        color: $color-form-error;
        font-size: $text-base;
        line-height: $lh-base;
      }
      &__title{
        font-size: inherit;
        color: $color-form-error;
      }
      &__info{
        color: $color-form-error;
      }
    }
  }