// styling for Summary blurb sentences
.summary_sentence {
  text-align: left;
  font-size: $text-base;
  font-weight: $fw-base;
  line-height: $lh-loose;
  color: $sn-primary-paragraph;
  padding-bottom: $s-px * 3;
  border-bottom: 1px solid $sn-border-gray;
  margin: 0 auto;
  &__study-guide{
    font-weight: $fw-chubby;
    color: $color-brandBlue2020;
    a{
      color: $color-brandBlue2020 !important;
      font-weight: $fw-chubby !important;
      text-decoration: unset !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  &__nofear{
    font-weight: $fw-chubby;
    color:$color-noFear;
  }
  @include media-small {
    padding-right: $pageMargin;
    padding-left: $pageMargin;
    text-align: center;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  //Handle Bold and italic tags from strapi
  strong {
    font-weight: $fw-chubby;
  }
  em {
    font-style: italic;
  }
}

.overview-title, .fullbook-title {
  color: $sn-primary-header;
  margin-top: 15px;
  margin-bottom: 12px;
  font-family: Raleway;
  font-size: $text-big;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: $lh-base;
  letter-spacing: normal;
  text-align: left;
  @include media-small  {
    margin-top : 0;
  }
}  

.overview-content, .fullbook-content {
  font-family: Raleway;
  font-size: $text-base;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $lh-base;
  letter-spacing: normal;
  text-align: left;
  color: $sn-black;
  margin-bottom: 55px;
  @include media-small  {
    margin-top : 0;
  }
}

.fullbook-content p {
  margin-bottom: 1.5rem;
}

// new box styling landing page//
.total_page_title_section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 16px;
  @include media-small {
    padding-top: 16px;
    flex-direction: column;
  }
}

.title_headline {
    width: 220px;
    height: auto;
    max-height: 88px;
    margin: 0 20px 40px 0;
    box-shadow: 0 0 4px 0 rgba(0, 102, 196, 0.35);
    border-radius: 10px;
    text-decoration: none!important;
    @include media-small {
      width: 100%;
      height: 88px;
      margin: 0 0 24px 0;
    }
    
  &__title_text_section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 16px;
    height: auto;
    max-height: 88px;
    &__title_images {
      border: none;
      width: 56px;
      height: 56px;
      margin-right: 8px;
    }
    @include media-small {
      gap: 10px;
    }
  }

  &__page_title {
    font-size: $text-slightly-big;
    color: $sn-primary-header;
    font-weight: $fw-chubby;
    line-height: 1.2;
    padding-top: 0;
    text-align: left;
    @include media-small  {
      font-size: $text-slightly-big;
    }
    @media (min-width: 320px) and (max-width: 359px) {
      font-size: $text-base;
    }
    @media (max-width: 280px) {
      font-size: $text-base;
    }
  }

  &:nth-child(3n) {
    @include media-notSmall {
      margin-right: 0 !important;
    }
    @media (min-width: 700px) and (max-width: 1162px) { 
      margin-right: 20px !important;
    }
  }
}

.letter-list__headline {
  .study_tools {
    color: $sn-primary-header;
    font-size: $text-big;
    font-weight: bold;
    line-height: $lh-base;
    text-align: left;
    @include media-small  {
      margin-top : 0;
    }
  }
}

.TitleHeader_header--studyGuide.titleRedesign-dropdown{
  .TitleHeader_authorLink.tag--authorLink-isLink {
    @media (max-width: $mobile-bp) {
      margin-bottom: 24px;
    }
    @include media-small  {
      margin-bottom: 0;
    }
  }
} 


.plus_content{
  box-shadow: 0 0 4px 0 rgba(0, 130, 98, 0.35);
  .title_headline__title_text_section__title_images {
    border: none;
  }
}

.hoverBlue_image {
  display: none;
}

.title_headline.plus_content:hover {
  box-shadow: 0 0 20px 0 rgba(0, 130, 98, 0.45) !important;
  .title_headline__title_text_section__title_images {
    display: none;
  }
  .hoverBlue_image {
    display: block;
  }
  .title_headline__page_title {
    color: #004371;
  }
  @include media-small  {
    box-shadow: 0 0 20px 0 rgba(0,130,98,.45) !important;
  }
}

.title_headline:hover {
  box-shadow: 0 0 20px 0 rgba(0, 122, 205, 0.45) !important;
  transform: scale(1.04);
  .title_headline__title_text_section__title_images {
    display: none;
  }
  .hoverBlue_image {
    display: block;
  }
  .title_headline__page_title {
    color: #004371;
  }
  @include media-small {
    transform: scale(1.0);
    box-shadow: 0 0 20px 0 rgba(0,122,205,.45)!important;
  }
}

.plusTitleLandingPromo {
  text-align: center;
  margin: 0 auto;
  background-color: #008262;
  margin-bottom: 60px;
  height: 127px;
  @include media-small  {
    margin : 40px 0;
    height: auto;
    border-radius: 12px;
  }

  &__header {
    &__text {
      line-height: $lh-base;
      color: $white;
      padding-top: 0px;

      &__desktop {
        @include media-notSmall {
          font-size: $text-base;
          font-weight: $fw-base;
        }

        @include media-small {
          display: none;
        }
      }

      &__mobile {
        @include media-notSmall {
          display: none;
        }

        @include media-small {
          margin: 0;
          padding: 20px 0px;
          font-size: $text-base;
          font-weight: $fw-base;
        }
      }
    }
  }

  &__cat-container {
    display: flex;

    @include media-notSmall {
      padding: 20px 20px 0 20px;
      align-items: center;
      height: 127px;
    }

    @media (min-width: 698px) and (max-width: 756px) {
      padding: 20px 10px 0 10px;
    }

    @include media-small {
      flex-direction: column-reverse;
      padding: 0px 20px 0 20px;
    }

    &__img {
      width: 187.9px;
      height: 100px;
      float: left;

      @include media-small {
        margin: 0 auto;
        float: none;
      }
    }
  }

  &__button-container {
    @include media-notSmall {
      display: flex;
      flex-direction: row;
      padding: 15px 0 20px 4px;
    }

    &__signupButton,
    &__renewButton {
      border-radius: 5.6px;
      background-color: $white;
      border: solid 2px $white;
      padding: 7px 0;

      @include media-notSmall {
        margin-right: 10px;
      }

      @include media-small {
        margin-bottom: 12px;
        padding: 8.3px 0px;
        width: 298px;
        @media (min-width: 320px) and (max-width: 359px) {
          width: 260px;
          padding: 8px 10px;
        }
      }

      &__trial-text,
      &__renew-text {
        font-size: $text-base;
        line-height: $lh-base;
        text-align: center;
        color: $color-brandBlue2020;
        width: 215px;
  
        @include media-small {
          width: auto;
          text-align: center;
          margin: 0 auto;
        }

        @media (min-width: 320px) and (max-width: 359px) {
          width: auto;
        }

        &__free-text {
          font-weight: $fw-fat;
        }
      }

      @include media-notSmall {
        &:hover {
          background-color: #004371;
          border: solid 2px #004371;

          .plusTitleLandingPromo__button-container__signupButton__trial-text,
          .plusTitleLandingPromo__button-container__renewButton__renew-text {
            color: $white;
          }
        }
      }
    }

    &__exploreButton {
      border-radius: 5.6px;
      border: solid 2px $white;
      background-color: #008262;
      padding: 7px 0;

      @include media-small {
        margin-bottom: 20px;
        padding: 8.3px 0px;
        width: 298px;
      }

      @media (min-width: 320px) and (max-width: 359px) {
        width: 260px;
        padding: 8px 10px;
      }

      &__text {
        font-size: 16px;
        line-height: $lh-base;
        text-align: center;
        color: $white;
        width: 215px;

        @include media-small {
          width: auto;
          text-align: center;
          margin: 0 auto;
        }

        @media (min-width: 320px) and (max-width: 359px) {
          width: auto;
        }
      }

      @include media-notSmall {
        &:hover {
          background-color: #004371;
          border: solid 2px #004371;
        }
      }
    }
  }
}

@include media-small {
  .title_headline:nth-child(2n) {
    margin-right: 0;
  }

  .TitleHeader_header--studyGuide.titleRedesign-dropdown {
    padding-top: 34px !important;
    .TitleHeader_heading {
      align-items: flex-start;
      padding: 0;
    }

    .TitleHeader_title {
      margin: 3px 0 3px;
      font-size: $text-very-big;
    }
    
    .TitleHeader_container {
      padding: 0 32px 53px 32px ;
    }

    .TitleHeader_authorLink__header {
      padding: 4px 0px;
      border-radius: 0;
      box-shadow: none;
      border: none;
      
    }
  }
  
}

.TitleHeader_header--studyGuide.titleRedesign-dropdown .TitleHeader_tabs.mobile-nav {
  @media (max-width: $mobile-bp)  {
    display: none;
  }
}

.overview-container {
  @include media-1020-and-up{
    padding-right: 0;
  }
}

.fullbook-container {
  @include media-1020-and-up{
    padding-right: 0;
  }
}

.plus_cat_image {
  margin-right: 18.1px;
  margin-bottom: -6px;
  @media (min-width: 698px) and (max-width: 756px) {
    margin-right: 4.1px;
  }
  @include media-small {
    margin-right: unset;
    margin-bottom: -2.57px;
  }
}

.plusTitle_heading {
  margin: 0 auto;
}

.overview-content .summary_sentence__study-guide p{
  margin-bottom: 1.5rem;
}

.title_headline__title_text_section__title_images path {
  fill: #008262;
}