@mixin l-media-bp1 {
  @media (max-width: 1059px) {
    @content;
  }
}
@mixin l-media-bp2 {
  @media (max-width: 959px) {
    @content;
  }
}
@mixin l-media-bp3 {
  @media (max-width: 759px) {
    @content;
  }
}
.sparkteach-homepage__included__lens{

  @include media-notSmall {
    background-color: $color-teacherSoftGray;
  }
  
  max-height: 1000px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: "all";
  padding-top : 70px;
  padding-bottom : 70px;
  @include l-media-bp3{
    grid-template-rows: auto auto;
    grid-template-areas: "top" "bottom";
    overflow: initial;
    max-height: none;
  }
  &__image{
    grid-area: all;
    justify-self: flex-end;
    max-width: 2000px;
    width: 100%;
    @include l-media-bp2{
      display: flex;
    }
    @include l-media-bp3{
      grid-area: top;
      display: block;
    }
    picture{
      display: flex;
    }
    &__img{
      width: 100%;
      height: 100%;
      @include l-media-bp2{
        object-fit: cover;
        object-position: 72%;
      }
      @include l-media-bp3{
        object-fit: initial;
        object-position: 72%;
      }
    }
  }
  &__container{
    grid-area: all;
    @include l-media-bp3{
      grid-area: bottom;
    }    
    max-width: $sn-max-width;
    width: 100%;
    padding-left: $pageMargin;
    padding-right: $pageMargin;
    align-self: center;
    justify-self: center;    
  }

  &__section-header {
    @include teacherHome-sectionHeading;
    font-weight: $fw-slim;
    @include media-small {
      font-size: $text-big-ish;
      font-weight: $fw-base;
      line-height : $lh-tight;
    }
  }

  &__short-border {
    width         : 72px;
    height        : 2px;
    border        : 2px solid $color-teacher;
    margin-top    : 32px;
    margin-bottom : 32px;
    @include media-small {
      margin-top    : 24px;
      margin-bottom : 24px;
    }
  }

  &__title{
    @include teacherHome-sectionHeading;
    font-size: $text-big;
    margin-bottom: 32px;
    @include media-small {
      font-size: $text-base;
      font-weight: $fw-chubby;
      line-height : $lh-loose;
    }
  }
  &__additional-title{
    @include teacherHome-sectionHeading;
    font-size: $text-big;
    margin-top: 40px;
    margin-bottom: 32px;
    @include media-small {
      font-size: $text-base;
      font-weight: $fw-chubby;
      line-height : $lh-loose;
    }
  }
  &__blurb{
    @include media-notSmall {
      max-width : 66%;
    }
    line-height: $lh-loose;
    margin-bottom: 24px;
  }
  &__benefits{
    display: grid;
    grid-row-gap: 24px;
    grid-template-columns: auto auto auto;
    grid-column-gap: 24px;
    @include media-small{
      grid-template-columns: auto 
    }
    line-height: $lh-loose;
    text-shadow: 0 0 4px white;
  }
  &__benefit{

    break-inside: avoid;
    &__title {
      @include media-small {
        font-size: $text-base;
        font-weight: $fw-chubby;
        line-height : $lh-loose;
        }
    }
  }
}