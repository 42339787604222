@import 'headings/right-rail-heading';

.most-viewed-blogs{
  &__heading{
    @include right-rail-heading;
  }
  &__link{
    display: flex;
    align-items: center;
    padding: $s-px * 2;
    border-radius: $radius-base;
    &:hover{
      background-color: $color-teacherSoftGray;
      .most-viewed-blogs__link__thumb {
          box-shadow: 0 0 0 $s-px *.25 #004371;
      }
      .most-viewed-blogs__link__title{
        color: #004371;
      }
    }    
    &__thumb{
      $size: $s-px * 9.5;
      width: $size;
      height: $size;
      border-radius: 50%;
      overflow: hidden;
      flex: none;
      margin-right: $s-px * 1.5;
      img, svg{
        width: auto;
        display: block;
        height: $size;
        transform: translate(-50%);
        margin-left: $size / 2;
      }
    }
    &__title{
      font-weight:$fw-chubby;
      line-height:$lh-tight;
      color:$sn-primary-header;
    }
  }
}