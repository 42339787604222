  .preorder__titles {
    max-height: 1000px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "all";
    padding-top : 48px;
    @include media-notSmall {
      background-color: $color-teacherSoftGray;
      padding-top : 70px;
      padding-bottom: 56px;
      margin-top: 22px;
    }

    @include media-small {
      grid-template-rows: auto auto;
      grid-template-areas: "top" "bottom";
      overflow: initial;
      max-height: none;
    }

    &__container{
      grid-area: all;
      @include media-small {
        grid-area: bottom;
      }    
      max-width: $sn-max-width;
      width: 100%;
      padding-left: $pageMargin;
      padding-right: $pageMargin;
      align-self: center;
      justify-self: center; 
      
      &__panel {
        margin-top: 36px;
        @include media-small {
          margin-top: $pageMargin;
        }
        &__collapse-button {
            display: flex;
            @include media-notSmall {
                display : none;
            }
            justify-content: center;
            font-weight: $fw-chubby;
            padding: 8px 12px;
            border-radius: $radius-base;
            margin-bottom : $pageMargin;
            color : $sn-primary-header;
            border : 1px solid $sn-primary-header;
            &:hover {
              background-color: $color-linkHover;
              color: $white;
              border : none;
              svg {
                path {
                  fill: $white;
                }
              }         
            }

            &__text {
              align-self: center;
              margin-right: $s-px;
            }

            &__icon {
              transform: rotate(-90deg);
            }

            &__icon-top {
              transform: rotate(90deg);
            }
        }

        &__preorder-button {
            display: flex;
            @include media-notSmall {  
                width: 196px;
            }
            justify-content: center;
            font-weight: $fw-chubby;
            background-color: #0066c4;
            padding: 14px 12px;
            border-radius: $radius-base;
            color: $white;

            &:hover {
              background-color: #004371;
              color: $white;
            }
        }
      }
    }
  
    &__section-header {
      @include teacherHome-sectionHeading;
      font-weight: $fw-slim;
      @include media-small {
        font-size: $text-big-ish;
        font-weight: $fw-base;
        line-height : $lh-tight;
      }
    }

    &__short-border {
        width         : 72px;
        height        : 2px;
        border        : 2px solid $color-teacher;
        margin-top    : 32px;
        margin-bottom : 32px;

        @include media-small {
          margin-top    : 24px;
          margin-bottom : 24px;
        }
    }

    &__benefits{
      display: grid;
      grid-template-columns: auto auto auto;
      grid-column-gap: 24px;
      @include media-small{
        grid-template-columns: auto 
      }
      line-height: $lh-loose;
      text-shadow: 0 0 4px white;
    }

    &__benefit{
      break-inside: avoid;
      &__text {
        @include media-notSmall {
          color: $sn-primary-header;
        }
      }
    }

    &__more-title { 
      @include media-small {

        &:nth-child(10) {
          opacity : .5;
        }

        &:nth-child(11) {
          opacity : .2;
        }

        &:nth-of-type(n+12)
        {
            display:none;
        }
      }
    }
  }

.teacher_buyNow {
  background-color: #0066c4 !important;
}