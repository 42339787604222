.nofear-nav {
    border-bottom: 1px solid $sn-border-gray;
}
.noFear__cell--original {
    padding-left: 24px;
}
.noFear_table {
    width: 100%;
}
.nofear__interior {
    position: relative;
    max-width: 1164px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-right: 12px;
    &__heading {
        display: flex;
        padding: 16px 8px;
        width: 190px;
        span {
            display: flex;
            align-items: center;
            svg {
                margin-right: 8px;
            }
        }
        &:hover,
        &:focus {
            border-bottom: 3px solid #004371;
            color: #004371;
            background-color: $color-teacherSoftGray;
            svg {
                path {
                    fill: #004371;
                }
            }
        }
    }
    &__content {
        &:hover, &:focus + #content-section {
            @media (min-width: $mobile-bp) {
                display:  flex !important;
            } 
        }	
        background-color: $white;
        position: absolute;
        top: 59px;
        width: auto;
        left: 12px;
        z-index: 5;
        border: 1px solid $sn-border-gray;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        overflow-x: auto;
        overflow-y: hidden;
        -ms-scroll-snap-type: x proximity;
        scroll-snap-type: x proximity;
        border-radius: 0 0 12px 12px;
        @media (max-width: $mobile-bp) {
            display:block !important;
            top: 0;
            left: 0 !important;
            position: fixed;
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            visibility: hidden;
            display: block;
            z-index: 5;
        }
    }
    &__section {
        padding: 8px 0;
        @media (min-width: $mobile-bp) {
            width: 190px;
            border-right: 1px solid $sn-border-gray;
        }
        @media (max-width: $mobile-bp) {
            text-decoration: underline;
        }
        h5 {
            @include L-title_nav-links;
            padding: 8px 16px;
            color: $sn-primary-paragraph;
            padding-bottom: $s-px * 1.5;
            text-transform: uppercase;
            @media (max-width: $mobile-bp) {
                font-size: $text-slightly-big;
            }
        }
        &__list {
            @media (max-width: $mobile-bp) {
                font-size: $text-slightly-big;
            }    
            font-weight: $fw-chubby;
            font-size: $text-small;
            display: flex;
            line-height: $lh-base;
            &__item {
                width: max-content;
                @media (max-width: $mobile-bp) {
                    width: 100%;
                }
            }
            &__link {
                color: $color-brandBlue2020;
                padding: 8px 16px;
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                &:hover,
                &:focus {
                    color: #004371;
                    background-color: $color-teacherSoftGray;
                }
                &.active {
                    color: $sn-primary-paragraph;
                    &:hover,
                    &:focus {
                        color: $sn-primary-paragraph;
                        background-color: transparent;
                        cursor: default;
                    }
                }
            }
        }
    }
}
.noFear__tooltip__popup {
    display: none;
}

#content-section {
    display: none;
}

#header-section:hover + #content-section {
    @media (min-width: $mobile-bp) {
        display:  flex;
    }    
}
#content-section:hover + #content-section {
    @media (min-width: $mobile-bp) {
        display:  flex;
    }    
}

.nofear-nav-mobileHeader {
    font-size: $text-slightly-big;
    padding: $s-px * 2;
    border-bottom: 1px solid $sn-border-gray;
    display: flex;
    align-items: center;
    &:after {
      margin-left: auto;
      display: block;
      content: "";
      width: 20px;
      height: 20px;
      background: url("/images/svgicon-close-color-off-gray.svg") center no-repeat;
      cursor : pointer;
    }
}

.toc-menu {
    cursor: pointer;
}

.toc-hover {
    border-bottom: 3px solid #004371;
    color: #004371;
    background-color: $color-teacherSoftGray;
    svg {
        path {
            fill: #004371;
        }
    }
}
.toc-additional-styling {
    grid-template-rows: repeat(32, auto);
    height: 450px;
    overflow-y: scroll;
    grid-auto-flow: column;
    display: grid !important;
    &::-webkit-scrollbar-track{
        background-color: $sn-border-gray;
    }

    &::-webkit-scrollbar{
        width: 5px;
        height: 10px;
    }

    &::-webkit-scrollbar-thumb{
        background-color: $sn-dark-hash;
    }
    &:hover {
        display: grid !important;
    }
    .nofear__interior__section {
        padding: unset !important;
    }
}

//Redesign subnav for shakespeare//
.nofear-title-redesign {
    display: flex;
    padding: 8px 0 13px 0;
    flex-direction: row;
    height: 50px;
    &__all-scenes {
        padding: 0 16px;
        width: auto;
        position: relative;
        &:first-child {
            padding: 0 16px 0 0;
        }       
    }

    &__all-acts {
        font-size: $text-base;
        line-height: $lh-base;
        color: $color-a11yGray;
        font-weight: $fw-chubby;
        &:hover {
            color: #004371;
            cursor: pointer;
            border-bottom: 2px solid #004371 !important;
            padding-bottom: 2px;
        }
    }

    &__list {
        padding: 12px 16px 12px 10px;
        &:hover {
            background-color: #f5f1f5;
            color: #004371 !important;
        }
        &__list {
            &__link {
                color: #767676;
                font-weight: $fw-chubby;
                line-height: 1.57; 
            }
        }
    }

    &__individual-scenes {
        visibility: hidden;
        background: $white;
        z-index: 8;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        position: absolute;
        width: auto;
        padding: 0 0 12px 0;
        left: 4px;
        max-width: 190px;
        border-radius: 0 0 6px 6px;
        max-height: 292px;
        overflow-y: auto;
        height: auto;      
        &::-webkit-scrollbar{
            width: 4px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: $color-a11yGray;
            border-radius: 5px;
            height: 2px;
        }
    } 

    &__individual-sonet-scenes {
        .nofear-title-redesign__list {
            padding: 0 !important;
        }

        .nofear-title-redesign__list__link.scene-active {
            border-bottom: 2px solid $sn-primary-header;
            padding-bottom: 4px;
        }

    }
}

.noFearSonnetRedesign {
    .nofear-title-redesign {
        &__individual-scenes {
            max-width: unset;
            max-height: unset;
            display: grid;
            grid-auto-flow: column;
            grid-template-rows: repeat(6,auto);
            left: 1px;
        }
        &__list {
            padding: 12px 22px 11px 16px;
            &:hover {
                background-color: unset;
            }
        }
    }
}

.nofear-title-redesign__all-scenes:hover .nofear-title-redesign__individual-scenes {
    visibility: visible;
}

.nofear-title-redesign__all-acts:focus-within~.nofear-title-redesign__individual-scenes {
    visibility: visible !important;
}

.nofear-shakespeare-subnav-left-arrow {
    padding: 11px 0;
    height: 20px;
    width: 20px;
}

.noFear_redesign__more__text {
    display: flex;
    align-items: center;
    font-family: Raleway;
    font-size: $text-small;
    font-weight: $fw-chubby;
    line-height: $lh-base;
    text-align: left;
    color: #0066c4;
    cursor: pointer;
    margin-top: 2px;
    padding-left: 16px;
}

.nofear-title-redesign__all-scenes:hover .nofear-title-redesign__all-acts {
    border-bottom: unset !important;
    color: #004371 !important;
    padding-bottom: unset;
}

.subnav-partition-noFear {
    display: flex;
    white-space: nowrap;
}

.nofear-title-redesign__all-scenes:has(.nofear-title-redesign__list__link.scene-active) .nofear-title-redesign__all-acts{
    border-bottom: 2px solid $sn-primary-header;
    color: $sn-primary-header;
    padding-bottom: 2px;
    font-weight: $fw-chubby;
}

.nofear-title-redesign__list__link.scene-active {
    color: $sn-primary-header !important;
    font-weight: $fw-chubby;
    line-height: 1.57;
    font-size: $text-base;
}

.nofear-title-redesign__list:hover {
    .nofear-title-redesign__list__link.scene-active {
        color: #004371 !important;
    }
}

.redesign-nofear-nav {
    height: 94px;
    border-bottom: 1px solid $sn-border-gray !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
    @include media-small {
        border-bottom: none !important;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
        height: auto;
        padding-bottom: 14.42px;
    }
    .nofear__interior {
        @media (min-width: 700px) and (max-width: 980px) {
            padding-left: 20px;
        }
    }
    
}

.titleStyling h2.interior-header__title__text__page_scene_title {
    padding-top: 0 !important;
    font-size: $text-big-ish !important;
    font-weight: 600 !important;
    line-height: $lh-base !important;
    display: flex;
    gap: 5px;
    @include media-small {
        font-size: $text-big-ish !important;
        padding-left: 8px;
    }
}

.redesign-free-sample {
    display: flex;
    gap: 5px;
}

.free-sample-title span , .free-sample-sticky-title span {
    color: #008262;
}

.titleStyling h2.interior-header__title__text__pagetitle {
    padding-top: 0 !important;
    font-size: $text-big-ish !important;
    font-weight: 600 !important;
    line-height: $lh-base !important;
}

.first-scene0 {
    left: -8px;
}

.redesign-nofear-title {
    .interior-header__pageFavoritingIcon__noFear {
        margin-top: 4px !important;
    }
}

.redesign-nofear-interior-header-title {
    padding: unset !important;
    border: none;
}

@include media-notSmall {
    .redesign-nofear-sticky-nav {
        z-index: 3 !important;
        .interior-sticky-nav__main {
            height: 0;
            border-bottom: none !important;
        }
    }
    .nofear-slick-toc-shakespeare__active {
        position: fixed;
        top: 0;
        z-index: 4;
    }
}

.interior-sticky-nav.redesign-nofear-sticky-nav.interior-sticky-nav--active {
    z-index: 4 !important;
    height: 94px;
    @include media-small {
        height: auto;
    }
}

.redesign-nofear-sticky-nav.interior-sticky-nav--active .interior-sticky-nav__main {
    height: auto;
    background: $white;
    @include media-small {
        padding-left: 12px;
    }
}

.nofear-title-redesign__list__list__link {
    font-size: $text-base;
}

// redesign audio changes//
@include media-notSmall {
    .interior-audio.redesign-audio{
        margin: -50px 0 0 346px !important;
        width: 356px !important;
        height: 47px !important;
        border: solid 2px #00cc9a !important;
        top: 0 !important;
        position: absolute !important;
    }

    .redesign-nofear-sticky-nav.interior-sticky-nav--active .interior-audio{
        display: block;
        margin: 47px 0 0 347px!important;
        width: 354px !important;
    }

    .redesign-audio {
        .plyr__controls {
            padding: 3.4px 5px!important;
        }

        .plyr__control {
            padding: 5px 6px !important;
            height: 24px !important;
            width: 24px !important;
            svg {
                width: 12.4px !important;
                height: 14px !important;
            }
        }

        .plyr__volume {
            .plyr__control {
                padding: 3px !important;
                svg {
                    width: 20px !important;
                    height: 16px !important;
                }
            }
        }

        .plyr__menu  {
            .plyr__control {
                padding: 3px !important;
                svg {
                    width: 18px !important;
                    height: 18px !important;
                }
            }
            &__container {
                .plyr__control {
                    width: 100% !important;
                }
                .plyr__menu__value {
                    padding-left: calc(var(--plyr-control-spacing, 10px) * 0.7 * 2.5);
                    padding-right: 10px;
                }
                .plyr__control--forward:after {
                    right: 0;
                }

                .plyr__control > span {
                    justify-content: center;
                }

                .plyr__control[role="menuitemradio"]{
                    &:after {
                        left: 7.8px;
                    }
                }
            }
        }

    }

    .redesign-audio {
        .audio_legend_text {
            margin-top: 1.5px;
            width: auto !important;
        }

        .audio_legend_sub_text {
            width: 79px;
            font-size: 11px;
        }

        .interior-audio_legend_plus {
            font-size: 11px;
        }

        .free_sample-text {
            width: 83px;
            padding: 0 !important;
            height: 18px;
            margin-top: -3px;
            font-size: 11px !important;       
            text-align: center;
        }

        .blank-line {
            width: 22px !important;
            background-color: unset !important;
            border-bottom: 2px solid #00cc9a;
        }
        
        .interior-audio_legend.free-audio {
            width: 220px!important;
        }
    }

    .audio-error-display.redesign-audio-error {
        width: 495px;
        height: 40px;
        margin: -60px 77px 14px 350px;
    }
    .redesign-nofear-pagination-sticky-header.interior-sticky-nav--active {
        .audio-error-display.redesign-audio-error {
            width: 495px;
            height: 40px;
            margin: -28px 77px 0px 346px;
        }
    }
    
    .redesign-audio-error {
        .audio-error-message {
            display: flex;
            width: 100%;
        }
        .audio-error-message__icon {
            padding: 0 10px;
        }
        .audio-error-message__text {
            padding-top: 10px;
        }
    }   
}

.interior-sticky-nav--active .redesign-nofear-sticky-title {
    height: 30px !important;
    padding-top: 0px !important;
    display: flex;
    z-index: 0;
    @include media-small {
        height: auto !important
    }
}

.redesign-nofear-sticky-pageTitle {
    .interior-sticky-nav__title__section {
        font-size: $text-big-ish;
        font-weight: $fw-chubby;
        position: relative;

        @include media-small {
            font-size: $text-slightly-big;
            line-height : $lh-base;
            color: $sn-primary-paragraph;
        }
    }
}
 
.redesign-pagination-section {
    position: relative;
    margin: 8px 0 25px 0;
    .top-page-turn-nav {
        margin: 0 !important;
    }
    .top-page-turn-nav__link__label {
        color: $color-brandBlue2020;
        &:hover {
            color: #004371;
        }
    }

    @include media-small {
        margin: 0px 0 16px 0;
    }

    .top-page-turn-nav__link.tag--page-turn {
        &:hover , &:focus {
            .top-page-turn-nav__link__label {
                color: #004371;
            }
        }
    }
}

.nofear_redesign_footer {
    z-index: 5;
}

.lit-redesign-titles-dropdown {
    justify-content: center!important;
    top: -9px;
    position: absolute;
    width: 59px !important;
    left: 50%;
    right: 0;
    height: 388px;

    .interior-pagination-long__dropdown {
        position: absolute;
        left: 0;
        right: 14px;
        @include media-small {
            right: 50%;
        }

        .interior-pagination-long__trigger {
            background-color: $white;
        }
    }

    .interior-pagination-long__dropdown.page_one_dropdown {
        left: unset;
        right: 14px;
        background: $white;
        svg {
            display: none;
        }
    }

    .interior-pagination-long__list {
        min-width: 98px!important;
        width: auto !important;
        position: absolute;
        top: 40px;
        right: 0;
        z-index: 3;
        max-height: 388px;
        overflow-y: auto;
        box-shadow: 0 4px 4px 0 rgba(118, 118, 118, 0.25);
        &::-webkit-scrollbar{
            width: 2px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: $color-a11yGray;
            border-radius: 5px;
            height: 2px;
        }
    }

    .tag--interior-pagination.interior-pagination-long__link {
        padding: 12px 12px 12px 26px;
        font-size: $text-small !important;
        font-weight: $fw-chubby !important;
        text-decoration: none !important;
        line-height: 1.39;
    }
    .interior-pagination-long__link {
        color: $color-brandBlue2020 !important;
        &:hover,&:focus {
            background-color: #f5f1f5;
            color: #004371 !important;
        }
    }

    .interior-pagination-long__link--long-active {
        color: $sn-primary-header !important;
    }

    .interior-pagination-long__step--disabled:first-child {
        display: none;
    }

    .tag--interior-pagination.interior-pagination-long__step--prev.interior-pagination-long__step {
        display: none;
    }

    .interior-pagination-long__step--next.interior-pagination-long__step {
        display: none;
    }
    
    .interior-pagination-long__trigger__text {
        font-size: $text-base;
        font-weight: $fw-chubby;
        line-height: $lh-base;
        color: $sn-primary-header;
    }
    
    .interior-pagination-long__trigger {
        background: none;
        padding: 0;
    }
}

.lit-redesign-titles-dropdown.interior-sticky-nav__navigation--short .interior-pagination-long__list {
    height: auto;
}

.redesign-nofear-pagination-sticky-nav {
    display: none;
}

.redesign-nofear-pagination-sticky-header {
    z-index: 4;
}

.redesign-nofear-nav~.noFear_scene_header {
    padding: 0 !important;
    display: -webkit-box;
}

.show_pagination svg {
    transform: rotate(180deg);
}


@include media-small {
    .redesign-nofear-nav~.noFear_scene_header {
        padding: 0 !important;
        display: -webkit-box;
    }
}

.has_nofear_act {
    .nofear-title-redesign__list__list__link {
        font-size: $text-small;
        color: #0066c4;
        &:hover {
            color: #004371;
        }
    }
}

.has_nofear_act .nofear-title-redesign__list__link.scene-active {
    font-size: $text-small !important;
}

.redesign-nofear-nav .nofear__interior__section {
    width: auto !important;
    border-right: 1px solid $sn-border-gray !important;
    display: flex !important;
}

.nofear_lit {
    //display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    padding: 8px 0 13px 0;

    @include media-small {
        padding: 12px 0 14px 8px;
    }

    .nofear__interior__section__list__link {
        padding: 0 0 2px 0!important;
        font-size: $text-base;
        line-height: $lh-base;
        color: $color-a11yGray;
        font-weight: $fw-chubby;

        &:hover,&:focus {
            color: #004371 !important;
            cursor: pointer;
            padding-bottom: 2px !important;
            background: unset !important;
        }

        @media only screen and (max-width: 980px) {
            padding-bottom: 0 !important;
        }

        @include media-small {
            color: rgba(118, 118, 118, 0.75);
        }
    }

    .owl-container {
         height:unset !important;
    }

    .lit-subnav-activelist {
        padding-bottom: 4px;
        font-size: $text-base;
        font-weight: $fw-chubby;
        line-height: $lh-base;
        text-align: left;
        color: $sn-primary-header;
        border-bottom: 2px solid $sn-primary-header;
        &:hover {
            color : $sn-primary-header !important;
        }
    }

    // .owl-next {
    //     display: flex !important;
    //     align-items: center !important;
    //     font-weight: 600 !important;
    //     top: -2% !important;
    //     right: -1.45%!important;
    //     color: #0066c4!important;
    //     font-size: $text-small !important;
    //     padding: 4px 6px 3px 0!important;
    //     background-color: $white !important;
    //     background-image: unset !important;
    //     margin: unset !important;
    //     background-position: unset !important;
    //     width: unset !important;
    //     height: unset !important;

    //     &:hover {
    //         span {
    //             color: #004371;
    //         }
    //         color: #004371;
    //         .owl-right-arrow {
    //             color: #004371!important;
    //             filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
    //         }
    //     }

    //     @include media-small {
    //         padding: 4px 6px 5px 0 !important;
    //     }
    // }

    // .owl-prev {
    //     top: 2px;
    //     background-color: $white;
    //     left: 0;
    //     width: unset;
    //     background-image: unset;
    //     background-position: unset;
    //     font-weight: 600;

    //     &:hover {
    //         color: #004371!important;
    //         .owl-left-arrow {
    //             color: #004371!important;
    //             filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
    //         }
    //     }

    //     @include media-small {
    //         left: -2px;
    //     }
    // }
    
    // .owl-right-arrow {
    //     padding-top: 1.26px;
    // }

    // .owl-theme .owl-nav .disabled {
    //     opacity: 0!important;
    //     display: none;
    // }

    // .owl-theme .owl-nav .disabled {
    //     opacity: 0!important;
    //     display: none;
    // }

    .onHoverMore {
        .owl-next {
            top: 47.38% !important;
        }
        .owl-prev {
            top : 48.40% !important;
            height: unset !important;
        }
    } 
    
    .nofear_left_fade {
        position: absolute;
        z-index: 4;
        width: 70px;
        height: 10px;
        margin-left: 0px;
        background-image: -webkit-linear-gradient(left,$white,rgba(255,255,255,0));
        background-repeat: repeat-x;
        padding: 20px 0;
        top: 3px;
        display: none;
    }

    .nofear_right_fade {
        position: absolute;
        z-index: 4;
        width: 70px;
        top: 3px;
        height: 10px;
        float: right;
        right: 4.7%;
        background-image: -webkit-linear-gradient(right,$white,rgba(255,255,255,0));
        background-repeat: repeat-x;
        padding: 20px 0;

        @media (min-width: 700px) and (max-width: 1147px) {
            right: 55px;
        }

        @include media-small {
            width: 38px;
            top: 8px;
            height: 10px;
            float: right;
            right: 4.5%;
            padding: 16px 0;
        }
    }

    .all_lit_titles_acts{
        position: relative;
    }

    .redesign_acts {
        visibility: hidden;
        background: $white;
        z-index: 20;
        box-shadow: 0 4px 4px 0 rgba(0,0,0,.25);
        position: absolute;
        width: max-content;
        padding: 0 0 12px 0;
        left: 0;
        border-radius: 0 0 6px 6px;
        max-height: 292px;
        overflow-y: auto;
        height: auto;

        &::-webkit-scrollbar{
            width: 4px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: $color-a11yGray;
            border-radius: 5px;
            height: 2px;
        }
        
        .nofear__interior__section__list__link {
            font-size: $text-small;
            color: $color-brandBlue2020;
        }

        .lit-subnav-activelist {
            text-decoration: none !important;
            color: $sn-primary-paragraph;  
            border: none;
        }

        @media only screen and (max-width: 980px) {
            display: none;
        }
       
    }

    .redesign_acts.first-scene0 {
        left: 2px !important;
       .nofear__interior__section__list__item {
            padding: 12px 17px 12px 5px;
        }
    }

    .all_lit_titles_acts {
        &:hover , &:focus-within , &:focus {
            cursor: pointer;
            h5 {
                color: $text-base;
            }
            .nofear-lit-title-redesign__act {
                @media only screen and (max-width: 980px) {
                    color: #004371;
                }
            }
            .redesign_acts {
                visibility: visible;
            } 
        }
        
        .nofear__interior__section__list__item {
            padding: 12px 17px 12px 5px;
            &:hover {
                background: #f5f1f5;
                .nofear__interior__section__list__link {
                    color: #004371;
                }
            }
            
        }  
         
    }

    .nofear-lit-title-redesign__all-acts {
        font-size: $text-base;
        line-height: $lh-base;
        color: $color-a11yGray;
        font-weight: $fw-chubby;

        @include media-small {
            color: rgba(118, 118, 118, 0.75);
        }
    }

    .all_lit_titles_acts:has(.nofear__interior__section__list__link.lit-subnav-activelist) .nofear-lit-title-redesign__all-acts{
        border-bottom: 2px solid $sn-primary-header;
        color: $sn-primary-header;
        padding-bottom: 2px;
        font-weight: $fw-chubby;
        @media only screen and (max-width: 980px) {
            padding-bottom: 0;
        }
    }
   
    .setSlickHeight {
        height: auto;
        min-height: 378px;
        //margin-top: 348.21px;
    } 

    .slide-arrow.prev-arrow {
        position: absolute;
        left: -15px;
        top: 4px;
        border: none;
        font-size: 20px;
        color: #0066c4;
        background: $white;
        cursor: pointer;
        img {
            width: 19px;
            height: 19px;
        }
        &:hover {
            color: #004371;
            img {
                color: #004371!important;
                filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
            }
        }
        @include media-small {
            left: -14px;
            top: 4px;
        }
    }
    
    .slide-arrow.next-arrow {
        display: flex;
        align-items: center;
        font-weight: $fw-chubby;
        position: absolute;
        top: -1px;
        right: -10px;
        color: #0066c4;
        font-size: $text-small;
        padding: 3.93px 0px 9px 0;
        background-color: $white;
        background-image: unset;
        margin: unset;
        background-position: unset;
        width: unset;
        height: unset;
        cursor: pointer;
        @include media-small {
            padding: 5px 0 5px 0;
            span {
                display: none;
            }
        }
        img {
            padding-top: 1.26px;
            width: 19px;
            height: 19px;
        }
        &:hover {
            color: #004371;
            img {
                color: #004371!important;
                filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
            }
        }
    }

    .noFear_lit_slick {
        display: flex !important; 
        z-index: 2;
        visibility: hidden;
    }
    
    .slick-slide {
        width: max-content;
        margin-right: 28px;
        user-select : none;
        @include media-small {
            margin-right: 16px;
        }
    }
    
    .slick-slider .slick-track, .slick-slider .slick-list {
        display: flex;
    }

    .nofear__interior__section__list__link.without_scene.is_title_active {
        border-bottom: 2px solid #484848;
        color: #484848;
        padding-bottom: 2px;
        font-weight: 600;
    }
    .next-arrow.slick-arrow.slick-disabled {
        display: none !important;
    }
    .prev-arrow.slick-arrow.slick-disabled {
        display: none !important;
    }
    .redesign-pagination-section.reduceAdZindexOnHover {
        visibility: hidden;
    }
    .slick-track:before, .slick-track:after {
        display: flex;
    
    }
    
}

.reducePromoZindexOnHover.redesign-main-content{
    z-index: -9;
}

.nofear-slick-toc-shakespeare {

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    padding: 8px 0 13px 0;

    //slick slider css for shakespeare
    .noFear_shakespeare_slick {
        display: flex !important; 
        z-index: 4;
        visibility: hidden;
    }

    .slide-arrow.prev-arrow {
        position: absolute;
        left: -15px;
        top: 4px;
        border: none;
        font-size: 20px;
        color: #0066c4;
        background: $white;
        cursor: pointer;
        img {
            width: 19px;
            height: 19px;
        }
        &:hover {
            color: #004371;
            img {
                color: #004371!important;
                filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
            }
        }
        @include media-small {
            left: -14px;
            top: 4px;
        }
    }
    
    .slide-arrow.next-arrow {
        display: flex;
        align-items: center;
        font-weight: $fw-chubby;
        position: absolute;
        top: 1px;
        right: -10px;
        color: #0066c4;
        font-size: $text-small;
        padding: 2.93px 0 9px 0;
        background-color: $white;
        background-image: unset;
        margin: unset;
        background-position: unset;
        width: unset;
        height: unset;
        cursor: pointer;
        @include media-small {
            padding: 3px 1px 4px 0;
            span {
                display: none;
            }
        }
        img {
            padding-top: 1.26px;
            width: 19px;
            height: 19px;
        }
        &:hover {
            color: #004371;
            img {
                color: #004371!important;
                filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
            }
        }
    }

    .noFear_lit_slick {
        display: flex !important; 
        z-index: 2;
        visibility: hidden;
    }
    
    .slick-slide {
        width: max-content;
        margin-right: 28px;
        user-select : none;
        @include media-small {
            margin-right: 16px;
        }
    }
    
    .slick-slider .slick-track, .slick-slider .slick-list {
        display: flex;
    }

    .nofear__interior__section__list__link.without_scene.is_title_active {
        border-bottom: 2px solid #484848;
        color: #484848;
        padding-bottom: 2px;
        font-weight: 600;
    }

    .next-arrow.slick-arrow.slick-disabled {
        display: none !important;
    }

    .prev-arrow.slick-arrow.slick-disabled {
        display: none !important;
    }

    .redesign-pagination-section.reduceAdZindexOnHover {
        visibility: hidden;
    }

    .slick-track:before, .slick-track:after {
        display: flex;
    
    }

    .setSlickHeight {
        height: auto;
        min-height: 378px;
        margin-top: 349px;
    } 

    .slide-arrow.next-arrow.onHoverMore{
        top: 349px;
        .slide-arrow.next-arrow img {
            width: 19px;
            height: 19px;
        }
    }

    .slide-arrow.prev-arrow.onHoverMore{
        top: 353px;
    }

    .nofear__interior__section__list__link {
        padding: 0 0 2px 0!important;
        font-size: $text-base;
        line-height: $lh-base;
        color: $color-a11yGray;
        font-weight: $fw-chubby;
        &:hover,&:focus {
            color: #004371 !important;
            cursor: pointer;
            padding-bottom: 2px !important;
            background: unset !important;
        }
        @media only screen and (max-width: 980px) {
            padding-bottom: 0 !important;
        }
    }

    // .owl-container {
    //      height:unset !important;
    // }

    .shakespeare-subnav-activelist {
        padding-bottom: 4px;
        font-size: $text-base;
        font-weight: $fw-chubby;
        line-height: $lh-base;
        text-align: left;
        color: $sn-primary-header;
        border-bottom: 2px solid $sn-primary-header;
        &:hover {
            color : $sn-primary-header !important;
        }
    }

    // .owl-next {
    //     display: flex !important;
    //     align-items: center !important;
    //     font-weight: 600 !important;
    //     top: -2% !important;
    //     right: -1.3% !important;
    //     color: #0066c4!important;
    //     font-size: $text-small !important;
    //     padding: 4px 6px 3px 0!important;
    //     background-color: $white !important;
    //     background-image: unset !important;
    //     margin: unset !important;
    //     background-position: unset !important;
    //     width: unset !important;
    //     height: unset !important;

    //     &:hover {
    //         span {
    //             color: #004371;
    //         }
    //         color: #004371;
    //         .owl-right-arrow {
    //             color: #004371!important;
    //             filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
    //         }
    //     }

    //     @include media-small {
    //         padding: 4px 6px 5px 0 !important;
    //     }
    // }

    // .owl-prev {
    //     top: 2px;
    //     background-color: $white;
    //     left: 0;
    //     width: unset;
    //     background-image: unset;
    //     background-position: unset;
    //     font-weight: 600;
    //     @include media-small {
    //         left: -2px;
    //     }

    //     &:hover {
    //         color: #004371!important;
    //         .owl-left-arrow {
    //             color: #004371!important;
    //             filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
    //         }
    //     }
    // }

    // .onHoverMore {
    //     .owl-next {
    //         top: 47.38% !important;
    //     }
    //     .owl-prev {
    //         top : 48.40% !important;
    //         height: unset !important;
    //     }
    // } 

    // .owl-theme .owl-nav .disabled {
    //     opacity: 0!important;
    //     display: none !important;
    // }

    .nofear_left_fade {
        position: absolute;
        z-index: 5;
        width: 70px;
        height: 10px;
        margin-left: 0px;
        background-image: -webkit-linear-gradient(left,$white,rgba(255,255,255,0));
        background-repeat: repeat-x;
        padding: 17px 0;
        top: 3px;
        display: none;
    }

    .nofear_right_fade {
        position: absolute;
        z-index: 4;
        width: 70px;
        top: 3px;
        height: 10px;
        float: right;
        right: 4.7%;
        background-image: -webkit-linear-gradient(right,$white,rgba(255,255,255,0));
        background-repeat: repeat-x;
        padding: 20px 0;

        @media (max-width: 1080px) {
            right: 55px;
        }

        @include media-small {
            width: 38px;
            top: 5px;
            height: 10px;
            float: right;
            right: 4.8%;
            padding: 16px 0;
        }
    }

    .all_shakespeare_titles_acts{
        position: relative;
    }

    .redesign_acts {
        visibility: hidden;
        background: $white;
        z-index: 20;
        box-shadow: 0 4px 4px 0 rgba(0,0,0,.25);
        position: absolute;
        width: max-content;
        padding: 0 0 12px 0;
        left: -17px;
        border-radius: 0 0 6px 6px;
        max-height: 292px;
        overflow-y: auto;
        overflow-x: hidden;
        height: auto;

        &::-webkit-scrollbar{
            width: 4px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: $color-a11yGray;
            border-radius: 5px;
            height: 2px;
        }
        
        .nofear__interior__section__list__link {
            font-size: $text-small;
            color: $color-brandBlue2020;
        }

        .shakespeare-subnav-activelist {
            text-decoration: none !important;
            color: #292c2e;  
            border: none;
        }

        @media only screen and (max-width: 980px) {
            display: none;
        }
    }

    .redesign_acts.first-scene0 {
        left: 2px !important;
       .nofear__interior__section__list__item {
            padding: 12px 17px 12px 5px;
        }
    }

    .all_shakespeare_titles_acts {
        &:hover , &:focus-within , &:focus {
            cursor: pointer;
            h5 {
                color: $text-base;
            }
            .redesign_acts {
                visibility: visible;
            }
            .nofear-lit-title-redesign__act {
                @media only screen and (max-width: 980px) {
                    color: #004371;
                }
            }
        }
        
        .nofear__interior__section__list__item {
            padding: 12px 17px 12px 16px;
            &:hover {
                background: #f5f1f5;
                .nofear__interior__section__list__link {
                    color: #004371;
                }
            }
            
        }  
         
    }

    .nofear-lit-title-redesign__all-acts {
        font-size: $text-base;
        line-height: $lh-base;
        color: $color-a11yGray;
        font-weight: $fw-chubby;

        @include media-small {
            color: rgba(118, 118, 118, 0.75);
        }
    }

    .all_shakespeare_titles_acts:has(.nofear__interior__section__list__link.shakespeare-subnav-activelist) .nofear-lit-title-redesign__all-acts{
        border-bottom: 2px solid $sn-primary-header;
        color: $sn-primary-header;
        padding-bottom: 2px;
        font-weight: $fw-chubby;
        @media only screen and (max-width: 980px) {
            padding-bottom: 0;
        }
    }
   
    .setOwlItemHeight {
        height: auto;
        min-height: 378px;
        margin-top: 349px;
    }

    @include media-small {
        .owl-item {
            margin-right: 16px;
        }
        padding: 12px 0 14px 8px;
    }

}

.redesign-nofear-lit-sticky-nav {
    z-index: -1;
    height: 98px;
    display: none;
}

.redesign-nofear-sticky-title {
    z-index: -1;
    height: 98px;
    display: none;
    @include media-small {
        .redesign-nofear-sticky-pageTitle {
            padding: 0 0 12px 8px;
            height: auto;
        }
    }
}

.hide-lit-audio {
   height: inherit;
}

@include media-notSmall {
    .redesign-main-content{
        .modernTxtHidden {
            .noFear__tooltip {
                opacity: 0.2;
                text-shadow: none;
                color: transparent !important;
            }
        }
    }

    .reducePromoZindexOnHover {
        z-index: 0;
    }
    
    #pwContainerRightRail.reduceAdZindexOnHover {
        z-index: unset !important;
    }
}

.nofear-slick-toc-shakespeare.nofear_sonnet_redesign{
    .redesign_acts {
        .nofear__interior__section__list__link {
            width: max-content;
        }
    }
}

@media only screen and (max-width: 980px) { 
    .nofear-slick-toc-shakespeare,.nofear_lit {
        .owl-container {
            position: relative;
        }
        
        .owl-carousel .owl-item {
            cursor: pointer;
            margin-right: 16px !important;
        }

        .owl-next span {
            display: none;
        }

        .nofear_left_fade {
            position: absolute;
            z-index: 5;
            width: 38px;
            height: 10px;
            margin-left: 0px;
            padding: 16px 0;
            top: 10px;
            display: none;
        }
    }

    .lit_mobile_popup {
        position: fixed;  
        width: 0;
        height: 0;
        background: $white;
        z-index: 9999;
        opacity: 0;
        padding: 16px 10px 0 10px;
    
        .nofear-lit-title-redesign__all-acts {
            font-size: $text-big-ish;
            line-height: $lh-base;
            font-weight: $fw-chubby;
            color: $sn-primary-header;
            padding-bottom: 16px;
            border-bottom: 1px solid $color-teacherSoftGray;
        }

        .nofear-lit-title-redesign__act {
            padding-left: 6px;
        }
    
        .redesign_acts {
            visibility: visible;
            background: $white;
            z-index: 20;
            box-shadow: none;
            position: unset;
            width: 100%;
            left: 0;
            max-width: unset;
            min-width: unset;
            border-radius: 0;
            max-height: 800px;
            overflow-y: auto;
            overflow-x: hidden;
            height: 100%;
            margin-top: 16px;
            display: inline-block;
        }

        .is_lit_scene_active {
            background: #0066c4;
        }
    
        .nofear__interior__section__list__item {
            text-align: left;
            padding: 8px 5px 8px 12px !important;
            display: flex;

            &:hover,&:focus {
                background: #f5f1f5 !important;
                .nofear__interior__section__list__link {
                    color: #004371 !important;
                }
            }
        }

        .nofear__interior__section__list__link.lit-subnav-activelist {
            color: $white !important;
        }

        .nofear__interior__section__list__link {
            color: #0066c4 !important;
            font-size: $text-base !important;
            line-height: $lh-base !important;
            font-weight: $fw-chubby;
            display: inline-block;
        }

        .close_popup {
            position: absolute;
            top: 21px;
            right: 16px;
            float: right;
            height: 24px;
            width: 24px;
        }
    
    }

    .shakespeare_mobile_popup {
        position: fixed;  
        width: 0;
        height: 0;
        background: $white;
        z-index: 9999;
        opacity: 0;
        padding : 16px 10px 0 10px;

        .nofear-lit-title-redesign__all-acts {
            font-size: $text-big-ish;
            line-height: $lh-base;
            font-weight: $fw-chubby;
            color: $sn-primary-header;
            padding-bottom: 16px;
            border-bottom: 1px solid $color-teacherSoftGray;
        }

        .redesign_acts {
            visibility: visible;
            background: $white;
            z-index: 20;
            box-shadow: none;
            position: unset;
            width: 100%;
            left: 0;
            max-width: unset;
            min-width: unset;
            border-radius: 0;
            max-height: 800px;
            overflow-y: auto;
            height: 100%;
            margin-top: 16px;
            display: inline-block !important;
        }

        .nofear__interior__section__list__link {
            display: inline-block;
        }

        .nofear__interior__section__list__item {
            text-align: left;
            padding: 8px 5px 8px 12px !important;
            display: flex;

            &:hover,&:focus {
                background: #f5f1f5 !important;
                .nofear__interior__section__list__link {
                    color: #004371 !important;
                }
            }
        }

        .nofear-lit-title-redesign__act {
            padding-left: 6px;
        }

        .close_popup {
            position: absolute;
            top: 21px;
            right: 16px;
            float: right;
            height: 24px;
            width: 24px;
        }

        .is_shakespeare_scene_active {
            background: #0066c4;
        }

        a.nofear__interior__section__list__link.shakespeare-subnav-activelist.has_shakespeare_act_link {
            color: $white !important;
            font-size: $text-base !important;
            line-height: $lh-base !important;
            font-weight: $fw-chubby;
        }

        a.nofear__interior__section__list__link.shakespeare-subnav-activelist.has_shakespeare_act_link .toc-free-text {
            color: $white !important;
        }

        .redesign_acts .nofear__interior__section__list__link {
            color: #0066c4 !important;
            font-size: $text-base !important;
            line-height: $lh-base !important;
            font-weight: $fw-chubby;
            &:hover,&:focus {
                color: #004371!important;
            }
        }

        .nofear_sonnet_redesign .redesign_acts {
            display: inline-block !important;
        }

    }

}

@media only screen and (max-width: 320px) { 
    .nofear-slick-toc-shakespeare,.nofear_lit {
        .owl-carousel .owl-item {
            cursor: pointer;
            margin-right: 5px !important;
        }
    }

}

@media only screen and (min-width: 981px) {
    .close_popup {
        display: none;
    }
}

@media (min-width: 700px) and (max-width: 1147px) {
    .owl-next span {
        display: none;
    }
}

.redesign_favorite_icon {
    @include media-small {
        gap: 13px;
    }
}

.redesign-mobile-heading-title {
    @include media-small {
        color: $sn-primary-paragraph;
    }
}

.redesin_promo_modal {
    @include media-small {
        .nofearPromotionalModal.modal_moving_top {
            top: 220px;
        }
        .remove_modal_moving_top {
            top: 10px !important;
        }
    }
}

@include media-notSmall {
    .has_lit_act {
        min-width: 100%;
    }
}