// No Fear / Full Text TOC page
.texts-landing-page {
  // No Fear / Full Text TOC page
  &__toc {
    &__section {
      @include media-landing-small {
        margin-right: $pageMargin;
        margin-left: $pageMargin;
      }
      // each Act will be in a section
      &:not(:last-child) {
        border-bottom: 1px solid $sn-border-gray;
        &.literature {
          border-bottom: none;
        }
      }
      &__title {
        font-weight: $fw-chubby;
        font-size: $text-big;
        line-height: $lh-tight;
        color: $sn-primary-header;
        @include media-landing-small {
          padding-top: 24px;
          padding-bottom: 12px;
          text-align: center;
        }
        @include media-landing-not-small {
          padding-top: 40px;
          padding-bottom: 24px;
        }
      }
      &__list {
        @include media-landing-not-small {
          display: flex;
          flex-wrap: wrap;
        }
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        &--no-description {
          // needs logic for this in XSL file
        }
        &--has-description {
          // needs logic for this in XSL file
        }
      }
      &__item {
        display: flex;
        &--no-description {
          @include media-landing-small {
            justify-content: center;
          }
          @include media-landing-not-small {
            margin-right: 48px;
            margin-bottom: 16px;
          }
        }
        &--has-description {
          width: 100%;
          margin-bottom: 20px;
          .texts-landing-page__toc__section__link {
            padding: 0;
            &__title {
              font-size: $text-slightly-big;
              font-weight: $fw-chubby;
              line-height: $lh-base;
            }
          }
        }
      }
      // Hiding the citation links appearing in no fear toc page. Better approach TBD.
      &__link[href*="/citing/"] {
        display: none;
      }
      &__link {
        padding: 12px 0;
        display: block;
        &:hover {
          color: unset;
          text-decoration: none;
        }
        @include media-landing-small {
          text-align: center;
        }
        &__title {
          color: $color-brandBlue2020;
          line-height: $lh-tight;
        }
        &__description {
          color: $sn-primary-paragraph;
        }
        &:hover {
          .texts-landing-page__toc__section__link__title {
            color: #004371;
            text-decoration: underline;
            font-weight: $fw-chubby;
          }
          .texts-landing-page__toc__section__link__description {
            color: $sn-primary-paragraph;
          }
        }
      }
    }
  }
}

.full-text-blurb {
  &:hover { 
    .texts-landing-page__toc__section__link__description {
        p {
          color: $sn-primary-paragraph !important;
      }
    }
  }
}
