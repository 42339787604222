.layout-wrapper-2018 {
  @include container($padding:true);
  display: flex;
  margin-top: $s-px * 3;
  @include media-under-1020{
    flex-direction: column;
  }
  &--landing-page{
    @include media-small {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__column{
    &--main{
      flex: 1 1 auto;
      margin-bottom: 60px;
      @include media-under-1020{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
      //fighting with a playwire ad too - a fixed width gets added that prevents the column from flexing properly
      .pw-in-article,
      .pw-in-article_hidden{
        // width: 300px !important;
        // margin-left: auto;
        // margin-right: auto;
      }
      [data-pw-mobi]{
        margin-top: $s-px * 8;
        @include playwire-gray-box;
      }
    }
    &--right-rail{
      display: flex;
      flex-direction: column;
      @include media-650-and-upto-1020 {
        margin: auto;
        flex-direction: row;
        flex-wrap: wrap;
      }
      @include media-under-1020{
        width: 100%;
      }
      @include media-1020-and-up{
        margin-left: $s-px * 3;
        // eventually want this to be flexible. May need distroscale to help out though
        max-width: 405px;
        flex: 2 1 auto;
        margin-bottom: 60px;
      }
      &__chunk{
        min-width: 320px;
        >*:last-child{
          margin-bottom: 60px;
        }
        @include media-650-and-upto-1020 {
          width: 50%;
          display: flex;
        }
      }
      &__rect{
        @include media-650-and-upto-1020 {
          width: 100%;
          display: flex;
        }
        @include playwire-gray-box;
      }
    }
  }
}

.apTest-interior {
  @include media-1020-and-up{
    max-width: 500px;
    width: 100%;
  }
}

.apTest-interior .layout-wrapper-2018__column--right-rail__chunk {
  @include media-small {
    min-width: unset;
    width: unset;
  }
}

.layout-wrapper-2018__column--right-rail.redesign-right-rail {
  @include media-1020-and-up{
    margin-left: 12px;
  }
}

.redesign-main-content {
  @include media-1020-and-up{
    padding-right: 23px;
    max-width: 723px;
  }
}

.redesign-main-content:has(.letter-list__headline).redesign-main-content {
  @include media-1020-and-up{
    padding-right: 23px;
  }
}

.layout-wrapper-2018__column--right-rail.newGraphicNovel-noRightRail {
  display: none;
}

.displaying-full-img-graphic-novel {
  @include media-1020-and-up {
    padding-right: 16px;
    max-width: unset;
  }
}

.redesign-lit-right-rail.right_rail {
  padding-left: 0 !important;
}

.redesign_shakespeare_right_rail.right_rail {
  padding-left: 0 !important;
}

.addleft-background-mint-color {
  background-color: #f3fdf9;
  padding: 24px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  margin-bottom: 60px;
  @include media-under-1020 {
    margin-bottom: unset;
  }
  @include media-small {
    background-color: unset;
    padding: unset;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    margin-bottom: 60px;
  }
}

.addright-background-mint-color {
  background-color: #f3fdf9;
  padding: 24px 24px 24px 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  @include media-1020-and-up{
    margin-left: unset !important;
  }
  @include media-small {
    background-color: unset;
    padding: unset;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }
}