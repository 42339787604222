// Global variables

// $sn-max-width: used to set max site width
$sn-max-width: 1164px;

// sizes for breakpoints
$mobile-bp: 980px;
$tablet-bp: $sn-max-width;

// $pageMargin: used to set a standard space around the site content when window is narrower than Tom's design
$pageMargin: 12px;

// $s-px: used when adding pading or spacing to an element. Using multiples of this value helps keep things consistent.
// Note this is only to be used when space is definitiely pixel dependent.
$s-px: 8px;

// $text-base: the default font-size.
$text-base: 1rem; // (16px)

// $text-small: to be used when we need something smaller than standard.
$text-small: 0.875rem; // (14px)

// $text-tiny: to be used when we need something really small - avoid where possible.
$text-tiny: 0.8125rem; // (13px)

// Text heading sizes. 99.999% of the time we will want to use a size defined in this variables file as it matches the style guide and imposed design rules.
// If Design handover a spec or design that uses a font-size not included in this file, they will need to have a written noted reason why they are breaking the rules.
$text-slightly-big: 1.125rem; // (18px)
$text-big-ish: 1.3125rem; // (21px)
$text-big: 1.5rem; // (24px)
$text-very-big: 1.875rem; // (30px)
$text-extra-big: 2.25rem; // (36px)
$text-stupid-big: 3rem; // (48px)


// $lh-base: the line-height ratio to use for regular text.
$lh-base: 1.6;

// $lh-tight: the line-height ratio to use for nav items.
$lh-tight: 1.4;

// $lh-loose: used occassionally when we need to give text more room to breathe.
$lh-loose: 1.8;

// $lh-extra-tight: the line-height ratio to use for really tight nav items. Only use when text is uppercase.
$lh-extra-tight: 1.25;

// Font-weight values: Only these five font weights can be used. Designs using thin (100), extra light (200), medium (500), or bold (700) should be rejected.

// $fw-slim: Light font-weight for Raleway
$fw-slim: 300;

// $fw-base: Regular font-weight for Raleway
$fw-base: 400;

// $fw-chubby: Semi-bold font-weight for Raleway
$fw-chubby: 600;

// $fw-fat: Extra-bold font-weight for Raleway
$fw-fat: 800;

// $fw-huge: Heavy font-weight for Raleway
$fw-huge: 900;


// shadows

// $form-focus: used for adding custom focus style to form elements as box-shadow.
// Remember to set outline:none when using this.
$form-focus: 0 0 4px 2px $color-brandBlue2020; 

// border-radius sizes
$radius-base: 4px;
$radius-big: 12px;

// $text-super: to be used only for superscript and subscript notation.
$text-super: 0.75rem; // (12px)

// $channels: A list of all channels. Useful for looping over with @each
$channels: "history", "cs", "us-government-and-politics", "physics", "math", "biology", "sociology", "poetry", "health", "chemistry", "biography", "psychology", "economics", "philosophy", "drama", "film", "short-stories", "lit";