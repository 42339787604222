$flashcard-promo-text: #004a3e;
@mixin mobile-galaxy-max {
    @media (max-width: 280px) {
      @content;
    }
}
@mixin mobile-iphone5-max {
    @media (max-width: 320px) {
      @content;
    }
}
.flashcard_interior {
    width:100%;
    justify-content: center;
    position: relative;
    &__flipcard{
        width: 100%;
        height: 366px;
        perspective: 1000px;
        background-color: transparent;
        padding-top: 6px;
        overflow: hidden !important;
        @include media-small {
            height: 375px;
        }
    }
    &__flipcard_inner{
        position: relative;
        transition: transform 0.6s;
        transform-style: preserve-3d;
    }
    &__flipcard_inner_front, &__flipcard_inner_back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    &__flipcard_inner_back {
        transform: rotateX(180deg);
    }

    &__flipcard_inner_fliped {
        transform: rotateX(180deg);
    }
    &__flipcard_inner_fliped &__flipcard_inner_front {
        display: none;
    }
    &__flipcard_inner_hideshow {
        visibility: hidden;
    }
    &__readmore {
        font-weight: $fw-chubby;
        display: block;
        font-family: Raleway;
        font-size: 16px;
        color: #0066c4;
        &:hover {
            color : #004371;
        }
    }

    &__content {
        min-height: 344px;

        @include media-notSmall {
            padding: 7px 24px 0px 24px;
            margin: 10px auto 0;
            width:560px;
        }
        @include media-small {
            padding: 12px;
            width: 100%;
            min-height: 356px;
            margin: 10px auto 0;
        }

        border-radius: 12px;
        box-shadow: 0 2px 8px 0 rgba(118, 118, 118, 0.45);
        border: solid 4px $sn-green;

        legend {
            border-radius: 15px;
            box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.21);
            background-color: $white;
            padding:  7px 12px;
            text-align: center;
            margin: 0px auto;
        }
        &__hidelegend{
            visibility: hidden;
            padding:0px !important;
        }

        &__inactive_star
        {
            background: url(/images/flashcard/bookmark.svg) no-repeat -5px center;
            width: 16px;
            height: 26px;
            visibility: visible;
            position: relative;
            caret-color: transparent;
            cursor: pointer;
            display: block;
            border: none;

            &__hovertext {
            margin: 6px 25px;
            font-family: Raleway;
            font-size: $text-tiny;
            font-weight: $fw-base;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #008262;
            position: absolute;
            visibility: hidden;
            }


            @include media-notSmall{
                &:hover> &__hovertext {
                    visibility:visible;
                    margin: 6px 25px;
                    font-family: Raleway;
                    font-size: $text-tiny;
                    font-weight: $fw-base;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #008262;
                    position: absolute;
                    }
            }
            &__Solid_star
                {
                    background-position-x: -65px;
                    width: 16px;
                    height: 26px;
                    visibility:visible;
                    position: relative;
                    cursor: pointer;


                    @include media-notSmall{

                    &:hover
                    {
                        background-position-x: -35px;
                        width: 16px;
                        height: 26px;
                        visibility:visible;
                        position: relative;
                        margin: 0 8px 0 0;
                    }
                    &:hover> &__hovertext
                    {
                        visibility:visible;
                        margin: 6px 25px;
                        font-family: Raleway;
                        font-size: $text-tiny;
                        font-weight: $fw-base;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #008262;
                        position: absolute;
                    }
                    }

                }

            @include media-notSmall{
                &:hover
                {
                    background-position-x: -35px;
                    width: 16px;
                    height: 26px;
                    visibility:visible;
                    position: relative;
                    display: block;
                }

            }
        }

        &__thumb_active
        {
            margin: auto;
            padding: 15px 0;
            content: url("/images/flashcard/icon-thumb.svg");
        }
        &__inner {
            width: 100%;
            font-family: Raleway;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.17px;
            text-align: center;
            color: $sn-primary-header;
            // line-height: 200px;
            // margin-top: $s-px;
            position: relative;
            height: 200px;

            h3{
                font-size: $text-big-ish;
                font-weight: $fw-fat;
                line-height: $lh-base;
                text-align: center;
                color: $flashcard-promo-text;
            }
            @include media-notSmall{
                padding:0 50px;
            }
            @include media-small{
            }
            &__question{
                font-size: $text-big;
                font-weight: $fw-fat;
                line-height: $lh-base;
                display: inline-block;
                transition: transform 0.6s;
                transform-style: preserve-3d;
                // transform: rotateX(360deg); // commented this line to fix safari issue.
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                position: absolute;
                margin: auto auto;
                height: fit-content;
                @include media-small{
                    padding: 25px 0;
                }
            }
            &__answer{
                .flashcard_interior__readmore{
                    margin-top: 20px;
                }
                font-size: $text-base;
                font-weight: $fw-chubby;
                line-height: $lh-base;
                display: block;
                margin-top: 8px;
                letter-spacing: 0.11px;
                transition: transform 0.6s;
                transform-style: preserve-3d;
                color: #004a3e;
                font-size: $text-base;
            }
            &__valign{
                vertical-align: top !important;
            }

        }
        &__footer {
            text-align: center;
            margin-top: 0px;

            &__button{
                box-shadow: none;
                &:hover {
                  background-color: $color-brandBlue2020;
                  box-shadow: none;
                }
                @include media-notSmall {
                  width: 200px;
                  position: absolute;
                  bottom: 35px;
                  left: 0;
                  right: 0;
                  margin: auto;
                }
                @include media-small {
                  min-width: 170px;
                  position: relative;
                  bottom: 75px;
                  left: 0;
                  right: 0;
                  margin: auto;
                }
                &:focus-visible {
                    outline: 2px solid $sn-black;
                }
                @include mobile-galaxy-max {
                    min-width: 125px;
                }
            }
        }
    }

    &__bottom {
        &__left {
            position: absolute;
            pointer-events: none;
            @include media-notSmall {
                top: 50%;
                left: 0;
            }
            @include media-small {
                bottom: 73px;
                left: 07px;
            }
            content: url("/images/flashcard/icon-gray-left.svg");

            &__active {
                content: url("/images/flashcard/icon-dark-left.svg");
                cursor: pointer;
                pointer-events: unset !important;
            }
            @include media-small {
                width: 32px;
                margin: 7px 0px 7px 0px;
            }
        }
        &__right {
            position: absolute;
            pointer-events: none;

            @include media-notSmall {
                top: 50%;
                right: 0;    
            }
            @include media-small {
                bottom: 73px;
                right: 07px;
            }
            content: url("/images/flashcard/icon-gray-right.svg");

            &__active {
                content: url("/images/flashcard/icon-dark-right.svg");
                cursor: pointer;
                pointer-events: unset !important;
            }
            @include media-small {
                width: 32px;
                margin: 7px 0px 7px 0px;
            }
        }
    }
    &__deckfooter
    {
        @include media-notSmall {
            width: 560px;
            padding: 40px 24px;
            margin: 0px 70px;
        }
        @media screen and (max-width: 1019px){
                width: 100%;
                margin: 0;
            }
        @include media-small {
            width: 100%;
        }
        float: left;
        text-align: center;
        font-family: Raleway;
        font-size: $text-base;
        font-weight: $fw-chubby;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.37px;
        color: $color-brandBlue2020;

        &__startover
        {
            color: $color-brandBlue2020;
            @media screen and (max-width: 310px) {
                width: 74px;
                float: left;
                margin-left: 12px;
            }
            &::before {
                content: url("/images/flashcard/icon-deck-refresh.svg");
                vertical-align: middle;
                margin-right: 8px;
                width: 26px;
                display: inline-block;
                @media screen and (max-width: 310px) {
                        margin-right: 0px;
                        float: left;
                    }
                }

            &:hover {
                color: $color-linkHover;
                cursor: pointer;
                &::before {
                    content: url("/images/flashcard/icon-deck-refresh-hover.svg");
                    vertical-align: middle;
                    margin-right: 8px;
                    width: 26px;
                    display: inline-block;
                    @media screen and (max-width: 310px) {
                        margin-right: 0px;
                        float: left;
                    }
                }
            }
            @include mobile-galaxy-max {
                width: 79px;
                display: flex;
            }
        }

        &__shuffle {
            @include media-notSmall {
               padding-left: 60px;
            }
            @include media-small {
                padding-left:30px;
            }
            @media screen and (max-width: 310px) {
                width: 132px;
                display: inline-block;
                float: right;
                text-align: left;
            }
            &::before {
                @media screen and (max-width: 310px) {
                    margin-right: 0;
                    width: 34px;
                    float: left;
                }
            }
            &:hover{
                color: $color-linkHover;
                cursor: pointer;
                @media screen and (max-width: 310px) {
                    width: 132px;
                    display: inline-block;
                    float: right;
                    text-align: left;
                }
                &::before {
                    content: url("/images/flashcard/icon-deck-shuffle-hover.svg");
                    vertical-align: middle;
                    margin-right: 8px;
                    width: 26px;
                    display: inline-block;
                    @media screen and (max-width: 310px) {
                        margin-right: 0!important;
                        width: 34px;
                        float: left;
                    }
                }
                @include media-small{
                    color: $color-linkHover;
                    cursor: pointer;
                    &::before {
                        content: url("/images/flashcard/icon-deck-shuffle-hover.svg");
                        vertical-align: middle;
                        margin-right: 8px;
                    }
                }
            }
            @include mobile-galaxy-max {
                display: flex;
                text-align: left;
                margin-left: 30px;
            }
            &__deck-complete-overlay {
                opacity: 0.39;
                pointer-events: none;
            }
        }
        &__shuffle::before {
            content: url("/images/flashcard/icon-deck-shuffle.svg");
            vertical-align: middle;
            margin-right: 8px;
            width: 26px;
            display: inline-block;
        }
        &__shuffleyes {
            color: $color-linkHover;
                &::before{
                    content: url("/images/flashcard/icon-deck-shuffle-check-mark.svg");
                    vertical-align: middle;
                    margin-right: 8px;
                    width: 26px;
                    display: inline-block;
                }
        }
        @include mobile-galaxy-max {
            display: flex;
            // margin: 0 0px 0 15px;
        }
    }
    &__success {
        border: solid 4px $color-correct;
        background: #e1faef;
    }

    &_modalmsg
    {
        margin: 36px 80px 10px 20px;
        line-height: 1.38;
        @include media-small{
            margin: 0;
            padding: 22px;
        }
    }
    &_shufflecardyesbtn{
        width: 45%;
        margin-right: 5%;
        padding: 12px;
        height: 42px;
        border-radius: 4px;
        border: solid 1px $color-brandBlue2020;
        background-color: $color-brandBlue2020;
        color: $white;
        font-family: 'Raleway';
        font-size: 16px;

        &:hover  {
            border: solid 1px $color-linkHover;
            background-color: $color-linkHover;
            cursor: pointer;
        }

    }

    &_shufflecardbuttons{
        margin-top: 9%;
        width: 100%;
        display: flex;
        padding: 0 26px;
        &_error {
            margin-bottom: 20px;
        }
    }

    &_shufflecardcancelbtn{
        width: 45%;
        margin-left: 5%;
        background-color: $white;
        height: 42px;
        border-radius: 4px;
        border: solid 1px $color-brandBlue2020;
        color: $color-brandBlue2020;
        font-family: 'Raleway';
        font-size: 16px;

        &:hover  {
            border: solid 1px $color-linkHover;
            background-color: $color-linkHover;
            cursor: pointer;
            color: $white;
        }

    }

    &__startover_modal {
        &_background {
            visibility: hidden;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width:100%;
            height:100%;
            overflow: hidden;
            background-color: rgba(72,72,72,.75);
            z-index: 11;
        }

        &_box {
            z-index: 999;
            position: absolute;
            background-color: $white;
            border-radius: 12px;
            box-shadow: 0 2px 4px 0 $sn-off-black;
            overflow: hidden;
            @include media-notSmall {
                top: 200px;
                left: 32%;
                width: 473px;
                height: 259px;
                animation: modalGrowIn .3s cubic-bezier(0, 0, .2, 1);
             }
            @include media-small {
                left:10%;
                width: 80%;
                top: 30%;
                box-shadow: none;
             }
        }

        &_icon_container {
            width: auto;
            height: 83px;
            box-shadow: none;
            background-color: #008262;
            border: 3px solid #008262;
            border-radius: 0;
        }

        &_cross {
            position: absolute;
            padding: 12px;
            right:0%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover, :focus
            {
            filter: drop-shadow(16px 16px 20px #292c2e) invert(77%);
            }
        }

        &_heading {
            margin: 6% 0 0 5%;
            position: absolute;
            color: $white;
            font-family: Raleway;
            font-size: $text-big-ish;
            font-weight: 550;
            font-stretch: normal;
            font-style: normal;
            line-height: $lh-base;
            letter-spacing: normal;
        }
    }

    &__startover_modal_box {
        p {
            margin: 35px 29px 34px 20px;
            font-family: 'Raleway';
            color: $sn-primary-header;
            font-size: $text-base;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
        }
        .modal-buttons {
            margin-bottom: 7%;
            margin-right: 0;
            width: 100%;
            display: flex;
            padding: 0 26px;
        }

        .submit_state_button {
            height: 42px;
            width: 45%;
            margin-right: 5%;
            border-radius: 2px;
            border: solid 1px $color-brandBlue2020;
            background-color: $color-brandBlue2020;
            color: $white;
            font-family: 'Raleway';
            font-size: $text-base;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            &:hover  {
                border: solid 1px $color-linkHover;
                background-color: $color-linkHover;
                cursor: pointer;
            }

        }

        .cancel_state_button {
            width: 45%;
            margin-left: 5%;
            height: 42px;
            border-radius: 2px;
            border: solid 1px $color-brandBlue2020;
            color: $color-brandBlue2020;
            font-family: 'Raleway';
            font-size: $text-base;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            background-color: $white;
            &:hover {
                border: solid 1px $color-linkHover;
                background-color: $color-linkHover;
                cursor: pointer;
                color: $white;
            }

        }
    }

    legend.progress_indicator {
        width: auto;
    }
    &__go-to-study-guide-mobile {
        display: none;
        position: relative;
        font-weight: 600;
        color: #007acd;
        bottom: 75px;
        left: 0;
        right: 0;
        margin: auto;
        padding: 12px 24px;
    }
    &__deckfooter-has-error-shuffle {
        opacity: 0.39;
        pointer-events: none;
    }
    &__deckfooter.flashcard_interior__deckfooter-has-error a.flashcard_interior__deckfooter__startover {
        opacity: 0.39;
        pointer-events: none;
    }
    &__slider-has-error.flashcard_interior__bottom__left__active, &__slider-has-error.flashcard_interior__bottom__right__active {
        opacity: 0.15;
        pointer-events: none;
    }
    &__content-has-error {
        border: none;
        @include media-small {
            width: 99%;
        }
    }

}

.flashcard_interior.notext_selection {
    -webkit-user-select: none !important;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none !important;
}

.flashcard_interior__deckfooter.disableStartOver a.flashcard_interior__deckfooter__startover {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.39;
    pointer-events: none;
}


.shuffle-deck-modal.snplusmodal__header .snplusmodal__close:focus svg, .snplusmodal__close:hover svg {
    filter: none;
}

.flashcard_promo_desktop {
    @include media-small {
        display: none;
    }
}

.flashcard_promo_responsive {
    @include media-notSmall {
        display: none;
    }
    @include media-small {
        padding-left: unset !important;
        padding-right: unset !important;
    }
}

.flashcard-image-question-wrapper {
    position: relative;
    width: 100%;
}

.flashcard-promo-image {
    padding-top: 0 !important;
}

.gating-page_content__question {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -50%);
    width: 470px;
    height: 65.2px;
    padding: 18px 50px;
    font-size: $text-big;
    font-weight: $fw-fat;
    line-height: $lh-base;
    letter-spacing: 0.17px;
    text-align: center;
    color: $flashcard-promo-text;
    @include media-small {
        top: 65%;
        width: auto;
        height: auto;
        padding: 0;
        font-size: 20px;
    }
    @include mobile-galaxy-max {
        font-size: 11px;
    }
    @include mobile-iphone5-max {
        font-size: 15px;
    }
    @include media-650-and-upto-1020 {
        width: 310px;
        height: auto;
    }
    @media (max-width: 340px) {
        top:0;
        padding-top: 75%;
    }
    @media (min-width: 700px) and (max-width: 1019px) {
        margin-top: 42% !important;
        top: 0;
        font-size: 1.3rem;
    }
    @media (min-width: 1020px) {
        margin-top: 40%;
        top: 0;
    }
}

.shuffle-deck-modal.shuffle-deck-error-container {
    @include media-small{
      height: auto !important;
    }
}
.question-line-more-than-3{
    padding: 10px 0;
}
.question-line-more-than-4{
    font-size: 1.2rem;
    padding: 10px 0;
}
.question-line-more-than-5{
    font-size: 1.2rem;
    padding: 10px 0;
    @include media-small {
        font-size: 1rem;
    }
    @include media-very-tiny {
        font-size: 0.89rem;
        padding: 0;
    }
}
.hide-answer {
    display: none!important;
}

.flashcard_interior__content__inner.answer-container {
    height: fit-content;
    top: 0;
    bottom: 0;
}

.answer-line-more-than-3 {
    padding: 35px 0;
    .flashcard_interior__content__inner__item.flashcard_interior__content__inner__answer {
        font-size: $text-base;
    }
    .flashcard_interior__content__inner__item.flashcard_interior__content__inner__answer a{
        font-size: $text-base;
    }
    @include media-small {
        padding: 20px 0;
    }
}

.answer-line-more-than-4 {
    padding: 30px 0;
    .flashcard_interior__content__inner__item.flashcard_interior__content__inner__answer {
        font-size: $text-small;
    }
    .flashcard_interior__content__inner__item.flashcard_interior__content__inner__answer a{
        font-size: $text-base;
    }
    @include media-small {
        padding: 20px 0;
    }
}

.answer-line-more-than-2 {
    padding: 40px 0;
    .flashcard_interior__content__inner__item.flashcard_interior__content__inner__answer {
        font-size: $text-big-ish;
    }
    .flashcard_interior__content__inner__item.flashcard_interior__content__inner__answer a{
        font-size: $text-base;
    }
    @include media-small {
        padding: 35px 0;
    }
}

