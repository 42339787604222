// $L-break-noFear-small: A breakpoint custom to No Fear pages. We use it to make the content overflow and scroll horizontally at this width and below.
$L-break-noFear-small: 600px;
// $L-lh-noFear: Custom line-height we use for No Fear content.
$L-lh-noFear: 1.8;
// $L-enumeration: the font size we use in No Fear for line numbers. Note this is smaller than we allow anywhere else on the site.
$L-enumeration: 12px;

// media query for applying styling at or below the $L-break-noFear-small breakpoint
@mixin L-media-noFear-small {
  @media screen and (max-width: $L-break-noFear-small) {
    @content;
  }
}

// media query for applying styling above the $L-break-noFear-small breakpoint
@mixin L-media-noFear-notSmall {
  @media screen and (min-width: ($L-break-noFear-small + 1)) {
    @content;
  }
}

// media query for applying styling above for the  breakpoint
@mixin mobile-galaxy-max {
  @media (max-width: 280px) {
    @content;
  }
}

@mixin ipad-pro {
  @media (max-width: 1025px) {
    @content;
  }
}

@mixin ipad {
  @media (max-width: 769px) {
    @content;
  }
}

// A class added to the 'content-container interior-container' div that wraps the 'left rail' or 'main content area'.
.nofear-container {
  padding-top: 0;
  max-width: 752px;
}

//A div that wraps the table
#noFear-comparison {
  border-top: 1px solid $sn-gray;
  border-bottom: 1px solid $sn-gray;
  width: 100%;
  line-height: $lh-base;
  //on small devices we overflow this:
  @include L-media-noFear-small {
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
  }
  margin-bottom: $s-px * 3;
}

// The table that contains the no-fear text.
.noFear {
  //we make each column equal width:
  table-layout: fixed;
  @include L-media-noFear-notSmall {
    width: 100%;
  }
  //on small devices we use a fixed width here and it scrolls within its parent:
  @include L-media-noFear-small {
    width: $L-break-noFear-small;
  }
}

// The class on the h3 elements that say 'Original text' and 'Modern text' at the top of the table
.noFear__title {
  font-weight: $fw-base;
  text-transform: uppercase;
  color: $color-a11yGray;
}
.nofear-comparison-default .noFear__title {
  padding-top: 15px;
} 
// All <td> elements
.noFear__cell {
  width: 50%;
  padding-top: $s-px * 1.5;
  padding-right: $s-px * 1.5;
  padding-bottom: $s-px;

  color: $sn-primary-paragraph;
  line-height: $L-lh-noFear;
  //only 'original' cells
  &--original {
    background-color: #fafafa;
    border-right: 1px solid $sn-gray;
    // titles with line numbers get this class added to the table. We use it to allow some space for them.
    .noFear--hasNumbers & {
      padding-left: $s-px * 3;
    }
    // titles without line numbers don't need the extra space
    .noFear--noNumbers & {
      padding-left: $s-px * 1.5;
    }
  }
  //only 'modern' cells
  &--modern {
    padding-left: $s-px * 1.5;
  }
  //only the cells that house the 'original text' and 'modern text' titles
  &--title {
    padding-bottom: 0;
  }
}

//applies to any regular line of text - Original only

.noFear__stage {
  font-style: italic;
}

.noFear__speaker {
  font-weight: $fw-chubby;
  // stacking classes to increase specificty
  @at-root p#{&}#{&} {
    line-height: $lh-base;
    //hack to overwrite styling caused by more general purpose css that applies to multiple pages
    padding-bottom: 0;
  }
}

// lines with line numbers get this class added. We use it to allow the number to be positioned absolutely on it
.noFear__line {
  // Below is a possible improvement for sonnets, but the modern text would look comparatively tight.
  // Leaving out for now until Editorial can advise.
  // TODO: See how John/Jennie would like this and either uncomment or delete as appropriate.

  // + .noFear__line {
  //   margin-top: $s-px;
  // }

  &--hasLineNumber {
    position: relative;
  }
}

// The line number that appears in the original text
.noFear__number {
  $hackyLineHeight: 28px;
  position: absolute;
  color: $sn-off-gray;
  font-size: $L-enumeration;
  line-height: $hackyLineHeight; // matches up with line-height of larger text on page. Would need changing if we update main text line-height.
  font-variant-numeric: tabular-nums; //doesn't do anything right now, but will improve number alignment when we switch to WOFF versions
  top: 0;
  right: calc(
    100% + #{$s-px} * 0.75
  ); //positioning from the right insteadof the left allows the item to sitll align the same with a flexible width
}

//EVERYTHING BELOW HERE IS TOOLTIP SPECIFIC

//any item that has tooltip text
.noFear__tooltip {
  $triangleSize: 12px;
  position: relative;
  // A span that wraps the word in the main text
  &__trigger {
    white-space: normal;
  }
  // A span that is used as the tooltip content
  &__popup {
    position: absolute;
    z-index: 1;
    top: calc(100% + #{$triangleSize});
    display: none;
    width: $s-px * 40; // in IE11 we just always make a fixed width
    // In modern browsers:
    @supports (width: max-content) {
      // Allow to be width of text within it:
      width: max-content;
      // but not smaller than this:
      min-width: $s-px * 15;
      // and not bigger than this:
      max-width: $s-px * 40;
    }
    .noFear__cell--modern & {
      left: 50%;
      transform: translateX(-75%);
    }
    .noFear__cell--original & {
      right: 50%;
      transform: translateX(75%);
    }
    background-color: $white;
    border-radius: $radius-base;
    padding: 12px;
    filter: drop-shadow(0 0 15px rgba(#000, 0.2));
    // The title in the tooltip content
    &__heading {
      font-weight: $fw-chubby;
      // text-transform: uppercase;
      line-height: $lh-base;
    }
    // the description text inside the tooltip content
    &__copy#{&}__copy {
      padding-bottom: 0;
    }
    // creates the triangle for the tooltip
    &:after {
      border: solid transparent;
      content: " ";
      position: absolute;
      border-width: $triangleSize;
      border-bottom-color: $white;
      bottom: 100%;
      .noFear__cell--modern & {
        transform: translateX(50%);
        right: 25%;
      }
      .noFear__cell--original & {
        transform: translateX(-50%);
        left: 25%;
      }
    }

    // Things that change on the .tooltip-css when you hover over the .tooltip-link. Some of these rules should merge into the main style.
    .noFear__tooltip:hover & {
      display: block;
    }
  }
}
// Audio //
.next-previous {
  display: none;
}
:root {
  --plyr-color-main: #00cc9a;
}
.interior-audio {
  position: absolute;
  background-color: $white;
  border-radius: 4px;
  border: solid 1px #00cc9a;
  top: 9px;
  @include media-notSmall {
    width: 361px;
    height: 68px;
    padding: 0px 1px 0px 2px;
    .interior-audio-button {
      display: none;
    }
  }

  @include media-small {
    position:fixed;
    position: fixed;
    width: 100%;
    height: fit-content;
    bottom: 0;
    z-index: 999;
    bottom: 0px;
    right: 0%;
    border: 2px solid #00cc9a;

  .plyr__volume {
    display: none;
  }
}
}
.hide-interior-audio {
  position: absolute!important;
  top: 5px!important;
  z-index: 1;
  @include media-notSmall {
    display: none;
  }
}
.page-turn-nav__link-audio {
  margin-top: 65px;
  @include media-notSmall {
    display: none;
  }
}
.interior-audio_legend {
  width: 125px;
  height: 14px;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  color: #004a3e;
  margin-left: 8px;
  padding-left: 4px;
  &_plus {
    color: #008262;
  }
  @include media-small {
    margin-left: 6px;
    border-radius: 0;
    background-color: $white;
    border: none;
    padding: 0;
    height: unset;
    position: relative;
  }
}
.interior-audio-button {
  margin-top: 8%;
  margin-right: 20%;
  @include media-notSmall {
    display: none;
  }
}
// Modern Text Table //
.nofear-modernText-styling
td.noFear__cell.noFear__cell--modern.noFear__cell--title {
  border-top: solid 2px #00cc9a;
}

.nofear-modernText-styling
td.noFear__cell.noFear__cell--modern {
  border-left: solid 2px #00cc9a;
  border-right: solid 2px #00cc9a;
  color: #004a3e;
  pointer-events: all;
}

.nofear-modernText-styling
.noFear tr:last-child >td.noFear__cell.noFear__cell--modern {
  border-bottom: solid 2px #00cc9a;
}

.nofear-modernText-styling
.nofear-table-data .noFear__cell.noFear__cell--modern {
  border: none !important;
}
//STUFF THAT IS OUTSIDE OF THE MAIN TEXT AREA:

// This hides a select element that allows you to jump to any page.
// Appears to be legacy code.
// TODO: explore removing from XSL.
.next-previous {
  display: none;
}
.nofear-table-data #noFear-comparison {
  display: block !important;
}
.nofear-modernText-styling {
  @include media-small {
    display: none;
  }
}
.modernTxtHidden {
  @include media-small {
    display: none;
  }
}
.noFear-comparison-responsive {
  @include media-notSmall {
    display: none;
  }
}
.nofear__tabbed {
  &__tabs {
    @include media-small {
      display: flex;
      justify-content: center;
    }
  }
  &__tabs__tab {
    @include media-small {
      flex: 1 1 50%;
      text-align: center;
      padding: 12px 24px;
      display: block;
      border-radius: 12px 12px 0 0;
      margin: 0;
      font-family: Raleway;
      font-size: 14px;
      letter-spacing: 0.5px;
      color: #484848;
      padding: 20px;
    }
  }
  &__content {
    @include media-small {
    line-height: 1.6;
    padding-left: 20px;
    background-color: #fafafa;
    }
  }
  &__original__content {
    @include media-small {
      border-bottom: 1px solid #e5e5e5;
      border-right: 1px solid #e5e5e5;
      border-left: 1px solid #e5e5e5;
    }
  }
  &__modern__content {
    @include media-small {
      border-right: 1px solid $sn-border-gray;
      border-left: 1px solid $sn-border-gray;
      background: $white;
      color: #004a3e;
    }

  }
  &__tabs__modern__tab {
    &.active-tab {
      @include media-small {
        font-weight: 600;
        background-color: $white;
        border-top: 1px solid #00cc9a;
        border-right: 1px solid #00cc9a;
        border-left: 1px solid #00cc9a;
        border-radius: 12px 12px 0 0;
        border-bottom: none;
        color: #004a3e;
      }
    }

  }
  &__tabs__modern__tab {
    @include media-small {
      border-bottom: 1px solid #e5e5e5;
      background-color: #fff;
      border-radius: 0;
    }
  }
  &__tabs__modern__tab {
    @include media-small {
      border-bottom: 1px solid #e5e5e5;
      background-color: #fff;
      border-radius: 0;
    }
  }
  &__tabs__original__tab {
    @include media-small {
       border-bottom: 1px solid #00cc9a;
       background-color: #fff;
       border-radius: 0;
    }
}
  &__tabs__original__tab {
    @include media-small {
       border-bottom: 1px solid #00cc9a;
       background-color: #fff;
       border-radius: 0;
    }
}
  &__tabs__original__tab {
    &.active-tab {
      @include media-small {
        font-weight: 600;
        background-color: #fafafa;
        border-top: 1px solid #e5e5e5;
        border-right:1px solid #e5e5e5 ;
        border-left: 1px solid #e5e5e5;
        border-radius: 12px 12px 0 0;
        border-bottom: none;
      }
    }
  }
}

// .modernTxtHiddenTab
// .nofear__tabbed {
//   &__modern__content {
//     @include media-small {
//       background-color: #e1faef;
//       color: transparent;
//       text-shadow: 0 0 5px rgb(0 0 0 / 50%);
//     }
//     p.noFear__speaker.noFear__speaker {
//       color: transparent;
//       text-shadow: 0 0 5px rgb(0 0 0 / 50%);
//     }
//   }
// }

.modernTxtHiddenTab.removingModernTxt
.nofear__tabbed {
  &__modern__content {
    @include media-small {
      background-color: $white;
      color: #004a3e;
      text-shadow: none;
    }
    p.noFear__speaker.noFear__speaker {
      color: #004a3e;
      text-shadow: none;
    }
  }
}

.modernTxtHiddenTab .nofear__tabbed__tabs__modern__tab.active-tab {
  background-color: #e1faef !important;
  color: #004a3e;
  padding-top: 17px;
}

.modernTxtHiddenTab.removingModernTxt .nofear__tabbed__tabs__modern__tab.active-tab {
  background-color: $white !important;
}

//For Hiding the Morden-Text//
.modernTxtHidden td.noFear__cell.noFear__cell--modern.noFear__cell--title {
  border-top: solid 2px #00cc9a;
  color: #004a3e;
  text-shadow: none;
  font-size : $text-slightly-big;
  text-shadow: none;
  letter-spacing: 0.16px;
}

.modernTxtHidden td.noFear__cell.noFear__cell--modern {
  border-left: solid 2px #00cc9a;
  border-right: solid 2px #00cc9a;
  background-color: #e1faef;
  color: transparent;
  text-shadow: 0 0 5px #635959;
  transition: 0.3ms;
  user-select: none;
  pointer-events: none;
}

.modernTxtHidden.removingModernTxt td.noFear__cell.noFear__cell--modern {
  background-color: $white;
  color: $sn-primary-paragraph;
  text-shadow: none;
}

.modernTxtHiddenTab .nofear__tabbed__modern__content span.nofear-modern-text p {
  color: transparent;
  text-shadow: 0 0 5px #635959;
  line-height : 2.4;
}

.modernTxtHidden td.noFear__cell.noFear__cell--modern
p.noFear__speaker.noFear__speaker {
  background-color: #e1faef;
  color: transparent;
  text-shadow: 0 0 5px #635959;
}

.modernTxtHidden.removingModernTxt td.noFear__cell.noFear__cell--modern
p.noFear__speaker.noFear__speaker {
  background-color: $white;
  color: $sn-primary-paragraph;
  text-shadow: none;
}

.modernTxtHidden table.noFear tr:last-child >td.noFear__cell.noFear__cell--modern {
  border-bottom: solid 2px #00cc9a;
}

.modernTxtHidden td.noFear__cell.noFear__cell--original.noFear__cell--title h3 {
  font-size : $text-slightly-big;
  text-shadow: none;
  letter-spacing: 0.16px;
}

.nofear-modernText-styling td.noFear__cell.noFear__cell--modern.noFear__cell--title h3 {
  font-size : $text-slightly-big;
  color: #004a3e;
  text-shadow: none;
  font-weight: normal;
}

.nofear__interior__heading {
  @include media-small {
    width: 100% !important;
  }
}

.nofear__tableofcontent_blue{
  @include media-small {
    margin: 0 4px 0 0;
    font-family: Raleway;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #007acd;
  }
}

.noFear_header_title_hide {
  @include media-small {
    display: none !important;
  }
}

.interior-header.noFear_scene_header {
  padding: 17px 0 15px 0;
  @include media-small {
    padding: 0;
  }
}

.TitleHeader_header.TitleHeader_header--noFear.noFearScene_show~.nofear-nav {
  @include media-small {
    border :none;
  }
}

.interior-header.noFear_scene_header
span.interior-header__title__icon.interior-header__title__icon--noFear {
  width: 47px;
  height: 47px;
}

.interior-header.noFear_scene_header .interior-header__title__text {
  flex-direction: row;
}

.interior-header.noFear_scene_header
span.interior-header__title__umbrella-label.interior-header__title__umbrella-label--noFear {
  padding: 11px 10px 0 0;
}

.interior-header.noFear_scene_header
h2.interior-header__title__text__pagetitle {
  padding-top: 14px;
}
@include media-small {
  .interior-header.noFear_scene_header { 
    position:relative;
    padding: 6px!important;
    .interior-header__title__icon {
      display: none;
    }
    .nofear-scene-title{
      position:absolute;
      top:-50px;
      left:50px;
    }
  }
  .nofear__interior__heading .toc-text {
    display: none;
  }
  .noFear-mobile{
      padding-top: 3.5px!important;
  }
  .paginationContainer .noFearTabSticky {
    top: 95px;
  } 
  .pagination_adjust .adjust_title_styling_for_sticky {
    height: 50px;
  }
}
.nofear-modernText-styling 
td.noFear__cell.noFear__cell--original.noFear__cell--title h3 {
  font-size : $text-slightly-big;
  letter-spacing: 0.16px;
}

.nofear-modernText-styling td.noFear__cell
.noFear__cell--modern.noFear__cell--title h3 {
  font-size : $text-slightly-big;
  font-weight: $fw-base;
  letter-spacing: 0.16px;
  color: #004a3e;
}

.nofear-modernText-styling td.noFear__cell.noFear__cell--modern
p.noFear__speaker.noFear__speaker {
  color: #004a3e;
}

span.nofear-modern-text p.noFear__speaker.noFear__speaker {
  color: #004a3e;
}

.layout-wrapper-2018__column--main.promotionalModalPosition {
    position: relative;
}

.layout-wrapper-2018.promotionalModalPositionForMobile {
  @include media-small {
  padding-top: 0 !important;
  }
}

.interior-header.noFear_scene_header .interior-header__title {
  @include media-notSmall {
    padding-left: 16px;
  }
}
.noFearTabSticky {
  position: sticky;
  z-index: 2;
  top: 39px;
  border-top: 1.5px solid $white;
  background-color: $white;
  box-shadow: 0 3px 5px -2px rgb(0 0 0 / 20%);
  padding-top: 10px;
  scroll-behavior: smooth;
}
.noFear_audio h3.noFear__title {
  @include media-small {
    display: none;
  }
}

.audio_disabled {
  pointer-events: none;
  opacity: 0.5;
  @include media-small {
    display: none !important;
  }
}

.audio_gatted {
  @include media-small {
    display: none;
  }
}

fieldset.interior-audio.audio_show.audio_gating_error {
  display: none;
}

.sticky_toc-menu {
  @include media-small {
    position: fixed;
    top: 32px;
    z-index: 4;
  }
  @include media-mobile {
    top:8px;
  }
}

.adjust_title_styling_for_sticky {
  @include media-small {
    padding: 0 46px;
  }
}

.hideTocIconForMobileSticky {
  @include media-small {
    display: none !important;
  }
}

.nofearPromotionalModal {
  width: 320px;
  position: absolute;
  margin: 0;
  padding: 26px;
  background-color: $white !important;
  background:url('/images/nofear/icon-asterisk.svg') no-repeat;
  height: auto;
  border-radius: 14px;
  top: 0;
  z-index: 2;
  box-shadow: 0 4px 14px rgb(0 0 0 / 30%);
  
  @include ipad-pro {
     width: 286px;
  }

  @include ipad {
    top: 30px;
    width: 320px;
  }

  @include media-small {
    z-index: unset;
    visibility: hidden;
  }

  @media (max-width: 320px) {
    width: 240px;
  }

  &__header {
    position:relative;
    text-align: center;

    &__icon {
      width: 96px;
      height: 55px;
      border-top-left-radius: 110px;
      border-top-right-radius: 110px;
      border: 2px solid #00cc9a;
      border-bottom: 0;
      background: $white;
      position: absolute;
      top: -80px !important;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    &__lock {
      padding: 20px 12px 9px 13px;
      width: 74px;
      max-width: 100%;
      text-align: center;
      border-radius: 60% 0 0;
      border-top-left-radius: 60%;
      border-top-right-radius: 60%;
    }
  }

  &__plusLogo {
    text-align: center;
    &__main_logo {
      width: 180px;
      height: auto;
      padding: 20px 0 0px 0;
    }
  }

  &__body {
    margin: 0px;
    padding-top: 12px;
    text-align: center;

    &__content {
      font-weight: bold;
      font-family: Raleway;
      font-size: $text-big-ish;
      font-stretch: normal;
      font-style: normal;
      line-height: $lh-tight;
      letter-spacing: normal;
      text-align: center;
      color: #004a3e;

      @include ipad-pro {
        font-size: $text-slightly-big;
      }

    }

    &__button {
      margin-top: 25.5px;
      width: 221px;
      height: 37px;
      background: $color-brandBlue2020;
      padding: 3px 0 6px 0;
      border: none;
      border-radius: 5.6px;
      color: $white;
      font-size: $text-base;
      font-weight: $fw-chubby;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      text-align: center;

      &:hover, &:focus-visible {
        background-color: #004371;
      }

      @media (max-width: 320px) { 
        margin: 25.5px auto 0;
        width: 190px;
        padding: 0 15px;
      }

      @include mobile-galaxy-max {
        padding: 3px 2px 6px 2px !important;
        width: 165px;
      }

    }

    &__renew-button {
      margin-top: 25.5px;
      width: 221px;
      height: 37px;
      background: $color-brandBlue2020;
      border: none;
      border-radius: 5.6px;
      color: $white;
      font-size: $text-base;
      font-weight: $fw-chubby;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;

      &:hover {
        background-color: $sn-brand-blue;
      }

      @media (max-width: 320px) { 
        margin: 25.5px auto 0;
        width: 190px;
        padding: 0 15px;
        font-size: 14px;
      }

      @include mobile-galaxy-max {
        padding: 3px 2px 6px 2px !important;
        width: 165px;
        font-size: 12px;
      }
    }

    &__icons {
      padding: 25px 44px 0 32px;
      display: flex;
      width: 100%;
      text-align: center;

      @include ipad-pro {
        padding: 25px 12px 0 12px;
      }

      @include mobile-galaxy-max {
        padding: 25px 0 0 0 !important;
      }

      &__study_icon {
        width: 56%;

        @include media-small {
          width: 55%;
        }

        &__study_guide_translation {
          background: #41ffaa;
          border-radius: 50px;
          padding: 8px;
          height: 48px;
          margin-right: 30px;

          @include media-small {
            margin-right: 0px;
          }

        }

        &__description {
          margin-right: 26px;
          line-height: normal;
          padding-top: 9px;
          font-family: Raleway;
          font-size: $text-small;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #004a3e;

          @include media-small {
            margin-right: 0px;
          }

        }
      }

      &__audio_icon {
        width: 43%;

        @include media-small {
          width: 54%;
        }

        &__nofear_audio {
          background: #41ffaa;
          border-radius: 50px;
          padding: 8px;
          height: 48px;
          margin-left: 31px;
        }

        &__description {
          margin-left: 27px;
          line-height: normal;
          padding-top: 9px;
          font-family: Raleway;
          font-size: $text-small;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #004a3e;
        }

      }

    }
  }
}
.nofearPromotionalModal__body__button-free-text {
  font-weight: $fw-fat;
}

.nofearPromotionalModalHide {
  display: block;

  @include media-small {
    display:none;
  }

}

.nofearPromotionalModal.modal_moving_top {
  position: fixed;
  top: 17%;
  margin: 0 auto;

  @include media-small {
    margin: 0 auto;
    top: 231px;
  }

}

.nofearPromotionalModal.modal_moving_top .nofearPromotionalModal__header__icon {
  top: -81px;
}

.PromotionalModalWithIcons {
  background-size: 320px 404px !important;
}

.PromotionalModalWithoutIcons {
  background-size: 319px 352px !important;
}

.nofearPromotionalModal_content_position {
  width: 320px;
  margin: 0 auto;
  position: relative;
  max-width: 100% !important;

  @include media-small {
    float :none;
  }

  @media (max-width: 320px) {
    width: 240px;
  }

}

.nofearPromotionalModal_content_position_absolute {
  position: absolute;
  top: 80px;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  @include media-small {
    margin: 0 auto;
    z-index: 1;
  }

}

.nofearPromotionalModal.PromotionalModalWithIcons.nofearPromotionalModalHide.modal_position_set_for_bottom {
  top: -479px;
}

.modal_position_set_for_bottom{
  position: absolute;
  top: -383px;

  @include media-small {
    top: -600px;
    margin: 0 auto;
  }

}

.promotional_modal_outer_container {
  width: 100%;
}

.promotional_modal_container_reference {
  width: 100%;
}

.original_text_reference {
  width: 50%;
  float: left;
  margin-top: -15px;
  @include media-small {
    width: 100%;
    display: none;
  }

}

.modern_text_reference {
  width: 50%;
  float: right;

  @include media-small {
    width: 100%!important;
  }

}

.nofear_inner_container {
  width: 100%;
}

.modal_moving_top.remove_modal_moving_top{
  position: relative!important;
  top:10px;
}

.remove_modal_moving_top{
  position: relative!important;
}

.modal-fixed-bottom {
  position: absolute;
  bottom: 110px;
}

.footer.nofear-footer {
  @include media-small {
    z-index: 4;
  }
}

.remove_audio_disabled {
  opacity: unset;
  pointer-events: all;
  display: block;
}

.modernTxtHidden td.noFear__cell.noFear__cell--modern .noFear__tooltip__trigger {
  color: transparent;
  text-shadow: 0 0 5px #635959;
  transition: 0s;
}

.nofearPromotionalModal__body__button{
  &:focus-visible{
    box-shadow: inset 0 0 0 3px $sn-black;
  }
}

fieldset.interior-audio.remove_audio_disabled.audio_show.audio_gating_error {
  display: none !important;
}

.hide-buttom-ad-show {
  @include media-small {
    display: none;
  }
}

.hide_promotional_modal {
    visibility: hidden;
}

.interior-sticky-nav__navigation--no-pagination-data a {
  visibility: none;
}

.interior-sticky-nav__navigation--no-pagination-data a::after {
  border: none;
  background: none;
}

.nofear-scene-title
.interior-header__title__umbrella-label--noFear {
  padding: 11px 10px 0 0 !important;
}

.nofear-scene-title h2.interior-header__title__text__pagetitle {
  padding-top: 14px !important;
}

.nofear-interior-header-title {
  padding: 19px 0 15px 0 !important;
}

.nofear-interior-header-title span.interior-header__title__icon.interior-header__title__icon--noFear {
  width: 47px !important;
  height: 47px !important;
}

.nofear-interior-header-title .interior-header__title {
  @include media-notSmall {
    padding-left: 16px !important;
  }
}

.interior-header__title__text.nofear-scene-title {
  flex-direction: row !important;
}

// .modern_text_loader {
//   position: absolute;
//   height: 1400px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: rgba(72,72,72,.75);
//   z-index: 2;
//   top: 2px;
//   @include media-mobile {
//     max-height: unset;
//   }
//   @include media-small {
//     width: 100%;
//     height: 100%;
//     top: 0.4%;
//   }
//   &[hidden] {
//     display: none;
//   }
//   &__loader-img {
//     width: 25%;
//     margin-top: 16%;
//     align-self: self-start;
//     @include media-mobile {
//       width: 20%;
//       height: auto;
//     }
//     @include media-small {
//       margin-top: 35%;
//     }
//   }
// }

// .disableScroll {
//   overflow: hidden;
// }

// .modern_text_loader.hideLoaderOriginalText {
//   @include media-small {
//     visibility: hidden;
//   }
// }

// .hide_loader {
//   visibility: hidden;
// }

.without_audio+ .interior-sticky-nav__navigation {
  height: 44px !important;
}

.pagination_adjust+.interior-sticky-nav__navigation {
  height: 44px !important;
}

.nofearPromotionalModal.PromotionalModalWithoutIcons.nofearPromotionalModalHide.remove_modal_moving_top.modal_moving_top {
  top: 10px;
}

.nofearPromotionalModal.PromotionalModalWithoutIcons.nofearPromotionalModalHide.modal_moving_top {
  top: 28%;
}

.without_audio .adjust_title_styling_for_sticky {
  @include media-small {
    padding: 4px 10px 4px 46px;
  }
}

.PromotionalModalWithoutIcons.modal_moving_top.modal_moving_top_for_lit {
  top: 25%;
  @include media-small {
    top: 34%;
  }
}

.PromotionalModalWithoutIcons.modal_moving_top_for_lit.remove_modal_moving_top.modal_moving_top {
  @include media-small {
    top: 22px !important;
  }
}

.noFear_promo_login {
  font-size: $text-small;
  line-height: $lh-base;
  padding: 14px 0 0;
}

a.promo_login_link {
  cursor: pointer;
  font-weight: $fw-chubby;
  color: #007acd;
  text-decoration: none;
}

a.promo_login_link {
  &:hover, &:focus-visible {
    color: #004371;
  }
}

.noFear-comparison-responsive.modernTxtHiddenTab .noFear__tooltip {
  @include media-small {
    color: transparent;
    text-shadow: 0 0 5px #635959;
  }
}

.modernTxtHiddenTab .noFear__cell--modern {
  @include media-small {
    background: #e1faef;
    color: transparent !important;
    text-shadow: 0 0 5px #635959;
  }
}

.modernTxtHiddenTab .noFear__cell--modern p.noFear__speaker.noFear__speaker {
  @include media-small {
    color: transparent !important;
    text-shadow: 0 0 5px rgb(0 0 0 / 50%);
  }
}

@include media-small {
  .noFear-comparison-responsive {

    #noFear-comparison {
      overflow-x: hidden !important;
      border-top: none;
    }

    .noFear__cell.noFear__cell--modern p {
      color: $plus-green-dark;
    }

    .modernTxtHiddenTab .noFear__cell--modern {
      background: #e1faef;
    }

    .noFear {
      width: 200% !important;
      table-layout: fixed !important;
    }

    .noFear-comparison-responsive .noFear__line--modern {
      color: $plus-green-dark;
    }  

    .noFear--hasNumbers .noFear__cell--original {
        padding-left: 24px !important;
    }

    .noFear__cell--title {
        padding-bottom: 0 !important;
    }
    
    .nofear-modernText-styling td.noFear__cell.noFear__cell--modern {
        border-left: solid 2px #00cc9a;
        border-right: solid 2px #00cc9a;
    }

    .modernTab_styling {
      border-right: 1px solid #00cc9a;
      border-left: 1px solid #00cc9a;
    }

    .modernTab_styling #noFear-comparison {
      border-bottom: 1px solid #00cc9a;
    }
    
  }
  .interior-sticky-nav__title.without_audio {
    height: 42px;
  }
}

.hide-modal-onload {
  visibility: hidden !important;
}

//Nofear free scene pages green div styling for Desktop//
.free_sample-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: $white;
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 0 24px;
  opacity: 0.96;
  background: url(/images/nofear/icon_free_sample_asterisk.svg) right bottom no-repeat;
  background-color: #008262;
  background-size: 380px 119px;

  &__description {
    font-weight: $fw-chubby;
    width: 70%;
    padding: 16px 24px 16px 0;
    font-size: $text-big;
    line-height: $lh-base;
    opacity: 0.96;
  }

  &__logo {
    width: 30%;
    padding: 21px 0 54px 0;
    img {
      width: 100%;
    }
  }
}

.free_sample-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: $white;
  border-radius: 12px;
  margin: 0 0 -7px 0;
  padding: 0 24px;
  background: url(/images/nofear/icon_free_sample_asterisk.svg) right bottom no-repeat;
  background-color: #008262;
  opacity: 0.96;
  background-size: 368px 290px;

  &__description {
    font-weight: $fw-chubby;
    width: 70%;
    padding: 16px 24px 16px 0;
    &__title {
      font-weight: $fw-chubby;
      font-size: $text-big;
      line-height: $lh-base;
      color: $white;
      opacity: 0.96;
    }
    &__text {
      font-size: $text-base !important;
      font-weight: 400;
      color: $white;
      padding: 16px 0;
      line-height: 1.75;
      opacity: 0.96;
    }
  }
  
  &__logo {
    width: 30%;
    padding: 21px 0px 54px 0;
    img {
      width: 100%;
    }
    &__button {
      width: 100%;
      padding: 10px 13px;
      height: auto;
      color: #0066c4;
      border: 2px solid transparent;
      background-color: $white;
      margin-top: 32px;
      font-size: $text-base;
      font-weight: $fw-chubby;
      line-height: $lh-base;
      border-radius: 4px;
      &:hover,&:focus {
        background: #004371;
        border-color: $white;
        color: $white;
        opacity: 0.96;
      }
      &__button-free-text {
        font-weight: $fw-fat;
      }  
    }
    &__renew-button {
      width: 100%;
      padding: 10px 13px;
      height: auto;
      color: #0066c4;
      border: 2px solid transparent;
      background-color: $white;
      margin-top: 32px;
      font-size: $text-base;
      font-weight: $fw-chubby;
      line-height: $lh-base;
      border-radius: 4px;
      &:hover,&:focus {
        background: #004371;
        border-color: $white;
        color: $white;
        opacity: 0.96;
      }
    }
    &__manage-your-purchase {
      width: 100%;
      padding: 10px 9px;
      height: auto;
      color: #0066c4;
      border: 2px solid transparent;
      background-color: $white;
      margin-top: 32px;
      font-size: $text-base;
      font-weight: $fw-chubby;
      line-height: $lh-base;
      border-radius: 4px;
      &:hover,&:focus {
        background: #004371;
        border-color: $white;
        color: $white;
        opacity: 0.96;
      }
    }
  }
}

//Nofear free scene pages green div styling for Mobile//
@include media-small {
  .mobile_free_sample-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    color: $white;
    border-radius: 12px;
    margin: 16px 0;
    background-color: #008262;
    opacity: 0.96;

    &__description {
      font-weight: $fw-chubby;
      width: 100%;
      padding: 12px;
      font-size: $text-slightly-big;
      line-height: $lh-base;
      opacity: 0.96;
    }
  }

  .mobile_free_sample-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $white;
    border-radius: 12px;
    margin: 32px 0 24px 0;
    background-color: #008262;
    opacity: 0.96;

    &__description {
      font-weight: $fw-chubby;
      width: 100%;
      padding: 0 12px;
      &__title {
        font-weight: $fw-chubby;
        font-size: $text-slightly-big;
        line-height: $lh-base;
        color: $white;
        opacity: 0.96;
      }
      &__text {
        font-size: $text-base !important;
        font-weight: 400;
        color: $white;
        padding: 16px 0;
        line-height: 1.75;
        opacity: 0.96;
      }
    }

    &__logo {
      width: 100%;
      padding: 12px;
      padding: 24px 12px;
      text-align: center;
    }

    &__signUp {
      width: 100%;
      padding: 0 12px;
      &__button {
        width: 100%;
        padding: 10px 13px;
        height: auto;
        color: #0066c4;
        border: 2px solid transparent;
        background-color: $white;
        margin-bottom: 24px;
        font-size: $text-base;
        font-weight: $fw-chubby;
        line-height: $lh-base;
        border-radius: 4px;
        &:hover,&:focus {
          background: #004371;
          border-color: $white;
          color: $white;
          opacity: 0.96;
        }
        &__button-free-text {
          font-weight: $fw-fat;
        }  
      }
      &__renew-button {
        width: 100%;
        padding: 10px 13px;
        height: auto;
        color: #0066c4;
        border: 2px solid transparent;
        background-color: $white;
        margin-bottom: 24px;
        font-size: $text-base;
        font-weight: $fw-chubby;
        line-height: $lh-base;
        border-radius: 4px;
        &:hover,&:focus {
          background: #004371;
          border-color: $white;
          color: $white;
          opacity: 0.96;
        }  
      }
    }
  }

  .adjust_title_styling_for_sticky .interior-sticky-nav__title__umbrella {
    color: $color-brandBlue2020;
    font-weight: $fw-chubby;
  }
}

//Hide Nofear sample div for signed in sbscribed user//
.sampleDivHidden {
  display: none;
}

.free_sample-text {
  width: 100%;
  padding: 0 5px 0px 5px;
  background-color: #41ffaa;
  color: #007036;
  height: 23px;
  margin-top: -2px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  margin-right: 2px;
  line-height: $lh-base;
  @include media-small {
    margin: 0 0 0 12px;
    padding: 0 8px 0 12px;
  }
}

.interior-audio .plyr__controls {
    background-color: transparent !important;
}

.blank-line {
  float: left; 
  height: 1px; 
  background-color: #00cc9a; 
  width: 50px;  
  margin: 7px 2px 0 2px;
  @include media-small {
    display: none;
  }
}

.audio_legend_text {
  display: flex; 
  width: 100%;
  margin-top: 0;
  @include media-small {
    margin-top: 5.7px;
  }
}

.audio_legend_sub_text {
  width: 85px;
}

.whole_legend_section {
  display: flex; 
  width: 100%;
  @include media-small {
    display: flex;
    width: 100%;
    margin-top: -7.7%;
    background: #fff;
    border: 2px solid #00cc9a;
    border-bottom: none;
    height: 20px;
    padding: 2px 3px 0 3px;
    border-radius: 4px 4px 0 0;
    position: absolute;
  }
}

.sample-text-hide {
  display: none;
}

.legend-text-hide {
  display: none;
}

.toc-free-text {
  line-height: $lh-base;
  font-size: $text-small;
  color: #008262;
  @include media-small {
    font-size: $text-slightly-big;
    color: $color-brandBlue2020;;
    text-transform: uppercase;
  }
}

.hide-toc-free-sample-text {
  display: none;
}

.page-turn-nav .interior-audio.audio_show {
  @include media-small {
    margin-top: 18px;
  }
}

.page-turn-nav__link-audio {
  @include media-small {
    margin-top: 90px;
    border-top: 1px solid #e5e5e5;
  }
}

.without_free_text {
  @include media-small {
    margin-top: -13%;
    border: 2px solid #00cc9a;
    display: flex;
    padding: 6px 2px;
    border-bottom: none;
    height: 17px;
    border-radius: 4px 4px 0 0;
    width: 100%;
    background-color: $white;
    position: absolute;
  }
}

.hideTocIconForMobileSticky~.interior-sticky-nav__title__text {
  .interior-sticky-nav__title__umbrella {
    @include media-small {
      color: #007acd;
      font-weight: 600;
    }
  }
}

.nofear__interior__section__list__link {
  &:hover ,&:focus {
    .toc-free-text {
      color: #004371;
    }
  }
}

.nofear__interior__section__list__link.active {  
  .toc-free-text {
    color: #008262;
    @include media-small {
      color: $sn-primary-paragraph;
    }
    &:hover,
    &:focus {
      color: #008262;
      background-color: transparent;
      cursor: default;
      @include media-small {
        color: $sn-primary-paragraph;
      }
    }
  }
}

@include media-small {
  .free-audio-adjust {
    .freeSample_audio_set {
      border-bottom: 2px solid #e5e5e5;
      margin: 31px 0 18px 0;
    }

    .interior-audio.audio_show {
      position: relative !important;
    }

    .audio_gating_error+.audio-error-display.hide-interior-audio {
      position: relative !important;
    }
  }

  .remove_margin_for_audio {
    a {
      margin: 0 !important;
      border: none;
    }
  }

  .hideDummyDiv {
    display: none;
  }
}

.nofearManageYourPurchase {
  padding: 10px 9px;
}