@mixin l-media-bp4 {
  @media only screen and (max-width: 1160px) {
    @content;
  }
}

.sparkteach-homepage__stay-updated__form{
  $l-colorFail: #A60038;
  $l-colorSuccess: #639704;
  border: 1px solid $white;
  margin-top: 12px;
  border-radius: 36px;
  width: 100%;
  max-width: 400px;
  transition: all 0.12s;
  transform-origin: 50% 100%;
  position: relative;
  @include media-notSmall { 
    margin-right: auto;
  }
  @include media-small { 
    width : 280px;
    margin-top : 24px;
  }
  &__label-container{
    display: flex;
  }
  &:focus-within,
  &:hover{
    transform: scale(1.025);
    box-shadow: 0 12px 9px -8px rgba(0,0,0,0.25);
    border-color: $white;
  }
  &__field{
    flex:1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "all";    
  }
  &__input{
    grid-area: all;
    @include removeInputStyles;
    padding: 4px 16px 4px 24px;
    color: $white;
    height: 40px;
    margin-top: 7px;
    margin-left: 14px;
    div:focus-within &{
      outline: none;
    }
  }
  &__label{
    color: $white;
    padding: 4px 8px;
    border-radius: 40px;
    margin-left: 16px;
    grid-area: all;
    align-self: flex-start;
    justify-self: flex-start;
    transform: translateY(-50%);
    background-color: #98348b;
    cursor: pointer;
  }

  &__button{
    @include removeBtnStyles;
    width: 50px;
    height: 50px;
    border-radius: 24px;
    background-color: $color-brandBlue-2020;
    background-image: url('/images/SparkTeach-homepage-form-submit-icon.svg');
    background-repeat: no-repeat;
    background-position: 50%;
    margin: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
 
  }
  &__message{
    position: absolute;
    bottom: calc(100% + 10px);
    width: 100%;
    left: 0;
    @include l-media-bp4 {
      left: unset;
      max-width: 400px;
    }
    &__bubble{
      padding: 4px 4px 4px 24px;
      line-height: $lh-tight;
      border-radius: 40px;
      background: $sn-primary-paragraph;
      z-index: 2;
      position: relative;
      min-height: 60px;
      display: flex;
      align-items: center;
      color: $white;
      &:before{
        content: '';
        position: absolute;
        display: block;
        top: 100%;
        left: calc(50% - 10px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 10px 0 10px;
        border-color: $sn-primary-paragraph transparent transparent transparent;
      }
      &:after{
        content: '';
        $size: 52px;
        width: $size;
        height: $size;
        border-radius: 50%;
        background-color: $sn-primary-paragraph;
        flex: none;
        margin-left: auto;
        background-repeat: no-repeat;
        background-position: 50%;
      }
    }
    &__fail{
      background-color: $l-colorFail;
      &:before{
        border-top-color: $l-colorFail;
      }
      &:after{
        background-image: url('/images/SparkTeach-homepage-form-error-icon.svg');
      }
    }
    &__success{
      background-color: $l-colorSuccess;
      &:before{
        border-top-color: $l-colorSuccess;
      }
      &:after{
        background-image: url('/images/SparkTeach-homepage-form-success-icon.svg');
      }
    }    
  }
  &--loading.sparkteach-homepage__stay-updated__form{
    $size: 10px;
    $space: 12px;

    @keyframes sparkteach-homepage__stay-updated__form__loader {
      0%,
      80%,
      100% {
        box-shadow: 0 12px 0 -1.3em;
      }
      40% {
        box-shadow: 0 12px 0 0;
      }
    }
    .sparkteach-homepage__stay-updated__form__button{
      background-image: none;
    }
    .sparkteach-homepage__stay-updated__form__loader,
    .sparkteach-homepage__stay-updated__form__loader:before,
    .sparkteach-homepage__stay-updated__form__loader:after {
      border-radius: 50%;
      width: $size;
      height: $size;
      animation-fill-mode: both;
      animation: sparkteach-homepage__stay-updated__form__loader 1.8s infinite ease-in-out;
    }
    .sparkteach-homepage__stay-updated__form__loader {
      color: $white;
      margin-top: -$size * 2;
      position: relative;
      transform: translateZ(0);
      animation-delay: -0.16s;
    }
    .sparkteach-homepage__stay-updated__form__loader:before,
    .sparkteach-homepage__stay-updated__form__loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.sparkteach-homepage__stay-updated__form__loader:before {
  left: -$space;
  animation-delay: -0.32s;
}
.sparkteach-homepage__stay-updated__form__loader:after {
  left: $space;
}



  }
  &--success.sparkteach-homepage__stay-updated__form{
    border-color: $l-colorSuccess;
  }
  &--fail.sparkteach-homepage__stay-updated__form{
    border-color: $l-colorFail;
  }
}

.signup-add-form {
  position : relative;
  display : flex;
  margin-top: 40px;
  @include l-media-bp4 {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }
  &__fname {
    margin-right : 10px;
    @include l-media-bp4 {
      margin-right : unset;
      width : 100%;
    }
    height: 56px;
  }
  &__lname {
    height: 56px;
    @include l-media-bp4 {
      margin-top: 24px;
      margin-right : unset;
      width : 100%;
    }
  }
}