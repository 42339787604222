.author {
	&__header {
		background-image: linear-gradient(90deg, $color-brandBlue2020 15%, #005995 100%);
		&__container {
			@include container($padding: true);
			display: flex;
			align-items: center;
			padding-top: 20px;
			padding-bottom: 20px;
			@media (max-width: $tablet-bp) {
				flex-direction: column;
				text-align: center;
				padding: 32px 0;
			}
		}
		&__text {
			padding: 0 12px;
			width: auto;
		}
		&__image {
			width: 120px;
			height: 120px;
			border: 4px solid #fff;
			border-radius: 50%;
			box-shadow: 0 10px 30px 0 rgba($color: #000000, $alpha: 0.5);
			margin: 0 20px 0 0;
			object-fit: cover;
			@media (max-width: $mobile-bp) {
				margin: 0;
			}
		}
		&__headline {
			font-size: $text-extra-big;
			color: #fff;
			font-weight: $fw-huge;
			line-height: $lh-tight;
			@media (max-width: $mobile-bp) {
				padding: 16px 0 8px 0;
			}
			@include media-small {
				font-size: $text-big;
			}
		}
		&__subheadline {
			font-size: $text-base;
			color: #fff;
			font-weight: $fw-base;
			line-height: $lh-tight;
		}
	}
}
.author-page {
	@media (max-width: $tablet-bp) {
		padding-left: $pageMargin;
		padding-right: $pageMargin;
	}

	&__book-list {
		padding-left: 90px;
		@include media-small {
			padding-left: 0px;
		}
	}
}

#author-more p {
	font-weight: 400;
	padding-top: 0 !important;
}
.authorPageText p {
	font-weight: 600;
	padding-top: 16px !important;
}

.authorpage__collapse {
	font-weight: $fw-slim;
	font-size: 15px;
	padding: 7px 30px;
	background: none;
	border: 1px solid $sn-gray;
	border-radius: 8px;
	color: $sn-primary-link;
	text-align: center;
}
.authorPageText {
	border-top: none !important;
	padding-top: 16px !important;
}
.author-page__other-block {
	display: grid;
	padding-top: 40px;
	padding-bottom: 40px;
	scroll-margin-top: 56px;
	@include media-small {
		padding-top: 24px;
		padding-bottom: 24px;
		width: 100%;
	}
	border-top: 1px solid $sn-border-gray;

	&:last-child {
		@include media-small {
			border-bottom: 1px solid $sn-border-gray;
		}
	}
	h2 {
		margin-top: 20px;
		margin-bottom: 32px;
		grid-row: 1;
		grid-column: 1;
		padding-left: 90px;
		font-size: $text-big;
		font-weight: $fw-chubby;
		line-height: $lh-base;
		padding-bottom: 0;
		@include media-small {
			margin-bottom: 24px;
			margin-top: 10px;
			padding-left: 68px;
		}
	}
	p {
		font-size: 1rem;
		padding: 0;
		margin: 0;
		padding-bottom: 32px;

		&:last-child {
			padding-bottom: 0px;
		}
	}
	.author-quote__block {
		padding-left: 90px;
		p {
			padding-bottom: 30px;
		}
		@include media-small {
			padding-left: 0;
		}
	}
	.view-guide {
		background-color: $color-brandBlue2020;
		color: #fff;
		border-radius: $radius-base;
		font-weight: $fw-chubby;
		font-size: $text-small;
		padding: 8px 24px;
		float: right;
		margin-right: 10px;
		@include media-small {
			display: none;
		}
	}
	.view-guide.arrow {
		display: none;
		@include media-small {
			display: block;
			border-radius: 50%;
			height: 32px;
			margin-left: 45px;
			padding: 0;
			width: 32px;
		}
	}
	.view-guide img {
		margin: -12px 0 0 -12px;
	}
	.view-guide:hover {
		background-color: $color-linkHover;
	}
	& .content {
		padding-top: 15px;
	}
}
.author-page__other-block .icon {
	width: 80px;
	height: 80px;
	flex: none;
	border: 2px solid #007acd;
	border-radius: 50%;
	margin-right: 12px;
	grid-row: 1;
	grid-column: 1;
	@include media-small {
		width: 56px;
		height: 56px;
	}

	// To make default
	background: url(/images/author-icons/72px/icon-Biography-72-brandBlue.svg) no-repeat 50% 50%;
	@include media-small {
		background: url(/images/author-icons/32px/icon-Biography-32-brandBlue.svg) no-repeat 50% 50%;
	}

	&.Biography, &.Autobiography {
		background: url(/images/author-icons/72px/icon-Biography-72-brandBlue.svg) no-repeat 50% 50%;
		@include media-small {
			background: url(/images/author-icons/32px/icon-Biography-32-brandBlue.svg) no-repeat 50% 50%;
		}
	}
	
	&.Studyguide {
		background: url(/images/author-icons/72px/icon-StudyGuide-72-brandBlue.svg) no-repeat;
		@include media-small {
			background: url(/images/author-icons/32px/icon-StudyGuide-32-brandBlue.svg) no-repeat 50% 50%;
		}
	}
	&.Quotes {
		background: url(/images/author-icons/72px/icon-Quotes-72-brandBlue.svg) no-repeat;
		background-position: 50% 50%;
		@include media-small {
			background: url(/images/author-icons/32px/icon-Quotes-32-brandBlue.svg) no-repeat 50% 50%;
		}
	}
	&.Novels, &.Essay.Collections {
		background: url(/images/author-icons/72px/icon-Novels-72-brandBlue.svg) no-repeat 50% 50%;
		@include media-small {
			background: url(/images/author-icons/32px/icon-Novels-32-brandBlue.svg) no-repeat 50% 50%;
		}
	}
	&.Stories {
		background: url(/images/author-icons/72px/icon-ShortStories-72-brandBlue.svg) no-repeat;
		@include media-small {
			background: url(/images/author-icons/32px/icon-ShortStories-32-brandBlue.svg) no-repeat 50% 50%;
		}
	}
	&.Nonfiction, &.Fiction {
		background: url(/images/author-icons/72px/icon-Nonfiction-72-brandBlue.svg) no-repeat;
		@include media-small {
			background: url(/images/author-icons/32px/icon-Nonfiction-32-brandBlue.svg) no-repeat 50% 50%;
		}
	}
	&.Poetry {
		background: url(/images/author-icons/72px/icon-Poetry-72-brandBlue.svg) no-repeat;
		@include media-small {
			background: url(/images/author-icons/32px/icon-Poetry-32-brandBlue.svg) no-repeat 50% 50%;
		}
	}
	&.Plays {
		background: url(/images/author-icons/72px/icon-Poetry-72-brandBlue.svg) no-repeat 50% 50%;
		@include media-small {
			background: url(/images/author-icons/32px/icon-Poetry-32-brandBlue.svg) no-repeat 50% 50%;
		}
	}
	&.Novellas {
		background: url(/images/author-icons/72px/icon-Novels-72-brandBlue.svg) no-repeat 50% 50%;
		@include media-small {
			background: url(/images/author-icons/32px/icon-Novels-32-brandBlue.svg) no-repeat 50% 50%;
		}
	}
	&.Short.Fiction {
		background: url(/images/author-icons/72px/icon-ShortStories-72-brandBlue.svg) no-repeat;
		@include media-small {
			background: url(/images/author-icons/32px/icon-ShortStories-32-brandBlue.svg) no-repeat 50% 50%;
		}
	}
	&.Graphic.Novels {
		background: url(/images/author-icons/72px/icon-graphicNovel-72-brandBlue.svg) no-repeat;
		@include media-small {
			background: url(/images/author-icons/32px/icon-graphicNovel-32-brandBlue.svg) no-repeat 50% 50%;
		}
	}
}
#author-book__titles {
	list-style-type: none;
	padding-left: 76px;
	padding-top: 0;
	@include media-small {
		padding-left: 0;
	}
	display: block;
	li {
		margin: 16px 12px;
		background-color: $color-teacherSoftGray;
		width: 100%;
		padding: 20px 10px 20px 20px;
		@include media-small {
			display: flex;
			width: 95%;
			margin-left: 0px;
		}
		& .book-title {
			font-size: $text-base;
			color: $color-brandBlue2020;
			font-weight: $fw-slim;
		}
		a:first-child {
			flex: 1 1 100%;
			line-height: 2;
		}
		&:hover {
			box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), inset 0 3px 0 0 $color-linkHover;
			color: $color-linkHover;
			transform: translateY(-2px);
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			& .view-guide {
				background-color: $color-linkHover;
			}
			& .book-title {
				color: $color-linkHover;
			}
		}
	}
	font-weight: $fw-chubby;
}

.author-page__other-block__list {
	display: grid;
	display: -ms-grid;
	-ms-grid-rows: auto, auto, auto;
	grid-template-columns: repeat(1, auto);
	grid-auto-flow: row;
	padding-top: 10px;
	list-style-type: disc;
	list-style-position: inside;
	font-size: 14px;
	@media (max-width: $mobile-bp) {
		-ms-grid-columns: auto, auto;
		grid-template-columns: repeat(2, auto);
	}
}

.author-page__books-list {
	display: flex;
	flex-wrap: wrap;
	padding-left: 76px;
	@include media-small {
		padding-left: 0;
	}
	.block-right {
		margin: 16px 12px;
		@include media-small {
			margin-left: 0px;
		}
		background-color: $color-teacherSoftGray;
		width: 100%;
		padding: 16px 20px;
		.author-book__title {
			display: inline-block;
		}
		a {
			line-height: 50px;
			margin: 0;
		}
	}
	.block-left {
		float: left;
		a {
			line-height: 50px;
		}
	}
	.book-title {
		font-weight: $fw-chubby;
		line-height: 24px;
		padding-bottom: 8px;
	}
	.book-year {
		color: $color-a11yGray;
		padding-top: 4px;
	}
	li {
		padding-right: 2rem;
	}
	a {
		font-weight: $fw-chubby;
		color: $sn-primary-link;
	}
	.buy-it-now {
		font-size: 11px;
		font-weight: $fw-chubby;
		text-transform: uppercase;
		background-color: $sn-green;
		color: #395848;
		padding: 7px 9px;
		padding-left: 10px !important;
		border-radius: 5px;
		border: none;
		@media (max-width: $mobile-bp) {
			padding: 7px 7px;
			padding-left: 7px !important;
		}
		&:hover {
			background-color: darken($color-buyNow, 6);
		}
	}
}

.author-page__book {
	display: flex;
	flex-direction: row;
	width: 50%;
	@media (max-width: $mobile-bp) {
		flex: 1 1 100%;
	}
}

.author-quote {
	width: max-content;
	max-width: 558px;
	font-weight: $fw-chubby;
	font-style: italic;
	line-height: 30px;
	padding-bottom: 30px;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	position: relative;
	&:before {
		display: table;
		content: "";
		position: absolute;
		left: 0;
		width: 20px;
		height: 18px;
	}
	&:after {
		display: table;
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		width: 20px;
		height: 18px;
		background-position: -27px;
	}
	@media (max-width: $mobile-bp) {
		width: 100%;
	}
}
.author-quote__block i {
	font-style: normal;
	font-weight: 400;
	&:before {
		content: '"';
	}
	&:after {
		content: '"';
	}
}
.book-suggestion {
	width: 80px;
	border: 1px solid $sn-border-gray;
}
