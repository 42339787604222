@mixin l-media-bp1 {
  @media (min-width: 700px) and (max-width: 850px) {
    @content;
  }
}
@mixin l-media-bp2 {
  @media (min-width: 851px) and (max-width: 999px) {
    @content;
  }
}

@mixin l-media-bp3 {
  @media (min-width: 700px) and (max-width: 1380px) {
    @content;
  }
}

@mixin l-media-bp4 {
  @media only screen and (max-width: 1160px) {
    @content;
  }
}

.footer {
  background-color: $dark-blue;
  float: left;
  width: 100%;
  padding: 0;
  min-height: 350px;
  position: relative;
  p {
    color: $footer-link;
    text-align: left;
    width: 65%;
    line-height: 33px;
    @include media-small {
      width: 100%;
      text-align: center;
    }
    @include l-media-bp1 {
      width: 100%;
      text-align: center;
    }
    @include l-media-bp2 {
      width: 100%;
      text-align: center;
    
    } 


  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
  ul {
    li {
      display: inline-block;
      font-weight: $fw-chubby;
      font-size: 14px;
      padding: 4px 0;
      width: 100%;
      text-align: left;
      &:nth-child(-n + 8):after {
        display: inline-block;
        padding-left: 5px;
      }
      a {
        color: $footer-link;
        text-decoration: none;
      }
      @media (max-width: $mobile-bp) {
        line-height: 28px;
      }
    }
  }


  &__signup {
            width: 100%;
            background-color: #023e8d;
            @include l-media-bp4 {
            padding-bottom: 48px;
            }
            &__container{
              margin: 0 auto;
              max-width: 1164px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              background-color: #023E8D;
              &__text{
                  width: 45%;
                  margin-right: 78px;
                  text-align: left;
                  &__big{
                    font-size:$text-big; 
                    font-weight:$fw-slim;
                    line-height: $lh-base;       
                    width: 100%; 
                    color: $white;
                  }
                  &__small{
                    font-size:$text-base; 
                    font-weight:$fw-base;
                    line-height: $lh-base;
                    margin-top: 8px;
                    width: 100%; 
                    color: #98B1D2;
                    margin-bottom: 40px; 
                    @include l-media-bp4 {
                      margin-bottom: 10px;
                    }
                  }
                  &__link {
                    color : #98b1d2;
                    &:hover {
                      color: $white;
                      font-weight: $fw-chubby;
                    }
                  }
                  @include l-media-bp3 {
                    padding-left: 15px;
                    padding-right: 15px;
                    margin-right:24px;
                  }
                  @include l-media-bp4 {
                    text-align: center;
                    width: 100%;
                    margin-right:0;
                  }
                }
                @include l-media-bp4 {
                  margin-right: 10px;
                  margin-left: 10px;
                  padding-top: 48px; 
                }
            }                     
      
        }
  
}


.footer-menu {
  @media (max-width: $mobile-bp) {
    line-height: 20px;
    text-align: center;
  }
  text-align: left;
  line-height: 20px;
}

.footer-content {
  padding-bottom: 90px;
  display: grid;
  width: $sn-max-width;
  margin: 0 auto;
  @include media-small {
    display: flex;
    flex-direction: column;
  }
  .link-head {
    color: $footer-link;
    font-size: $text-base;
    font-weight: $fw-fat;
    @include media-small {
      margin-top: 24px;
    }
  }
  & .footer-image {
    display: block;
    height: auto;
    width: 266px;
    grid-column: 1/1;
    grid-row: 1;
    
    @include l-media-bp1 {
      display: none;
    }
    @include l-media-bp2 {
      margin-right: 40px;
    }

    @include l-media-bp4 {
      width: 266px;
    }
    
    @include media-small {
      display: none;
    }
  }

  & .footer-email-image {
    svg {
      position:absolute;
      margin-top : -15px;
      }
  }

  & .main-link {
    color: $footer-link;
    font-size: $text-base;
    font-weight: $fw-fat;
    display: grid;
    padding-bottom: 34px;
    @include media-small {
      grid-column-gap: unset;
    }
    @include l-media-bp1 {
      padding: 0 20px;
    }
    li {
      @include media-small {
        text-align: center;
      }
    }
    .popular {
      @include media-small {
        grid-column: 2;
      }
    }
    .help {
      @include media-small {
        grid-column: 2;
      }
    }
    a {
      font-size: $text-small;
      font-weight: $fw-base;
      line-height: $lh-tight;
      text-decoration: none;
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
    h2 {
      color: $footer-link;
      font-size: $text-big;
      font-weight: $fw-base;
      grid-column: 1/4;
      text-align: left;
      padding: 48px 0 16px 0;
      @include l-media-bp1 {
        text-align: center;
      }
      @include media-small {
        text-align: center;
        padding-bottom: 0;
      }
    }
    .footer-menu-link {
      &.social {
        @include media-small {
          grid-column: 2;
        }
        .social-link {
          display: flex;
          padding: 4px 0;
          @include media-small {
            display: grid;
            margin: 4px 0 0 0;
          }
          .icon {
            display: block;
            width: 26px;
            height: 26px;
            margin-right: 8px;
            @include media-small {
              grid-row: 1;
              grid-column: 2;
            }
            &.facebook {
              background: url("/images/footer/icon-facebook.svg") no-repeat;
              &:hover {
                background: url("/images/footer/icon-facebook-hover.svg") no-repeat;
              }
            }
            &.instagram {
              background: url("/images/footer/icon-instagram.svg") no-repeat;
              &:hover {
                background: url("/images/footer/icon-instagram-hover.svg") no-repeat;
              }
              @include media-small {
                margin-left: 40px;
              }
            }
            &.twitter {
              background: url("/images/footer/icon-twitter.svg") no-repeat;
              &:hover {
                background: url("/images/footer/icon-twitter-hover.svg") no-repeat;
              }
              @include media-small {
                margin-left: 80px;
              }
            }
          }
        }
        .app-link {
          display: flex;
          margin-top: 16px;
          a {
            @include media-small {
              margin-right: 0 !important;
            }
            &:first-child {
              margin-right: 12px !important;
            }
          }
          @include media-small {
            margin: 16px 0 40px 0;
            display: grid;
          }
          @include l-media-bp1 {
            display: grid;
            grid-row-gap: 10px;
          }
          @include l-media-bp2 {
            display: grid;
            grid-row-gap: 10px;
          }
          .icon {
            display: block;
            width: 129px;
            height: 44px;
            margin-right: 12px;
            @include media-small {
              grid-row: 1;
              grid-column: 2;
            }
            &.apple-store {
              background: url("/images/footer/icon-app-store.svg") no-repeat;
              &:hover {
                background: url("/images/footer/icon-app-store-hover.svg") no-repeat;
              }
            }
            &.google-play {
              background: url("/images/footer/icon-play-store.svg") no-repeat;
              &:hover {
                background: url("/images/footer/icon-play-store-hover.svg") no-repeat;
              }
              @include media-small {
                margin-left: 142px;
              }
            }
          }
        }
      }
    }
  }
  & .legal-link {
    border-top: 1px solid #8a9aa7;
    color: $footer-legal-link;
    display: flex;
    font-size: $text-base;
    font-weight: $fw-base;
    line-height: $lh-base;
    grid-column: 1/9;
    grid-row: 2;
    padding: 32px 10px;
    box-shadow: 0 -15px 15px -15px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    @include media-small {
      display: flex;
      flex-direction: column-reverse;
      padding: 32px 0 40px 0;
      justify-content: center;
      grid-template-rows: auto;
      margin-left: 10px;
      margin-right: 10px;
    }
    @include l-media-bp1 {
      display: grid;
      justify-content: center;
      grid-template-rows: auto;
    }
    @include l-media-bp2 {
      display: grid;
      justify-content: center;
      grid-template-rows: auto;
    }
    li {
      width: auto;
      text-align: center;
    }
    a {
      color: $footer-link;
      text-decoration: none;
      &:hover {
        color: #fff;
      }
    }
    .terms-privacy {
      width: 100%;
      text-align: right;
      @include media-small {
        text-align: center;
        padding: 10px 0 40px 0;
      }
      @include l-media-bp1 {
        text-align: center;
      }
      @include l-media-bp2 {
        text-align: center;
      }
      li {
        @include media-small {
          padding: 0;
        }
      }
      .separator {
        padding: 0 5px;
        &.last {
          @include media-small {
            display: none;
          }
        }
      }
      a {
        font-size: $text-base;
        font-weight: $fw-base;
      }
    }
  }
  @media (max-width: $tablet-bp) {
    width: 100%;
  }
}

.footer-copyright {
  float: left;
  padding-right: 20px;
  @media (max-width: $mobile-bp) {
    float: none;
    padding: 0;
  }
}
.custom_field {
max-width: 420px;
margin-right: 12px;
@include l-media-bp4 {
  max-width: 400px;
}
height: 56px; 
@include l-media-bp4 {
  margin: 0 auto;
  width: 100%;
}
.custom_footer_email_button{
  width: 50px;
  height: 50px;
  margin: 2px;
  background-position: 60%;
}

}

.signup-email-container {
  display : flex;
  margin-top : 24px;
  margin-bottom: 40px;
  @include l-media-bp4 {
    align-items: center;
    flex-direction: column;
    margin-bottom: 0px;
  }
}

.footer-signup-container {
  @include l-media-bp4 {
    width: 100%;
  }
}

.custom-signup-button {
  width: 120px !important;
  height: 48px !important;
  border-radius : 4px!important;
  color : $white;
  margin-top: 14px;
  background-image: unset !important;
  &:disabled {
    opacity : .5;
  }
  @include l-media-bp4 {
    width: 100% !important;
    max-width: 400px;
    margin-top: 32px;
  }
}

.footer input:-webkit-autofill,
.footer input:-webkit-autofill:hover, 
.footer input:-webkit-autofill:focus, 
.footer input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
  caret-color: white;
}

.social-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0;
  @include media-small {
    display: flex;
    margin: 4px 0 15px 0;
  }
  .icon {
    display: block;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    &.facebook {
      &:hover {
        svg {
          circle {
            fill: $white;
          }
        }
      }
    }
    &.instagram {
      &:hover {
        svg {
          circle {
            fill: $white;
          }
        }
      }
    }
    &.twitter {
      &:hover {
        svg {
          circle {
            fill: $white;
          }
        }
      }
    }
    &.tiktok {
      &:hover {
        svg {
          circle {
            fill: $white;
          }
        }
      }
    }
  }
}
.footer-signin, .footer-signup {
  cursor: pointer;
}

.legal-link-menus {
  display: flex;
  flex-direction: column;
  p {
    padding-bottom: 8px;
    font-size: $text-small !important;
  }
  a {
    font-size: $text-tiny !important;
    &:hover {
      text-decoration: underline;
      color: $footer-link !important;
    }
  }
}

.main-link-new {
  display: grid;
  grid-template-columns: auto auto auto auto;
  @include media-small {
    margin-left: 12px;
  }
}

.footer-menu-link.help {
  @include media-notSmall {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 100px;
  }
  @media (min-width: 800px)  and (max-width: 1200px) {
    padding-right: 12px;
    padding-left: 70px;
  }
}

