#result-zone {
  margin-top: 2rem;
  font-size: 18px;
  text-align: center;
  background-color: $sn-border-gray;
}

#result-content {
  padding: 40px 0 70px;
  .semi-bold {
    font-size: 20px;
    padding-bottom: 40px;
  }
}

// hide the "Something Went Wrong Error" until
// further notice...
.quiz-error {
  display: none;
}

.retake-quiz-btn {
  font-weight: $fw-chubby;
  font-size: 14px;
  padding: 20px 80px;
  width: 222px;
  text-transform: uppercase;
  border-radius: 3px;
  background-color: $sn-primary-link;
  color: #fff !important;
  &:hover {
    cursor: pointer;
    background-color: $sn-link-hover;
  }
}

#see-all-quizzes {
  padding-top: 10px;
}

.quick-quiz-question {
  border: 1px solid $sn-gray;
  padding: 20px;
  h3 {
    padding-bottom: 1rem;
    font-size: 18px;
    line-height: 20px;
  }
  ul {
    display: grid;
    display: -ms-grid;
    -ms-grid-columns: auto auto;
    grid-template-columns: repeat(2, auto);
    @media (max-width: $mobile-bp) {
      grid-template-columns: auto;
    }
    li {
      padding-bottom: 1rem;
      margin: 0.5rem;
      position: relative;
    }
  }
}

.quiz-header {
  padding-bottom: 40px;
  font-size: 24px;
}

.border-blue {
  min-width: 220px;
  vertical-align: middle;
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 5px;
  label {
    vertical-align: sub;
    padding-left: 20px;
    display: block;
    line-height: $lh-loose;
  }
  &:hover {
    border-color: $sn-primary-link;
    color: $sn-primary-link;
  }
}
// explicity speciifed for IE
.border-blue:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
}

.border-blue:nth-child(2) {
  -ms-grid-column: 2;
  -ms-grid-row: 1;
}

.border-blue:nth-child(3) {
  -ms-grid-column: 1;
  -ms-grid-row: 2;
}

.border-blue:nth-child(4) {
  -ms-grid-column: 2;
  -ms-grid-row: 2;
}

#quickQuiz {
  input {
    float: left;
    margin-top: 6px;
  }
}

.quick-quiz-radio {
  float: left;
}

.question-number {
  width: 50px;
  text-align: center;
  margin: 0 15px 10px 0;
  padding: 12px;
  font-weight: $fw-slim;
  font-size: 34px;
  border: 1px solid #ccc;
  border-radius: 5px;
  float: left;
}

.question-total {
  display: block;
  font-size: 12px;
  padding-top: 10px;
}

.current-number {
  display : flex;
  justify-content: center;
}

// right/wrong answers.
// class "selected" is dynamically added in the wrapping <ul>
.selected {
  // gray out unselected answers
  .semi-bold {
    color: $sn-off-gray;
  }
  .semi-bold.chosen {
    background: #000;
  }
  // styling for true
  .true-answer {
    background: $color-correct;
    color: #fff;
    border: none;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 16px;
      height: 19px;
      background: url("/images/sprites.png");
      background-position: -15px 458px;
      background-color: $color-correct;
      top: 16px;
    }
  }
  // the RIGHT answer
  .border-blue.true-answer.chosen {
    background: $color-correct;
    color: #fff;
    border: none;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      background: url("/images/sprites.png");
      background-position: -15px 458px;
      background-color: $color-correct;
      top: 15px;
    }
  }
  // the WRONG answer
  .border-blue.chosen {
    background: #ffded9;
    color: $color-incorrect;
    border: 1px solid $color-incorrect;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 18px;
      height: 20px;
      background: url("/images/sprites.png");
      background-position: -30px 458px;
      background-color: #ffdeda;
      top: 16px;
    }
  }
  .border-blue {
    &:hover {
      border-color: transparent;
    }
  }
  .border-blue.chosen {
    &:hover {
      border: 1px solid $color-incorrect;
      margin: 8px;
    }
  }
  .border-blue.true-answer.chosen {
    &:hover {
      border: 0px solid transparent;
      margin: 8px;
    }
  }
  // hide inputs on chosen answer and highlighted right/wrong answer
  .chosen,
  .true-answer {
    input {
      display: none;
    }
    label {
      padding-left: 20px;
    }
  }
}
