$c-local-toc-linkColorHover: darken($color-teacherSoftGray,5);

.howTo-textStyles {
  &--container{
    &:not(:first-child){
      margin-top: 2em;
      padding-top: 2em;
      border-top: 1px solid $sn-border-gray;
    }
  }
  color: $sn-primary-paragraph;
  a:not([class]){
    color: $color-brandBlue2020;
    font-weight: $fw-chubby;
    &:hover{
      text-decoration: underline;
      color: $color-linkHover;
    }
  }
  h2:not([class]){
    font-size: $text-big;
    font-weight: $fw-chubby;
    line-height: $lh-base;
    margin-bottom: .333em;
  }
  h3:not([class]){
    font-size: $text-big-ish;
    font-weight: $fw-chubby;
    line-height: $lh-base;
    margin-bottom: .5em;
  }
  h4:not([class]){
    font-size: $text-slightly-big;
    font-weight: $fw-chubby;
    line-height: $lh-base;
    margin-bottom: .5em;
  }
  h5:not([class]){
    font-size: $text-base;
    font-weight: $fw-fat;
    line-height: $lh-base;
    margin-bottom: .5em;
  }
  h6:not([class]){
    font-size: $text-base;
    font-weight: $fw-chubby;
    line-height: $lh-base;
    margin-bottom: .5em;
  }

  h5+ h6{
    margin-top: 1.5em;
  }
  p:not([class]){
    line-height: $lh-loose;
    &:not(:last-child){
      margin-bottom: 2em;
    }
  }
  ul{
    &:not(:last-child){
      margin-bottom: 2em;
    }
  }
  li:not([class]){
    line-height: $lh-loose;
    &:not(:last-child){
      margin-bottom: 1em;
    }
  }
  strong{
    font-weight: $fw-chubby;
  }
  em{
    font-style: italic;
  }
  
  // numbered heading sections
  &__stepHeading{
    font-size: $text-slightly-big;
    font-weight: $fw-chubby;
    line-height: $lh-base;
    padding-top: 3em;
    margin-bottom: .5em;
    &__number{
      font-size: $text-base;
      font-weight: $fw-slim;
      font-variant-numeric: lining-nums;
      $size: $s-px * 3.5;
      min-width: $size;
      height: $size;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $color-brandBlue2020;
      color: $white;
      margin-right: .25em;
    }
  }
  // text for examples or other things we want to call out
  &__exampleText{
    background: $color-teacherSoftGray;
    padding: $s-px * 2.5;
    margin-top: 1em;
    &:not(:last-child){
      margin-bottom: 2em;
    }
  }
  &__exampleText--minimal{
    font-weight: $fw-chubby;
    font-style: italic;
    margin-top: 1em;
    margin-bottom: .5em;
  }
  &__borderList{
    h5{
      font-size: $text-base;
      font-weight: $fw-fat;
    }
    padding-left:   $s-px * 2;
    border-left: 3px solid rgba($color-studyGuide,0.2);
    >li{
      &:not(:last-child){
        margin-bottom: 1em;
      }
    }
  }
  &_jump-link {
    position: absolute;
    top: -30px;
  }
  &__term{
    line-height: $lh-loose;
    margin-bottom: 2em;
    dt{
      font-size: $text-slightly-big;
      font-weight: $fw-chubby;
      line-height: $lh-base;
      margin-bottom: .5em;
    }
  }
  &__toc{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__title{
      background-color: $color-teacherSoftGray;
      border-radius: 12px 12px 0 0;
      padding: $s-px * 2 $s-px * 3;
    }
    &__list{
      background-color: $color-teacherSoftGray;
      border-radius: 0 12px 12px 12px;
      align-self: stretch;
    }
    &__list-item{
      &:first-child a{
        border-top-right-radius: 12px;
      }
      &:last-child a{
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
      }
      a{
        display: block;
        padding: $s-px * 2 $s-px * 3;
        color: $sn-primary-header;
        &:hover, &:focus{
          color: $color-linkHover;
          background-color: $c-local-toc-linkColorHover;
        }
      }
    }
  }
}
//Glossary page layout
.orders {
  position: sticky;
  top: 10px;
  z-index: 2;
  background-color: $white;
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 30%);
  padding: 6px 6px 12px;
  border-radius: 4px;
  transition: box-shadow .2s;
  @include media-small {
    top: 0px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(32px,1fr));
    grid-gap: 4px;
    &__item {
      display: flex;
    }
  }

  &__link {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
    background-color: $color-teacherSoftGray;
    color: $color-brandBlue2020;
    border-radius: 2px;
    padding: 5px;
    &:hover,
    &:focus {
      color: $white;
      background-color: $color-linkHover;
    }
  }

  &__notLink {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
    color: $color-a11yGray;
    cursor: not-allowed;
  }
}

.spacing {
  display: block;
  height: 32px;
  background-color: $white;
  width: 100%;
  margin-bottom: 50px;
  @include media-small {
    margin-bottom: 70px;
  }
}

.activeClass {
  color: $white;
  background-color: $sn-primary-header;
  &:hover,
  &:focus {
    color: $white;
    background-color: $sn-primary-header !important;
  }
}
