
// mixin for applying styles to elements when component becomes sticky
@mixin L-whenSticky(){
  .interior-sticky-nav--active &{
    @content;
  }
}
@mixin ipad {
  @media (max-width: 769px) {
    @content;
  }
}
// the sticky nav bar component
.interior-sticky-nav {
  position: -webkit-sticky; // still required for Safari
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: $white;
  transition: box-shadow 0.2s, border 0.2s;

  // class added when it becomes sticky
  .interior-audio {
    display: none;
  }

  .interior-audio.audio_show {
    display: block;
    margin: -80px 0 0 353px;
    @media (max-width: 769px) {
      right: 1%;
    }
    @include media-small { 
      margin-top: auto;
      right: 0;
    }
    .plyr__menu__container {
      @include media-notSmall {
        bottom: unset;
        top: 100%;
      }
    }
    .plyr__menu__container:after {
      @include media-notSmall {
        bottom: 100%;
        top: unset;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
      }
    }
  }
  
  &--active {
    border-color: darken($sn-border-gray,20);
    box-shadow: 0 3px 5px rgba(black,0.2);
    .interior-audio.audio_show {
      display: block;
      margin-top: auto;
    }
    .interior-sticky-nav__title.audio_adjust {
      height: 80px;
      @include media-small {
        height: 42px;
        padding: 0;
      }
    }
  }
    
  .interior-audio_legend {
    width: 125px;
    height: 14px;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    color: #004a3e;
    margin-left: 8px;
    padding-left: 4px;

  &_plus {
    color: #008262;
  }
  @include media-small {
    margin-left: 6px;
    border-radius: 0;
    background-color: $white;
    border: none;
    padding: 0;
    height: unset;
  }
}
  @include media-small {
    .interior-audio.audio_show .plyr__controls {
      padding : 10px;
    }
  }
  // class added when long pagination is open
  &--modal {
    @include media-small {
      background-color: transparent;
      z-index: 4;
    }
  }

  // container that holds all the 'text based UI'
  &__main{
    @include container($padding: true);
    display: flex;
    position: relative;
    border-bottom: 1px solid $sn-border-gray;
    flex-direction: column;
    justify-content: center;
    @include L-whenSticky{
      border: none;
    }
    .interior-sticky-nav--modal &{
      border-color: transparent;
    }
  }
  // the title - only appears once it becomes sticky
  // TODO: swap out with more performant animation (possibly use GSAP)
  &__title{
    font-size: 0;
    opacity: 0;
    color: $sn-primary-header;
    transition: all 0.2s;
    line-height: $lh-tight;
    display: flex;
    align-items: center;
    transition: padding 0.2s;
    &--hide {
      @include media-small {
      display:none;
      }
    }

    // the umbrella name
    &__umbrella{
      font-weight: $fw-base;
      @include L-whenSticky{
      font-size: $text-base;
      margin-right: 8px;
      }
    }
    // the section name
    &__section{
      font-weight: $fw-base;
      @include L-whenSticky{
      font-size: $text-small;
      color:$sn-primary-header;
      }
    }
    @include L-whenSticky{
      opacity: 1;
      @include media-1020-and-up{

      }
      @include media-under-1020{

      }
      padding-top: 4px;
    }
  }
  
  // conatiner for the navigation buttons
  &__navigation{
    justify-content: flex-start;
    align-self: stretch;
    width: 100%;
    display: flex;
    &--long{
      height: 48px;
    }

    @include L-whenSticky{
      width:auto;
    }
   }

  // div for the reading progress bar
  // TODO: implement this functionality later as a follow-on ticket
  &__progress{
    width: 100%;
    flex: 1 0 auto;
  }
  // &__Audio{
  //   display: none;
  // }
  &__scroll-to-top{
    display: none;
    width:0;
    height:0;
    @include L-whenSticky{
      margin-top: 8px;
      margin-bottom: 8px;
      margin-right: 8px;
      width:32px;
      height:32px;
      $size: $s-px * 3;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      display:flex;
      border: 1px solid $color-brandBlue2020;
      border-radius: 50%;

      &--noFear{
        border-color: $color-brandBlue2020;
      }
      &--ctQuizzes {
        border-color: #008262;
      }
      &--infographic {
        border-color: #008262;
      }

      &:hover, &:focus {
          border-color: $color-brandBlue2020;
          background-image: url(/images/icon-nextPage-brandBlue-24.svg);
          background-repeat: no-repeat;
          background-position: 50%;
          transform: rotate(-90deg);
          svg{
            display: none;
          }
      }
    }
  }
  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($sn-primary-header,0.5);
    backdrop-filter: blur(3px);
    z-index: 3;
    cursor: pointer;
    @include media-notSmall {
      width: 0;
      height: 0;
    }
  }
  // adding styles for interior sticky page view
  &__pagination-style {
    @media (min-width: $mobile-bp) {
      display: flex !important;
      flex-direction: row;
      justify-content: flex-start;
      padding-left: 0px !important;
      div:nth-child(1) {
        padding-left: 7px;
        padding-bottom: 3px;
        margin-right: 0px !important;
      }
      @media (min-width: $mobile-bp) and (max-width: $sn-max-width) {
        margin-left: 35px;
      }
    }
  }
  // hiding interior sticky icon and title when it become sticky
  .show-pageicon-pagetitle {
    @media (min-width: $mobile-bp) {
        display: none !important;
    }
  }
}
.interior-text-size {
  font-size: $text-base !important;
  @media (max-width: $mobile-bp) {
    font-size: $text-small !important;
  }
}
.interior-sticky-nav-position {
  top: 54px;
  @media (max-width: $mobile-bp) {
    top: 0px;
  }
}

@include media-small {
  .is-docked.pw-pos-top.addCustomMargin {
    margin-top: -400px;
  }

  .sticky-pw-with-pagination {
    margin-top: 11%;
  }
}

.interior-audio_legend.free-audio {
  width: 243px !important;
  @include media-small {
    width: 245px!important;
  }
}