$L-color-1: #903EC7;
$L-color-2: #6BC5C5;
$L-color-3: #6BC5C5;

// $L-break-moreLikeThis-small: A breakpoint custom to this component.
$L-break-moreLikeThis-small: 600px;
$L-break-moreLikeThis-medium: 800px;

@mixin L-media-moreLikeThis-small {
  @media screen and (max-width: $L-break-moreLikeThis-small) {
    @content;
  }
}
@mixin L-media-moreLikeThis-notSmall {
  @media screen and (min-width: $L-break-moreLikeThis-small + 1) {
    @content;
  }
}
@mixin L-media-moreLikeThis-medium {
  @media screen and (max-width: $L-break-moreLikeThis-medium) {
    @content;
  }
}
.more-like-this{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: $s-px * 3;
  @include L-media-moreLikeThis-medium{
    grid-gap: $s-px * 2;
  }
  margin-top: $s-px * 4;
  margin-bottom: $s-px * 4;

  justify-content: center;
  @include L-media-moreLikeThis-small{
    grid-template-columns: 1fr;
    grid-gap: 16px;
    display: none;
  }
  &__link{
    @include elliottShadowHover;
    &:not(.specific-enough):hover{
      text-decoration: none;
    }
    &__content{
      @include L-media-moreLikeThis-small{
        display: flex;
      }
    }
  }
  &__img{
    @include L-media-moreLikeThis-small{
      $size: 80px;
      width: $size;
      height: $size;
      border-radius: 50%;
      overflow: hidden;
      flex: none;
      margin: 12px 0 12px 12px;
      align-self: flex-start;
      img, svg{
        width: auto;
        display: block;
        height: $size;
        transform: translate(-50%);
        margin-left: $size / 2;
      }
    }
    @include L-media-moreLikeThis-notSmall{
      svg{
        width: 100%;
        height: auto;
        display: block;
      }      

    }
    &--quickQuiz, &--quickquiz, &--character {
      background-image: linear-gradient(135deg, $color-studyGuide 0%, #24C3D1 100%);
      path{
        fill: $white;
      }
    }
    &--noFear, &--nofear{
      background-image: linear-gradient(135deg, $color-noFear 0%, #2AC6CD 100%);
      path{
        stroke: $white;
      }
    }
    &--graphicnovel--image, &--infographic--image, &--quickQuiz--image, &--quickquiz--image, &--noFear--image, &--nofear--image, &--summary--image, &--character--image, &--teacherguide--image {
      width: 100%;
      @include L-media-moreLikeThis-small{
        padding-left: 27px;
        margin: 0px;
      }
    }

    &--summary{
      background-image: linear-gradient(135deg, $color-studyGuide 0%, #24C3D1 100%);
      path{
        stroke: $white;
      }
    }
    &--lifeAndTimes{
      
      img{
        @include L-media-moreLikeThis-small{
          // on mobile we don't want to center the shakespeare image as it crops badly
          transform: translate(0);
          margin-left: 0;
        }
        @include L-media-moreLikeThis-notSmall{
          width: 100%;
          display: block;
        }
      }
    }
    &--blog{
      @include L-media-moreLikeThis-notSmall{
        img{
          width: 100%;
          display: block;
        }
      }
    }

  }
  &__text{
    line-height: $lh-base;
    padding: $s-px * 1.5;
    display: flex;
    flex-direction: column;
    flex: auto;
  }
  &__strap{
    font-size: $text-small;
    font-weight: $fw-chubby;
    @include L-media-moreLikeThis-medium{
      font-size: $text-tiny;
    }
    color: $sn-off-gray;
    &--quickQuiz, &--quickquiz, &--character, &--graphicnovel, &--infographic, &--teacherguide {
      color: $color-studyGuide;
    }
    &--noFear, &--nofear {
      color: $color-noFear;
    }
    &--summary{
      color: $color-studyGuide;
    }
    &--lifeAndTimes{
      color: $color-lifeAndTimes;
    }   
    &--blog{
      color: $color-blog;
    }    
  }
  &__title:not(.specific-enough){
    color: $sn-primary-header;
    font-weight: $fw-base;
    padding-top: $s-px;
    font-size: $text-base;
    span{
      font-weight: $fw-chubby;
    }
  }
}