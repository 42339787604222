$text-mobile-base: 0.8rem;
@include premiumFlag{
    .shuffleCard-error-message{
      @keyframes bg {
        from{
          background: rgba($color-error,0);
        }
      }
      @keyframes content {
        from{
          opacity: 0;
          transform: translateY(48px);
        }
        to{
          opacity: 1;
          transform: translateY(0);
        }
      }
      background: rgba(197, 28, 0, 0.07);
      padding: 10px;
      align-items: center;
      animation: bg 0.4s;
      overflow: hidden;
      border: 2px solid $color-form-error;
      border-radius: unset;
      &:not([hidden]){
        display: flex;
        margin-bottom: 16px;
        text-align: left;
      }
      &__icon{
        animation: content 0.4s;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        img{
          transform: translateY(-2px);
        }
      }
      &__text{
        animation: content 0.4s;
        color: $color-form-error;
        font-size: $text-base;
        line-height: $lh-base;
        @include media-small {
          font-size: $text-mobile-base;
        }
      }
      &__title{
        font-size: inherit;
        color: inherit;
        @include media-small {
          font-size: $text-mobile-base;
        }
      }
      &__info{
        color: inherit;
        @include media-small {
          font-size: $text-mobile-base;
        }
      }
    }
    .shuffle-deck-error-container {
      height: 360px !important;
      @include media-small {
        height: 320px !important;
      }
    }
  }