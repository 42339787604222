@mixin right-rail-heading{
  font-size: $text-base;
  color: $sn-primary-header;
  font-weight: $fw-fat;
  margin-bottom: 12px;
  padding-top: 0.2em;
  padding-left: 16px;
  padding-right: 16px;
  line-height: $lh-tight;
  span{
    font-style: italic;
    font-weight: $fw-chubby;
  }
}