// ========================================
// Type settings
// ========================================

// Set raleway as the font fon the entire site
// TODO: Explore with Tom whether we want a more opinionated fallback stack
// Note: almost all of the styles here will eventually be removed as they either shouldn't be in a base styles file or are affecting too mcuh of the site
html {
  font-family: "Raleway", sans-serif;
  color: $sn-primary-paragraph;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $sn-primary-header;
}

h1 {
  font-weight: $fw-huge;
  font-size: 30px;
}


h2 {
  font-weight: $fw-chubby;
  font-size: 25px;
}

h3 {
  font-weight: $fw-chubby;
  font-size: 16px;
}

h4 {
  font-weight: $fw-chubby;
}

p {
  font-weight: $fw-base;
  font-size: $text-base;
  line-height: $lh-loose;
  color: $sn-primary-paragraph;

}

i, em {
  font-style: italic;
}

.helpIphoneuser {
  font-weight: bolder;
  text-transform:none;
}


b {
  font-weight: bolder;
  text-transform: capitalize;
}



a {
  text-decoration: none;
  color: $sn-primary-link;
}

input{
  font-family: inherit;
}

sup {
  font-size: $text-super;
  vertical-align: super;
}
sub {
  font-size: $text-super;
  vertical-align: sub;
}


// styling for title page promo unit
.title-page--books-list {
  .buy-it-now {
    margin-left: 0;
    margin-top: 10px;
    &:hover {
      background-color: darken($color-buyNow, 6);
    }
  }
  @media (max-width: $mobile-bp) {
    width: 25%;
  }
  .author-page__book {
    @media (max-width: $mobile-bp) {
      width: 100%;
    }
  }
  padding-top: 2rem;
  // keeps book on purchase on BN aligned with paragraph if there is one
  margin-top: -3rem;
  .book-title {
    display: block;
    &_title{
      color:$white;
      font-weight: $fw-fat;
      font-size: $text-slightly-big;
      line-height: $lh-extra-tight;
    }
    span{
      display: block;
      color:$white;
      font-weight: $fw-base;
      font-size: $text-base;
      line-height: $lh-tight;
    }
  }
}

.container--faq {
  width: 900px;
  margin-top: 2rem;
  @media (max-width: $mobile-bp) {
    width: 90%;
  }
  table {
    border-bottom: 1px solid $sn-gray;
    margin-bottom: 4rem;
    margin-top: 4rem;
    @media (max-width: $mobile-bp) {
      margin: 2rem 2rem;
    }
  }
  td {
    @media (max-width: $mobile-bp) {
      display: block;
      clear: both;
    }
  }
  h1 {
    position: relative;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 4rem;
    border-bottom: 1px solid $sn-gray;
    @media (max-width: $mobile-bp) {
      padding-top: 4rem;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -100%;
      bottom: -1px;
      border-bottom: 1px solid $sn-gray;
      width: 100%;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 100%;
      bottom: -1px;
      border-bottom: 1px solid #ccc;
      width: 100%;
    }
  }
  h2 {
    padding-bottom: 24px;
  }
  h3 {
    padding-bottom: 24px;
    padding-top: 4rem;
    font-size: 22px;
    color: $sn-primary-paragraph;
  }
  ul {
    @media (max-width: $mobile-bp) {
      list-style-position: inside;
    }
  }
  li {
    list-style-type: disc;
    list-style-position: inside;
    width: 90%;
    padding: 0;
    a {
      font-weight: $fw-chubby;
      text-decoration: underline;
    }
  }
}
.strapi-class__small-text {
  font-size: $text-small;
}
