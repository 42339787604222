// toms-cols($count): A function for calculating columns according to Tom's designs (12 cols @ $sn-max-width)
@function toms-cols($count) {
  $sn-grid-gutter: 24;
  $sn-grid-col: 75;
  $sn-grid-cols: ();
  @for $i from 1 through 12 {
    $sn-grid-cols: append(
      $sn-grid-cols,
      $i * $sn-grid-col + (($i - 1) * $sn-grid-gutter) * 1px
    );
  }
  @return nth($sn-grid-cols, $count);
}

// removeBtnStyles: A mixin for stripping browser styles from <button> elements
// note that to remove default focus styles you must pass in $removeFocus: true when calling this mixin
@mixin removeBtnStyles($removeFocus: false) {
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font: inherit;
  margin: 0;
  @if $removeFocus == true {
    outline: none;
  }
}

// removeInputStyles: A mixin for stripping browser styles from <input> elements
// note that to remove default focus styles you must pass in $removeFocus: true when calling this mixin
@mixin removeInputStyles($removeFocus: false) {
  background: none;
  border: none;
  border-radius: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  @if $removeFocus == true {
    outline: none;
  }
}

// container: a mixin for a container div that wraps content to the 12 column grid width set by Tom
@mixin container($padding: false) {
  max-width: $sn-max-width;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @if $padding == true {
    padding-left: $pageMargin;
    padding-right: $pageMargin;
  }
}

// visuallyHidden: a mixin for setting an element to be isually hidden, but still screen-reader accessible.
@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// toms-cols($count): An updated column generator for Tom's redesigned grid
@function cols-2019($count) {
  $sn-grid-gutter: 24;
  $sn-grid-col: 48;
  $sn-grid-cols: ();
  @for $i from 1 through 16 {
    $sn-grid-cols: append(
      $sn-grid-cols,
      $i * $sn-grid-col + (($i - 1) * $sn-grid-gutter) * 1px
    );
  }
  @return nth($sn-grid-cols, $count);
}

// playwire-gray-box: Styling for when we wrap playwire ads in a gray box with 'ad' label.
@mixin playwire-gray-box {
  &:empty{
    margin: 0;
  }
  &:not(:empty) {
    background-color: $color-background;
    padding-bottom: $s-px;
    padding-left: $s-px;
    padding-right: $s-px;
    border-radius: $radius-big;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    &:before{
      content: 'Advertisement';
      text-align: center;
      font-size: $text-tiny;
      padding-top: $s-px;
      padding-bottom: $s-px;
      color: $sn-off-gray;
    }
  }
}

@mixin bn-pod-bg(){
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)),
  linear-gradient(
    135deg,
    $color-bnGreen 0%,
    darken($color-bnGreen, 17) 100%
  );
  background-size: 100% 48px, 100%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
}

@mixin softGrayCardLink(){
  background-color: $color-teacherSoftGray;
  border-radius: $radius-base;
  color: $color-brandBlue2020-onSoftGray;
  transition: all 0.2s;
  &:hover{
    color: $color-linkHover;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.2), inset 0 3px 0 0 $color-linkHover;
    transform: translateY(-2px);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@mixin bn-pseudo-button(){
  font-weight: $fw-fat;
  line-height: $lh-tight;
  color: $color-bnGreen;
  background: $white;
  padding: 4px 12px;
  border-radius: 4px;
}

@mixin elliottShadow($bg: $white, $opacity: 1){
  position: relative;
  background-color: $bg;
  &:before{
    // This is the shadow
    position: absolute;
    content:'';
    display: block;
    width: 100%;  // same width as actual element
    top: 10px;  // starts slightly lower
    left: 0;
    height: calc(100% - 60px); //ends 50px before bottom of card
    background-color: rgba(black,0.3);  //shadow color
    opacity: $opacity; // make shadow less prominent by default
    filter: blur(20px); // use the blur filter to create shadow
    z-index: -1; // place behind the card content
    //transform: translate3d(0,0,0.0001px); //fix some CSS transition weirdness with context and painting
    transition: opacity 0.2s;
  }
}

@mixin elliottShadowHover($bg: transparent){
    display: flex;
    >div{
      background-color: #fff;
      flex: 1;
      transition: transform 0.2s;
    }
    @include elliottShadow($bg, $opacity: 0.4);

    &:hover{
      >div{
        // move card up slightly
        transform: translateY(-10px);
      }
      &:before{
        // increase shadow strength;
        opacity: 1;
      }
    }
}

@mixin scrollShadows($dir: 'vertical'){
  &__sentinel{
    height: 1px;
    width: 1px;
  }
  &:before,
  &:after{
    content: '';
    display: block;
    @if $dir == 'vertical'{
      position: sticky;
      left: 0;
      right: 0;
      height: 18px;
      transform: scaleY(0);
    }
    @if $dir == 'horizontal'{
      position: sticky;
      top: 0;
      bottom: 0;
      width: 18px;
      transform: scaleX(0);
    }
    pointer-events: none;
    opacity: 0;
    transition: all 0.3s;
    z-index: 1;
    flex: none;
  }
  &:before{
    @if $dir == 'vertical'{
      background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 80%);
      transform-origin: top center;
      top: 0;
      margin-bottom: -18px;
    }
    @if $dir == 'horizontal'{
      background: linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 80%);
      transform-origin: top left;
      left: 0;
      margin-right: -18px;
    }
  }
  &:after{
    @if $dir == 'vertical'{
    background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 80%);
    transform-origin: bottom center;
    bottom: 0;
    margin-top: -18px;
    }
    @if $dir == 'horizontal'{
      background: linear-gradient(270deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 80%);
      transform-origin: center right;
      right: 0;
      margin-left: -18px;
    }
  }
  &.scrolled-from-start{
    &:before{
      opacity: 1;
      @if $dir == 'vertical'{
        transform: scaleY(1);
      }
      @if $dir == 'horizontal'{
        transform: scaleX(1);
      }
    }
  }
  &.scrolled-from-end{
    &:after{
      opacity: 1;
      @if $dir == 'vertical'{
        transform: scaleY(1);
      }
      @if $dir == 'horizontal'{
        transform: scaleX(1);
      }
    }
  }
}

@mixin previewHover{
  overflow: hidden;
  position: relative;
  transition: all 0.25s;
  &:before {
    z-index: 1;
    content: "";
    width: 72px;
    height: 72px;
    background-image: url("/images/icon-expandThumb-24-white.svg"),
      linear-gradient(45deg, transparent 50%, $color-linkHover 50%);
    background-repeat: no-repeat;
    background-position: calc(100% - 12px) 12px, 50% 50%;
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    transform: translate(100%, -100%);
    transition: all 0;
  }
  &:hover,
  &:focus {
    &:before {
      opacity: 1;
      transform: translate(0, 0);
      transition: all 0.25s ease-out;
    }
  }
}

$break-landing-small: 700px;
// media query for applying styling at or below the $L-break-textsLanding-small breakpoint
@mixin media-landing-small {
  @media screen and (max-width: $break-landing-small) {
    @content;
  }
}

// media query for applying styling above the $L-break-textsLanding-small breakpoint
@mixin media-landing-not-small {
  @media screen and (min-width: $break-landing-small + 1) {
    @content;
  }
}

@mixin media-small {
  @media screen and (max-width: 699px) {
    @content;
  }

}
@mixin media-notSmall {
  @media screen and (min-width: 700px) {
    @content;
  }
}

@mixin media-tiny {
  @media screen and (max-width: 399px) {
    @content;
  }
}
@mixin media-very-tiny {
  @media screen and (max-width: 300px) {
    @content;
  }
}
@mixin media-notTiny {
  @media screen and (min-width: 400px) {
    @content;
  }
}

@mixin media-1020-and-up {
  @media (min-width: 1020px) {
    @content;
  }
}
@mixin media-under-1020 {
  @media (max-width: 1019px) {
    @content;
  }
}

@mixin media-650-and-upto-1020 {
  @media (min-width: 651px) and (max-width: 1019px) {
    @content;
  }
}


@mixin interior-page-icon {
  $iconSize: 80px;
  $iconMargin: 12px;
  width: $iconSize;
  height: $iconSize;
  flex: none;
  border: 2px solid $color-brandBlue2020;
  border-radius: 50%;
  margin-right: $iconMargin;
}

@mixin media-hi-res{
  @media (-webkit-min-device-pixel-ratio: 2),  (min-resolution: 192dpi) {
    @content;
  }
}

// code in this block only gets compiled to styles.css if the Premium Flag is enabled
@mixin premiumFlag{
  @if($premiumEnabled == 'true') {
    @content;
  }
}

// code in this block only gets compiled to styles.css if the Strapi Study Guide Flag is enabled
@mixin strapiStudyGuideFlag{
  @if($strapiStudyGuideEnabled == 'true') {
    @content;
  }
}

// code in this block only gets compiled to styles.css if the Strapi Teachers Guides Flag is enabled
@mixin strapiTeachersGuidesFlag {
  @if($strapiTeachersGuidesEnabled == 'true') {
    @content;
  }
}
