.switch-sort-method{
  display: flex;
  &__choice{
    padding-top: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    line-height: $lh-tight;
    font-weight: $fw-chubby;
    color: $sn-primary-header;
    &--link{
      padding-left: 12px;
      font-weight: $fw-base;
      color: $color-brandBlue2020;
      border-radius: $radius-base;
      &:hover,
      &:focus{
        color: $color-linkHover;
        background-color: $color-teacherSoftGray;
      }
    }
  }
}