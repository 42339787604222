// variable for setting max width of title-nav__subitem
$title-nav__subitem-width-max: $sn-max-width - ($pageMargin * 2);

@mixin L-whenSubnavSticky(){
  .sticky-subnav &{
    @content;
  }
}
// for sticky subnav
.sticky-subnav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;
  transition: box-shadow .2s,border .2s;
  height: 56px;
  @media (max-width: $mobile-bp) {
    position: unset;
  }
}
.title-nav {
  $shadow: #bbb;

  //may be use in future-
  // background:
  // linear-gradient(to right, $white 24px, rgba($white,0) 72px),
  // linear-gradient(to left, $white 24px, rgba($white,0) 72px) 0 100%,
  // linear-gradient(to right, $shadow 1px, rgba($shadow,0) 16px),
  // linear-gradient(to left, $shadow 1px, rgba($shadow,0) 16px);
  background-attachment: local, local, scroll, scroll;
  
  background-color: #e5f0f9;
  border-bottom: 1px solid $sn-border-gray;

  @include media-mobile {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }

  &.-show-mobile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 5; // just high enough to move above DistroScale on mobile
    border-top: 0;
    @include media-mobile {
      width:100%;
    }
  }
}

#title-nav--items {
  // the container that houses all the links
  display: flex;
  // on desktop view we set width of tab list so it aligns with content.
  // on smaller widths it will default to auto and so will scroll.
  @include media-desktop {
    @include container($padding:true);
  }
  @include media-tablet {
    max-width: 100vw;
    align-items: stretch;
  }
}

.title-nav__item {
  position: relative;
  display: block;
  //don't shrink or grow even when in a flex container:
  flex: none;
  @include media-tablet {
    flex: 0 1 auto;
  }
  @include media-tablet-and-desktop {
    &>a{

      &:after{
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: transparent;
      }
    }
  }
  // increased z-index to move above distroscale ad
  z-index: 4;
  //we add a class via js on mobile to fix z-index issue caused by switch from display:none to visibility:hidden
  &.-show-mobile-overlay {
    z-index: 5;
  }
  line-height: $lh-base;

  // the main title for each section of the title_nav (eg 'Summary', 'Characters', etc)
  // these always have either class of .arrow-nav-item or .no-arrow
  // TODO: refactor html to have a common class so we don't have to use a direct decendant selector here
  > a {
    //we hide these when the mobile overlay is shown
    .-show-mobile-overlay & {
      visibility: hidden;
    }
    // set to display flex for easy positioning of pseudo-element arrow
    display: flex;
    align-items: center;
    color: $color-a11yGray;
    line-height: $lh-tight;
    padding: $s-px * 2;

    // styles for the section title that matches the currently active page
    &.title-nav__item__active-tab {
      color: $sn-primary-header;
      font-weight: $fw-chubby;
      border-bottom: 2px solid $sn-primary-header !important;
      &::after {
        // background-color: $sn-primary-header;
      }
    }
    @include media-tablet {
      height: 100%;
      padding: $s-px * 2 $s-px;
    }
    @at-root .title-nav__item:hover > a {
      color: #004371;
      background-color: $color-teacherSoftGray;
      &:after {
        background-color: #004371;
      }
    }
  }
}

.title-nav__subitem {
  //hidden by default
  //note - '-show' gets added by title-nav.js when the page has a query string called 'expanded' (it doesn't matter what the value is as the JS runs off the active subnav which gets set in the html)
  &:not(.-show) {
    visibility: hidden;
  }
  //displays when hovering over parent
  @at-root .title-nav__item:hover & {
    @include media-tablet-and-desktop {
      visibility: visible;
    }
  }

  @at-root .title-nav__item:focus-within & {
    @include media-tablet-and-desktop {
      visibility: visible;
    }
  }

  background-color: $white;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1); //TODO: Devise a standard box-shadow and use it here.

  -webkit-overflow-scrolling: touch; //smooth scrolling on iOS

  @include media-tablet{
    // don't allow it to expand wider than screen on tablet
    max-width: calc(100vw - #{$pageMargin * 2});
  }
  @include media-desktop{
    // don't allow it to expand wider than main content area on desktop
    max-width: 1146px;
  }

  @include media-mobile {
    //on mobile we add a class with js to trigger an overlay mode
    // display: none;
    visibility: hidden;
    height: 0;
    width: 0;
    &.-show-mobile-overlay {
      top: 0;
      left: 0 !important;
      position: fixed;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      visibility: visible;
      display: block;
      z-index: 2;
      .list-grid,
      .sub-list {
        // display: block;
        border: none;
        li {
          border: none;
          list-style-type: disc;
          a {
            font-size: 18px;
            text-decoration: underline;
          }
        }
      }
      .sub-list {
        border: none;
      }
    }
  }
  @include media-tablet-and-desktop {
    display: flex;
    // position is based on position of tab (note js kicks in if it's too wide and shifts it left to fit)
    position: absolute;
    top: 100%;
    // give fixed width for scrolling effect
    width: auto;
    left: 0;
    z-index: -1; //TODO: Check if this is really needed or if something else should be refactored.
    border: 1px solid $sn-border-gray;
    // scroll snapping:
    border-radius: 0 0 12px 12px;
    @include L-whenSubnavSticky {
      border-top: 0px;
    }
    .sub-list{
      scroll-snap-align: start;
    }
    // scrollbar styling:
    &::-webkit-scrollbar-track{
      background-color: $sn-border-gray;
    }

    &::-webkit-scrollbar{
      width: 5px;
      height: $s-px;
    }

    &::-webkit-scrollbar-thumb{
      background-color: $sn-dark-hash;
    }
  }

}

.title-nav-mobileHeader {
  font-size: $text-slightly-big;
  padding: $s-px * 2;
  border-bottom: 1px solid $sn-border-gray;
  display: flex;
  align-items: center;
  &:after {
    margin-left: auto;
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    background: url("/images/svgicon-close-color-off-gray.svg") center no-repeat;
    cursor : pointer;
  }
}

// variables for defining widths of .sub-list and .list-grid:
$max-sub-list-count: 6;
$max-sub-list-width: $title-nav__subitem-width-max / $max-sub-list-count;

.section-style {
  padding-top: 30px !important;
  margin-left: -18px !important;
}
@mixin L-title_nav-links {
  // text styling (could be moved up DOM tree a bit but as these styles apply to multiple areas it's easier to define here)
  font-weight: $fw-chubby;
  font-size: $text-small;
  // use flex to expand items to allow child link to fill height (required only for '.list-grid' but no harm in applying to both)
  display: flex;

  & > a {
    //add some clickable space around the link text
    padding: $s-px $s-px * 2;
    // expand link click area to fill width of parent
    flex: 1 1 auto;
    //use flex to vertically center text in link area
    display: flex;
    align-items: center;

    // If the link is for the current page we make it look ike regular text.
    // TODO: investigate refactoring html so that this is no longer an anchor.
    &.subnav-active {
      color: $sn-primary-header;
      cursor: default;
      pointer-events: none;
    }

    // hover styles for links that are actually clickable:
    &[href]:not(.subnav-active) {
      transition: color 0.2s, background-color 0.2s;
      color: $color-brandBlue2020;
      //HACK: owl-carousel appears to interact with any transitions in a weird way. Removed from sonnets to prevent it happening.
      @at-root .owl-carousel & {
        transition: none;
      }
      &:hover, &:focus {
        color: #004371;
        background-color: $color-teacherSoftGray;
      }
    }
    
    // subtitles (eg 'Act 1', 'Section quizzes') are rendered as links with no href so this is how we target them with CSS
    // TODO: Refactor the html to make these something other than <a> tags
    &:not([href]) {
      color: $sn-primary-header;
      padding-bottom: $s-px * 1.5;
      text-transform: uppercase;
      @include L-whenSubnavSticky {
        border-bottom: 1px solid $sn-primary-header;
      }
    }
  }
}

@mixin L-title_nav-link-owl {
  // text styling (could be moved up DOM tree a bit but as these styles apply to multiple areas it's easier to define here)
  font-weight: $fw-chubby;
  font-size: $text-small;
  // use flex to expand items to allow child link to fill height (required only for '.list-grid' but no harm in applying to both)
  display: flex;

  & > a {
    //add some clickable space around the link text
    padding: $s-px $s-px * 2;
    // expand link click area to fill width of parent
    flex: 1 1 auto;
    //use flex to vertically center text in link area
    display: flex;
    align-items: center;

    // If the link is for the current page we make it look ike regular text.
    // TODO: investigate refactoring html so that this is no longer an anchor.
    &.subnav-active {
      color: $white;
      cursor: default;
      pointer-events: none;
      background-color: #0066c4!important;
    }
    @media (max-width: $mobile-bp) {
      // hover styles for links that are actually clickable:
      &[href]:not(.subnav-active) {
        transition: unset !important;
        color: #0066c4 !important;
        //HACK: owl-carousel appears to interact with any transitions in a weird way. Removed from sonnets to prevent it happening.
        @at-root .owl-carousel & {
          transition: none;
        }
        &:hover {
          color: #004371 !important;
          background-color: $color-teacherSoftGray !important;
        }
      }
    }

    // hover styles for links that are actually clickable:
    &[href]:not(.subnav-active) {
      transition: color 0.2s, background-color 0.2s;
      color: $color-brandBlue2020;
      //HACK: owl-carousel appears to interact with any transitions in a weird way. Removed from sonnets to prevent it happening.
      @at-root .owl-carousel & {
        transition: none;
      }
      &:focus {
        color: #004371 !important;
        background-color: $color-teacherSoftGray;
      }
      &:hover {
        color: #004371 !important;
        background-color: $color-teacherSoftGray;
      }
    }
    
    // subtitles (eg 'Act 1', 'Section quizzes') are rendered as links with no href so this is how we target them with CSS
    // TODO: Refactor the html to make these something other than <a> tags
    &:not([href]) {
      color: $sn-primary-header;
      padding-bottom: $s-px * 1.5;
      text-transform: uppercase;
      margin-bottom: 8px;
      padding: 3px 0px 4px 12px !important;
      font-size: $text-slightly-big !important;

      @include L-whenSubnavSticky {
        border-bottom: 1px solid $sn-primary-header;
      }
    }
  }
}

.sub-list {
  // set to width of 1 row:
  @include media-tablet-and-desktop {
    width: 232px;
    flex: none;
  }
  padding-top: $s-px;
  padding-bottom: $s-px;
  @include L-whenSubnavSticky {
    padding-left: 8px;
    padding-right: 8px;
  }
  li {
    a {
      padding: 12px 12px !important;
    }
  }
  // By default sublists are 1/6 of the width of Toms grid.
  // When the page gets narrower than this it would cause the nav to not fit if there are six sub-lists (the current max count).
  // In this case we adjust to set their width to 1/6th the viewport width.
  @include media-tablet {
    width: calc(100vw / 6);
  }
  // this is where we specify specific columns for IE11:
  @for $i from 1 through 9 {
    &:nth-child(#{$i}) {
      -ms-grid-column: $i;
    }
  }
  // all except the first item use a border-left for the divider
  &:not(:first-child) {
    @include L-whenSubnavSticky {
      border-left: 0px !important;
    }
    border-left: 1px solid $sn-gray;
  }
  // hide if empty
  &:empty {
    display: none;
  }
  // use shared link styles:
  li {
    @include L-title_nav-links;
  }
}

.list-grid {
  //set as flex to work in all browsers including IE11
  display: flex;
  @include media-tablet-and-desktop {
    // set to width of 2 rows:
    width: $max-sub-list-width * 2;
    flex-wrap: wrap;
  }
  @include media-mobile {
    flex-direction: column;
  }

  li {
    // use shared link styles:
    @include L-title_nav-links;
    @include media-tablet-and-desktop {
      // set to width of 1 row:
      width: $max-sub-list-width;
    }
    //first 2 items have extra padding to visually match with .sub-grid items
    &:nth-child(1),
    &:nth-child(2) {
      padding-top: $s-px;
    }
  }
  // we add a border to the first item in each row to create the divider line (as long as there are more than one item)
  li:nth-child(odd):not(:only-child) {
    @include L-whenSubnavSticky {
      border-right: 0px !important;
    }
    @include media-tablet-and-desktop {
      border-right: 1px solid $sn-gray;
    }
  }
  // if there is only one item we set it to width of two rows
  // TODO: consider refactor to adjust html in cases where there is only one item.
  li:only-child {
    width: $max-sub-list-width * 2;
  }
}

//sonnets menu
//TODO: sonnets was rushed, I should refactor more
#sonnets-menu {
  $buttonspace: 38px + $s-px * 2;

  visibility: hidden;
  // display: none;
  .title-nav__item:hover & {
    visibility: visible;
    // display: block;
    // @media (max-width: $mobile-bp) {
    //     display: none !important;
    // }
  }
  .sub-list {
    width: calc((100vw - 108px) / 6);
    max-width: 100%;
  }
  width: $max-sub-list-width * 5 + $buttonspace * 2;
  overflow: hidden;
  position: absolute;
  left: 0;
  //TODO: once i replace the image with an SVG I should amend this
  padding-left: 38px + $s-px * 2;
  padding-right: 38px + $s-px * 2;
  .owl-stage-outer {
  }
  @media (max-width: $mobile-bp) {
    left: 0 !important;
  }
  .owl-item:not(:last-child) .sub-list {
    border-right: 1px solid #ccc;
  }
  .owl-prev {
    margin-left: $s-px * 2;
    background-position-x: -222px;
    background-position-y: 0px;
    width: 38px;
    height: 38px;
  }
  .owl-prev.disabled {
    background-position: 145px 515px;
  }
  .owl-next {
    margin-right: $s-px * 2;
    background-position-x: -297px;
    background-position-y: 0px;
    width: 38px;
    height: 38px;
  }
  .owl-next.disabled {
    background-position-x: -261px;
    background-position-y: 0px;
  }
}

//temporary style for No Fear and Full Texts
.interior-subnav__toc-menu-header{
  display: flex;
  &__icon{
    flex: none;
    margin-right: 8px;
    align-items: center;
    path{
      fill: $sn-primary-header;
      .title-nav__item:hover &{
        fill: $color-linkHover;
      }
    }
  }

}
// changing the display of subnav
.subnav-display-style {
  display: flex;
  max-width: 1260px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @media(max-width: $sn-max-width){
    margin-left: auto;
  }
}
// adjusting the margin for subnav
.subnav-margin {
  margin-left: auto;
  @media(max-width: $sn-max-width) {
    margin-left: auto;
  }
}
//addding scrolling effect
.scrolling-item {
  height: 550px;
} 

.title-nav__item:hover {
  .scrolling-item {
    @include media-tablet-and-desktop {
      overflow-y: scroll;
    }
  }  
}

.title-nav__item:focus-within {
  .scrolling-item {
    @include media-tablet-and-desktop {
      overflow-y: scroll;
    }
  }  
}

// Studyguide Redesign subnav //
.redesign-subnav {
  background: $white !important;
  border-bottom: none !important;
  border-radius: 0px 0px 12px 12px;
}
.title-nav-mobileHeader {
  font-size: 1.3125rem;
}

.redesign-sticky-nav {
  background: $white !important;
}

.redesign-sticky-nav .interior-sticky-nav__main {
  border-bottom: none !important;
}

.redesign-sticky-nav .interior-sticky-nav__navigation--short ~ .studyGuide_PageFavorite {
  @include media-mobile {
    margin-left: 6px;
  }
}

.redesign-sticky-nav .interior-sticky-nav__navigation--long {
  @include media-mobile {
    padding: 0 0 0 20px!important;
  }
}

.redesign-sticky-nav .interior-sticky-nav__navigation--long ~ .studyGuide_PageFavorite {
  @include media-mobile {
    margin-left: 14px;
  }
}

.redesign-sticky-nav {
  top: 55px !important;
  @include media-mobile {
    top: 47px !important; 
  }
}

.redesign-sticky-nav {
  .show-pageicon-pagetitle {
    display: none !important;
  }
}

.redesign-header {
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 15%);
  max-width: unset !important;
  width: 100%;
  padding: 6px 0 7px 0 !important;
  margin-bottom: 14px;
}

.redesign-header .interior-header__container {
  max-width: 1164px !important;
  width: 100%;
  margin: auto;
  padding: 0 12px;
}

.redesign-subnav .title-nav__item>a {
  display: flex;
  align-items: center;
  color: $color-a11yGray;
  line-height: $lh-tight;
  padding: 0;
  margin: 13px 32px 0 0;
  @include media-small { 
    margin: 12px 16px 0 0;
  }
}

@media (max-width: 1116px) {
  .redesign-subnav .owl-item:first-child .title-nav__item>.title-nav__subitem {
    left: 0px;
  }
}

.redesign-subnav .owl-item .title-nav__item>.title-nav__subitem {
  left: -20px;
}

.redesign-subnav .subnav-display-style {
  max-width: 1164px;
}

.redesign-subnav .show-homelink-searchbtn {
  display: none !important;
}

.redesign-sticky-nav .interior-sticky-nav__scroll-to-top.interior-sticky-nav__scroll-to-top--studyGuide {
  display: none !important;
}

.redesign-sticky-nav.interior-sticky-nav--active .interior-sticky-nav__title {
  padding: 8px 0 9px 0!important;
}

.redesign-title-text {
  padding: 0 0 0 12px !important;
}

.redesign-page-title.interior-text-size {
  font-size: $text-big-ish !important;
  font-weight: $fw-fat !important;
  line-height: $lh-tight;
}

.redesign-subnav .sections .arrow-nav-item {
  font-size: $text-base !important;
  font-weight: $fw-chubby;
  color: #767676;
  line-height: $lh-base;
  border-bottom: 2px solid transparent;
}

.redesign-subnav .subnav-display-style.subnav-margin 
.sections .arrow-nav-item {
  color:#767676;
}

.redesign-subnav .subnav-display-style.subnav-margin 
.sections .arrow-nav-item.title-nav__item__active-tab {
  color: $sn-primary-header !important;
}

.redesign-subnav .sections 
.arrow-nav-item.title-nav__item__active-tab {
  color: $sn-primary-header;
}

.redesign-subnav .title-nav__item:hover>a:after {
  background: none;
}

.redesign-subnav .title-nav__item:hover>a {
  background: 0!important;
  color: $text-base !important;
  @include media-small {
    color: #004371 !important;
  }
}

.redesign-subnav .title-nav__item:focus-within>a {
  background: 0!important;
  color: $text-base !important;
  @include media-small {
    color: #004371 !important;
  }
}

.redesign-sticky-nav .studyGuide_PageFavorite 
.interior-header__myPageInput {
  background: url(/images/flashcard/bookmark.svg) no-repeat;
  background-size: unset;
  @include media-notSmall {
    &:hover {
      background-position: -30px 0;
    }
  }
}

.redesign-sticky-nav .commonStyle_PageFavorite {
  padding: 0 0 3px 13px;
}

.redesign-header .interior-header__pageFavoritingIcon {
  margin-top: 3px;
}

@media (min-width: $mobile-bp) and (max-width: $sn-max-width) {
  .redesign-sticky-nav .interior-sticky-nav__pagination-style {
    margin-left: 0 ;
  }

}

.redesign-subnav #title-nav--items {
  @media (min-width: $mobile-bp) and (max-width: $sn-max-width) {
    // padding:0 12px;
  }
  @include media-small {
    // padding:0 20px;
  }
}


.redesign-sticky-nav 
.studyGuide_PageFavorite.pageSolidStar input+label {
  background-position: -60px 0 !important;
}

.hide-umbrella-title {
  display: none;
}

.redesign-sticky-bookmark.commonStyle_PageFavorite 
.interior-header__myPageInput {
  background-size: unset;
  @include media-notSmall {
    &:hover {
      background-position: -30px 0;
    }
  }
}

.redesign-sticky-bookmark.commonStyle_PageFavorite.pageSolidStar 
input+label {
  background-position: -60px 0;
}

.commonStyle_PageFavorite.redesign-sticky-bookmark{
  padding: 0 0 1px 6px;
}

.redesign-sticky-bookmark.commonStyle_PageFavorite.nofear_PageFavorite {
  margin: unset;
  @include media-small {
    margin-top: -5px;
    padding: 0 0 3px 13px;
  }
}


#title-nav--items {
  width: 100%;
  position: relative;
  height: 100%;
}

.study {
  top: 0;
  left: 0 !important;
  position: fixed !important;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.study1 {
  height: 100%;
  width: 100% !important;
}

.study2 {
  top: 0px;
  position: fixed !important;
  width: 100% !important;
  height: 100%;
  visibility: visible;
  display: block;
  z-index: 2;
  left: 0px !important;
}

//study guide owl carousel css
.hidden-scroll-bar {
  position: sticky;
  overflow-x: unset;
  overflow-y: unset;
  height: 56px;
  @media (max-width: $mobile-bp) {
    height: 47px; 
  }
}

.redesign-subnav {

  #title-nav--items {
    display: block;
  }

  @media (max-width: $mobile-bp) {
    .sticky-subnav {
      position: -webkit-sticky;
    }
    .mobile-owl-popup {
      display: none;
    }
  }
  
  .owl-carousel .item {
    height: 10rem;
    background: #4DC7A0;
    padding: 1rem;
    cursor: pointer;
  }

  @media (max-width: 1116px) {
    .owl-item:first-child .title-nav__item {
      padding-left: 20px;
    } 
  }

  @media (max-width: $mobile-bp) {
    .owl-item:first-child .title-nav__item {
      padding-left: 20px;
    }
  }

  .owl-item:last-child .title-nav__item {
    padding-right: 20px;
  }

  .popup {
    position: fixed;  
    width: 0;
    height: 0;
    background: white;
    z-index: 10;
    opacity: 0;
  }

  .owl-theme .owl-nav .disabled {
    opacity: 0 !important;
    display: none;
  }

  .owl-carousel {
    height: 56px;
    @media (max-width: $mobile-bp) {
      height: 47px;
    }
  }

  .owl-next {
    background-color: rgba(255, 255, 255, 0.8);
    top: 1px;
    right: 0%;
    color: #0066c4 !important;
    font-size: 14px !important;
    background-image: unset;
    margin: unset !important;
    background-position: unset;
    width: 50px;
    height: unset;
    &:hover {
      color: #004371 !important;
    }
    @include media-mobile {
      right: 0%;
    }
    @include media-small {
      right: 0%;
    }
  }

  .owl-next .owl-right-arrow {
    padding: 14px 15px;
  }
  
  .owl-next .owl-right-arrow:hover {
    color: #004371 !important;
    filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
  }

  .owl-next:hover .owl-right-arrow {
    color: #004371 !important;
    filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
  }

  .owl-prev .owl-left-arrow {
    padding: 14px 15px;
  }
  
  .owl-prev .owl-left-arrow:hover {
    color: #004371 !important;
    filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
  }

  .owl-prev:hover .owl-left-arrow {
    color: #004371 !important;
    filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
  }

  .owl-prev {
    top: 1px;
    background-color: rgba(255, 255, 255, 0.8);
    left: 0%;
    width: 50px;
    height: unset;
    background-image: unset;
    background-position: unset;
  }

  .displayNone {
    display: block !important;
  }

  .owl-carousel .owl-stage-outer {
    overflow: unset;
    @media (max-width: $mobile-bp) {
      overflow: hidden;
    }
  }

  .sub-list {
    // set to width of 1 row:
    @include media-tablet-and-desktop {
      width: 232px;
      flex: none;
    }
    padding-top: 24px;
    padding-bottom: unset;
    padding-left: 10px;
    padding-right: 10px;
    @include L-whenSubnavSticky {
      padding-left: 10px;
      padding-right: 10px;
    }
    li {
      a {
        padding: 8px 12px !important;
      }
      
    }
    // By default sublists are 1/6 of the width of Toms grid.
    // When the page gets narrower than this it would cause the nav to not fit if there are six sub-lists (the current max count).
    // In this case we adjust to set their width to 1/6th the viewport width.
    @include media-tablet {
      width: calc(100vw / 6);
    }
    // this is where we specify specific columns for IE11:
    @for $i from 1 through 9 {
      &:nth-child(#{$i}) {
        -ms-grid-column: $i;
      }
    }
    // all except the first item use a border-left for the divider
    &:not(:first-child) {
      @include L-whenSubnavSticky {
        border-left: 0px !important;
      }
      border-left: 1px solid $sn-gray;
    }
    // hide if empty
    &:empty {
      display: none;
    }
    // use shared link styles:
    li {
      @include L-title_nav-link-owl;
    }
  }

  .list-grid {
    //set as flex to work in all browsers including IE11
    display: flex;
    @include media-tablet-and-desktop {
      // set to width of 2 rows:
      width: $max-sub-list-width * 2;
      flex-wrap: wrap;
    }
    @include media-mobile {
      padding: 16px 10px 10px 8px;
      flex-direction: column;
    }
  
    li {
      // use shared link styles:
      @include L-title_nav-link-owl;
      @include media-tablet-and-desktop {
        // set to width of 1 row:
        width: $max-sub-list-width;
      }
      //first 2 items have extra padding to visually match with .sub-grid items
      &:nth-child(1),
      &:nth-child(2) {
        padding-top: unset;
      }
    }
    // we add a border to the first item in each row to create the divider line (as long as there are more than one item)
    li:nth-child(odd):not(:only-child) {
      @include L-whenSubnavSticky {
        border-right: 0px !important;
      }
      @include media-tablet-and-desktop {
        border-right: 1px solid $sn-gray;
      }
    }
    // if there is only one item we set it to width of two rows
    // TODO: consider refactor to adjust html in cases where there is only one item.
    li:only-child {
      width: $max-sub-list-width * 2;
    }
  }
  .section-style {
    padding-top: 53px !important;
    @include media-mobile {
      padding-top: 30px !important; 
      padding-left: 30px;
    }
    margin-left: -18px !important;
  }
}

.redesign-header {
  box-shadow: 0 0px 4px 0 rgba(0,0,0,.15);

  @media (max-width: $mobile-bp) {
    .interior-header__title__pagetitle {
      font-size: $text-slightly-big;
      font-weight: $fw-chubby;
      line-height: $lh-base;
    }
  }

  .interior-header__title__favoritingIcon {
    gap: 8px;
    align-items: center;
  }

  .interior-header__container {
    padding: 0 12px;
    @media (max-width: 1116px) {
      padding: 0 20px;
    }
  }

  .interior-header__title__pagetitle {
    font-size: $text-big-ish;
    font-weight: $fw-chubby;
    color: $sn-primary-paragraph;
  }

  .interior-header__title__pagetitle__topTitle {
    font-size: $text-big-ish;
    font-weight: $fw-chubby;
    color: $sn-primary-paragraph;
    line-height: $lh-tight;
  }

}

.redesign-sticky-nav {

  .redesign-page-title.interior-text-size {
    font-size: $text-slightly-big !important;
    font-weight: $fw-chubby !important;
    line-height: $lh-base;
    color: $sn-primary-paragraph;
  }

  .redesign-title-text {
    padding: 0 0 0 12px !important;
    @media (max-width: 1116px) {
      padding: 0 0 0 20px !important;
    }
  }

  .interior-sticky-nav__main {
    padding-left: 0px;
  }

  .interior-sticky-nav__title__umbrella {
    margin-right: unset !important;
  }
}

.addZindexvalue {
  z-index: 5;
}

.redesign-subnav .title-nav__subitem.-show-mobile-overlay .list-grid li a,
.redesign-subnav  .title-nav__subitem.-show-mobile-overlay .sub-list li a {
  font-size: $text-base;
  text-decoration: unset;
  font-weight: $fw-chubby;
}

@media (max-width: $mobile-bp) {
  .redesign-subnav #new-question-sublist {
    width: auto !important;
  }
}

.redesign-subnav .popup-displayNone {
  display: block !important;
}

.redesign-subnav {
  
  .slick-guide {
    width: max-content;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .title-nav__item {
    width: max-content;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (max-width: 1116px) {
    .slick-guide:first-child .title-nav__item {
      padding-left: 20px;
    }
  }

  .studyGuide_slick {
    display: flex;
  }

  .slick-list {
    display: flex;
    overflow: unset;
    @media (max-width: $mobile-bp) {
      overflow: hidden;
    }
  }

  .otherSubjectSlickSlider {
    .slick-list {
      display: flex;
      overflow: hidden;
      @media (max-width: $mobile-bp) {
        overflow: hidden;
      }
    }
  }

  .slick-track {
    display: flex;
    .title-nav__subitem {
      top: unset;
      padding-bottom: 10px;
    }
  }
  
  .slick-track:before {
    display: flex;
  }
  
  .prev-arrow.slick-arrow.slick-disabled {
    display: none !important;
  }

  .prev-arrow.slick-arrow {
    position: absolute;
    cursor: pointer;
    top: 2px;
    background-color: unset;
    left: -1px;
    width: 50px;
    height: unset;
    background-image: unset;
    background-position: unset;
    z-index: 5;
    border: unset;
    padding: unset;
    &:hover, &:focus {
      img {
        color: #004371;
        filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
      }
    }
  }

  .icon-prev-arrow {
    padding: 14px 15px;
  }

  .next-arrow.slick-arrow.slick-disabled {
    display: none !important;
  }

  .otherSubjectSlickSlider {
    .next-arrow.slick-arrow {
      display: flex;
      padding: 14px 6px;
      align-items: center;
      width: unset;
      position: absolute;
      background-color: unset;
      top: 1px;
      right: -1px;
      color: #0066c4;
      font-size: 14px;
      background-image: unset;
      margin: unset;
      background-position: unset;
      height: unset;
      cursor: pointer;
      border: unset;
      z-index: 6;
      font-weight: $fw-chubby;
      img {
        padding-top: 1.26px;
        width: 19px;
        height: 19px;
      }
      @include media-mobile {
        .studyGuide-more-button {
          display: none;
        }
      }
      &:hover, &:focus {
          color: #004371;
          img {
              color: #004371;
              filter: invert(14%) sepia(62%) saturate(482%) hue-rotate(15deg) brightness(60%) contrast(101%);
          }
      }
    }
  }

  .next-arrow.slick-arrow {
    position: absolute;
    background-color: unset;
    top: 1px;
    right: 2px;
    color: #0066c4;
    font-size: 14px;
    background-image: unset;
    margin: unset;
    background-position: unset;
    width: 50px;
    height: unset;
    cursor: pointer;
    border: unset;
    padding: unset;
    z-index: 6;
    font-weight: 600;
  }

  .otherSubjectSlickSlider {
    .icon-next-arrow {
      padding: unset;
    }
  }

  .icon-next-arrow {
    padding: 14px 15px;
  }

  .slide-arrow.prev-arrow:focus-within {
    color: #004371 !important;
  }

  .slide-arrow.next-arrow:focus-within {
    color: #004371 !important;
  }

  .popup {
    .title-nav-mobileHeader {
        justify-content: space-between;
        &:after {
         display: none;
       } 
    }
  }
  .owl-popup-close {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover, &:focus {
     svg {
       path {
         fill: #004371; 
       }
     }
    }
  }

  .left-fade {
    position: absolute;
    background-color: rgba(255, 255, 255, .8);
    width: 70px;
    height: 10px;
    padding: 20px 10px;
    left: 12px;
    top: 4px;
    z-index: 5;
    @include media-mobile {
      left: 0px;
    }
    @include media-small {
      left: 0px;
      width: 50px;
    }
  }

  .right-fade {
    position: absolute;
    background-color: rgba(255, 255, 255, .8);
    width: 70px;
    height: 10px;
    padding: 20px 50px;
    top: 6px;
    z-index: 5;
    right: 0;
    @include media-mobile {
      padding: 20px 10px;
    }
    @include media-small {
      padding: 20px 10px;
      width: 50px;
    }
  }
}

.desktop-title-pipesymbol {
  border-left: 3px solid #292c2e;
  padding-right: 4px;
}

// .desktop-title-show {
//   padding-right: 5px;
// }

// .subnav-active-title {
//   padding-left: 5px;
// }

.mobile-sticky-title {
  @include media-small {
    display: none;
  }
}

.mobile-sticky-pipesymbol {
  @include media-small {
    display: none;
  }
}

.mobile-sticky-active {
  @include media-small {
    padding-left: unset;
  }
}

.restr-nav {
  .all-study-guides {
    .nav-link {
      position: relative;
      font-weight: 600;
    }

    .title-nav__subitem {
      right: -12px;
      display: block;
    }

    .sub-list {
      width: auto;
      li a {
        padding: 12px 20px !important;
      }
    }
  }

  .plus-study-tools {
    position: relative;

    .sub-list {
      width: auto;
      li a {
        padding: 12px 20px !important;
      }
    }

    .title-nav__subitem {
      right: -42px;
      display: block;
    }
  }

  .ap-test-prep-nav {
    position: relative;

    .sub-list {
      width: auto;
      li a {
        padding: 12px 20px !important;
      }
    }

    .title-nav__subitem {
      right: -42px;
    }
  }

  .teacher-nav {
    position: relative;

    .sub-list {
      width: auto;
      li a {
        padding: 12px 20px !important;
      }
    }

    .title-nav__subitem {
      right: -109px;
    }
  }

  .plus-content-redesign {
    color: #008262;
  }

  .plus-study-tools .nav-link.menu__title-text:hover .plus-content-redesign {
    color: #004371;
  }
}

.global-header--homepage {
  .restr-nav {
    .plus-content-redesign {
      color: #41ffaa !important;
    }
  }
}

.new-title-global-nav {
  right: -90px !important;
}

.otherSubject-subnav { 
  display: flex;
  position: absolute;
  opacity: 0;
  @include media-mobile {
    display: none;
  }
  &__list {
    margin: 13px 32px 0 0;
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
  }
}