.pick-plan-page {
    h1, h3 {
        margin-bottom: 3rem;
        font-size: 28px;
        font-weight: 300;
        color: #484848;
    }
    h2 {
        font-size: 26px;
        font-weight: bold;
        margin: 32px 0 32px;
    }
    h3 {
        text-align: center;
        margin-top: 100px;
        margin-bottom: 0;
    }
}

.pick-plan-wrapper {
    display: flex;
    flex-direction: row;
    width: 1128px;
    margin-top: 3rem;
    @media (max-width: $mobile-bp) {
        flex-direction: column;
        width: 100%;
    }
}

.pick-plan-block {
    @extend %form-style;
    position: relative;
    width: 624px;
    padding: 0;
    margin-right: 24px;
    &__header-img {
        margin-top: -4rem;
    }
    @media (max-width: $mobile-bp) {
        width: 100%;
    }
}

.pick-plan-sign-up {
    @extend %form-submit-button-style;
    display: block;
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    &:hover {
        cursor: pointer;
    }
}

.limited-time-price {
    display: block;
    margin: 0 110px 25px;
    @media (max-width: $mobile-bp) {
        margin: 0 auto;
        padding-bottom: 15px;
    }
}

.coming-soon-badge {
    position: absolute;
    right: 0;
    top: 10rem;
}

.sign-up-premium-benefits {
    color: #484848;
    width: 60%;
    margin: 0 auto;
    ul {
        text-align: left;
        margin: 40px 0 40px;
        li.benefit-list-header {
            font-weight: bold;
            position: relative;
            &:before {
                content: '';
                display: inline-block;
                position: absolute;
                left: -40px;
                width: 40px;
                height: 40px;
                background: url('/images/icon_check_solidgreen.svg') no-repeat;
            }
        }
        li {
            font-size: 16px;
            line-height: 1.88;
        }
    }
}

.sign-up-find-more {
    display: block;
    margin: 40px 0 40px;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    color: $sn-primary-link;
}

#student-edition-email {
    .field {
        position: relative;
    }
    .student-edition-email-input {
        @extend %input-style;
        width: 55%;
    }
    .student-edition-email-label {
        @extend %label-style;
        left: 16%;
    }
    .input-label--focused {
        top: 25%;
        font-size: 12px;
    }
    .student-edition-email-submit {
        @extend %form-submit-button-style;
        display: inline;
        vertical-align: super;
        padding: 21px 20px 18px 20px;
    }
}

.pick-plan-questions-section {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 1fr;
    width: 1128px;
    margin: 0 auto;
    margin-top: 3rem;
    @media (max-width: $mobile-bp) {
        display: block;
        width: 100%;
    }
    h3 {
        text-align: center;
        margin-bottom: 3rem;
        margin-top: 3rem;
        font-size: 28px;
        font-weight: 300;
        color: #484848;
    }
    .pick-plan-question-block {
        padding: 10%;
        h4 {
            font-size: 18px;
            padding-bottom: 10px;
        }
        p {
            line-height: 1.6;
        }
    }
}

.student-edition-email-message{
    $l-colorFail: #A60038;
    $l-colorSuccess: #639704;
    position: absolute !important;
    bottom: calc(100% + 5px);
    width: 75%;
    left: 12.5%;
    &__bubble{
      padding: 4px 4px 4px 24px;
      line-height: $lh-tight;
      border-radius: 3px;
      background: $sn-primary-paragraph;
      min-height: 60px;
      display: flex;
      align-items: center;
      color: $white;
      &:before{
        content: '';
        position: absolute;
        display: block;
        top: 100%;
        left: calc(50% - 10px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 10px 0 10px;
        border-color: $sn-primary-paragraph transparent transparent transparent;
      }
      &:after{
        content: '';
        $size: 52px;
        width: $size;
        height: $size;
        border-radius: 50%;
        background-color: $sn-primary-paragraph;
        flex: none;
        margin-left: auto;
        background-repeat: no-repeat;
        background-position: 50%;
      }
    }
    &__fail{
      background-color: $l-colorFail;
      &:before{
        border-top-color: $l-colorFail;
      }
      &:after{
        background-image: url('/images/SparkTeach-homepage-form-error-icon.svg');
      }
    }
    &__success{
      background-color: $l-colorSuccess;
      &:before{
        border-top-color: $l-colorSuccess;
      }
      &:after{
        background-image: url('/images/SparkTeach-homepage-form-success-icon.svg');
      }
    }    
  }