.search-results, .search-result-block {
  position: relative;
  padding-bottom: 10px;
  h1 {
    font-size: $text-slightly-big;
    font-weight: $fw-chubby;
    padding-bottom: 1rem;
  }
  h2 {
    font-size: $text-base;
    font-weight: $fw-chubby;
  }

  hr {
    margin-top: 0;
    margin-bottom: 20px;
    color: $sn-gray;
  }
  p {
    @media (max-width: $mobile-bp) {
      line-height: 26px;
    }
  }  
}
.search-result-block{
  h3 {
    color: $sn-primary-link;
    padding-bottom: 5px;
    font-size: 16px;
    line-height: 18px;
    font-weight: $fw-chubby;
    &:hover {
      text-decoration: underline;
    }
  }
}
.search-result-plus-block {
  &:before {
    @include media-small {
      height: 65px !important;
    }
  }
}
.search-result-plus-block h3 {
  color: #008262 !important;
  @include media-small {
    height: 65px;
    line-height: 1.4;
    padding-top: 12px;
  }
}    
.search-plus-text {
  width: 46px;
  height: 18px;
  display: flex;
  justify-content: center;
  font-size: 11px;
  font-weight: bold;
  align-items: center;
  margin-right: 8px;
  border-radius: 30px;
  background-color: #41ffaa;
  float: left;
  @include media-small {
    margin-top: 12px;
  }
}
.search-results {
  padding-top: 5px;
  @media (max-width: $mobile-bp) {
    padding-top: 35px;
  }
}

.search-results-section {
  .top-result {
    position: relative;
    padding-left: 5rem;
    @media (max-width: $mobile-bp) {
      padding-left: 0;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 72px;
      height: 72px;
      top:-10px;
      @media (max-width: $mobile-bp) {
        position: relative;
        float: left;
      }
    }
  }
  .shakespeare-search-icon {
    &:before {
      background-image: url(/images/icon-translation-72-brandBlue.svg);
    }
  }
  .full-text-search-icon {
    &:before {
      background-image: url(/images/icon-fullText-72-brandBlue.svg);
      }
  }
  .lit-search-icon {
    &:before {
      background-image: url(/images/icon-literature-72-brandBlue.svg);
    }
  }
  .author-search-icon {
    &:before {
      background-image: url(/images/icon-author-72-brandBlue.svg);
    }
  }
  .bio-search-icon {
    &:before {
      background-image: url(/images/icon-biography-72-brandBlue.svg);
    }
  }
  .film-search-icon {
    &:before {
      background-image: url(/images/icon-films-72-brandBlue.svg);
    }
  }
  .graphic-novel-search-icon {
    &:before {
      background-image: url(/images/icon-search-graphicnovel.svg);
    }
  }

  .test-prep-search-icon {
    &:before {
      background-image: url(/images/icon-search-testprep.svg);
    }
  }

  .mastery-quiz-search-icon {
    &:before {
      background-image: url(/images/icon-search-mastery-quiz.svg);
    }
  }
  .nofear-search-icon {
    &:before {
      background-image: url(/images/icon-search-nofear.svg) !important;
    }
  }
  .flashcard-search-icon {
    &:before {
      background-image: url(/images/icon-search-flashcards.svg);
    }
  }
  .memes-search-icon {
    &:before {
      background-image: url(/images/icon-memes-72-brandBlue.svg);
    }
  }
  .infographic-search-icon {
    &:before {
      background-image: url(/images/icon-search-infographic.svg);
    }
  }
  .teaching-guide-search-icon {
      &:before {
        background-image: url(/images/icon-teacherGuide-72-brandBlue.svg);
      }
  }
  h2 {
    padding-bottom: 25px;
  }
}

.search-results-amount {
  font-weight: $fw-base;
}

// begin refactored CSS here:

$L-break-small: 600px;
@mixin L-media-small {
  @media screen and (max-width: $L-break-small) {
    @content;
  }
}
@mixin L-media-notSmall {
  @media screen and (min-width: ($L-break-small + 1)) {
    @content;
  }
}

.searchResults{
  // the nagination nav:
  &__pagination{
    $stepSize: 48px;
    display: grid;
    @include L-media-notSmall{
      grid-template-columns: $stepSize 1fr $stepSize;
    }
    @include L-media-small{
      grid-template-columns: 1fr 0 1fr;
    }
    grid-template-areas: "prev list next";
    border-top: 1px solid $sn-gray;

    // ul that contains the numbered nav:
    &__list{
      @include L-media-notSmall{
        grid-area: list;
        display: flex;
        justify-content: center;
      }
      @include L-media-small{
        display: none;
      }
    }
    // li that contains the links and ellipsis:
    &__item{
      padding: 0 !important; //Temporary fix to overwrite descendant styles of .content-container. When the search page gets refactored we should remove this ENTIRE LINE.
      display: flex;

    }
    //individual numbered page link:
    &__link{
      font-variant-numeric: lining-nums;
      padding-right: 16px;
      padding-left: 16px;
      display: flex;
      align-items: center;
      color: $color-brandBlue2020;
      position: relative;
      &:before{
        content:'';
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        top: 0;
        left: 0;
        background-color: transparent;
      }
      // the current page:
      &:not(.searchResults__pagination__link--active):hover{
        color: $color-linkHover !important; //Temporary fix to overwrite descendant styles of .content-container. When the search page gets refactored we should remove this !IMPORTANT NOTATION ONLY.
        background-color: $color-teacherSoftGray;
        &:before{
          background-color: currentColor;
        }
        text-decoration: none !important; //Temporary fix to overwrite descendant styles of .content-container. When the search page gets refactored we should remove this ENTIRE LINE.
      }
      &--active{
        color: $sn-primary-paragraph !important; //Temporary fix to overwrite descendant styles of .content-container. When the search page gets refactored we should remove this !IMPORTANT NOTATION ONLY.
        &:before{
          background-color: currentColor;
        }
        &:hover{text-decoration: none !important;} //Temporary fix to overwrite descendant styles of .content-container. When the search page gets refactored we should remove this ENTIRE LINE.
      }
    }
    // ellipsis when more than five pages of results - eg '1 2 3 4 5 ... 130'
    &__ellipsis{
      display: flex;
      align-items: center;
      cursor: default;
    }
    // prev / next links:
    &__step{
      display: block;
      background-repeat: no-repeat;
      @include L-media-notSmall{
        &__a11y-span{
          @include visuallyhidden;
        }
        height: $stepSize;
        background-position: 50%;
      }
      @include L-media-small{
        padding: 24px;
      }
      position: relative;
      &:before{
        content:'';
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        top: 0;
        left: 0;
        background-color: transparent;
      }      
      &:hover,
      &:focus{
        background-color: $color-teacherSoftGray;
        color: $color-linkHover !important; //Temporary fix to overwrite descendant styles of .content-container. When the search page gets refactored we should remove this !IMPORTANT NOTATION ONLY.
        text-decoration: none !important; //Temporary fix to overwrite descendant styles of .content-container. When the search page gets refactored we should remove this ENTIRE LINE.
        &:before{
          background-color: $color-linkHover;
        }        
      }
      
      &--prev{
        grid-area: prev;
        background-image: url(/images/icon-prevPage-brandBlue-24.svg);
        @include L-media-small{
          background-position: 2px 50%;
          padding-left: 36px;
        }
      }
      &--next{
        grid-area: next;
        background-image: url(/images/icon-nextPage-brandBlue-24.svg);
        @include L-media-small{
          background-position: calc(100% - 2px) 50%;
          text-align: right;
          padding-right: 36px;
        }
      }
    }
  }
}

.showing_results_for {
  font-size: $text-small;
  line-height: $lh-base;
  color: $sn-black;
  span {
    font-size: $text-small;
    line-height: $lh-base;
    font-weight: 700;
    color: $sn-black;
  }
}

.no-result-found {
  &__hmmm {
    font-weight: bold;
    font-size: $text-base;
    color: $sn-primary-header;
  }
  &__notes {
    margin-top: 12px;
    font-family: "Raleway";
    font-size: $text-base;
    color: $sn-primary-paragraph !important;
    @include media-small {
      line-height: 1.4;
    }
    ul {
      padding-left: 30px;
      li {
        padding: 7px 0px 0px 0px !important;
        list-style: disc;
      }
    }
  }
}
.no-result-suggestion, .no-result-top-section {
  font-family: "Raleway";
  font-size: $text-base;
  font-weight: bold;
  color: $sn-primary-header;
}
.no-result-suggestion {
  margin-top: 50px;
  &__content {
    margin-top: 20px;
    border-top: 1px solid $sn-border-gray;
    padding-top: 20px;
  }
}
.no-result-top-section {
  margin-top: 25px;
  &__content {
    margin-top: 20px;
    border-top: 1px solid $sn-border-gray;
    padding-top: 20px;
  }
}

.no-result-contents {
  a {
    h3 {
      font-family: 'Raleway' !important;
      font-weight: bold !important;
      color: #0066c4;
      line-height: 1.6;
    }
    p {
      margin-top: -5px;
      color: $sn-black !important;
    }
  }
}

.nofear-no-result-styling {
  padding-top: 32px !important;
  .search-plus-text {
    margin-top: 4px !important;
  }
  a {
    h3 {
      color: #008262;
    }
  }
}

.nofear_top_search {
  padding-top: 32px !important;
}

.noResults_icon_upper {
  &:before {
    background-image: url(/images/icon_misc_author.svg) !important;
  }
}

.search-results-section {
  .nofear-search-icon.noResults_icon_lower:before {
    background-image: url(/images/icon_misc_shakespeare.svg)!important;
  }
}

.noResults_icon_lower {
  a {
    h3 {
      color: #0066c4 !important;
      @include media-small {
        height: unset;
      }
    }
  }
}

.hide-pagination {
  display: none;
}