// Component specific colors:
$l-color-1: #9bbed6;
$l-color-2: #f8333c;
$l-color-3: #013e8d;
.global-banner {

  // Component size variables:
  $closeBtnSize: 36px;
  
  --iconWidth: 112px;

  // Component media queries:
  @mixin global-banner__breakpoint--small {
    @media screen and (max-width: 699px) {
      @content;
    }
  }
  @mixin global-banner__breakpoint--notSmall {
    @media screen and (min-width: 700px) {
      @content;
    }
  }

  &__container {
    max-width : 1164px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right : auto;
  }
  &__section {
    &__image--desktop {
      z-index: 0;
      margin-bottom: -3px;
      object-fit: cover;
      @include global-banner__breakpoint--notSmall {
        height: 58px;
        width: 100%;
      }      
      @include global-banner__breakpoint--small{
        display: none;
      }
    }
    &__image--mobile {
      z-index: 0;
      margin-bottom: -3px;
      object-fit: cover;
      @include global-banner__breakpoint--small{
        height: 68px;
        width: 100%;
      }
      @include global-banner__breakpoint--notSmall {
        display: none;
      }
    }
  }
  &__content {
    padding: 12px 16px;
    display: flex;
    font-size: $text-slightly-big;
    font-weight: $fw-chubby;
    flex: 1;
    height: 58px;
    justify-content: center;
    @include global-banner__breakpoint--small{
      padding: 8px;
      flex-direction: column;
      font-size: $text-base;
      text-align: center;
      height: 68px;
    }
  }
  &__text{
    padding: 8px;
    @include global-banner__breakpoint--small{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @include global-banner__breakpoint--notSmall{
      &:before,
      &:after{
        color:$l-color-2;
        padding: 0 12px;
      }
    }
  }
  &__heading {
    font-weight: $fw-fat;
    color: $white;
    margin-right: 8px;
    @include global-banner__breakpoint--small{
      margin-right: 0;
      margin-bottom: 4px;
      display: flex;
      &:before,
      &:after{
        color:$l-color-2;
        padding: 0 12px;
      }
    }
  }
  &__link {
    font-weight: $fw-chubby;
    color: $l-color-1;
    text-decoration: underline; // overwrites the generic 'a' styles

    &:hover {
      color: $color-linkHover;
    }
  }
  &__close {
    flex: none;
    position: absolute;
    right: 5px;
    z-index: 1;
    width: 36px;
    height: 36px;
    @include removeBtnStyles;
    background-image: url("/images/icon-svg-close-blackCircle-transparentX.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: $closeBtnSize;
    background-position: 50%;
    // border-radius: 0 0 $closeBtnSize/2 $closeBtnSize/2;
    &:hover {
      filter: contrast(3);
    }
  }
  &__close-margin {
    margin-top: 11px;
    @include global-banner__breakpoint--small{
      margin-top: 18px;
    }
  }
}
// This class is added by default and only removed if the JS doesnt find the cookie to hide it
.js-global-banner--hidden {
  display: none;
}

.partner__pw__header {
  [data-pw-desk] {
    background-color: $l-color-3;
  }
}
.login-banner {
  cursor: pointer;
}