@include premiumFlag{
    .ecom-page {
        text-align: center;
        h1 {
            font-weight: $fw-slim;
            margin-bottom: 4rem;
        }
        .signin-page-header {
            border-bottom: 1px solid $sn-border-gray;
            padding-bottom: 2rem;
            font-weight: 900;
        }
    }

    .thank-you-page {
        @media (max-width: $mobile-bp) {
            margin: 2rem;
            text-align: center;
            height: auto
        }
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 56vh;
        h1 {
            font-weight: $fw-slim;
            margin-bottom: 4rem;
        }
        p {
            font-size: 20px;
            text-align: center;
        }
        ul {
            @media (max-width: $mobile-bp) {
                text-align: left;
            }
        }
        li {
            font-size: 16px;
            @media (max-width: $mobile-bp) {
                font-size: 14px;
            }
        }
        a {
            @media (max-width: $mobile-bp) {
                width: 80%;
                margin: 0 auto 24px !important;
            }
            display: inline-block;
            vertical-align: middle;
            height: 55px;
            width: 217px;
            text-align: center;
            padding: 20px 30px;
            border-radius: 3px;
            border: solid 1px #217cb5;
            font-size: 14px;
        }
        a:first-of-type {
            margin: 0 24px;
        }
        .goto-forteachers {
            color: #fff;
            background-color: #217cb5;
            white-space: nowrap;
        }
    }

    .thank-you-features-container {
        ul {
            @media (max-width: $mobile-bp) {
                margin: 50px 50px 50px;
            }
            margin: 40px 96px;
            li {
                margin-bottom: 20px;
                margin-left: 10px;
                svg {
                    vertical-align: middle;
                    width: 25px;
                    margin-right: 20px;
                }
            }
        }
    }

    .subscribe-progress {
        @media (max-width: $mobile-bp) {
            margin: -2rem 0;
        }
        margin: -1.7rem 0;
        .subscribe-progress-icon__wrapper {
            display: inline-block;
            span {
                font-weight: $fw-slim;
                font-size: 14px;
                white-space: nowrap;
            }
        }
        .subscribe-progress-icon {
            @media (max-width: $mobile-bp) {
                margin: 0 2.5rem 10px;
            }
            width: 35px;
            height: 35px;
            overflow: hidden;
            margin: 0 4rem 10px;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #ccc;
            box-shadow: 1px 1px 10px -5px;
            svg {
                display: block;
                width: 33px;
                margin: 0 auto;
            }
        }
    }

    //pick plan page icon colorings
    .pick-plan-page .subscribe-progress-icon--confirmed {
        @media (max-width: $mobile-bp) {
            width: 25px;
            height: 25px;
        }
        svg {
            @media (max-width: $mobile-bp) {
                width: 23px;
                height: 23px;
            }
        }
        background-color: #00adef;
        + span {
            font-weight: bold;
        }
        .svg-color {
            stroke: #fff;
        }
    }
    .pick-plan-page .subscribe-progress-icon--useraccount {
        @media (max-width: $mobile-bp) {
            width: 25px;
            height: 25px;
        }
        svg {
            @media (max-width: $mobile-bp) {
                width: 23px;
                height: 23px;
            }
        }
        background-color: #fff;
        .svg-color {
            fill: #ccc;
        }
    }
    .pick-plan-page .subscribe-progress-icon--pay {
        background-color: #fff;
        .svg-color {
            fill: #ccc;
        }
    }

    // sign up page color icons
    .subscribe-page .subscribe-progress-icon--confirmed {
        @media (max-width: $mobile-bp) {
            width: 25px;
            height: 25px;
        }
        svg {
            @media (max-width: $mobile-bp) {
                width: 23px;
                height: 23px;
            }
        }
        background-color: #2ed091;
        .svg-color {
            stroke: #fff;
        }
    }
    .subscribe-page .subscribe-progress-icon--useraccount {
        background-color: #00adef;
        + span {
            font-weight: bold;
        }
        .svg-color {
            fill: #fff;
        }
    }
    .subscribe-page .subscribe-progress-icon--pay {
        @media (max-width: $mobile-bp) {
            width: 25px;
            height: 25px;
        }
        background-color: #fff;
        .svg-color {
            fill: #fff;
        }
    }

    //payment page icon colorings
    .payment-page .subscribe-progress-icon--confirmed {
        @media (max-width: $mobile-bp) {
            width: 25px;
            height: 25px;
        }
        svg {
            @media (max-width: $mobile-bp) {
                width: 23px;
                height: 23px;
            }
        }
        background-color: #2ed091;
        .svg-color {
            stroke: #fff;
        }
    }
    .payment-page .subscribe-progress-icon--useraccount {
        @media (max-width: $mobile-bp) {
            width: 25px;
            height: 25px;
        }
        svg {
            @media (max-width: $mobile-bp) {
                width: 23px;
                height: 23px;
            }
        }
        background-color: #2ed091;
        .svg-color {
            fill: #fff;
        }
    }
    .payment-page .subscribe-progress-icon--pay {
        background-color: #00adef;
        + span {
            font-weight: bold;
        }
        .svg-color {
            fill: #fff;
        }
    }

    .progress-line {
        @media (max-width: $mobile-bp) {
            width: 250px;
        }
        width: 346px;
        height: 1px;
        color: #e5e5e5;
        background-color: #e5e5e5;
        border: none;
    }

    #reset-password-form {
        @extend %form-style;
        width: 408px;
        padding: 45px;
        margin-bottom: 325px;
        @media (max-width: $mobile-bp) {
            width: 100%;
            flex-direction: column;
            background: none;
            border: none;
            box-shadow: none;
        }
        .field {
            position: relative;
        }
        .reset-password-form-header {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            color: #484848;
            padding-bottom: 35px;
            text-transform: uppercase;
        }
        .reset-password-submit {
            @extend %form-submit-button-style;
            display: block;
            width: 100%;
            padding: 20px;
            &:disabled {
                @extend %form-submit-button-style-disabled;
            }
        }
        .reset-password-email-input {
            @extend %input-style;
            width: 100%;
        }
        .reset-password-password-input {
            @extend %input-style;
            width: 100%;
            &:disabled {
                @extend %form-submit-button-style-disabled;
            }
        }
        .reset-password-input-label {
            @extend %label-style;
            left: 5%;
        }
        .input-label--focused {
            @media (max-width: $mobile-bp) {
                left: 4%;
            }
            top: 20%;
            left: 5%;
            font-size: 12px;
            color: #6d7474;
        }
        .show-password {
            @extend %show-password;
            z-index: 1;
            width: 30px;
            height: 30px;
            opacity: 0;
        }
        .show-password__icon {
            @extend %show-password;
            z-index: 0;
            width: 20px;
            height: 20px;
            background-image: url('/images/eye_hide.svg');
        }
        .show-password {
            left: 90% !important;
            &__icon {
                left: 90% !important;
            }
        }
        input.show-password:checked + .show-password__icon {
            background-image: url('/images/eye_open.svg');
        }
        .show-password.input-label--focused {
            @extend %show-password;
        }
        .reset-password-error {
            display: none;
        }
        .reset-password-error-msg,
        .reset-password-password__error {
            display: block;
            text-align: left;
            color: #c51c00;
            padding-bottom: 20px;
        }
        .reset-form-error {
            border: solid 1px #c51c00;
            background-repeat: no-repeat;
            height: 55px;
            font-size: 16px;
            margin: .5rem 0;
            padding-left: 13px;
            padding-top: 15px;
            border-radius: 3px;
            font-weight: 300;
        }
    }

    .reset-sent-block {
        margin: 4rem;
        .reset-sent {
            text-align: center;
            font-size: 20px;
            font-weight: $text-base;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.6;
            letter-spacing: normal;
            text-align: center;
            color: #292c2e;
        }
    }

    .reset-success-block {
        @extend %form-style;
        margin-bottom: 325px;
        .reset-success {
            font-size: 20px;
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            color: #484848;
        }
        .reset-success-signin {
            @extend %form-submit-button-style;
            display: block;
            width: 312px;
            margin: 35px auto;
            padding: 15px;
            text-align: center;
        }
    }
    .signup-created-block {
        margin: 4rem;
        .created-sub-header {
            text-align: center;
            text-transform: uppercase;
            margin: $pageMargin*4 0px;
        }
        #signup-created {
            @extend  %form-style
        }
        .created-content {
            text-align: center;
            font-size: 20px;
            font-weight: $text-base;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.6;
            letter-spacing: normal;
            text-align: center;
            color: #292c2e;
            margin: $pageMargin*4 0px;
        }
        .created-button-container{ 
            display: flex;    
            align-items: center;
            justify-content: center;
            margin: $pageMargin*4 0px;        
        }       
        .created-continue-button {
            @extend %form-submit-button-style;
            padding: 10px 27px;
            font-weight: 300;
            margin-left: $pageMargin;
            @media(max-width :625px){
                margin: $pageMargin 20px;
            }  
        }
        
    }

    #loginform-page {
        @extend %form-style;
        width: 408px;
        margin-bottom: 325px;
        @media (max-width: $mobile-bp) {
            width: 100%;
            flex-direction: column;
            background: none;
            border: none;
            box-shadow: none;
            .login-label {
                left: 8%;
            }
        }
        .field-margin {
            width: 88%;
            margin: 0 auto;
            .rememberme-label {
                float: left;
                padding: 4% 0 5% 0;
            }
        }
        .field {
            position: relative;
            padding-bottom: 1%;
            .login-page {
                &-password-warning-icon, &-email-warning-icon {
                    background-image: url(/images/icon_warning.svg);
                    width: 20px;
                    height: 20px;
                    margin-left: -20px;
                    background-position: 95%;
                    display: none;
                }
            }
            .show-login-page-warning-icon {
                display: inline-block;
            }
            .login-page-error {
                border: solid 1px $color-form-error;
                background-repeat: no-repeat;
                background-position: 95%;
            }
            .login-page-tooltip-email-error,
                .login-page-tooltip-pwd-error {
                    display: none;
                    text-align: left;
                    color: $color-form-error;
                    margin: 0 auto;
                    width: 88%;
            }
            .login-page-error-block-text-msg {
                display: block;
            }
            .show-password__icon {
                right: 8%;
            }
            .show-password {
                right: 7%;
            }
        }
        label {
            font-size: 14px;
            font-weight: $text-base;        
            color: #292c2e;
        }
        .login-form-input {
            @extend %input-style;
            width: 88%;        
        }
        #email{
            padding-right: 13px;
        }
        #login-page-password{
            padding-right: $s-px * 7;
        }
        input[type=checkbox] {
            transform: scale(1.2);  
            margin: 0px; 
        }
        .login-label {
            @extend %label-style;
        }
        .g-recaptcha{
            margin: 4% 6%;
        }
        .sign-in-button {
            @extend %form-submit-button-style;
            display: block;
            width: 88%;
            padding: 15px;
            margin: 0 auto;
        }
        .sign-in-button:disabled {
            @extend %form-submit-button-style;
            background-color: #e5e5e5;
            color: #6d7474;
            padding: 15px;
            margin: 0 auto;
        }
        .loginform-page-forgot-password {
            float: right;
            font-size: 14px;
            padding: 5% 0 5% 0;
        }
        .input-label--focused {
            top: 20%;
            font-size: 12px;
            color: #6d7474;
        }
        .showPassword{
            &__toggle{
                @extend %show-password;
                background-color:$white !important;
                right: 7% !important;
                top: 11px;
            }
        }
        .create-account {
            color: #484848;
            margin-top: 24px;
            font-size: 14px;
            a {
                text-decoration: underline;
            }
        }
        .login-page-invalid {
            &-email-error, &-password-error {
                display: block;
                color: $color-form-error;
                width: 88%;
                text-align: left;
                margin: 0 auto;
            }
        }
    }

    .subscription-expired-block {
        margin: 4rem;
        .expired-content {
            text-align: center;
            font-size: 20px;
            font-weight: $text-base;
            font-style: normal;
            line-height: 1.6;
            text-align: center;
            color: $sn-primary-paragraph;
        }
        .expired-button-container{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: $pageMargin*3 0px;
            flex-wrap: wrap;
            flex: 1;
        }
        .expired-continue-button {
            @extend %form-submit-button-style;
            padding: 9px 27px;
            font-weight: 300;
            &--hollow {
                margin-right: $pageMargin;
                background: none;
                border-radius: 3px;
                color: $sn-primary-link;            ;
                border: solid 1px #217cb5;
                @media(max-width :625px){
                    margin: $pageMargin 20px;
                }
            }  

        }    
        .expired-resubscribe-button {
            @extend %form-submit-button-style;
            padding: 10px 27px;
            font-weight: 300;
            margin-left: $pageMargin;
            @media(max-width :625px){
                margin: $pageMargin 20px;
            } 
            .expired-resubscribe-redirect {
                color: $white;
            } 
        }
        
    }

    #signupform {
        @extend %form-style;
        @media (max-width: $mobile-bp) {
            width: 100%;
            flex-direction: column;
            background: none;
            border: none;
            box-shadow: none;
        }
        .field {
            position: relative;
            &--lower-content {
                text-align: left;
                margin: 40px 30px 30px;
                p {
                    margin-left: 22px;
                    padding-top: 10px;
                }
                a {
                    text-decoration: underline;
                }
            }
            .showPassword{
                &__toggle{
                    @extend %show-password;
                    background-color:$white !important;
                    right: 8% !important;
                    top: 11px;
                }
            }
        }
        .signup-form-input {
            @extend %input-style;
            &__error {
                display: none;
                text-align: left;
                color: $color-form-error;
                padding-left: 7.5%;
            }
            @media (max-width: $mobile-bp) {
                width: 100%;
            }
        }
        .signup-form-input:disabled{
            background-color: #e5e5e5 !important;
            color: #6d7474 !important; 
        }
        .signup-form-input-label {
            @extend %label-style;
        }
        .input-label--focused {
            @media (max-width: $mobile-bp) {
                left: 4%;
            }
            top: 15px;
            left: 11%;
            font-size: 12px;
            color: #6d7474;
        }
        .input-error-icon {
            display: none;
            position: absolute;
            left: 0;
            top: 25px;
            width: 20px;
            height: 20px;
            background: url('/images/icon_warning.svg');
        }
        #signup-next {
            @extend %form-submit-button-style;
        }
        #update-opt-checkbox {
            opacity: 0;
            position: absolute;
            width: 15px;
            height: 15px;
            z-index: 2;
        }
        .update-opt-label {
            margin-left: 22px;
            display: block;
            margin-bottom: 10px;
        }
        .update-opt-label:before {
            display: table;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 15px;
            height: 15px;
            border-radius: 2px;
            border: solid 1px #217cb5;
        }
        .update-opt-label:after {
            display: none;
        }
        #update-opt-checkbox:checked ~ .update-opt-label:after {
            display: table;
            content: '';
            position: absolute;
            left: 3px;
            top: 4px;
            border-left: 1px solid #217cb5;
            border-bottom: 1px solid #217cb5;
            width: 10px;
            height: 5px;
            transform: rotate(317deg);
        }
        .form-error {
            border: solid 1px $color-form-error;
            background-repeat: no-repeat;
            background-position: 95%;
        }
    }
    .str-element-wrapper {
        position: relative;
    }

    .payment-resubscribe-amount {
        text-align: center;
        padding-bottom: 2rem;
        font-weight: 600;
    }

    #payment-form {
        @media (max-width: $mobile-bp) {
            width: 100%;
            flex-direction: column;
            background: none;
            border: none;
            box-shadow: none;
        }
        @extend %form-style;
        display: flex;
        flex-direction: row;
        text-align: left;
        width: 408px;
        h3 {
            font-size: 20px;
            padding-bottom: 24px;
        }
        input[name='coupon'] {
            @media (max-width: $mobile-bp) {
                width: 70%;
            }
        }
        .form-row {
            width: 100%;
        }
        .intro-callout {
            margin-bottom: 31px;
            text-align: center;
            p {
                margin-top: 1rem;
            }
        }
        .limited-time-price {
            margin: 0;
        }
        #purchase-warning {
            color: #c51c00;
            font-size: 14px;
        }
        .default-encouragement-div {
            @media (max-width: $mobile-bp) {
                margin: 0;
            }
            margin-left: 124px;
        }
        .default-encouragement {
            display: none;
            font-size: 14px;
            font-weight: 300;
            background-color: #ffcd11;
            color: #292c2e !important;
            line-height: 1.8;
            padding: 5px;
        }
        .default-encouragement--show {
            display: inline;
        }
        .pre-price-line {
            width: 348px;
            height: 2px;
            border: 0;
            margin-top: 35px;
            margin-left: 0;
            border-top: solid 2px #e5e5e5;
        }
        .coupon-results {
            @media (max-width: $mobile-bp) {
                width: 100% !important;
            }
            display: none;
            width: 348px;
            height: 55px;
            padding-top: 10px;
            padding-left: 14px;
            background-color: #f8f8f8;
        }
        .coupon-results__code {
            font-size: 14px;
            font-weight: $fw-slim;
        }
        .coupon-results__info {
            font-size: 14px;
        }
        #payment-form-pay {
            width: 100%;
            height: 55px;
            @extend %form-submit-button-style;
        }
        #payment-form-pay:disabled {
            border: solid 1px #e5e5e5;
            background-color: #e5e5e5;
            color: #909090;
        }
        #card-cvc-element {
            background-image: url('/images/i_info.svg');
            background-repeat: no-repeat;
            background-position: 95%;
        }
        #selected-price {
            font-size: 38px;
            color: #292c2e;
        }
        #old-price {
            font-size: 38px;
            text-decoration: line-through;
            color: red;
        }
        .signup-form-input {
            @extend %input-style;
            width: 232px;
            padding-top: 0;
            margin-right: 15px;
            @media (max-width: $mobile-bp) {
                width: 100%;
            }
            &__error {
                display: none;
                font-size: 14px;
                font-weight: 300;
                color: #c51c00;
            }
            &__label {
                position: absolute;
                pointer-events: none;
                top: 36%;
                left: 3%;
                transition: .2s ease;
            }
        }
        .info-tooltip {
            @media (max-width: $mobile-bp) {
                right: 0;
            }
            display: none;
            position: absolute;
            top: -85px;
            right: -70px;    
            z-index: 2;
            width: 206px;
            padding: 10px;
            border-radius: 3px;
            border: solid 1px #e5e5e5;
            background-color: #ffffff;
            font-size: 12px;
            font-weight: $text-base;
            line-height: 1.4;
            &:before {
                @media (max-width: $mobile-bp) {
                    left: 76%;
                }
                border: solid transparent;
                content: " ";
                position: absolute;
                left: 46.5%;
                border-width: 13px;
                border-bottom-color: #e5e5e5;
                transform: rotate(180deg);
                top: 100%;
            }
            &:after {
                @media (max-width: $mobile-bp) {
                    left: 76.5%;
                }
                border: solid transparent;
                content: " ";
                position: absolute;
                left: 47%;
                border-width: 12px;
                border-bottom-color: #fff;
                transform: rotate(180deg);
                top: 100%;
            }
        }
        .info-tooltip-trigger {
            position: absolute;
            z-index: 999999;
            right: 5%;
            top: 30%;
            width: 30px;
            height: 30px;
        }
        .info-tooltip-trigger:hover + .info-tooltip {
            display: block;
        }
        .discount-input {
            @media (max-width: $mobile-bp) {
                width: 70%;
            }
        }
        .StripeElement {
            @media (max-width: $mobile-bp) {
                width: 100% !important;
            }
        }
        .apply_coupon, .remove_coupon {
            width: 100px;
            height: 55px;
            text-transform: capitalize;
            @extend %form-submit-button-style;
        }
        .remove_coupon {
            @media (max-width: $mobile-bp) {
                margin: 10px 1rem;
            }
            float: right;
            height: 35px;
            margin: 10px 7rem;
        }
        .billing-cycle-label {
            position: absolute;
            left: 15px;
            top: 52px;
            font-size: 12px;
            color: #6d7474;
        }
        #plan-options {
            @extend %input-style;
            width: 348px;
            background-color: #fff;
            text-align: left;
            margin-top: 0;
            -webkit-appearance: none;
            background:
        linear-gradient(45deg, transparent 50%, #000 50%),
        linear-gradient(135deg, #000 50%, transparent 50%),
        linear-gradient(to right, transparent, transparent);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 16px) calc(1em + 2px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
            @media (max-width: $mobile-bp) {
                width: 100%;
            }
        }
        .selected-purchase-info {
            @media (max-width: $mobile-bp) {
                text-align: left;
                margin: 24px 0 24px;
            }
            margin: 24px 0;
            text-align: right;
            width: 343px;
            p {
                font-weight: $fw-slim;
                color: #6d7474;
                padding-bottom: 10px;
            }
        }
        .plan-options__dropdown-wrapper {
            margin: 0 auto;
        }
        .plan-options__items {
            position: absolute;
            left: -6px;
            top: 93px;
            background-color: #fff;
            box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
            border: solid 1px #e5e5e5;
            margin: 6px;
            a {
                font-weight: $fw-slim;
                display: block;
                padding: 18px;
                width: 348px;
                color: #292c2e;
                b {
                    color: #484848;
                    font-weight: bold;
                }
                &:hover {
                    background-color: #f8f8f8;
                }
            }
        }
        .plan-options__radio {
            visibility: hidden;
            position: absolute;
        }
    }

    .server-error {
        // display: block !important;
        .error-block-text {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 300;
        }
        .error-block-message {
            padding-left: 6px;
        }
    }

    .error-block {
        display: none;
        height: 55px;
        width: 100%;
        background-color: #c51c00;
        color: #fff;
        margin-bottom: 2rem;
        font-weight: $fw-slim;
        .error-block-text {
            padding-top: 12px;
            text-align: center;
        }
        &--main {
            margin-top: 0;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            background-color: #fff4f4;
            svg {
                vertical-align: sub;
            }
            @media (max-width: $mobile-bp) {
                width: 100%;
                height: 95px;
            }
        }
        .error-block-text {
            &--main {
            color: #c51c00;
            font-size: 14px;
            font-weight: $text-base;
            padding-top: 15px;
            max-width: 1164px;
            margin: 0 auto;
            @media (max-width: $mobile-bp) {
                padding-top: 26px;
            }
            }
        }
        .error-block-button {
            @extend %form-submit-button-style;
            font-size: 10px;
            padding: 7px 20px;
            margin-left: 5px;
            @media (max-width: $mobile-bp) {
                margin-top: 10px;
            }
        }
        .close-error-block {
            float: right;
            margin-right: 1rem;
            @media (max-width: $mobile-bp) {
                margin-right: 0.5rem;
                margin-top: -2.5rem;
            }
        }
    }

    .form-terms {
        @media (max-width: $mobile-bp) {
            width: 95%;
            margin: 0 33px 0 15px;
        }
        width: 744px;
        margin: 20px auto 60px;
        p {
            margin-bottom: 14px;
            padding: 0;
            line-height: 1.63;
            font-size: 12px;
            color: #8f8f8f;
            text-align: left;
        }
    }

    .account-settings-header {
        text-align: center;
        text-transform: uppercase;
        margin: 30px 0 30px;
    }

    .account-settings-area {
        width: 1400px;
        margin: 0 auto;
        @media (max-width: $mobile-bp) {
            display: block;
            width: 100%;
        }
        .product-payment-applied-coupon {
            border: solid 1px #e5e5e5;
            background-color: #fdfdfd;
            padding: 10px;
            font-weight: 300;
            font-size: 14px;
        }
    }

    .account-settings-wrapper {
        border-top: 2px solid $sn-border-gray;
        @media (max-width: $mobile-bp) {
            display: block;
            width: 100%;
        }
        .error-block {
            margin-top: 0;
        }
    }

    .account-settings-form {
        @extend %form-style;
        width: 624px;
        margin-bottom: 386px;
        margin-top: 30px;
        display: grid;
        grid-template-columns: 1fr 5fr;
        .form-error {
            border: solid 1px $color-form-error;
            background-repeat: no-repeat;
            background-position: 95%;
        }
        @media (max-width: $mobile-bp) {
            display: block;
            width: 100%;
            margin-top: 0;
        }
        .field {
            position: relative;
            padding: 15px;
        }
        .field-info {
            font-size: 14px;
            line-height: 1.6;
        }
        .signup-form-input__error {
            padding-left: 0;
        }
        .field-checkbox-pseudo {
            &:before {
                display: table;
                content: '';
                position: absolute;
                z-index: 1;
                left: 14px;
                top: 22px;
                width: 15px;
                height: 15px;
                border-radius: 2px;
                border: solid 1px #217cb5;
            }
        }
        .field-input-wrapper {
            position: relative;
            .error-block-text-msg {
                display: block;
            }
        }
        .account-settings-input {
            @extend %input-style;
            &__pwd_error,&__error, &__email_error {
                display: none;
                text-align: left;
                color: $color-form-error;
            }
            @media (max-width: $mobile-bp) {
                width: 100%;
            }
        } 
        h1 {
            text-align: center;
        }
        input {
            @extend %input-style;
            width: 95%;
            @media (max-width: $mobile-bp) {
                width: 100%;
            }
        }
        input:focus {
            box-shadow: 0 0 8px 0 #0092ce;
            border: solid 1px #1a628e;
        }
        #account-email-notifications {
            opacity: 0;
            position: absolute;
            z-index: 2;
            width: auto;
            height: inherit;
        }
        .field-checkbox-pseudo {
            margin-left: 20px;
            display: block;
        }
        #account-email-notifications:checked ~ .field-checkbox-pseudo:after {
            display: table;
            content: '';
            position: absolute;
            left: 17px;
            top: 25px;
            border-left: 1px solid #217cb5;
            border-bottom: 1px solid #217cb5;
            width: 10px;
            height: 5px;
            transform: rotate(317deg);
        }
        .signup-form-input-label {
            @extend %label-style;
        }
        .form-label-text {
            padding: 30px;
            font-size: 18px;
            text-align: right;
            @media (max-width: $mobile-bp) {
                padding-left: 15px;
            }
        }
        .signup-form-input {
            @extend %input-style;
            &__error {
                display: none;
                text-align: left;
                color: $color-form-error;
                padding-left: 7.5%;
            }
            @media (max-width: $mobile-bp) {
                width: 100%;
            }
        }
        .signup-form-input-label {
            @extend %label-style;
            left: 4%;
        }
        .input-label--focused {
            @media (max-width: $mobile-bp) {
                left: 4%;
            }
            top: 15px;
            left: 4%;
            font-size: 12px;
            color: #6d7474;
        }
        .field-save-settings {
            display: none;
            .field-save-settings-submit, .field-save-settings-cancel {
                @extend %form-submit-button-style;
                font-weight: 300;
                width: 96px;
                height: 35px;
                border-radius: 3px;
                display: inline-block;
                text-transform: capitalize;
                &:hover {
                    cursor: pointer;
                }
                &:disabled {
                    border: solid 1px #e5e5e5;
                    background-color: #e5e5e5;
                    color: #6d7474; 
                }
            }
            .field-save-settings-cancel {
                background: none;
                color: #217cb5;
            }
            &--saved {
                display: none;
                font-size: 14px;
                font-weight: $text-base;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: right;
                padding-right: 20px;
                color: #217cb5;
            }
        }
        .field.show-save-settings {
            background-color: #f8f8f8;
            .field-save-settings {
                display: block;
                text-align: right;
                margin-right: 20px;
            }
        }
        .show-saved-state {
            .field-save-settings--saved {
                display: block !important;
            }
            .field-save-settings {
                display: none !important;
            }
        }
        .account-form-focused-color {
            background-color: #f8f8f8;
        }
            // so this happens twice because stripe adds in the focused class on everything
        // in the field. !important needs to override the current 11% to keep it in place. 
        %show-password {
            position: absolute;
            right: 47px;
            left: 338px !important;
            top: 25px;
            @media (max-width: $mobile-bp) {
                left: 90% !important;
            }
        }
        .show-password {
            @extend %show-password;
            z-index: 1;
            width: 30px;
            height: 30px;
            opacity: 0;
        }
        .show-password__icon {
            @extend %show-password;
            z-index: 0;
            width: 20px;
            height: 20px;
            background-image: url('/images/eye_hide.svg');
        }
        input.show-password:checked + .show-password__icon {
            background-image: url('/images/eye_open.svg');
        }
        .show-password.input-label--focused {
            @extend %show-password;
        }
        .info-tooltip {
            @media (max-width: $mobile-bp) {
                right: 0;
            }
            display: none;
            position: absolute;
            top: -107px;
            right: -5px;
            z-index: 2;
            width: 246px;
            padding: 10px;
            border-radius: 3px;
            border: solid 1px #e5e5e5;
            background-color: #ffffff;
            font-size: 12px;
            font-weight: $text-base;
            line-height: 1.4;
            &:before {
                @media (max-width: $mobile-bp) {
                    left: 76%;
                }
                border: solid transparent;
                content: " ";
                position: absolute;
                left: 46.5%;
                border-width: 13px;
                border-bottom-color: #e5e5e5;
                transform: rotate(180deg);
                top: 100%;
            }
            &:after {
                @media (max-width: $mobile-bp) {
                    left: 76.5%;
                }
                border: solid transparent;
                content: " ";
                position: absolute;
                left: 47%;
                border-width: 12px;
                border-bottom-color: #fff;
                transform: rotate(180deg);
                top: 100%;
            }
        }
        .info-tooltip-trigger {
            position: absolute;
            z-index: 3;
            right: 22%;
            top: 7%;
            width: 30px;
            height: 30px;
            background: url('/images/i_info.svg') no-repeat;
            @media (max-width: $mobile-bp) {
                right: 6%;
            }
        }
        .info-tooltip-trigger:hover + .info-tooltip {
            display: block;
        }
        .password-form-errors {
            .signup-form-input__error {
                display: block;
                padding-left: 0;
            }
        }
        .form-input-error {
            border: 1px solid red;
        }
    }

    .account-form-links {
        display: block;
        font-size: 18px;
        padding-bottom: 2px;
        margin: 5px 6rem;
        position: absolute;
        li {
            margin-bottom: 30px;
        }
        .settings-page-link-selected {
            color: #000;
            border-bottom: 3px solid #000;
        }
        @media (max-width: $mobile-bp) {
            position: relative;
            margin: 0;
            ul {
                text-align: center;
            }
            li {
                display: inline-block;
                margin: 30px 15px 15px;
                a {
                    padding-bottom: 15px;
                }
            } 
        }
    }

    .subscription-overlay {
        display: none;
        position: absolute;
        top: 10%;
        left: 32%;
        width: 552px;
        height: auto;
        background-color: #fff;
        padding: 4rem;
        border: 1px solid #292c2e;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        border: solid 1px #e5e5e5;

        h2 {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 24px;
        }

        h3 {
            text-align: center;
            font-size: 28px;
            font-weight: $text-base;
            margin-bottom: 24px;
        }

        p {
            padding-bottom: 10px;
            font-size: 14px;
        }

        button {
            font-family: raleway, sans-serif;
            font-size: 14px;
        }

        .return-to-settings {
            @extend %form-submit-button-style;
            width: 100%;
            padding: 20px;
        }

        .StripeElement {
            width: 100%;
        }

        .modal-buttons {
            margin-bottom: 24px;
        }

        .submit-state-button {
            width: 192px;
            height: 55px;
            font-size: 14px;
            margin-left: 30px;
            border-radius: 3px;
            border: solid 1px #217cb5;
            background-color: #217cb5;
            color: #fff;
        }

        .cancel-state-button {
            width: 192px;
            height: 55px;
            border-radius: 3px;
            border: solid 1px #217cb5;
            background-color: #fff;
            color: #217cb5;
        }
    }

    .modal-icon-container {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.11);
        background-color: #00adef;
        border-radius: 50%;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .subscription-overlay__close {
        position: absolute;
        left: 94%;
        top: 15px;
        width: 20px;
        height: 20px;
        background: url('/images/overlay-close.png') no-repeat;
    }

    #gradient-background {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(133deg, #c7f1f6, rgba(240, 162, 188, 0.68) 55%, rgba(133, 207, 221, 0.8));
    }

    .settings-block {
        @media (max-width: $mobile-bp) {
            display: block;
            width: 100%;
            margin: 0;
            text-align: left;
        }
        margin: 30px auto;
        border-radius: 5px;
        border: solid 1px #e5e5e5;
        background-color: #fdfdfd;
        padding: 10px;
        width: 696px;
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-row-gap: 50px;

        &-label {
            text-align: right;
            padding: 15px 24px;
            @media (max-width: $mobile-bp) { 
                text-align: left; 
                padding-left: 0;
            }
        }

        .settings-block-section {
            font-size: 14px;
            position: relative;
            .info-tooltip {
                @media (max-width: $mobile-bp) {
                    right: 0;
                }
                display: none;
                position: absolute;
                top: 0;
                left: 8rem;
                z-index: 2;
                width: 206px;
                padding: 10px;
                border-radius: 3px;
                border: solid 1px #e5e5e5;
                background-color: #ffffff;
                font-size: 12px;
                font-weight: $text-base;
                line-height: 1.4;
                &:before {
                    border: solid transparent;
                    content: " ";
                    position: absolute;
                    left: 46.5%;
                    border-width: 13px;
                    border-bottom-color: #e5e5e5;
                    transform: rotate(180deg);
                    top: 100%;
                }
                &:after {
                    border: solid transparent;
                    content: " ";
                    position: absolute;
                    left: 47%;
                    border-width: 12px;
                    border-bottom-color: #fff;
                    transform: rotate(180deg);
                    top: 100%;
                }
            }
            button[data-modal="remove-card"]:hover + .info-tooltip {
                display: block;
            }
            .subscription-status-block + .info-tooltip {
                top: -2.5rem;
                left: -6.5rem;
            }
            .subscription-status-block:hover + .info-tooltip {
                display: block;
            }
            @media (max-width: $mobile-bp) { margin-bottom: 40px; }
        }

        .settings-block-section div {
            margin: 15px 0 15px;
        }

        .payment-history {
            padding: 10px 24px;
            background-color: #f8f8f8;
        }

        .payment-history > .payment-item {
            padding: 7px 0 7px;
            border-bottom: solid 3px #e5e5e5;
            background-color: #f8f8f8;
        }

        .payment-item > .payment-date {
            color: #8f8f8f;
            margin-bottom: 5px;
        }

        .payment-item > .product-payment-type {
            margin: 0;
        }

        .product-payment-price {
            font-weight: bold;
            font-size: 16px;
            float: right;
        }
        
        .billing-settings-button {
            @extend %form-submit-button-style;
            padding: 10px 27px;
            font-weight: 300;
            &--hollow {
                margin-left: 22px;
                background: none;
                border-radius: 3px;
                color: #217cb5;
                border: solid 1px #217cb5;
            }
            &--disabled {
                margin-left: 22px;
                background: none;
                border-radius: 3px;
                color: #6d7474;
                border: solid 1px #6d7474;
            }
        }
        
        .remove-card {
            border: solid 1px black;
            cursor: pointer;
        }
        .bold {
            font-weight: bold;
        }
        
        .coupon {
            background-color: #D3D3D3;
        }
        
        label {
            text-align: right;
            width: 100px;
            font-size: large;
            color: #484848;
        }

        .payment-method-info {
            display: flex;
            align-items: center;
        }

        .other-credit-card-numbers {
            margin: 0 12px !important;
        }

        .subscription-status {
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            margin-right: 6px;
            &--valid {
                background-color: #2ed091;
                border: 1px solid #2ed091;
                background-clip: content-box;
                padding: 1px;
            }
            &--expired {
                background-color: #c51c00;
                border: 1px solid #c51c00;
                background-clip: content-box;
                padding: 1px;
            }
        }

        .expired-subscription-copy {
            color: #c51c00;
            font-size: 14px;
            line-height: 1.6;
            margin-bottom: 20px;
            font-weight: $text-base;
        }
    }

    .pick-plan-wrapper {
        width: 700px;
        margin: 0 auto;
        text-align: center;
        h1 {
            margin-bottom: 3rem;
        }
    }

    // stripe UI CSS

     /*
    .StripeElement {
        box-sizing: border-box;

        height: 55px;
        width: 348px;

        padding: 18px 12px;
        margin-bottom: 24px;

        border-radius: 3px;
        border: solid 1px #b9b9b9;
        background-color: #ffffff;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
        background-image: url('/images/icon_warning.svg');
        background-repeat: no-repeat;
        background-position: 95%;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }
    */

    .accepted-credit-cards {
        padding-bottom: 22px;
    }

    .accepted-credit-cards li {
        display: inline-block;
    }

    @keyframes lds-ring {
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(360deg);
        }
    }

    .lds-ring {
        display: none;
        left: -43px;
        top: -6px;
        width: 40px;
        height: 23px;
    }

    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 23px;
        height: 23px;
        margin: 6px;
        border: 2px solid #217cb5;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #217cb5 transparent transparent transparent;
    }

    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }

    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }

    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    .unknown-credit-card {
        width: 40px;
        height: 24px;
        background-color: gray;
    }

    #update-payment-form {
        .overlay-payment-paragraphs {
            margin-top: 24px;
        }
        .info-tooltip {
            @media (max-width: $mobile-bp) {
                right: 0;
            }
            display: none;
            position: absolute;
            top: -80px;
            right: -60px; 
            z-index: 2;
            width: 206px;
            padding: 10px;
            border-radius: 3px;
            border: solid 1px #e5e5e5;
            background-color: #ffffff;
            font-size: 12px;
            font-weight: $text-base;
            line-height: 1.4;
            &:before {
                @media (max-width: $mobile-bp) {
                    left: 76%;
                }
                border: solid transparent;
                content: " ";
                position: absolute;
                left: 46.5%;
                border-width: 13px;
                border-bottom-color: #e5e5e5;
                transform: rotate(180deg);
                top: 100%;
            }
            &:after {
                @media (max-width: $mobile-bp) {
                    left: 76.5%;
                }
                border: solid transparent;
                content: " ";
                position: absolute;
                left: 47%;
                border-width: 12px;
                border-bottom-color: #fff;
                transform: rotate(180deg);
                top: 100%;
            }
        }
        &__form #card-cvc-element{
            background: url('/images/i_info.svg');
            background-repeat: no-repeat;
            background-position: 95%;
        }
        .info-tooltip-trigger {
            position: absolute;
            z-index: 999999;
            right: 4%;
            top: 30.5%;
            width: 30px;
            height: 30px;
        }
        .info-tooltip-trigger:hover + .info-tooltip {
            display: block;
        }
        .update-card-form-input{
            @extend %input-style;
            width: 232px;
            padding-top: 0;
            margin-right: 15px;
            @media (max-width: $mobile-bp) {
                width: 100%;
            }
            &__error {
                display: none;
                font-size: 14px;
                font-weight: 300;
                color: $color-form-error;
            }
        }
        .StripeElement--invalid ~ .update-card-form-input__error {
            display: block !important;
            margin-top: -20px;
            margin-bottom: 20px;
        }
        .StripeElement--invalid ~ .StripeElement--empty {
            .submit-state-button {
                background-color:$sn-primary-link;
                color: #6d7474;
                border: 1px solid $sn-border-gray;
                padding: 15px;
                margin: 20px 0;
            }
        }
    }

    .icon-top-space {
        margin-top: 2rem;
    }
    .retired_page_header {
        text-align: center;
        margin: 30px 0 30px;
    }
    .retired-sent-block {
    .retired-text-block {
            width: 500px;
            margin: 2rem auto;
            text-align: center;
            font-size: 20px;
            font-weight: $text-base;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.6;
            letter-spacing: normal;
            text-align: center;
            color: $sn-primary-paragraph;
        }
        .retired-page-link-subscribe {
            display: inline-block; 
            border: none;
            cursor: pointer;
            padding: 10PX;
            background-color: $sn-primary-link;
            border-radius: 4px;
            margin: 10px;
        }
        .retired-page-link-subscribe a{
            color: $white;
            padding: 23px;
            font-size: 14px;
        }
        .retired-page-link-home-page {
            display: inline-block; 
            border: 1px solid $sn-primary-link;
            cursor: pointer;
            padding: 10PX;
            background-color: $white;
            border-radius: 4px;
            margin: 10px;
        }
        .retired-page-link-home-page a{
            color: $sn-primary-link;
            padding: 23px;
            font-size: 14px;
        }    
    }
    .welcome_back_header {
        text-align: center;
        margin: 30px 0 30px;
    }
    .welcome_back-sent-block {
    .welcome_back-text-block {
            // width: 500px;
            margin: 3rem auto;
            text-align: center;
            font-size: 20px;
            font-weight: $text-base;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.6;
            letter-spacing: normal;
            text-align: center;
            color: $sn-primary-paragraph;
        }
        .welcome_back-link-subscribe {
            display: inline-block; 
            border: none;
            cursor: pointer;
            padding: 10PX;
            background-color: $sn-primary-link;
            border-radius: 4px;
            margin:35px 10px;
        }
        .welcome_back-link-subscribe a{
            color: $white;
            padding: 23px;
            font-size: 14px;
        }
        .welcome_back-link-home-page {
            display: inline-block; 
            border: 1px solid $sn-primary-link;
            cursor: pointer;
            padding: 10PX;
            background-color: $white;
            border-radius: 4px;
            margin: 10px;
        }
        .welcome_back-link-home-page a{
            color: $sn-primary-link;
            padding: 23px;
            font-size: 14px;
        }
    }

    .payment-expired-alert {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */    
        &-content {
            background-color: #fefefe;
            margin: auto;
            padding: 20px;
            border-bottom: 1px solid #888;
            width: 100%;
            text-align: center;
            &_header {
                text-align: center;
                margin: 15px 0 15px;
            }
            @media (max-width: $tablet-bp) {
                width: 80%;
            }
            &-block {          
                margin: 1rem auto;
            }
            &_text-block {
                // width: 500px;
                padding: 2rem 0;
                text-align: center;
                font-size: 20px;
                font-weight: $text-base;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.6;
                letter-spacing: normal;
                text-align: center;
                color: $sn-primary-paragraph;
                border-top: 2px solid #e5e5e5;
                border-bottom: 2px solid #e5e5e5;
            }
            &_link-close {
                display: inline-block; 
                border: none;
                cursor: pointer;
                padding: 10PX;
                background-color: $sn-primary-link;
                border-radius: 4px;
                margin:15px 0;
            color: #fff;
            }
            &_link-subscribe {
                display: inline-block; 
                border: none;
                cursor: pointer;
                padding: 10PX;
                background-color: $sn-primary-link;
                border-radius: 4px;
                margin:35px 10px;
            }
            &_link-subscribe a{
                color: $white;
                padding: 23px;
                font-size: 14px;
            }
            &_link-home-page {
                display: inline-block; 
                border: 1px solid $sn-primary-link;
                cursor: pointer;
                padding: 8px 15px;
                background-color: $white;
                border-radius: 4px;
                margin: 10px;
                color: $sn-primary-link;
            }
            &_link-home-page a{
                color: $sn-primary-link;
                padding: 23px;
                font-size: 14px;
            }
            &_link-close a{           
            color: #fff;
            }    
        }         
    }

    .teacher-signout-alert-content {
        .teacher-signed-out-notification-text {
            z-index: 1; 
            overflow: auto;
            margin: auto;
            padding: 20px;
            border-bottom: 1px solid $sn-border-gray;
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: $text-base;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.6;
            letter-spacing: normal;
            color: $sn-primary-paragraph;
            margin-bottom: 1%;
        }
    }

    .free-trial {
        display : flex;
        padding : 20px;

        &__button {
            margin : auto !important;
        }
    }
}