.popular-titles{
  background-color: $color-teacherSoftGray;
  &__container{
    @include container($padding: true);
    padding-top: 24px;
    @include media-notSmall{
      padding-bottom: 24px;
    }
    @include media-small{
      overflow: auto;
      padding-bottom: 12px;
    }
  }
  &__heading{
    font-size: $text-base;
    font-weight: $fw-fat;
    line-height: $lh-tight;
    color: $sn-primary-header;
    margin-bottom: 8px;
    @include media-small{
      position: sticky;
      left: 0;
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px; // negates link padding-left so that links visually align when hover state not shown
    @include media-small{
      flex-wrap: nowrap;
    }
    &__item{
      @include media-small{
        flex: none;
        &__expanded{
          display: none; //for simplicity we only use the hover effect on wider screen sizes. Narrow windows always link to Study Guide.
        }
      }
      @include media-notSmall{
        border-radius: $radius-base $radius-base 0 0;
        &:hover,
        &:focus-within{
          z-index: 3;
          background-color: $white;
          position: relative;
          box-shadow: 0 4px 24px rgba(#000, 0.3);
          &:after {
            //pseudo element helps fill in width of card background when main link is shorter than revealed content
            content: '';
            display: block;
            position: absolute;
            background: $white;
            border-radius: $radius-base $radius-base 0 0;
            top: 0;
            width: 100px;
            width: max(100%, 160px);
            height: 100%;
          }
        }
        &__expansion-holder{
          position: relative;
        }
        &__expanded{
          display: none;
          flex-direction: column;
          position: absolute;
          background-color: $white;
          width: 100px;
          width: max(100%, 160px);
          border-radius: 0 0 $radius-base $radius-base;
          box-shadow: 0 4px 24px rgba(#000, 0.3);
          .popular-titles__list__item:hover &,
          .popular-titles__list__item:focus-within &{
            display: flex;
          }
        }
      }
    }
    &__sub-item{
      color: $color-brandBlue2020;
      padding: 6px 8px;
      font-weight: $fw-chubby;
      strong{
        font-weight: $fw-fat;
      }
      line-height: $lh-tight;
      display: flex;
      align-items: center;
      &:before{
        content: '';
        display: block;
        width: 32px;
        height: 32px;
        flex: none;
        background-size: contain;
        margin-right: 8px;
      }
      &--noFear:before{background-image: url(/images/icon-translation-72-brandBlue.svg);}
      &--studyGuide:before{background-image: url(/images/icon-literature-72-brandBlue.svg);}
      &--fullText:before{background-image: url(/images/icon-fullText-72-brandBlue.svg);}
      &--graphic-novel:before{background-image: url(/images/icon-graphicNovel-72-brandBlue.svg);}
      &--teacher:before{background-image: url(/images/icon-teacherGuide-72-brandBlue.svg);}
      &--memes:before{background-image: url(/images/icon-memes-72-brandBlue.svg);}
      &--infographic:before{background-image: url(/images/icon-infographic-72-brandBlue.svg);}
      &:hover,
      &:focus{
        color: $color-linkHover;
      }
    }
    &__popular-sub-item {
      color: $color-brandBlue2020;
      padding: 8px 2px;
      font-weight: $fw-base;
      font-size: 14px;
      line-height: $lh-tight;
      display: flex;
      align-items: center;
      &:first-child {border-top : solid 1px $sn-border-gray;}
      &:last-child {
        border-bottom: solid 3px #00cc9a;
        border-radius: 0 0 4px 4px;
      }
      &:last-child:hover {border-bottom: solid 3px #01a87f !important;}
      &:before{
        content: '';
        display: block;
        width: 28px;
        height: 32px;
        flex: none;
        background-size: contain;
        margin-right: 4px;
        background: no-repeat;
        margin-left: 4px;
      }
      &--noFear:before{background-image: url(/images/icon-nofear-plus.svg);}
      &--studyGuide:before{background-image: url(/images/icon-StudyGuide-brandBlue.svg);}
      &--fullText:before{background-image: url(/images/icon-FullTexts-brandBlue.svg);}
      &--flashcards:before{background-image: url(/images/icon-Flashcards-brandGreen.svg);}
      &--quizzes:before{background-image: url(/images/icon-quizzes-brandGreen.svg);}
      &--graphic-novel:before{background-image: url(/images/icon-GraphicNovel-brandGreen.svg);}
      &--infographic:before{background-image: url(/images/icon-infographic.svg);}
      &--testPrep:before{background-image: url(/images/icon-testPrep.svg);}
      
      &--flashcards, &--infographic, &--graphic-novel, &--quizzes, &--testPrep, &--noFear {
        color: $plus-green-dark;
        background-color: #e1faef;
        border-left: solid 3px #00cc9a;
        border-right: solid 3px #00cc9a;
        &:hover {
          background: rgba(0, 204, 154, 0.2) !important;
          border-left: solid 3px #01a87f !important;
          border-right: solid 3px #01a87f !important;
        }
      }
      
      &--fullText, &--studyGuide {
        border-left: solid 3px $color-teacherSoftGray;
        border-right: solid 3px $color-teacherSoftGray;
      }
      
      &:hover {
        background-color: $color-teacherSoftGray;
        color: #004371 !important;
        &:before {
          filter: invert(41%) sepia(78%) saturate(3853%) hue-rotate(182deg) brightness(45%) contrast(107%) !important;
        }
        border-left: solid 3px rgba(118, 118, 118, 0.25);
        border-right: solid 3px rgba(118, 118, 118, 0.25);
      }
      
    }
  }
  &__title-link{
    @include media-notSmall{
      padding: 8px 12px;
    }
    @include media-small{
      padding: 12px;
    }
    font-size: $text-base;
    line-height: $lh-tight;
    display: flex;
    flex-direction: column;
    color: $sn-primary-header;
    position: relative; //required for moving above the pseudo-element on hover
    z-index: 2;
    &__title{
      font-weight: $fw-fat;
      margin-bottom: 2px;
    }
    &:hover{
      color: #004371;
    }
  }
}
