@mixin l-media-bp {
    @media (min-width: 766px) and (max-width: 849px){
      @content;
    }
  }

  @mixin l-media-bp2 {
    @media (min-width: 736px) and (max-width: 765px){
      @content;
    }
  }

  @mixin l-media-bp3 {
    @media (min-width: 700px) and (max-width: 735px){
      @content;
    }
  }
.sparkteach-homepage-print-promo{
    width: 100%;
    display: flex;
    
    background-color: #3f1239;
    @include media-small {
        flex-direction: column-reverse;
        position: relative;
        padding-left: $pageMargin;
        padding-right: $pageMargin;
    }
    &__teachimage{
        @include l-media-bp2{
            margin-left: auto !important;
            margin-top : 35px;
        }
        @include l-media-bp3{
            margin-left: auto !important;
            margin-top : 65px;
        }
        @include l-media-bp{
            margin-left: auto !important;
        }
        @include media-notSmall{
            width:400px;
            margin-left:10%;
            display:flex;
            padding-top : $pageMargin;
        }
        @include media-small {
            display: flex;
            justify-content: center;
        }  
    }
    &__text{
        align-items: center;
        justify-content: space-between;
        padding-right : 12px;      
        color:$white;
        margin-top: 65px;

        @include l-media-bp2{
            margin-bottom: 10px;
        };

        @include l-media-bp3{
            margin-bottom: 10px;
        };

        @include media-notSmall{
            width:800px;
            margin-left: 25px;
        }

        @include media-small {
            justify-content: center;
            margin-top: 0px;
        }
    }
    &__blurb{
        margin-top: $s-px;
        color:$white;
        font-weight: $fw-base;
        font-size: $text-slightly-big;
        line-height: $lh-base;
        padding-right: 16px;
        word-break: break-word;
        @include media-notSmall{
           margin-bottom:32px;
        }
        @include media-small{
            text-align: center;
            margin-bottom: 36px;
            line-height:$lh-tight;
            font-weight:$fw-base;
        }
    
      }
      &__pseudo-button{
        display : flex;
        justify-content : center;
        font-weight: $fw-chubby;
        line-height: $lh-base;
        color: $color-bnGreen;
        background: $white;
        border-radius: 8px;
        padding : 8px 40px;
        margin-right : 12px;
        margin-bottom : 12px;
        background-color: #007acd;
        color: #FFF;
        height: 42px;
        width:224px;
        @include media-small{
            margin-left: auto;
            margin-right: auto;
            margin-bottom : 24px;
        }
      }
      &__title{
        font-size: $text-big;
        line-height:$lh-extra-tight;
        font-weight:$fw-base;
        color: $white;
        @include media-small{
            margin-top: 40px;
            text-align: center;
            font-size: $text-slightly-big;
            line-height:$lh-tight;
            font-weight:$fw-chubby;  
        }
       
      }
      &__strap{
        text-align: center;
        color: $white;
        line-height: $lh-loose;
      }
      &__banner{
        &__image{
                height: 292px;
        }
    }
}