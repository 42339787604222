.notetaking__panel {
    width: 405px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 12px;
    object-fit: contain;
    border-radius: 12px;
    margin-bottom: 60px;
    background-color: #e5e5e5;
    position: relative;
    margin-top: 32px;

    &__plus-border {
      position: relative;
      border: 4px solid #00cc9a;
    }

    &__plus-container {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 95px;
      height: 85px;
      overflow: hidden;

      &__plus-text {
        position: absolute;
        transform: translate(23%, -14%) rotate(-45deg);
        text-align: center;
        background: #41ffaa;
        width: 100%;
        top: 11px;
        left: -52px;
        line-height: 1.5;
        font-weight: 700;
        color: #004a3e;
        font-size: 12px;
      }

    }

    @include media-small {
      width: 100%;
      margin-top: -46px;
    }
  }

  .note-container {
    @include media-small {
      width: 100%;
    }
  }
  .notetaking__frame {
    width: 373px;
    height: 34px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    @include media-small {
      width: 100%;
    }
  }

  .notetaking__plus-icon{
    width: 24px;
    height: 24px;
    flex-grow: 0;
    object-fit: contain;
    content: url("/images/notetaking/icon-plus-square-white.svg");
  }

  .notetaking__expand-icon{
    width: 24px;
    height: 24px;
    flex-grow: 0;
    object-fit: contain;
    float: right;
    content: url("/images/notetaking/icon-expand-more.svg");

    @include media-small {
     display: none;
    }
  }

  .notetaking__vertical-icon{
    width: 24px;
    height: 24px;
    flex-grow: 0;
    object-fit: contain;
    content: url("/images/notetaking/icon-more-vertical.svg");

    @include media-small {
      display: none;
    }
  }

  .tip{
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 12px solid #ffffff;
    position: absolute;
    left: 51px;
    top: -10px;
  }

  .dropdown {
    float: right;
    width: 36px;
    position: relative;
    left: 5px;
  }

  .dropbtn{
    background: none;
    border: none;
  }

  .dropdown-content{
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
    width: 75px;
    height: 84px;
    position: absolute;
    top: 34px;
    display: none;
    margin-left: -42px;
    margin-top: -2px;
    border: solid 1px #f5f1f5;
    background-color: #fff;
    z-index: 1;
  }

  .dropdown-edit {
    display: flex;
    padding: 6px 16px 10px;
    width: 100%;
    height: auto;
    flex-grow: 0;
    margin-bottom: 10px;
    line-height : 1.6;
    font-family: Raleway;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #007acd;
    min-height: 29px;
  }

  .dropdown-delete{
    width: 43px;
    padding: 6px 16px 10px;
    height: auto;
    flex-grow: 0;
    margin: 0 0 0;
    font-family: Raleway;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #007acd;
    min-height: 29px;
  }

  .show-dropdown{
    display: block !important;
    @include media-small {
      display: none !important;
    }
  }

  .notetaking__notes {
    width: 60px;
    height: 34px;
    flex-grow: 0;
    font-family: Raleway;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #292c2e;
  }
  .guestnotetakingnotes {
    margin-left: 18px;
  }

  .notetaking__see-all-notes {
    height: 26px;
    flex-grow: 0;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    background: none;
    border :none;
    color: #0066c4 !important;
    cursor: pointer;
    &:hover {
      color: #004371 !important;
    }
  }

   .notetaking__title {
    height: 42px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    padding: 8px 16px;
    background-color: #00cc9a;
  }

  .notetaking__title-highlighted-text {
    height: 26px;
    flex-grow: 1;
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #292c2e;
  }

  .notetaking_note-copy {
    height: 42px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 8px 16px;
    background-color: #fff;
    input:focus{
        outline: none !important;
    }
  }

  .notetaking__item {
    @include media-small {
      height: 84px;
    }
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0;
  }

  .notetaking__fullwidth.notetaking__add-notes {
    font-weight: normal;
  }

  #accordion{
    width: 100%;
  }

  .accordion-error {
    width: 100%;
  }

  .wrapper{
    width:70%;
  }

  @media(max-width:992px){
   .wrapper{
    width:100%;
  }
  }

  .panel-heading {
    padding: 0;
    border:0;
  }

  .panel-title>a, .panel-title>a:active{
    display:block;
    padding:15px;
    color:#555;
    font-size:16px;
    font-weight:bold;
    text-transform:uppercase;
    letter-spacing:1px;
    word-spacing:3px;
    text-decoration:none;
  }

  .panel-heading  a:before {
     font-family: 'Glyphicons Halflings';
     content: "\e114";
     float: right;
     transition: all 0.5s;
  }

  .panel-heading.active a:before {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .notetaking__panel, .notetaking_note-copy, .notetaking__title{
    height: unset!important;
  }

  .collapsed.notetaking_note-copy-sub {
    padding: 8px 16px;
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 0 0 4px 4px;
    border: none!important;
  }

  .notetaking_note-copy-sub {
    padding: 8px 16px;
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 0 0 4px 4px;
    border-left: 2px solid #00cc9a;
    border-right: 2px solid #00cc9a;
    border-bottom: 2px solid #00cc9a;
    overflow: hidden;

    @include media-small{
      border: none;
      height: 73px;
    }
  }

  .without-select-background{
    background-color:#d9ebf7!important;
  }

  .without-select-border {
    border-left: 2px solid #d9ebf7!important;
    border-right: 2px solid #d9ebf7!important;
    border-bottom: 2px solid #d9ebf7!important;
  }

  .notetaking__item{
    cursor: pointer;
    @include media-small {
      margin-left: auto;
      margin-right: auto;
      min-width: 285px;
      max-width: 300px;
      min-height: 110px;
      height: unset; //scroll issue
    }
  }

// expand-collapse strat
.notetaking{
    &__extra{
      float: right;
      width: 24px;
      -ms-transform: rotate(180deg); /* IE 9 */
      transform: rotate(180deg);
      position: relative;
      left: 5px;
    }

    &__text-container{
      width: 263px;
      float: left;
      height: unset;
      max-height: 48px;
      overflow: hidden;
      display: -webkit-box;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
      margin-bottom: 6px;
      @include media-small{
        -webkit-line-clamp: 1;
      }
    }

    &__title-highlighted-text{
      padding: 8px 6px 8px 16px;
      background-color: #00cc9a;
      min-height: 41px;
      border-radius: 4px 4px 0 0;

      @include media-small{
        background-color: #e1faef;
      }
    }

    &__fullwidth{
      width: 100%;
    }

    &__float-icon{
      position: fixed!important;
      right: 16px!important;
      bottom: 76px!important;
      height: 64px;
      width: 64px;
      border: none;
      background: none;
      cursor: pointer;
      padding: 0!important;
      @media (min-width: $mobile-bp) {
        display:none;
      }
    }
    &__float-icon-img{
      content: url("/images/notetaking/icon-note-taking-float.svg");
    }
    &__float-icon.hide-float-icon{
      position: absolute!important;
      right: 5px!important;
      top: -76px!important;
    }
}
.collapse:not(.show) {
  display: block;
  height: 25px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @include media-small {
    height: unset;
  }
}
.collapsing {
  height: 25px;
}
.notetaking__add-notes {
  p{
    @include media-small {
      display: -webkit-box !important;
      max-height: 89px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      width: 250px;
    }
  }
  line-height: 1.6;
  color: #767676;

  @include media-small{
    line-height: normal;
    color: #767676;
  }
}
#accordion{
  max-height: 495px;
  overflow-x: hidden;
  overflow-y: scroll;

  @include media-small{
    position: relative;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    overscroll-behavior-x: contain;
   -ms-scroll-snap-type: x proximity;
    scroll-snap-type: x proximity;
    column-gap: 10px;
  }
}

.accordion-error {
  max-height: 495px;
  overflow-x: hidden;
  overflow-y: scroll;

  @include media-small{
    position: relative;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    overscroll-behavior-x: contain;
   -ms-scroll-snap-type: x proximity;
    scroll-snap-type: x proximity;
    column-gap: 10px;
  }
}

.collapsed .notetaking__text-container{
  width: 263px;
  float: left;
  height: 27px;
  overflow: hidden;
  display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}

.without-select-background.collapsed .notetaking__text-container{
-webkit-line-clamp: unset;
}

.collapsed.notetaking__title-highlighted-text{
  padding: 8px 6px 8px 16px;
  background-color: #e1faef;
  min-height: 41px;

  @include media-small{
    background-color: #e1faef;
    min-height: 43px;
  }
}

.collapsed .notetaking__extra{
  float: right;
  -ms-transform: rotate(360deg)!important; /* IE 9 */
  transform: rotate(360deg)!important;
  position: relative;
  left: 5px;
}

.notetaking__title{
  height: unset;
  padding: 0!important;
  background-color: #00cc9a;
  border-radius: 4px 4px 0 0;
}

.dropbtn{
  float: right;
}

#add-note-tooltip {
  display: inline-block;
  width: 40px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-image: url('/images/notetaking/create-note-icon.svg');
}

.adding-border{
  border: 2px solid #00cc9a;
}

#selected-text{
  pointer-events: auto!important;
  z-index: 5;
}

.promotionalnote-taking-modal {
  .snplusmodal__header {
    height: 135px;
    @include media-small{
      height: 110px;
    }
  }
  .snplusmodal__header__content {
    padding-bottom: 0;
  }
  .snplusmodal__container {
    @include media-small{
      width: 360px;
      position: absolute;
      top: 64px;
      align-self: center;
      height: auto;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      overflow: hidden;
    }
  }

}
.promotionalnotetakingModal {
  &__icon-lock {
    height: 156px;
    background-image: url(/images/notetaking/icon-lock.svg);
    width: 156px;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
    position: absolute;
    top: 7em;
    left: 13em;
    @include media-small{
      width: 80px;
      height: 80px;
      left: 9em;
      top: 5em;
    }
  }
  &__heading {
    font-size: 21px;
    color: #004a3e;
    @include media-small{
      height: 34px;
      align-self: center;
      @-moz-document url-prefix() {
        font-size: 20px;
    }
    }
  }
  &__heading__text {
    width: 424px;
    height: unset;
    font-family: Raleway;
    font-size: 16px;
    line-height: 1.6;
    text-align: center;
    color: #004a3e;
    align-self: auto;
    @include media-small{
      width: 320px;
    }
  }
  &__overlay {
    background-color: #e1faef;
    gap: 1rem;
    padding-top: 32px;
    height: auto;
    @include media-small{
      width: 100%;
      padding: 32px 22px 30px;
    }
  }
  &__ads-free {
    content: url("/images/notetaking/icon-ads-free.svg");
  }
  &__icon-writing {
    content: url("/images/notetaking/icon-writing.svg");
  }
  &__icon-quizzes {
    content: url("/images/notetaking/icon-quizzes.svg");
  }
  &__icon-ap-exam {
    content: url("/images/notetaking/icon-ap-exam.svg");
  }
  &__icon-plus {
    content: url("/images/notetaking/icon-plus.svg");
  }
  &__body__content {
    display: flex;
  }
  &__body__icons {
    display: grid;
  }
  &__body__icons_container {
    width: 54px;
    height: 54px;
    flex-grow: 0;
    background-color: #41ffaa;
    text-align: center;
    border-radius: 50px;
    margin: auto;
  }
  &__body__icons__title {
    font-family: Raleway;
    font-size: 14px;
    line-height: 1.25;
    text-align: center;
    color: #004a3e;
    padding-top: 10px;
  }
  &__body__icon {
    text-align: center;
    align-items: center;
    writing-mode: vertical-lr;
    margin: auto;
    padding-top: 12px;
  }
  &__body__plus__icons_container {
    border: 2px solid $color-brandBlue2020;
    background-color: unset;
  }
  &__body__plus__icon {
    padding-top: 15px;
  }
  &__body__icons__plus__title {
    height: 44px;
    flex-grow: 0;
    font-family: Raleway;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.25;
    text-align: center;
    color: $color-brandBlue2020;
  }
  &__overlay__container {
    height: auto;
    @include media-small{
      width: 100%;
      margin-left: 0;
    }
  }
  &__sign__up {
    width: 300px;
    height: 46px;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background-color: $color-brandBlue2020;
    border: unset;
    color: $white;
    @include media-small{
      align-self: center;
    }
    &:hover {
      background-color: #004371;
    }
    &:focus {
      background-color: #004371;
      outline: 2px solid black;
    }

    &:focus-visible {
      border: 2px solid black;
      outline: unset;
    }
  }
  &__renew {
    width: 300px;
    height: 46px;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background-color: $color-brandBlue2020;
    border: unset;
    color: $white;
    @include media-small{
      align-self: center;
    }
    &:hover {
      background-color: $sn-brand-blue;
    }
  }
  &__body__icons__flashcards__quizzes__title {
    width: 83px;
  }
  &__sign__in {
    font-size: $text-small;
    font-weight: $fw-chubby;
    color: $color-brandBlue2020;
    &:hover,&:focus {
      color: #004371;
    }
  }
  &__sign__in__account {
    font-size: $text-small;
    font-weight: $fw-base;
    @include media-small{
      align-self: center;
      margin-bottom: 15px;
    }
  }
  &__desktop__heading {
    @include media-small{
      display: none;
    }
  }
  &__responsive__heading {
    @include media-notSmall{
      display: none;
   }
  }
  &__body_grid__one {
    display: grid;
    grid-template-columns: 102px 102px 102px;
    width: 295px;
  }
  &__body_grid__two {
    display: grid;
    grid-template-columns: 102px 102px;
    @include media-small{
      padding-top: 20px;
      justify-content: center;
    }
  }
  &__body__content {
    @include media-small{
      display: grid;
    }
  }
}
#promotional-notepane {
  @include media-small{
    display: none;
  }
}
.floating-note {
  background: url('/images/notetaking/icon-note-taking-float.svg') no-repeat;
  position: fixed!important;
  right: 16px!important;
  bottom: 16px!important;
  height: 64px;
  width: 64px;
  z-index: 1;
  cursor: pointer;
  @media (min-width: $mobile-bp) {
    display:none;
  }
}
.floating-note--hidden {
  display: none;
}
//Delete note
.deletenote{

  &__Bottom{
    width: 576px;
    height: 277px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 32px 40px 48px;
    background-color: #fff;
  }

  &__Delete-your-note {
    height: 39px;
    align-self: stretch;
    flex-grow: 0;
    font-family: Raleway;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: #008262;
  }

  &__Copy_CTAs {
    width: 496px;
    height: 138px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    padding: 0;
  }
  &__message {
    height: 52px;
    align-self: stretch;
    flex-grow: 0;
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #292c2e;
  }

  &__buttonContainer{
    display: flex;
    gap: 24px;
  }

  &__button-primary {
    width: auto;
    height: 46px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13.5px 60.5px;
    border-radius: 4px;
    background-color: #007acd;
    border: solid 0.1px #007acd;
  }

  &__button-primary:hover{
    background-color: #00adef;
    border-color: #00adef;
    outline: none;
  }

  &__primary {
    width: 101px;
    height: 19px;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.13px;
    text-align: center;
    color: #fff;
  }

  &__button-secondary {
    width: 222px;
    height: 46px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 4px;
    border: solid 2px #007acd;
    background-color: #fff;
  }

  &__button-secondary:hover{
    border: solid 2px #00adef;
    span {
      color: $sn-brand-blue;
    }
  }
  &__Secondary {
    width: 84px;
    height: 19px;
    flex-grow: 0;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.13px;
    text-align: center;
    color: #007acd;
  }
}

.expanded-green {
  background-color: #00cc9a;
}

.default-green {
  background-color: #e1faef;
}

mark {
  cursor: pointer;
  background : unset;
}
.audio-exist .notetaking__float-icon {
  bottom: 80px!important;
}
.audio-exist .floating-note {
  bottom: 80px!important;
}
.ad-exist .floating-note {
  bottom: 109px!important;
}

.page-turn-nav {
  position: relative;
}

.floating-note.hide-float-icon{
  position: absolute!important;
  right: 5px!important;
  top: -76px!important;
}

.note-error {
  &:not([hidden]){
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    background-color: #fff;
  }
  @include media-mobile {
    min-width: 275px;
  }
  &__container {
  display: flex;
  border: 2px solid $color-form-error;
  padding: 10px;
  margin-bottom: 115px;
  @include media-mobile {
    margin-bottom: unset;
  }
  background-color: #fbf0ee;
    &__icon {
      padding-right: 10px;
    }
    &__text {
      color: $color-form-error;
      font-size: $text-base;
      line-height: $lh-base;
    }
    &__title {
      font-size: inherit;
      color: inherit;
    }
    &__info {
      color: inherit;
    }
  }
}

.note-kabab-error {
  &:not([hidden]){
    margin-bottom: 10px;
    width: 100%;
    @include media-mobile {
      display: none;
    }
  }
    &__container {
    display: flex;
    border: 2px solid $color-form-error;
    padding: 10px;
    background-color: #fbf0ee;
      &__icon {
        padding-right: 10px;
      }
      &__text {
        color: $color-form-error;
        font-size: $text-base;
        line-height: $lh-base;
      }
      &__title {
        font-size: inherit;
        color: inherit;
      }
      &__info {
        color: inherit;
      }
    }
}

.note-loader {
  position: absolute;
  width: 94%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(72,72,72,.75);
  z-index: 2;
  top: 124px;
  @include media-small {
    max-height: 135px;
  }
  &[hidden] {
    display: none;
  }
  &__loader-img {
    width: 25%;
    height: auto;
    @include media-small {
      width: 20%;
      height: auto;
    }
  }
}

.accordion-error {
  overflow: hidden !important;
}

.notetaking-CTA {
  @include media-mobile {
    top: 150px;
  }
}
.no-note-panel {
  overflow: hidden !important;
  display: block;
}
#float-icon-mobile{
  display: none !important;
}
#float-icon-mobile.support-hide-float-icon.hide{
  display: none !important;
}
#float-icon-mobile.support-hide-float-icon{
  display: none !important;
  @include media-small {
    display: block !important;
  }
}
#float-icon-mobile.hide-float-icon{
  @include media-small {
    display: block !important;
  }
}

#float-icon-mobile[hidden] {
  display : none !important;
}
.note-panel-extend {
  padding-bottom: 32px;
}

.defaultnote {
  cursor: pointer;
}

.highlightText_for_desktop {
  @include media-small { 
    display: none;
  }
}

.notetaking_note-copy span {
  @include media-small { 
    font-style: italic;
  }
}

.without-select-background .note-title {
  @include media-small { 
    display: none !important;
  }
}

.page-title {
  @include media-small { 
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
}

.note-title.default-note-title {
    -webkit-line-clamp: 1;
    display: -webkit-box !important;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.note-title {
  @include media-small {
    display: block !important;
  }
}

.default_note {
  font-style: italic;
}

.promotionalnote-taking-modal.snplusmodal__header__container {
  .snplusmodal__title {
    margin-top: 42px !important;
  }

}

.promotionalnote-taking-modal.snplusmodal__container { 
  .free-trial-notification__option {
    @include media-small {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .free-trial-notification__option li {
    @include media-small {
      padding: 0 0 12px 0;
      width: 33.33%;
    }
  }
}

.promotionalnotetakingModal__overlay__container .free-trial-notification__option li {
  span {
    line-height: $lh-extra-tight;
    font-size: $text-small;
    padding-top: 6px;
    font-weight: normal;
  
    @include media-small {
      font-size: $text-small !important;
      line-height: $lh-extra-tight;
      padding-top: 6px;
    }
    
  }
}

.promotionalnotetakingModal__overlay__container .free-trial-notification__option li:last-child {
  span {
    line-height: $lh-extra-tight;
    font-size: $text-small;
    padding-top: 6px;
    font-weight: 600;
  
    @include media-small {
      font-size: $text-small !important;
      line-height: $lh-extra-tight;
      padding-top: 6px;
    }
    
  }
}
  
.notetakingmodal__delete {
  &:hover,&:focus {
    -webkit-filter: invert(50%) sepia(88%) saturate(2237%) hue-rotate(163deg) brightness(98%) contrast(101%);
    filter: invert(50%) sepia(88%) saturate(2237%) hue-rotate(163deg) brightness(98%) contrast(101%);
  }
}
   
.notetakingmodal__policy-container__terms-text__links {
  &:hover,&:focus {
    color: $color-linkHover;
  }
}

.guestnotetakingnotes +.notetaking__see-all-notes {
  color: $sn-primary-paragraph !important;
  &:hover,&:focus {
    color: $sn-primary-paragraph !important;
  }
}

.floating-note.hide-float-icon.nofear-set-float-icon {
  top: -563px!important
}