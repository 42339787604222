@include premiumFlag{
    .user-password-reset-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        &__container{
            flex: 1 0 auto;
            background-color: #e1faef;
        }
        &__header {
            color:$white;
            text-align: center;
            @include container($padding: true);
            padding-top: 40px;
            padding-bottom: 24px;
            &__title {
                color: inherit;
                font-size: $text-extra-big;
                font-weight: $fw-huge;
                line-height: $lh-tight;
            }
        }
        &__content {
            padding: 90px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            &-message {
                font-size: $text-base;
                color: $color-form-error;
                display: flex;
                flex-basis: 100%;
                justify-content: center;
                padding-bottom: 16px;
                @include media-small {
                    padding-bottom: 0;
                }
            }
            @include media-small {
                padding: 0 0 60px 0px;
            }
        }
        &__form{
            width: 100%;
            max-width: 520px;
            background-color: $white;
            //padding: 40px;
            border-radius: $radius-big;
        }
        &__invalidToken{
            width: 100%;
            max-width: 520px;
            background-color: $sn-primary-header;
            padding: 24px 40px 40px;
            border-radius: $radius-big;
            box-shadow: 0 24px 56px rgba(0,0,0,0.3);
            text-align: center;
            &__title{
                color: $white;
                line-height: $lh-tight;
                margin-bottom: .5em;
            }
            &__text{
                color: $white;
                line-height: $lh-base;
            }
        }
    }

    .new__password {
        &__container {
            width: 450px;
        }
    }
}

.form--setNewPassword {
    padding: 28px 40px 40px 40px;
    .fe-button {
        box-shadow: none;
    }
}

.snplus_header {
    width: 100%;
    background-color: #008262;
    vertical-align: middle;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    img {
        padding: 38px 0;
        width: 360px;
        @include media-small { 
            width: 100%;
        }
    }
}

.update_password {
    text-align: center;
    line-height: $lh-base;
    font-size: $text-big;
    font-weight: 800;
    color: #008262;
    padding-top: 32px;
    @include media-small { 
        font-size: $text-slightly-big;
    }
}

.confirm-pwd {
    .fe-tooltip {
        display: none !important;
    }
}

.resetPassword_error {
    color: $color-form-error;
    font-size: $text-base;
    line-height: $lh-base;
    margin-top: 8px;
}

.disabled_submit {
    opacity: 0.5;
    pointer-events: none;
}

.not-match {
    .fe-label-text--password {
        color: $color-form-error !important;
    }
    .fe-input-text {
        color: $color-form-error !important;
        background-color: #fbefed !important;
    }
    #snplus_confirm_password {
        border: 2px solid $color-form-error;
    }
    .fe-label{
        &:hover,
        &:focus-within {
            .fe-label-text{
                color: $color-form-error !important;
            }
        }
    }
}

.user-password-reset-page__form {
    @include media-small {
        .fe-tooltip__bubble {
            left: unset;
        }
    }
}
