.ad-page__container{
  @include container($padding: true);
  padding-top: 36px;
  padding-bottom: 36px;
}
.ad-page-stats {
  background-color: $color-brandBlue2020;
  color: $white;
  &__title {
    font-weight: $fw-fat;
    color: inherit; 
    margin-bottom: 36px;
  }
  &__stats{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    gap: 36px 16px;
  }
}
.ad-page-stat{
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 20px;
  &__image{
    height: 108px;
    display: flex;
    align-items: center;
    img{
      display: block;
      margin: 16px auto
    }
  }
  &__text{
    display: flex;
    flex-direction: column;
    font-size: $text-big; 
  }
  &__title{
    color: inherit;
    order: 2;
    font-size: $text-slightly-big;
    margin-top: 8px;
    line-height: $lh-tight;
  }

}

.ad-page-spiel {
  h2{
    margin-bottom: 24px;
  }
    li {
      list-style-type: disc;
      list-style-position: inside;
      line-height: $lh-base;
      margin-bottom: 0.5em;
    }
}


.ad-page-types{
  h2 {
    margin-bottom: 24px;
  }
  &__list{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 16px;
  }
  &__item{
    background: $color-teacherSoftGray;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    & > *{
      padding: 16px;
    }
    &__image{
      img{
        width: 100%;
        max-width: 300px;
        display: block;
      }
    }
    &__text{
      h3{
        margin-bottom: 8px;
      }
      p{
        font-size: $text-small
      }
    }
  }
}

.ad-page-contact {
  background-color: $color-brandBlue2020;
  color: $white;
  display: block;
  padding: 24px;
  border-radius: $radius-base;
  text-align: center;
  margin-bottom: 36px;
  &:hover,
  &:focus{
    background-color: $color-linkHover;
  }
}
