@mixin feCheckboxHover{
  .fe-label--wrap-checkbox:hover & {
    @content;
  }
}
@include premiumFlag{
  .fe-field{
    & + .fe-field{
      margin-top: 20px;
    }
  }

  .fe-buttons{
    display: flex;
    justify-content: center;
    margin-top: 32px;
    &:not(:last-child){
      margin-bottom: 40px;
    }
    &__container{
      display: grid;
      &--stacked{
        grid-gap: 12px;
      }
    }
  }

  .fe-label{
    display: flex;
    cursor: pointer;

    &--wrap-vertical{
      flex-direction: column;
      .fe-label-text{
        margin-bottom: 8px;
      }
    }
    &--wrap-checkbox{
      display: inline-flex;
    }
    &:hover,
    &:focus-within{
      .fe-label-text{
        color: #004371;
        &[aria-invalid='true'] {
          color: $color-form-error;
        }
      }
    }
  }

  .fe-label-text{
    line-height: $lh-tight;
    color: $sn-primary-header;
    transition: all 0.2s;
    &[aria-invalid='true'] {
      color: $color-form-error;
    }
  }

  .fe-input-text{
    @include removeInputStyles;
    flex-grow: 1;
    background-color: $color-teacherSoftGray;
    padding: 12px 20px;
    line-height: $lh-tight;
    border-radius: $radius-base;
    color: $sn-primary-header;
    transition: all 0.2s;
    &--password{
      padding-right: 48px // extra padding to account for show password button;
    }

    &:hover,
    &:focus{
      background-color: #FFFFFF;
      box-shadow: 0 10px 20px 0 RGB( 0 0 0 / 10% );
      outline: none;
    }

    &[aria-invalid='true']{
      color: $color-form-error !important;
      box-shadow: inset 0 0 0 2px $color-form-error;
      &:hover,
      &:focus{
        box-shadow: inset 0 0 0 2px $color-form-error, 0 10px 20px 0 RGB( 0 0 0 / 10% );
        outline: none;
      }
    }
   
    &::-ms-reveal  {
      display: none;
    }
  }

  .fe-error-message{
    font-size: $text-base;
    line-height: $lh-tight;
    color: $color-error;
    margin-top: 6px;
    color: $color-form-error;
  }

  .fe-error-list {
    font-size: $text-base;
    line-height: $lh-tight;
    color: $color-form-error;
    margin-top: 5px;
    margin-left: 9px;
    list-style-type: disc;
  }

  .fe-showPassword-wrap{
    display: flex;
    flex: 1;
    position: relative;
  }
  .fe-showPassword{
    @include removeBtnStyles;
    padding: 0;
    $size: 48px;
    width: $size;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &[aria-invalid='true'] {
      path {
      fill: $color-form-error;
      }
    }
    &:hover,
    &:focus{
      path{
        fill: #004371;
      }
    }
    [type='text'] + &{
      .fe-showPassword__icon--hidden{
        display: none;
      }
    }
    [type='password'] + &{
      .fe-showPassword__icon--revealed{
        display: none;
      }
    }
  }

  .fe-tooltip{
    position: relative;
    margin-left: 8px;
    &__trigger{
      @include removeBtnStyles;
      padding: 0;
      background-color: #fff;
      box-shadow: 0 0 0 1px $color-brandBlue2020;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      transform: translateY(0.15em);
      transition: box-shadow 0.2s;
      &[aria-invalid='true'] {
        box-shadow: 0 0 0 1px $color-form-error;
      }
      &[aria-invalid='true'] {
        path {
        fill: $color-form-error;
        }
      }
      &:before{
        content: '';
        position: absolute;
        display: block;
        width: 36px;
        height: 36px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
      &:hover,
      &:focus{
        background-color: $color-linkHover;
        box-shadow: 0 0 0 6px $color-linkHover;
        path{
          fill: #fff;
        }
      }
    }
    &__bubble{
      --feToolTipLeft: 20px;
      transform-origin: top left;
      position: absolute;
      display: block;
      z-index: 1;
      left: -10px;
      top: calc(100% + 12px);
      background-color: #fff;
      width: 100vw;
      max-width: 280px;
      color: $sn-primary-paragraph;
      font-size: $text-small;
      line-height: $lh-base;
      padding: 8px 12px;
      border-radius: $radius-base;
      filter: drop-shadow(0 0 20px rgba(#000, 0.15));
      strong{
        font-weight: $fw-chubby;
      }
      &:before{
        content: '';
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        bottom: 100%;
        left: var(--feToolTipLeft);
        border-style: solid;
        border-width: 0 7px 7px 7px;
        border-color: transparent transparent #fff transparent;
        transform: translateX(-50%)
      }
    }
  }

  .fe-buttons{
    display: flex;
    justify-content: center;
    &__container{
      display: grid;
      &--stacked{
        grid-gap: 12px;
      }
    }
  }
  .fe-button{
    @include removeBtnStyles;
    color: #fff;
    background-color: $color-brandBlue2020;
    padding: 12px 24px;
    line-height: $lh-tight;
    font-weight: $fw-chubby;
    border-radius: $radius-base;
    min-width: 224px;
    text-align: center;

    @include media-tablet-and-desktop{
      box-shadow: 0 10px 20px rgba( $color-brandBlue2020, 0.35);
    }

    &--minimal{
      color: $color-brandBlue2020;
      background-color: transparent;
      box-shadow: none;
    }
    &--loading{
      // background: red;
      pointer-events: none;
      position: relative;
      overflow: hidden;
      color: transparent;

      @include media-mobile {
        color: transparent !important;
      }

      @keyframes loader-animation {
        0% {
          transform: scaleX(0) translateX(0);
      }
      50% {
          transform: scaleX(0.5) translateX(50%);

      }

      100% {
          transform: scaleX(1) translateX(100%);
      }
    }
      &:before{
        animation: loader-animation 2s infinite;
        transform-origin: left;
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 4px;
        background: $color-linkHover;
        top: 0;
        left: 0;
      }
      &:after{
        content: attr(data-loadingText);
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
      }
    }
    &:hover, &:focus {
      background-color: #004371;
      color: #fff;
      box-shadow: 0 10px 20px rgba( $color-brandBlue2020, 0.35);
    }
    &:active{
      box-shadow: none;
    }
  }

  .form--sign-in  .fe-button--minimal {
    &:hover, &:focus {
      color: #004371!important;
      background-color: transparent!important;
      box-shadow: none!important;
    }
  }

  .fe-checkbox{
    @include visuallyhidden;
    display: inline-block;
    &-text{
      font-size: 13px;
      color: $sn-primary-paragraph;
      align-items: center;
      line-height: $lh-base;
      transition: all 0.2s;
      user-select: none;
      position: relative;
      display: flex;
      padding-right: 4px;
      @include feCheckboxHover{
        color: #004371;
      }
      &:before{
        margin-top: -0.0625em; // hack to line up checkbox better with the text.
        content: '';
        width: 24px;
        height: 24px;
        flex: none;
        border-radius: $radius-base;
        background-color: $color-teacherSoftGray;
        margin-right: 12px;
        transition: background 0.14s;
        background-image: url(/images/icon-checkbox-checked-24.svg);
        background-size: 0;
        @include feCheckboxHover{
          box-shadow: inset 0 0 0 1px $color-brandBlue2020;
        }
      }
      input:checked + & {
        &:before{
          background-color: $color-brandBlue2020;
          background-size: contain;
        }
      }
      input:focus + &{
        color: #004371;
        &:before{
          box-shadow: inset 0 0 0 1px $color-brandBlue2020; 
        }
      }
    }
  }

  .fe-fineprint{
    text-align: center;
    font-size: $text-small;
    line-height: $lh-base;
    color: $sn-primary-header;
    a{
      color: inherit;
      text-decoration: underline;
      &:hover{
        color: $color-linkHover;
      }
    }
  }
}
.error-msg-spacing {
  width: 200px;
  @include media-small {
    width: auto;
  }
}

.signin-tooltip .fe-tooltip__bubble {
  visibility: hidden;
}
.signin-tooltip:hover .fe-tooltip__bubble {
  visibility: visible;
}

.gentle-plea-modal {
  z-index: 0;
}