.hub-header{
  background-color: $color-brandBlue2020;
  overflow: hidden;
  max-width: 100vw;
  &__container{
    @include container($padding: true);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width:600px){
      flex-direction: column;
    }
  }
  &__text{
    color: $white;
    font-size: $text-very-big;
    line-height: $lh-tight;
    margin-top: 67px;
    margin-bottom: 60px;
    @media screen and (max-width:600px){
      text-align: center;
      margin-bottom: 24px;
    }    
  }
  &__title{
    font-weight: $fw-fat;
    color: inherit;
    font-size: inherit;
  }
  &__slogan{
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    margin-top: 0.9em;
    @media screen and (max-width:840px){
      font-size: $text-big-ish;
    }
    &--small{
      font-size: $text-base;
      line-height: $lh-base;
    }
  }
  &__image{
    display: block;
    align-self: flex-end;
    flex: 0 1 auto;
    @media screen and (max-width:600px){
      overflow: hidden;
      display: flex;
      align-self: normal;
    }
    &__img{
      display: block;
      width: 100%;
      @media screen and (min-width:1280px){
        margin-left: 12%;
      }
      @media screen and (max-width:600px){
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.hub-header--other-subjects {
  .hub-header__text{
    flex: 1 1 60%;
    @media screen and (max-width:800px){
      flex: 1 1 100%;
    }
  }
  .hub-header__image{
    @media screen and (max-width:600px){
      max-height: 240px;
    }
  }
  .hub-header__image__img{
    @media screen and (max-width:600px){
      object-fit: cover;
      object-position: top;
    }
    @media screen and (min-width:601px){
      transform: scale(1.1);
      max-height: 270px;
      object-fit: cover;
      object-position: bottom;
      margin-bottom: 2%;
      @media screen and (max-width:800px){
        transform: scale(1.5);
        transform-origin: bottom left;
      }
    }
  }
}

.hub-header--lit{
  background-image: linear-gradient(to right, $color-brandBlue2020 64%, #002869 64%);
  @media screen and (max-width:600px){
    background: #002869;
  }
  .hub-header__text{
    flex: 1 1 50%;
  }
  .hub-header__text__redesignHub {
    flex: 1 1 32%;
  }  
  .hub-header__image{
    flex: 1 1 75%;
    align-self: stretch;
    margin-right: -12px;
    @media screen and (max-width:600px){
      height: 240px;
      margin-left: -12px;
    }
  }
  .hub-header__image__img {
    height: 100%;
    object-fit: cover;
    object-position: left;
  }
}

@media only screen and (max-width: 600px) {  
  .hub-header__image {
    margin-left: -12px;
    margin-right: -12px;
  }
}

.hub-header--other-subjects {
  .hub-header__text__redesignHub {
    .hub-header__slogan {
      font-size: $text-slightly-big;
      line-height: $lh-base;
    }
    .mobile-sloganhub-header__slogan--small {
      display: none;
    }
  }
}

.hubSpecificSearch { //css//
  max-width: 1164px;
  margin: -26px auto 0 auto;

  @include media-small {
    padding-left: 8px;
    padding-right: 8px;
    margin: -20px auto 0 auto;
  }
  
  .lit_hub_search , .shakespeare_hub_search {
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 4 !important;
    width: 100%;
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    outline: 0;
    flex: 1;
    padding: 16px 102px 16px 16px;
    font-weight: $fw-chubby;
    color: $sn-primary-header;
    border: 2px solid transparent;
    &:focus {
      border: 2px solid $sn-primary-header;
    }

    @include media-small {
      padding: 16px 52px 16px 16px;
    }
  }

  .lit_search_icon {
    border: none;
    background: none;
    width: 32px;
    height: 32px;
    padding: 0;
    cursor: pointer;
    @include media-small {
      pointer-events: none;
      cursor: none;
    }
  }

  .lit_hub_search.active_search {
    border: 2px solid $sn-primary-header;
  }

  .lit_search_btn {
    display: flex;
    position: absolute;
    right: 11px;
    top: 11px;
    z-index: 9;
    align-items: center;
    align-self: stretch;
    -moz-column-gap: 12px;
    column-gap: 12px;
  }

  .clear_all_btn {
    color: #0066c4 !important;
    font-size: $text-base;
    line-height: $lh-base;
    cursor: pointer;
    display: none;
  }

  .clear_all_btn.showClearAll {
    display: block;
  }

  .litSearch {
    position: relative;
  }
  
  input::placeholder {
    font-weight: $fw-chubby;
    line-height: $lh-base;
    color: $color-a11yGray;
  } 
   
}

.dropdown-container {
  display: none;
  position: absolute;
  background-color: $white;
  padding: 1px;
  width: 100%;
  z-index: 4;
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, .2);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  &__topTitle, &__recentSearches {
    font-family: Raleway;
    font-size: $text-small;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: $lh-base;
    letter-spacing: normal;
    text-align: left;
    color: $sn-primary-header;
    padding: 16px 0px 0px 16px;
  }

  &__recentSearches {
    &__content-items {
      position: relative;
      padding: 11px 16px 11px 48px;
      font-size: $text-base;
      line-height: $lh-base;
      color: $sn-primary-paragraph;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-break: anywhere;

      &::before {
        content: url('/images/lit-hub-recent-search-icon.svg');
        position: absolute;
        left: 16px;
        top: 12px;
        width: 24px;
        height: 24px;
      }

      &:hover {
        background-color: $color-teacherSoftGray;
        color: #004371;
        text-decoration: underline;
        text-underline-offset: 2px;
        cursor: pointer;

        .dropdown-container__recentSearches__cross-icons {
          display: block;
        }
      }
    }

    &__cross-icons {
      display: none;
    }
  }
  &__litHub {
    margin: 1px;
    &__menu {
      margin-top: 1px;
      margin-bottom: 1px;
      &__text {
        display: block;
        width: 100%;
        font-family: Raleway;
        font-size: $text-base;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        text-align: left;
        color: $sn-primary-paragraph;
        padding: 11px 0px 11px 15px;
        &:hover, &:focus {
          background-color: $color-teacherSoftGray;
          color: #004371;
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }
  }

  &__mobileRecentSearch {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    &__clear {
      display: none;
      @include media-small {
        display: block;
        padding: 16px 16px 0 0;
        font-family: Raleway;
        font-size: $text-small;
        font-weight: normal;
        line-height: $lh-base;
        text-align: left;
        color: #0066c4;
        cursor: pointer;
        &:hover, &:focus {
          color: #004371;
        }
      }
    }
  }

}

.show-lithub-dropdown {
  display: block;
}

.lit-search-placeholder {
  position: relative;
  &__placeholder-text {
    position: absolute;
    top: 2px;
    padding: 14px 16px 14px 17px;
    background: $white;
    width: 100%;
    font-family: Raleway;
    font-size: $text-base;
    font-weight: $fw-chubby;
    font-stretch: normal;
    font-style: normal;
    line-height: $lh-base;
    letter-spacing: normal;
    text-align: left;
    color: $color-a11yGray;
    &__bold {
      font-weight: 700;
    }
  }
}

.hide-background-color {
  background-color: transparent !important;
}

.desktop-hub-search-placeholder {
  @include media-small {
    display: none;
  }
}

.dropdown-container__litHub__menu:last-child {
  @include media-small {
    display: none;
  }
}



.dropdown-container__recentSearches__content-items.highlight {
  background-color: $color-teacherSoftGray;
  color: #004371;
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor: pointer;

  .dropdown-container__recentSearches__cross-icons {
    display: block;
  }
}

// hero banner changes for mobile for lit hub
@include media-small {
  .hub-header.hub-header--lit.hubsearch {
    
    background-image: none;
    background: #002869;

    .hub-header__container {
      padding-left: 20.5px;
      padding-right: 20.5px;
    }
  
    .hub-header__image {
      display: none;
    }

    .hub-header__text {
      text-align: center;
      margin: 25px 0 44px 0;
    }

    .hub-header__title {
      font-weight: 800;
      color: $white;
      font-size: $text-very-big;
      line-height: $lh-base;
    }

    .hub-header__slogan {
      font-size: $text-big-ish;
      line-height: 1.22;
    }
  }
}

.dropdown-container__recentSearches__content-text {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}