.snplusmodal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: rgba($sn-primary-header, 0.75);
    @include media-small{
      overflow-x: hidden;
    }
    @include media-notSmall{
      padding-top: 120px;
      padding-top: min(120px, 4vh);

    }
    z-index: 11; // one more than mobile slideout (reduce this when we refactor that slideout)
    overflow-y: auto;
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  .snplusmodal__container {
    width: 100%;
    border-top-left-radius: $radius-big;
    border-top-right-radius: $radius-big;
    @include media-small{
      box-shadow: 0 -10px 30px 0 rgba(0,0,0,0.20);
      height: calc(100% - 24px);
      align-self: flex-end;
    }
    @include media-notSmall{
      max-width: 576px;
      border-bottom-left-radius: $radius-big;
      border-bottom-right-radius: $radius-big;
      box-shadow: 0 10px 30px 0 rgba(0,0,0,0.20);
      overflow: hidden;
      background-color: $white;
    }
  }

  .snplusmodal__header {
    background-color: #008262;
    padding-bottom: 36px;
    color: $white;
    border-radius: 12px 12px 0 0;
    &__container{
      display: flex;
      flex-wrap: wrap;
      @include media-very-tiny {
        flex-wrap: unset!important;
      }

    }
    &__content{
      flex: 1;
      padding-left: 60px;
      text-align: center;
      @include media-tiny {
        padding-left: 20px;
      }
      @include media-very-tiny {
        padding-left: 0px!important;
      }
    }
  }

  .snplusmodal__title {
    margin-top: 30px;
    font-weight: $fw-chubby;
    font-size: $text-slightly-big;
    text-align: center;
    color: $white;
    line-height: $lh-tight;
    &__sub{
      line-height: $lh-base;
      margin-top: 8px;
    }
    img {
      @include media-small {
        width: 240px;
      }
    }
  }

  .snplusmodal__close {
    @include removeBtnStyles;
    width: 36px;
    height: 36px;
    margin: 12px;
    @include media-very-tiny {
      margin: 12px 0;
    }
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:focus{
      // svg{
      //   filter: drop-shadow(0 0 12px $color-linkHover );
      // }
      path{
        fill: $sn-primary-paragraph;
      }
    }
    span {
      display: inline-block;
    }
  }

  .snplusmodal {
    display: none;
  }
  .snplusmodal.is-open {
    display: block;
  }

  .snplusmodal .snplusmodal__container,
  .snplusmodal .snplusmodal__overlay {
    will-change: transform;
    z-index : 100;
  }

  .snplusmodal[aria-hidden="false"] .snplusmodal__overlay:after {
    animation: modalFadeIn .12s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .snplusmodal[aria-hidden="false"] .snplusmodal__container {
    @include media-small{
      animation: modalSlideUpIn .4s ease-out;
    }
    @include media-notSmall{
      animation: modalGrowIn .2s cubic-bezier(0, 0, .2, 1);
    }
  }

  .snplusmodal[aria-hidden="true"] .snplusmodal__overlay:after {
    animation: modalFadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .snplusmodal[aria-hidden="true"] .snplusmodal__container {
    @include media-small{
      animation: modalSlideDownOut .4s ease-in;
    }
    @include media-notSmall{
      animation: modalGrowOut .3s cubic-bezier(0, 0, .2, 1);
    }
  }


  .snplusmodal__content {
    .snplusmodal__tabbed {
      margin-top: -1px;
      &__tabs {
        background: #008262;
        display: flex;
        justify-content: center;
        padding: 0 60px;
        box-shadow: 0 $radius-big 0 0 #008262;
        @include media-small {
          padding: 0 10px;
        }

        &__tab {
          font-size: $text-small;
          flex: 1 1 50%;
          max-width: 200px;
          text-align: center;
          padding: 12px 23px;
          display: block;
          background-color: rgba($white,.35);
          color: $white;
          border-radius: $radius-big $radius-big 0 0;
          margin:0 6px;
          line-height: $lh-tight;

          &[aria-selected] {
            background-color: $white;
            color: #004a3e;
            font-weight: $fw-chubby;
          }
          &:not([aria-selected]):hover{
            background-color: rgba($white, 0.85);
            color: #004371;
            box-shadow: 0 -5px 7px -2px rgba(0,0,0,0.3);
          }
          @include media-small {
            min-width: 150px;
          }
        }
      }

      &__content{
        background-color: $white;
        z-index: 1;
        padding: 32px 40px;
        border-radius: $radius-big $radius-big 0 0;
        @include media-small {
          border-radius: unset;
        }
        position: relative;
        &:focus{
          outline: none;
        }
        &[hidden]{
          display: none;
        }
        &:before{
          content: '';
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          border-radius: $radius-big $radius-big 0 0;
          width: 100%;
          height: $radius-big;
          background-color: $white;
        }
      }
    }

    &__untabbed{
      z-index: 1;
      padding: 32px 40px;
      box-shadow: inset 0px 12px #008262;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        border-radius: $radius-big $radius-big 0 0;
        width: 100%;
        height: $radius-big;
        background-color: $white;
      }
    }

    &__poppedIcon{
      @keyframes icon{
        from{
          opacity: 0;
          transform: scale(0);
        }
        to{
          opacity: 1;
          transform: scale(1);
        }
      }
      @keyframes content {
        from{
          opacity: 0;
          transform: translateY(80px);
        }
        to{
          opacity: 1;
          transform: translateY(0);
        }
      }
      &__header{
        background-color: #008262;
        display: flex;
        justify-content: center;
        position: relative;
        &__icon{
          animation: content 0.4s;
          width: 140px;
          height: 140px;
          border-radius: 50%;
          background-color: $white;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
          img{
            animation: icon 0.4s;
            width: 156px;
          }
        }
        &:before{
          position: absolute;
          display: block;
          content:'';
          width: 100%;
          height: 50%;
          top: 50%;
          background-color: $white;
        }
      }
      &__content{
        animation: content 0.4s;
        padding:  12px 40px 40px;
        text-align: center;
        background-color: $white;
      }
      &__content-change-plan {
        animation: content 0.4s;
        padding:  12px 40px 40px;
        @include media-mobile {
          padding: 12px 24px 24px;
        }
      }
    }
}
.note-taking-modal {
  .snplusmodal__header {
    height: 54px;
    padding: 12px 12px 16px 16px;
    background-color: #e5e5e5;

    @include media-small {
      display: none;
    }
  }
  .snplusmodal__header__container {
    justify-content: space-between;
    flex-wrap: unset;
    flex-direction: row-reverse;
  }
  .snplusmodal__header__content {
    flex: unset;
    padding-left: unset;
  }
  .snplusmodal__ql-tool {
    display: flex;
    gap: 16px;
    border: none !important;
    padding: 8px 8px 8px 0px !important;
  }
  .snplusmodal__ql-style {
    padding: 0px !important;
    &:focus-visible {
      outline: auto;
    }
  }
  .snplusmodal__close{
    margin: 0px;
  }
  .snplusmodal__container{
    background-color: #e5e5e5;
      @include media-small {
        width: 100%;
        height: auto;
        border-radius: 12px !important;
        margin-top : 24px;
        align-self: flex-start;
        //top: 1%; // changed bottom 30% to top 1% because this bottom 30% hiding buttons in smaller devices
      }
  }
  .snplusmodal__close {
    path{
      fill: $sn-primary-header;
    }
    &:focus{
      svg{
        filter: none !important;
      }
    }
  }
}
.shuffle-deck-modal {
  .snplusmodal__container {
    width: 473px;
    height: 281px;
    position: absolute;
    top: 200px;
    left: 32%;
    background-color: $white;
    border-radius: 12px;
    @include media-small {
      border-radius: 12px;
      left: 10%;
      width: 80%;
      top: 20%;
      height: 282px;
      box-shadow: none;
    }
  }
  .snplusmodal__header {
    height: 81px;
    border-radius: 0;
    border: 3px solid #008262;
    @include media-small {
      border-radius: 11px 11px 0 0px;
    }
  }
  .snplusmodal__header__content {
    padding-left: 20px;
    h2 {
      text-align: left;
      margin-left: 0px;
      width: 137px;
      font-size: 21px;
      margin-top: 25px;
    }
  }
}
//additional styling for cancel-subscription/update card/remove card modal
//need this code for other updation of models
/*.cancel-subscription-modal {
  .snplusmodal__container {
    width: 461px;
  }
  .snplusmodal__header {
    background-color: $white !important;
    margin-bottom: 33px;
    padding-bottom: 10px;
    border-bottom: 1px solid $sn-border-gray;
  }
  .snplusmodal__close {
    path{
      fill: $sn-primary-header;
    }
    &:hover,
    &:focus{
      svg{
        filter: none !important;
      }
      path{
        fill: $color-linkHover;
      }
    }
  }
}*/
.btn-spacing {
  margin-top: 60px;
}

.update-card-container{
  // height: 985px;  //this height not required so commented however kept commented for future reference
  .subsModal-error-message {
    margin: 0px !important;
  }
}

.form-updatecard-details {
  padding: 20px 40px;
  margin-bottom: 100px;
  .credit-card-btn {
    width: 300px;
  }
  @include media-small {
    background-color: $white;
    position: relative;
  }
}

//styling for changeplan-promo-modal and renew-subscription-modal
.change-plan-container {
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  @include media-small {
    padding-left: 35px;
    padding-right: 35px;
    background-color: $white;
    border-radius: 0 0 12px 12px;
  }
}

.plan-heading {
  padding-top: 20px;
  background-color: $white;
  &__change-heading{
    text-align: center;
    font-size: $text-big;
    font-weight: $fw-chubby;
    color: #008262;
    line-height: 1.6;
  }
}
.modal-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 30px;
}
.plan-container {
  width: 199px;
  height: 105px;
  position: relative;
  @include media-small {
    width: 136px;
    height: 125px;
  }
  &:first-child {
    margin-right : 10px;
  }
}
.change-plan-radio {
  -webkit-appearance: none;
  appearance: none;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  position: absolute;
  cursor: pointer;
  outline: none;
  background-color: #e1faef;
  opacity: 0.3;
  cursor: pointer;
  outline: none;
  border: 2px solid #00cc9a;
}
.planned-card {
  position: absolute;
  cursor: pointer;
  opacity: 0.3;
  width: 199px;
  height: 105px;
  @include media-small {
    width: 136px;
    height: 125px;
  }
}
.change-plan-radio:checked + .planned-card {
  opacity: 1;
}
.change-plan-radio:checked {
  border: 2px solid #00cc9a;
  opacity: 1;
}
.bill-month {
  text-align: center;
  padding: 16px 10px 0 16px;
  font-size: $text-tiny;
  color: #004a3e;
  @include media-mobile {
    padding: 18px 10px 0px 16px;
  }
}
.bill-rate {
  font-size: $text-big;
  font-weight: bold;
}
.bill-annual {
  text-align: center;
  padding: 16px 10px 0 16px;
  font-size: $text-tiny;
  color: #004a3e;
  @include media-mobile {
    padding: 18px 10px 0px 16px;
  }
}
.bill-best-value {
  position: absolute;
  top: -10px;
  left: 57px;
  text-align: center;
  display: block;
  border: 1px solid #41ffaa;
  padding: 6px 7px 6px 7px;
  border-radius: 24px;
  background: #41ffaa;
  font-size: $text-tiny;
  font-weight: $fw-chubby;
  z-index: 11;
  color: #004a3e;
  @include media-small {
    left: 28px;
  }
}
.current-plan {
  text-align: center;
  margin-top: 10px;
  &__plan-text {
    font-size: $text-small;
    font-weight: $fw-chubby;
  }
}
.plan-renewal-type {
  margin-top: 40px;
  &__detail-text{
    font-size: $text-base;
    line-height: 1.6;
    text-align: left;
    color: #008262;
  }
}
.renew-sub-display {
  display: inline-block;
}
.subscription-renewal-type {
  display: block;
  margin-top: 40px;
  &__detail-text{
    font-size: $text-base;
    line-height: 1.6;
    text-align: left;
    color: #008262;
  }
  &__choosing-plan{
    font-size: $text-base;
    line-height: 1.6;
    text-align: left;
    margin-bottom: 30px;
    color: #008262;
  }
}
.activeplan-color {
  color: $color-brandBlue2020;
}
.break-word {
  @include media-mobile {
    position: relative;
    top: -10px;
    &::before {
      content: "\A";
    }
  }
}
.plan-select {
  text-align: center;
  font-size: $text-base;
  font-weight: $fw-chubby;
  color: #004a3e;
  padding-top: 3px;
  padding-left: 10px;
}
.error-change-plan{
  &__error-msg {
    background-color: $color-form-error;
    color: $white;
    text-align: center;
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
    margin-right: -40px;
    margin-left: -40px;
    margin-top: 10px;
    &__logo {
    padding-top: 5px;
    }
    &__text {
    text-align: left;
    margin-left: 15px;
    line-height: 1.5;
    }
    &[hidden]{
    display: none !important;
    @include media-mobile {
        display: none !important;
    }
    }
  }
}
.modal-btnContainer {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   @include media-mobile {
    flex-direction: column-reverse;
   }
  &__btn-items {
    padding: 5px;
    @include media-mobile {
        flex-direction: column;
        .btn-container div:nth-of-type(1) {
            margin-top: 5px !important;
        }
    }
  }
}
.btn-change-plan {
  margin-left: 12px;
}
.confirm-btn {
  background-color: $color-brandBlue2020;
  color: $white;
}
.cancel-btn {
  border: 2px solid $color-brandBlue2020;
  padding: 10px 24px;
}

//styling for cancel-subscription/update card/remove card modal content.
.cancel-sub {
  &__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    background-color: $white;
    border-radius: 0 0 12px 12px;
  }
  &__error-msg {
    background-color: $color-form-error;
    color: $white;
    text-align: center;
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
    margin-bottom: 25px;
    margin-left: -40px;
    margin-right: -40px;
    margin-top: -32px;
    &__logo {
      padding-top: 5px;
    }
    &__text {
      text-align: left;
      margin-left: 15px;
      line-height: 1.5;
    }
    &[hidden]{
      display: none !important;
      @include media-mobile {
          display: none !important;
      }
    }
  }
  &__content {
    text-align: center;
    font-size: $text-base;
    font-weight: $fw-base;
    line-height: 1.6;
    text-align: left;
    color: $sn-primary-paragraph;
    margin-top: 20px;
    ul {
      li {
        display: flex;
        span {
          font-weight: $fw-chubby;
        }
      }
    }
  }
  &__btncontainer {
    margin-bottom: 48px;
    @include media-small {
      margin-bottom: 40px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-small {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
.keepsn-btn {
  width: 222px;
  height: 46px;
  background-color: #0066c4;
  color: $white;
  font-size: $text-base;
  font-weight: $fw-chubby;
  margin-left: 24px;
  @include media-small {
    margin-left: 0px;
    width: 300px;
  }
}
.cancelsub-btn {
  border: 1px solid $color-brandBlue2020;
  color: $color-brandBlue2020;
  width: 222px;
  height: 46px;
  font-size: $text-base;
  font-weight: $fw-chubby;
  @include media-small {
    width: 300px;
  }
}

.country-not-available-msg .choose-plan .payment-details-container .payment-details-container1 .payment-summary-container1 .payment-summary-container .amex-icon .dinners-icon .discover-icon .jcb-icon .master-icon .visa-icon {
  &[hidden]{
    display: none;
  }
}

//create-account
.modal-content-container {
  @include media-small {
    margin: -16px;
  }
}
.form-container {
  margin-top: 30px;
}
.title-container {
  margin-bottom: 16px;
  display: flex;
  &__steps {
    height: 25px;
    font-size: $text-tiny;
    font-weight: $fw-base;
    color: $sn-primary-paragraph;
    display: flex;
    align-content: center;
    justify-content: center;
    margin-right: 10px;
  }
  &__create-your-account {
    height: 30px;
    font-size: $text-slightly-big;
    font-weight: $fw-chubby;
    color: #004a3e;
    margin-top: -3px;
    line-height: 1.8;
    display: flex;
    flex-direction: column;
  }
}
.free-trial-msg-container {
  margin-left: -40px;
  margin-right: -40px;
  padding: 20px 38px;
  background-color: #e1faef;
}
.ap-new-icon{
  svg{
    height: 48px;
    width: 48px;
  }
}
.free-trial-notification {
  font-size: $text-base;
  font-weight: $fw-base;
  line-height: 1.6;
  color: #004a3e;
  &__group-discount {
    font-size: $text-small;
    font-weight: $fw-chubby;
    margin-top: 18px;
    text-align: center;
    span {
      @include media-small {
        display: none;
      }
    }
    a {
      &:hover, &:focus {
        color:#004371;
      }
    }
  }
  &__text {
    padding-bottom: 15px;
    flex-direction: column;
    text-align: center;
    @include media-small {
      padding-bottom: 0px;
      margin-bottom: 20px;
    }
    &__title {
      font-size: $text-slightly-big;
      font-weight: $fw-fat;
      line-height: 1.6;
      color: #004a3e;
    }
    &__content {
      line-height: 2;
      color: #004a3e;
    }
  }
  &__option {
    width: 100%;
    overflow: hidden;
    padding: 0px 10px 0px 10px;
    @include media-small {
      padding: unset;
    }
    li:nth-child(1):focus-within, li:nth-child(2):focus-within, li:nth-child(3):focus-within, li:nth-child(4):focus-within {
      svg {
        circle:first-child {
          fill: #004371;
        }
        path {
          fill: $white;
        }
      }
      .free-trial-notification__option__link {
        color: #004371 !important;
      }
    }

    li:nth-child(1):hover, li:nth-child(2):hover, li:nth-child(3):hover, li:nth-child(4):hover {
      svg {
        circle:first-child {
          fill: #004371;
        }
        path {
          fill: $white;
        }
      }
      .free-trial-notification__option__link {
        color: #004371 !important;
      }
    }
    
    li:nth-child(5):hover {
      svg {
        circle:first-child {
          fill: #004371;
          stroke: #004371;
        }
        rect {
          fill: $white;
        }
      }
      .free-trial-notification__option__link {
        color: #004371 !important;
      }
    }

    li:nth-child(5):focus-within {
      svg {
        circle:first-child {
          fill: #004371;
          stroke: #004371;
        }
        rect {
          fill: $white;
        }
      }
      .free-trial-notification__option__link {
        color: #004371 !important;
      }
    }

    li{
      :hover{
        color: $sn-brand-blue!important;
        font-family: Raleway;
        font-weight: normal;
        cursor: pointer;
      }
      padding: 2px 0px;
      // display: flex;
      float: left;
      width: 20%;
      text-align: center;
      color: $plus-green-dark!important;
      font-family: Raleway;
      font-weight: normal;
      cursor: pointer;
      // p {
      //   color: #004a3e;
      //   line-height: 1.6;
      // }
      span{
        padding-top: 5px;
        text-align: center;
        clear: both;
        // display: inline-block;
        color: $plus-green-dark!important;
        line-height: 1.25;
        font-size: 14px;
        font-weight: normal;
        @include media-small {
          font-size: 12px!important;
          font-weight: normal;
          :hover{
            font-weight: normal;
          }
        }
        @include media-tiny {
          font-size: 10px!important;
          :hover{
            color: $sn-brand-blue!important;
            font-family: Raleway;
            font-weight: bold;
            cursor: pointer;
          }
      }
      a{
        font-weight: 600;
        // color: $color-brandBlue2020;
        color: $plus-green-dark!important;
      }
      a:hover{
        font-weight: 600;
        // color: $sn-brand-blue;
      }
    }
  }
  li:last-child {
    :hover{
      color: #004371 !important;
      font-family: Raleway;
      font-weight: 600;
      cursor: pointer;
    }
    padding: 2px 0px;
    // display: flex;
    float: left;
    width: 20%;
    text-align: center;
    color: #0066c4 !important;
    font-family: Raleway;
    font-weight: 600;
    cursor: pointer;
    // p {
    //   color: #004a3e;
    //   line-height: 1.6;
    // }
    span{
      padding-top: 5px;
      text-align: center;
      clear: both;
      // display: inline-block;
      color: #0066c4 !important;
      line-height: 1.25;
      font-size: 14px;
      font-weight: 600;
      @include media-small {
        font-size: 12px!important;
        font-weight: normal;
        :hover{
          font-weight: normal;
        }
      }
      @include media-tiny {
        font-size: 10px!important;
        :hover{
          color: #004371 !important;
          font-family: Raleway;
          font-weight: bold;
          cursor: pointer;
        }
    }
    a{
      font-weight: 600;
      // color: $color-brandBlue2020;
      color: $plus-green-dark!important;
    }
    a:hover{
      font-weight: 600;
      // color: $sn-brand-blue;
    }
  }
}
    &__link {
      // color: $color-brandBlue2020;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: $fw-chubby;
    }
    &__link:hover {
      // color: $sn-brand-blue;
      font-weight: $fw-chubby;
    }
    &__login-link {
      font-weight: $fw-chubby;
    }
    &__link:hover {
      // color: $sn-brand-blue;
      font-weight: $fw-chubby;
    }
  }
}
.already-have-account {
  height: 25px;
  margin-top: 24px;
  font-size: $text-small;
  font-weight: $fw-base;
  line-height: 1.6;
  text-align: center;
  color: $sn-primary-paragraph;
  padding-bottom: 36px;
}
.created-account {
  cursor: pointer;
  color: #0066c4;
  font-weight: 800;
  &:hover,&:focus {
    color: #004371;
  }
}
.on-focus:focus {
  outline: none;
  border-radius: $radius-base;
  box-shadow: 0 0 3px 2px #004371;
}
.checkbox-focus {
  input:focus-visible + & {
    outline: 1px solid $sn-black !important;
  }
}
.steps-done {
  display: flex;
  border: 1px solid $sn-border-gray;
  border-left: 0px;
  border-right: 0px;
  align-items: center;
  height: 94px;
  &__dull-msg {
    &__tick {
      padding-left: 8px;
      padding-top: 2px;
    }
  }
  &__edit-option {
    margin-left: auto;
    margin-bottom: 16px;
    &__btn {
      font-size: $text-small;
      font-weight: $fw-chubby;
      color: #0066c4;
      margin-right: 30px;
      cursor: pointer;
      @include media-small {
        margin-right: 10px;
      }
      &:hover, &:focus {
        color: #004371;
      }
      &:focus-visible {
        outline: none;
        box-shadow: 0 0 1px 1px $sn-black;
      }
    }
  }
  &[hidden]{
    display: none;
    @include media-mobile {
        display: none !important;
    }
  }
}
.choose-plan-btn {
  width: 312px;
  box-shadow: none !important;
  &:disabled {
    opacity: 0.5;
    &:hover {
      background-color: none !important;
    }
  }
}
.placeholders-container {
  div {
    border-top: 1px solid $sn-border-gray;
    padding-top: 8px;
    display: flex;
    height: 70px;
    align-items: center;
    padding-bottom: 13px;
    border-left: 0;
    border-right: 0;
  }
  &__hide-border {
    border-bottom: 0px !important;
  }
}
.create-account-step-done {
  border-top: 0px;
  margin-top: -10px;
}
.auto-renew-msg {
  display: flex;
  &[hidden]{
    display: none !important;
  }
}
.user-details {
  font-weight: normal;
  font-size: $text-small;
  color: $sn-primary-paragraph;
}
.renew-expired-card {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  color: #c51c00;
  margin-left: 10px;
  margin-top: 2px;
}
.renew-no-card {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  color: #c51c00;
  margin-top: 2px;

}
.radio-card1 {
  @media screen and (max-width: 281px) {
    width: unset !important;
    height: unset !important;
  }
}
.plan-request1 {
  @media screen and (max-width: 281px) {
    margin-top: -20px;
  }  
}
.payment-details-step-done1{
  @include media-small {
    padding-bottom: 24px;
  }
}
.final-renew-sub:disabled {
  cursor: no-drop;
}
.user-card , .user-card1 {
  display: flex;
}
.card-brand-holder {
  height: 30px;
  width: 44px;
  margin-right: 10px;
  img {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
  }
}
//choose plan
//color code used in choose your plan in create acoount plan tab
$plan-price-color: #004a3e;
.choose-plan {
  margin-top: 25px;
  margin-bottom: 25px;
  &__container {
    display: flex;
    &__steps {
      font-size: $text-tiny;
      margin-right: 10px;
    }
    &__plan-type {
      font-size: $text-slightly-big;
      font-weight: $fw-chubby;
      margin-top: -3px;
      color: #004a3e;
    }
  }
  .radio-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 18px;
    @include media-small {
      justify-content: space-evenly;
      margin-left: -15px;
    }
  }
  .card {
    width: 199px;
    height: 105px;
    position: relative;
    border: none!important;
    @include media-small {
      width: 136px;
      height: 125px;
    }
    &:first-child {
      margin-right : 24px;
    }
  }

  .account-plan-radio {
    -webkit-appearance: none;
    appearance: none;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    position: absolute;
    cursor: pointer;
    outline: none;
    background-color: #e1faef;
    opacity: 0.3;
    border: 2px solid #00cc9a;
  }
  .account-plan-radio:before {
    content: '';
    position: absolute;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    top: 35px;
    right: 20px;
  }
  .account-plan-radio:after {
    content: '';
    position: absolute;
    height: 13px;
    width: 13px;
    background-color: transparent;
    border-radius: 50%;
    top: 39.5px;
    right: 24.5px;
  }

  .radio-card {
    position: absolute;
    cursor: pointer;
    opacity: 0.3;
    width: 199px;
    height: 105px;
    @include media-small {
      width: 136px;
      height: 125px;
    }
  }

  .account-plan-radio:checked + .radio-card {
    opacity: 1;
  }

  .account-plan-radio:checked {
    border: 2px solid #00cc9a;
    opacity: 1;
  }

  .account-plan-radio:focus-visible {
    border: 2px solid $sn-black;
    opacity: 1;
  }

  .best-value {
    position: absolute;
    top: -14px;
    left: 57px;
    text-align: center;
    display: block;
    border: 1px solid #41ffaa;
    padding: 6px 7px 6px 7px;
    border-radius: 24px;
    background: #41ffaa;
    font-size: $text-tiny;
    font-weight: $fw-chubby;
    z-index: 11;
    color: $plan-price-color;

    @include media-small {
      left: 28px;
    }

  }

  .annual-price {
    text-align: center;
    padding: 27px 10px 0px 16px;
    font-size: $text-tiny;
    margin-top: -11px;
    color: $plan-price-color;
    @include media-mobile {
      padding: 29px 10px 0px 16px;
    }
  }

  .month-price {
    text-align: center;
    padding: 16px 10px 0px 16px;
    font-size: $text-tiny;
    color: $plan-price-color;
    @include media-mobile {
      padding: 18px 10px 0px 16px;
    }
  }

  .year-price {
    font-size: $text-big;
    font-weight: bold;
  }

  .word-break {
    @include media-mobile {
      position: relative;
      top: -10px;
      &::before {
        content: "\A";
      }
    }
  }
  .plan-request {
    text-align: center;
    font-size: $text-base;
    font-weight: $fw-chubby;
    color: $plan-price-color;
    padding-top: 3px;
    padding-left: 10px;
  }
  .plan-description {
    display: flex;
    flex-direction: column;
    text-align: center;
    &__annual-save {
      font-size: $text-base;
      line-height: 1.6;
      color: $sn-primary-paragraph;
      margin: 24px 30px 0px 40px;
      @include media-small {
        margin: 24px 0px 0px 0px;
      }
    }
    &__continue-payment {
      width: 312px;
      box-shadow: none !important;
    }
  }
}

.gd-continue-pay:disabled {
  opacity: 0.5;
}

.new-gd-continue-pay:disabled {
  opacity: 0.5;
}

//payment-details
.payment-details-container {
  margin-top: 25px;
  margin-bottom: 25px;
}
.wrap-up-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  &__first-child {
    margin-top: 20px;
  }
  @include media-small {
    display: block;
    .wrap-up-content-child {
      margin-top: 20px;
    }
  }
}

.custom-wrap-content {
  margin-bottom: -6px !important;
}

.custom-credit-wrap-content {
  margin-bottom: 14px !important;
}

.cardicon-holder {
  right: 0;
  margin-top: 35px;
  position: absolute;
  margin-right: 50px;
  @include media-small {
    margin-right: 35px;
  }
}
.credit-card-icons {
  @include media-small {
    margin-right: 45px !important;
  }
}
.expand-section {
  right: 0;
  margin-top: 35px;
  position: absolute;
  margin-right: 50px;
  @include media-small {
    margin-right: 35px;
  }
}
.security-tooltip {
  position: absolute;
  margin-left: 120px;
  margin-top: 2px;
  content: url("/images/icon-security-tooltip.svg");
}
.security-tooltip:hover {
  content: url("/images/icon-security-onhover-tooltip.svg");
  margin-top: -83px !important;
  margin-left: 17px !important;
}
.payment-details-focus:focus , .update-card-postal-code:focus {
  outline: none;
  border-radius: $radius-base;
  box-shadow: 0 0 3px 2px #004371;
}
.custom-dropdown1, .custom-dropdown3 {
  margin-top: 15px;
  font-family: unset !important;
}
.save-card-btn {
  width: 300px;
  &:disabled {
    opacity: 0.5;
    &:hover {
      background-color: $color-brandBlue2020 !important;
      cursor: no-drop;
    }
  }
}

#renew-update-card {
  &:disabled {
    opacity: 0.5;
    &:hover {
      background-color: $color-brandBlue2020 !important;
      cursor: no-drop;
    }
  }
}
.renew-update-card-cta-container {
  justify-content: space-around !important;
  @include media-small {
    flex-direction: column-reverse;
  }
}
.renew-cancel-btn {
  background-color: white;
  color: #007acd;
  border: 2px solid $color-brandBlue2020;
  min-width: 200px !important;
  font-size: 16px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: unset !important;
  &:hover {
    border: 2px solid $color-linkHover;
  }
}
.renew-update-card {
  min-width: 200px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: unset !important;
  @include media-small {
    margin-bottom: 24px;
  }
}

//payment summary
.payment-summary-container {
  margin-top: 25px;
  margin-bottom: 25px;
}
.payment-summary {
  margin: 15px 0px 0px 0px;
  &__summary-details {
    background-color: #f5f1f5;
    padding: 17px 16px 17px 16px;
    border-radius: 4px;
    margin-bottom: 24px;
    @include media-mobile {
      padding: 17px 8px 17px 8px;
    }
    &__sparknotes {
      // display: flex;
      // justify-content: space-between;
      &__plan-type {
        font-size: $text-small;
        line-height: 1.6;
        color: $sn-primary-paragraph;
        text-align: left;
        float: left;
        display: inline-block;
      }
      &__price {
        font-size: $text-small;
        line-height: 1.6;
        color: $sn-primary-paragraph;
        text-align: right;
        float: right;
        display: inline-block;
      }
    }
    &__change-plan {
      margin-top: 7px;
      &__change-plan-text {
        font-size: $text-small;
        font-weight: $fw-chubby;
        line-height: 1.6;
        text-align: left;
        color: $color-brandBlue2020;
        cursor: pointer;
        float: left;
        display: inline-block;
      }
      &__change-plan-text:hover {
        color: $sn-brand-blue;
        cursor: pointer;
      }
      &__trail-end {
        font-size: $text-small;
        line-height: 1.6;
        text-align: left;
        color: $sn-primary-paragraph;
        padding-top: 25px;
        &__bold {
          font-weight: 600;
        }
      }
    }
    &__price-details {
      border-top: 1px solid rgba(0, 0, 0, 0.25);
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      padding-top: 23px;
      padding-bottom: 14px;
      margin-top: 23px;
      margin-bottom: 23px;

      &__statements {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        &__trial-day {
          font-size: $text-slightly-big;
          line-height: 1.6;
          text-align: left;
          font-weight: $fw-chubby;
        }
        &__renew-date {
          font-size: $text-small;
          font-style: italic;
          line-height: 1.6;
          text-align: left;
        }
        &__trial-price {
          font-size: $text-small;
          font-style: italic;
          line-height: 1.6;
          text-align: right;
        }
        &__group-trial {
          font-size: $text-small;
          font-style: italic;
          line-height: 1.6;
          text-align: right;
        }
        &__tax {
          font-size: $text-small;
          line-height: 1.6;
          text-align: left;
        }
        &__tax-rate {
          font-size: $text-small;
          line-height: 1.6;
          text-align: right;
        }
        &__due {
          font-size: $text-slightly-big;
          line-height: 1.6;
          text-align: left;
          font-weight: $fw-chubby;
        }

        &__due-rate {
          font-size: $text-slightly-big;
          line-height: 1.6;
          text-align: right;
          font-weight: $fw-chubby;
        }

        &__discount {
          font-size: $text-small;
          font-style: italic;
          line-height: 1.6;
          text-align: left;
        }

        &__discount-amount {
          font-size: $text-small;
          font-style: italic;
          line-height: 1.6;
          text-align: left;
        }
      }
      &__last-statement {
        display: flex;
        justify-content: space-between;
        &__total {
          font-size: $text-slightly-big;
          line-height: 1.6;
          text-align: left;
          font-weight: $fw-chubby;
        }
        &__total-rate {
          font-size: $text-slightly-big;
          line-height: 1.6;
          text-align: right;
          font-weight: $fw-chubby;
        }
      }
    }

    &__promo {
      &__promo-code {
        font-size: $text-base;
        line-height: 1.19;
        text-align: left;
        display: flex;
        &__text {
          font-weight : 600;
          align-self: center;
        }
        &__text-mini {
          font-size : 14px;
          align-self: center;
          margin-left: 4px;
        }
        &__image {
          width: 24px;
          height: 24px;
          align-self: center;
        }
        &:before {
          background-image: url('/images/icon-promo-code.png');
        }
      }
      &__code-container {
        display: flex;
        justify-content: space-between;
        &__promo-field {
          margin-top: 8px;
          &__code-field {
            width: 268px;
            border: 0;
            height: 42px;
            border: 1px solid #e5e5e5;
            outline: 0;
            font-size: $text-base;
            color: $sn-primary-paragraph;
            padding: 9px 16px 9px 16px;
            -webkit-transition: border-color .2s;
            transition: border-color .2s;
            border-radius: 4px;
            @include media-mobile {
              width: 97%;
            }
            &[placeholder]:focus::-webkit-input-placeholder {
              opacity: 0;
            }
          }
        }
        &__apply-button {
          padding-top: 6px;
          &__apply-code {
            width: 176px;
            height: 46px;
            font-size: $text-base;
            min-width: unset;
            box-shadow: none !important;
            &:disabled {
              opacity : .3;
            }
            &:hover, &:focus {
              background-color: #004371;
              color: $white;
            }
            @include media-mobile {
              width: 100%;
            }
          }
        }
      }
    }

    &__coupon-item, &__coupon-item2 {
      display : flex;
      justify-content : space-between;
      margin-bottom : 17px;
      &__text {
        font-size : 14px;
        &__item {
          font-weight: 600;
        }
      }
      &__btn {
        align-self: flex-start;
        flex-grow: 0;
        font-family: Raleway;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: #c51c00;
        border: none;
        background: none;
      }
    }
  }
  &__payment-description {
    overflow-y: auto;
    padding: 5px;
    width: 100%;
    height: 66px;

    &::-webkit-scrollbar{
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $sn-dark-hash;
      border-radius: 6px;
    }

    &__trial-blah {
      font-size: $text-tiny;
      line-height: 1.6;
      text-align: left;
      &__anchor-text {
        color: $sn-black !important;
        text-decoration: underline;
        // text-decoration-color: $color-brandBlue2020 !important;
        // text-decoration-thickness: 2px;
        // text-underline-offset: 2px;
        &:hover, &:focus {
          text-decoration: underline;
          color: #004371 !important;
        }
      }
    }
    &__start-free {
      text-align: center;
      margin-top: 24px;
      &__trail-button {
        width: 300px;
        height: 46px;
        font-size: $text-base;
        box-shadow: none !important;
        &:disabled {
          opacity : .3;
        }
        &:hover, &:focus {
          background-color: #004371;
          color: $white;
        }
        @include media-mobile {
          width: 100%;
        }
        &__text {
          // position: relative;
          // bottom: 6px;
        }
      }
    }
  }
  &__checkbox-container {
    margin-top: 24px;
    position: relative;
    margin-left: 5px;
    &__checkbox-description {
      margin-top: -27px;
      margin-left: 35px;
      &__description-text {
        font-size: $text-tiny;
        line-height: 1.6;
        &__anchor-text {
          color: $sn-black !important;
          text-decoration: underline;
          // text-decoration-color: $color-brandBlue2020 !important;
          // text-decoration-thickness: 2px;
          // text-underline-offset: 2px;
          &:hover, &:focus {
            text-decoration: underline;
            color: #004371 !important;
            // text-decoration-thickness: 2px;
          }
        }
      }
    }
  }
  &__gd-checkbox-container {
    margin-top: 24px;
    position: relative;
    margin-left: 5px;
    &__checkbox-description {
      margin-top: -27px;
      margin-left: 35px;
      &__description-text {
        font-size: $text-tiny;
        line-height: 1.6;
        &__anchor-text {
          color: $sn-black !important;
          text-decoration: underline;
          // text-decoration-color: $color-brandBlue2020 !important;
          // text-decoration-thickness: 2px;
          // text-underline-offset: 2px;
          &:hover, &:focus {
            text-decoration: underline;
            color: #004371 !important;
            // text-decoration-thickness: 2px;
          }
        }
      }
    }
  }
}

.promo-error-message {
  font-size: $text-base;
  line-height: 1.6;
  text-align: left;
  color: $color-form-error;
  padding-bottom: 15px;
}
.promo-error-header {
  font-size: $text-base;
  line-height: 1.19;
  text-align: left;
  color: $color-form-error;

  svg {
    path {
      fill: $color-form-error !important;
    }
  }
}
.promo-error-field {
  border: 2px solid $color-form-error;
  color: $color-form-error;
}

.loader {
  &[hidden]{
    display: none;
  }
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(72,72,72,.75);
  z-index: 101;
  &__description {
    font-size: $text-big;
    font-weight: bold;
    line-height: 1.6;
    text-align: center;
    color: $white;
    margin-bottom: 30px;
  }
}
.loader > img {
  width: 10%;
}
.custom-selector {
  position: relative;
  display: inline-block;
  select {
    appearance: none;
  }
}
.custom-selector::after {
  content: url("/images/icon-expand-more.svg");
  position: absolute;
}
.go-to-dashboard {
  width: 300px;
  height: 46px;
  background-color: $color-brandBlue2020;
  color: $white;
  font-size: $text-base;
  font-weight: $fw-chubby;
}
.back-to-sparknotes {
  border: 1px solid $color-brandBlue2020;
  color: $color-brandBlue2020;
  width: 300px;
  height: 46px;
  font-size: $text-base;
  font-weight: $fw-chubby;
  margin-top: 20px;
  margin-bottom: 50px;
}
.back-to-sparknotes:hover {
  border: 1px solid $sn-brand-blue!important;
}
.success-msg-free-trial {
  height: 39px;
  margin-bottom: 20px;
  font-size: $text-big;
  font-weight: $fw-chubby;
  text-align: center;
  color: #008262;
}
.country-not-listed, .error-selected-item, .error-selected-item2, .error-selected-item3 {
  border: 2px solid $color-form-error;
  color: $color-form-error !important;
}
.country-not-available-msg {
  height: 52px;
  flex-grow: 0;
  margin: 8px 1px 0 2px;
  font-size: $text-base;
  font-weight: $fw-base;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: $color-form-error;
  margin-bottom: -30px;
  margin-top: 7px!important;
  @include media-small {
    margin-bottom: 10px;
  }
}

//signin modal container
.signin-width {
  margin-right: -10px;
  margin-left: -10px;
}

.signin-btn {
  width: 325px;
}

.signin-agree-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  &__agree-detail {
    margin-top: 26px;
    &__text {
      font-size: $text-tiny;
      line-height: $lh-base;
      text-align: center;
      color: $sn-black;
    }
    &__terms {
      color: $sn-black;
      text-decoration: underline;
      //text-decoration-color: rgba(41, 44, 46, 0.5);
      //text-decoration-thickness: 1px;
     // text-underline-offset: 1px;
      &:hover, &:focus{
        color: #004371;
        text-decoration: underline;
      }
    }
  }
}

.signin-subscribe-container {
  display: flex;
  justify-content: center;
  border-top: 1px solid #e5e5e5;
  margin-right: -40px;
  margin-left: -40px;
  margin-bottom: 10px;
  &__subscribe-detail {
    margin-top: 20px;
    &__text-account {
      font-size: $text-small;
      line-height: $lh-base;
      text-align: center;
      &__text-color {
        color: $color-brandBlue2020;
        font-weight: $fw-chubby;
      }
    }
  }
}

.custom-select, .custom-select-state{
  position: relative;
}
.custom-dropdown, .custom-dropdown-state{
  position: relative;
  font-family: 'Raleway';
}
.custom-dropdown select, .custom-dropdown-state select {
  display: none; /*hide original SELECT element:*/
}
.custom-dropdown1, .custom-dropdown3, .custom-dropdown1-state, .custom-dropdown3-state{
  position: relative;
  font-family: 'Raleway';
}
.custom-dropdown3-state {
  font-family: 'Raleway' !important;
}  
.custom-dropdown1 select, .custom-dropdown1-state select, .custom-dropdown3 select, .custom-dropdown3-state select {
  display: none; /*hide original SELECT element:*/
}
.custom-select select, .custom-select-state select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: $color-teacherSoftGray;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: url("/images/icon-expand-more.svg");
  right: 10px;
  margin-top: -3px;
}
.same-as-selected:before {
  position: absolute;
  content: url("/images/icon-done-white.svg");
  float: left;
  margin-left: -23px;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  transform: rotate(180deg);
  margin-top: -5px;
}

/*style the items (options), including the selected item:*/
.select-items div, .select-selected {
  color: $sn-primary-header;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  height: 48px;
  line-height: 1.4;
  &:focus {
    color: #004371;
    background-color: #e5e5e5;
  }
}
.select-items div  {
  padding-left: 40px;
}
.select-state-items div {
  color: $sn-primary-header;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  height: 45px;
  padding-left: 40px;
  &:focus {
    color: #004371;
    background-color: #e5e5e5;
  }
}

/*style items (options):*/
.select-items, .select-state-items {
  position: absolute;
  background-color: $color-teacherSoftGray;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border-radius: 0px 0px 20px 20px;
  border-top: 1px solid $sn-border-gray;
  font-size: $text-base;
  font-weight: $fw-base;
}
.select-state-items {
  height: 185px;
  overflow-y: scroll;
  border-radius: 0px 0px 20px 20px;
}

/*hide the items when the select box is closed:*/
.select-hide{
  display: none;
}
.select-items div:hover, .same-as-selected, .select-state-items div:hover {
  background-color: $sn-border-gray;
  color: #004371;
}

//Payment summary using checkbox design in create account tab
.input-checkbox-term {
  // opacity: 0;
  position: relative;
  // left: 3px;
  width: 20px;
  height: 20px;
}

.input-checkbox-term + .checkbox-term {
  // position: relative;
  cursor: pointer;
  line-height: 25px;
  right: 6px;
  bottom: 1px;
}

.input-checkbox-term + .checkbox-term::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 0 1px $color-a11yGray;
  background: $color-teacherSoftGray;
  border-radius: 2px;
}

.input-checkbox-term + .checkbox-term:hover::before {
  box-shadow: 0 0 0 1px #004371;
}

.input-checkbox-term:checked + .checkbox-term::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 0 1px $color-brandBlue2020;
  background: $white;
  background: $color-brandBlue2020;
  border-radius: 2px;
}

.input-checkbox-term:checked + .checkbox-term::after {
  content: '';
  position: absolute;
  width: 7px;
  height: 15px;
  border-right: 1px solid $white;
  border-bottom: 1px solid $white;
  top: 2%;
  left: 2px;
  transform: translate(69%, -1%) rotateZ(39deg);
}

.signin-subcribe{
  cursor: pointer;
}
.signin-subcribe {
  &:hover, &:focus {
    color: #004371;
  }
}
.create-account-resetpassword{
  cursor: pointer;
  color: $color-brandBlue2020;
  text-decoration: underline;
  text-decoration-color: $color-brandBlue2020 !important;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
}

.create-account-mobile-view-scroll{
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 0 0 12px 12px!important;
}

@include media-tiny {
  .smaller-device-structure{
    padding-left: 10px;
    padding-right:10px;
    p{
      padding-left: 30px;
      padding-right:30px;
    }
  }
}
.reset-password-success-icon{
  height: 72px;
  width: 72px;
}

// stripe UI CSS

.StripeElement {
  box-sizing: border-box;

  height: 47px;

  padding: 14px 12px;
  margin-bottom: 6px;

  border-radius: 4px;
  background-color: #f5f1f5;
}

.StripeElement:hover {
  background-color: #fff;
  box-shadow: 0 10px 20px 0 RGB(0 0 0/10%);
  outline: 0;
}

.StripeElement--focus {
  outline: 0;
  border-radius: 4px;
  box-shadow: 0 0 3px 2px #004371 !important;
}

.StripeElement--invalid {
  border-color: #fa755a;
  background-image: url('/images/icon_warning.svg');
  background-repeat: no-repeat;
  background-position: 95%;
  box-shadow: inset 0 0 0 2px #c51c00 !important;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.CardBrandIcon-container {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  width: 1.5em;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.custom-stripe-width {
  width : 45%;
  margin-top : 16px !important;
  @include media-small {
    width : 100%;
  }
}

//reset-passward modal
.reset-password {
  background-color: $white;
  border-radius: 0 0 12px 12px;
}
.expired-user.snplusmodal {
  &__close {
    display: unset !important;
  }
  img {
    right: -10% !important;
    @include media-small {
      right: -20% !important;
    }
  }
  .dashboard-renew-sub-btn {
    margin-left: unset !important;
  }
}

.dashboard-expired.snplusmodal {
  &__container {
    align-self: center !important;
    overflow: visible;
    @include media-small {
      width: 336px;
      height: fit-content !important;
      margin-top: unset;
    }
  } 
  &__content__container {
    @include media-small {
      background-color: $white;
      margin-top: -38px;
      border-radius: 0 0 5px 5px;
    }
  }
  &__header {
    height: 120px;
    background-image: linear-gradient(to top, #143b36, #008262);
    @include media-small {
      height: 88px;
    }
  } 
  img {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -75px;
    @include media-small {
      top: -60px;
    }
  }  
  &__close {
    display: none;
  }
  &__header__container {
    height: 120px;
    @include media-small {
      height: 88px;
    }
  }
  &__header__content {
    padding-left: unset !important;
  }
}
.dashboard-expired {
  &__container {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
    @include media-small {
      padding: 0 20px 20px 20px;
    }
  }
  &__content {
    margin-top: 18px;
    padding-left: 15px;
    padding-right: 15px;
    color: $plus-green-dark;
    font-size: $text-slightly-big;
    font-weight: $fw-base;
    line-height: $lh-base;
    letter-spacing: normal;
    @include media-small {
      letter-spacing: 0.54px;
      margin-top: 8px;
    }
  }
  &__title{
    margin-top: 38px;
    &__text {
      text-align: center;
      font-size: $text-very-big;
      font-weight: $fw-fat;
      line-height: $lh-base;
      color: #008262;
      letter-spacing: normal;
      @include media-small {
        font-size: $text-big;
        font-weight: bold;
        padding-top: 20px;
      }
    }
  }
}
.dashboard-logo {
  position: relative;
}
.gotohomepage-btn {
  border: 2px solid $color-brandBlue2020;
  color: $color-brandBlue2020;
  background-color: $white;
  height: 46px;
  font-size: $text-base;
  font-weight: $fw-chubby;
  display: flex;
  align-items: center;
  &:hover{
    border: 2px solid $color-linkHover;
  }
}
.dashboard-renew-btn-cont {
  margin-top: 40px;
  @include media-small {
    margin-top: 35px;
  }
}
.dashboard-renew-sub-btn {
  height: 46px;
  background-color: $color-brandBlue2020;
  color: $white;
  font-size: $text-base;
  font-weight: $fw-chubby;
  margin-left: 24px;
  display: flex;
  align-items: center;
  @include media-small {
    margin-left: unset;
    margin-bottom: 18px;
  }
}

.offboarding-survey.snplusmodal {
  &__container {
    margin-top: 20px;
    margin-bottom: 20px;
    height: auto !important;
    align-self: unset !important;
  }
} 

.offboarding-survey-modal {
  background-color: $white;

  @include media-small {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .ques-for-desktop {
    @include media-small {
      display: none;
    }
  }

  .ques-for-mobile {
    @include media-notSmall {
      display: none;
    }
  } 

  .surver-wrapper {
    margin-right: 40px;
    margin-left: 40px;
  }

  .survey-question {
    margin-top: 20px;
    line-height: $lh-base;
    margin-bottom: 20px;
  }

  .user-feedback {
    width: 100%;
    height: 110px;
    padding: 10px;
    border-radius: 4px;
    border: solid 1px $sn-border-gray;
    &:disabled {
      cursor: no-drop;
    }
  }

  .survey-title {
    font-size: $text-big !important;
    font-weight: bold;
  }

  .survey-options {
    position: relative;
    height: 58px;
    display: flex;
    border-radius: 12px;
    padding-left: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    background-color: $color-teacherSoftGray;
    align-items: center;
    user-select: none;
    border: 1px solid $sn-border-gray;
    @include media-small {
      padding-left: 35px;
      margin-bottom: 24px;
    }
    @include media-notSmall {
      &:hover {
        color: $color-linkHover;
        border: solid 1px $color-linkHover;
      }
    }
  }

  .active-option {
    background-color: $color-brandBlue2020;
    border: solid 1px $color-brandBlue2020;
    color: $white;
    @include media-notSmall {
      &:hover {
        color: $white !important;
      }
    }
  }

  /* Hide the browser's default radio button */
  .survey-options input {
    position: absolute;
    opacity: 0;
    display: none;
    cursor: pointer;
  }

  .survey-wrapper {
    margin-right: 40px;
    margin-left: 40px;
    @include media-small {
      margin-right: 24px;
      margin-left: 24px;
    }
  }

  /* Create a custom radio button */
  .survey-radio-btn {
    position: absolute;
    left: 4%;
    height: 12px;
    width: 12px;
    border:  1px solid $sn-border-gray;
    background-color: $white;
    border-radius: 50%;
  }

  @include media-notSmall {
    .survey-options:hover input ~ .survey-radio-btn {
      background-color: $white;
      border: 1px solid $color-linkHover;
    }

    .active-option:hover input ~ .survey-radio-btn {
      background-color: $white;
      border: 1px solid $color-brandBlue2020 !important;
    }
    
    .active-option:hover {
      border: 1px solid $color-brandBlue2020 !important;
    }
  }

  .survey-options input:checked ~ .survey-radio-btn {
    background-color: $white;
    border: 1px solid $color-brandBlue2020;
  }

  .submit-feedback {
    margin-top: 20px;
    margin-bottom: 40px;
    @include media-small {
      margin-top: 24px;
    }
    &__btn {
      width: 222px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      border-radius: 4px;
      background-color: $color-brandBlue2020;
      &:hover{
        background-color: $color-linkHover;
      }
      &:focus-visible {
        outline: 2px solid $sn-black;
      }
      @include media-small {
        width: 100%;
        margin-left: 6px;
        margin-right: 6px;
      }
      &:disabled {
        opacity: .39;
        &:hover {
          cursor: no-drop;
          background-color: $color-brandBlue2020 !important;
        }
      }
    }
    @include media-small {
      padding-bottom: 40px;
    }
  }

  textarea{

    &:focus-visible {
      outline: 2px solid $color-brandBlue2020;
      border: unset !important;
    }

    &:hover {
      &:not([disabled]) {
        outline: 2px solid $color-linkHover !important;
      }
    }

    @include media-small{
      &::-webkit-input-placeholder , &::placeholder{
        line-height: $lh-base;
      }
    }
  }
}

.sn-promo-modal.snplusmodal {
  --promoHeaderColor: #{$white};  
  --promoContainerColor: #{$white};

  &__container {
    background-color: var(--promoContainerColor);
    @include media-small {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
  &__header {
    background-image: linear-gradient(to top,var(--promoContainerColor),var(--promoHeaderColor)) !important;
  }
  img{
    top: -50px !important;
  }
}

.sn-promo-modal {
  &__main-cont {
    background-color: #323398;
  }

  &__logo-cont {
    text-align: center;
    margin-top: 34px;
    @include media-small{
      margin-left: auto;
      margin-right: auto;
    }
    img{
      position: unset !important;
      @include media-small {
        width: 232px;
        padding-top: 30px;
      }
    }
  }

  &__content {
    margin-top: 32px;
    text-align: center;
    li {
      font-size: $text-big;
      font-weight: $fw-base;
      line-height: $lh-base;
      color: $white;
      padding-left: 86px;
      padding-right: 86px;
      span{
        font-weight: bold;
      }
      @include media-small {
        padding: 0 34px;
      }
    }
  }
  
  &__btn-cont {
    text-align: center;
    margin: 32px 0;
  }

  &__signup-btn {
    height: 46px;
    width: 298px;
    background-color: #41ffaa;
    color: $plus-green-dark;
    font-size: $text-base;
    font-weight: $fw-chubby;
    border-radius: 4px;
    span {
      font-weight: bold;
    }
    @include media-small {
      width: 284px;
    }
    @include media-365 {
      width: 240px;
    }
    &:hover {
      background-color: $color-linkHover !important;
      color: $white;
    }
  }

  &__legal-text {
    p {
      font-size: $text-tiny;
      color: $white;
      margin-top: 32px;
      line-height: $lh-base;

      a {
        color: $white;
        text-decoration: underline;
      }
    }
  }
}

#modal-promo-content .modal-container {
  @include media-small {
    display : block !important;
    margin-left: auto;
    margin-right: auto;
  }
}

// .promo_text_desktop {
//   @include media-notSmall{
//     display: block;
  
//   }
//   @include media-small{
//     display: none;
//   }
// }
// .promo_text_mobile {
//   @include media-notSmall{
//     display: none;
  
//   }
//   @include media-small{
//     display: block;
//   }
// }

.new-renewal-text {
  text-align: center !important;
  margin-top: 20px;
}
.payment-details-container1 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.payment-summary-container1 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.choose-plan1 {
  margin-top: 25px;
  margin-bottom: 25px;
  &__container {
    display: flex;
    &__steps {
      font-size: $text-tiny;
      margin-right: 10px;
    }
    &__plan-type {
      font-size: $text-slightly-big;
      font-weight: $fw-chubby;
      margin-top: -3px;
      color: #004a3e;
    }
  }
  .radio-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 18px;
    @include media-small {
      justify-content: space-evenly;
      margin-left: -15px;
    }
  }
  .card {
    width: 199px;
    height: 105px;
    position: relative;
    border: none!important;
    @include media-small {
      width: 136px;
      height: 125px;
    }
    &:first-child {
      margin-right : 24px;
    }
  }

  .account-plan-radio {
    -webkit-appearance: none;
    appearance: none;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    position: absolute;
    cursor: pointer;
    outline: none;
    background-color: #e1faef;
    opacity: 0.3;
    border: 2px solid #00cc9a;
  }
  .account-plan-radio:before {
    content: '';
    position: absolute;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    top: 35px;
    right: 20px;
  }
  .account-plan-radio:after {
    content: '';
    position: absolute;
    height: 13px;
    width: 13px;
    background-color: transparent;
    border-radius: 50%;
    top: 39.5px;
    right: 24.5px;
  }

  .radio-card {
    position: absolute;
    cursor: pointer;
    opacity: 0.3;
    width: 199px;
    height: 105px;
    @include media-small {
      width: 136px;
      height: 125px;
    }
  }

  .account-plan-radio:checked + .radio-card {
    opacity: 1;
  }

  .account-plan-radio:checked {
    border: 2px solid #00cc9a;
    opacity: 1;
  }

  .account-plan-radio:focus-visible {
    border: 2px solid $sn-black;
    opacity: 1;
  }

  .best-value {
    position: absolute;
    top: -14px;
    left: 57px;
    text-align: center;
    display: block;
    border: 1px solid #41ffaa;
    padding: 6px 7px 6px 7px;
    border-radius: 24px;
    background: #41ffaa;
    font-size: $text-tiny;
    font-weight: $fw-chubby;
    z-index: 11;
    color: $plan-price-color;

    @include media-small {
      left: 28px;
    }

  }

  .annual-price {
    text-align: center;
    padding: 27px 10px 0px 16px;
    font-size: $text-tiny;
    margin-top: -11px;
    color: $plan-price-color;
    @include media-mobile {
      padding: 29px 10px 0px 16px;
    }
  }

  .month-price {
    text-align: center;
    padding: 16px 10px 0px 16px;
    font-size: $text-tiny;
    color: $plan-price-color;
    @include media-mobile {
      padding: 18px 10px 0px 16px;
    }
  }

  .year-price {
    font-size: $text-big;
    font-weight: bold;
  }

  .word-break {
    @include media-mobile {
      position: relative;
      top: -10px;
      &::before {
        content: "\A";
      }
    }
  }
  .plan-request {
    text-align: center;
    font-size: $text-base;
    font-weight: $fw-chubby;
    color: $plan-price-color;
    padding-top: 3px;
    padding-left: 10px;
  }
  .plan-description {
    display: flex;
    flex-direction: column;
    text-align: center;
    &__annual-save {
      font-size: $text-base;
      line-height: 1.6;
      color: #008262;
      margin: 44px 30px 0px 40px;
      @include media-small {
        margin: 24px 0px 0px 0px;
      }
    }
    &__continue-payment {
      width: 312px;
      box-shadow: none !important;
    }
  }
  
  .stripe-plan-description {
    display: flex;
    flex-direction: column;
    text-align: center;
    &__annual-save {
      font-size: $text-base;
      line-height: 1.6;
      color: #292c2e;
      margin-top: 20px;
    }
    &__plan-billing-cycle {
      font-family: Raleway;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: center;
      color: #292c2e;
      margin-top: 32px;
    }
    &__continue-payment {
      width: 312px;
      box-shadow: none !important;
    }
    &__continue-stripe {
      font-family: Raleway;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: center;
      color: #292c2e;
      &__link {
        margin-bottom: -7px;
      }
    }
  }

  .stripe-plan-description .fe-buttons {
    margin-bottom: 32px;
  }
}
.to-hide-border {
  border-top: unset;
}

.study-notes {
  @include media-small {
    display: flex !important;
    justify-content: center;
  }
}

.save-card-btn.fe-button {
  box-shadow: none !important;
}

.signin-tooltip .fe-tooltip {
  &__trigger{
    background-color: $white !important;
    box-shadow: 0 0 0 1px $color-a11yGray !important;   
    
    path{
        fill: $color-a11yGray !important;
    }
    &:hover,
    &:focus{
      background-color: $white !important;
      box-shadow: 0 0 0 1px $color-a11yGray !important;
      path{
        fill: $color-a11yGray !important;
      }
    }
  }
}

//choose plan tab creation
.plan-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 28px;
  padding-bottom: 24px;
  &__individual {
    width: 100%;
    &__tab {
      font-family: Raleway;
      font-size: $text-base;
      font-weight: $fw-chubby;
      font-stretch: normal;
      font-style: normal;
      line-height: $lh-base;
      letter-spacing: normal;
      text-align: center;
      color: #008262;
      padding: 0px 64px 10px 64px;
      @include media-small {
        padding: 0px 18px 10px 18px;
      }
    }
  }
  &__group {
    width: 100%;
    &__tab {
      font-family: Raleway;
      font-size: $text-base;
      font-weight: $fw-chubby;
      font-stretch: normal;
      font-style: normal;
      line-height: $lh-base;
      letter-spacing: normal;
      text-align: center;
      color: #008262;
      padding: 0px 54px 10px 54px;
      @include media-small {
        padding: 0px 18px 10px 18px;
      }
    }
  }
}

.disable-tab { 
  border-bottom: 1px solid #008262;
  cursor: pointer;
  &:hover, &:focus-within {
    opacity: unset;
    border-bottom: 1px solid #008262;
  }
}

.active-tab {
  border-bottom: 5px solid #008262;
}

.disable-tab .plan-tabs__group__tab:hover {
  color: #004371;
}

.disable-tab .plan-tabs__individual__tab:hover {
  color: #004371;
}

.disable-tab .plan-tabs__group__tab {
  padding: 0px 54px 14px 54px;
  font-weight: normal;
  @include media-small {
    padding: 0px 18px 14px 18px;
  }
}

.disable-tab .plan-tabs__individual__tab {
  padding: 0px 64px 14px 64px;
  font-weight: normal;
  @include media-small {
    padding: 0px 18px 14px 18px;
  }
}

.gdtab-container { 
  padding-left: 30px;
  padding-right: 30px;
  @include media-small{
    padding-left: unset;
    padding-right: unset;
  }
  &__purchase {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
    &__group-text {
      font-size: $text-base;
      font-weight: $fw-chubby;
      font-stretch: normal;
      font-style: normal;
      line-height: $lh-base;
      letter-spacing: normal;
      color: $plus-green-dark;
      &__hide-mobile {
        @include media-small{
          display: none;
        }
      }
    }
    &__group-plan {
      font-size: $text-base;
      font-weight: $fw-chubby;
      font-stretch: normal;
      font-style: normal;
      line-height: $lh-base;
      letter-spacing: normal;
      color: $plus-green-dark;
      text-align: center;
    }
  }
  &__quantity {
    display: flex;
    justify-content: center;
    @include media-small{
      justify-content: unset;
    }
    // padding-left: 40px;
    // padding-right: 25px;
    // @include media-small{
    //   padding-left: unset;
    //   padding-right: unset;
    // }
    &__nos {
      padding-right: 30px;
    }
    &__price {
      &__text {
        font-size: $text-base;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        color: $sn-primary-paragraph;
        padding-top: 1px;
      }
      &__details {
        font-size: $text-slightly-big;
        font-weight: $fw-chubby;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        color: $sn-primary-paragraph;
        padding-top: 11px;
        &__striked {
          font-family: Raleway;
          font-size: $text-big;
          font-weight: $fw-chubby;
          font-stretch: normal;
          font-style: normal;
          line-height: $lh-base;
          letter-spacing: normal;
          color: $color-a11yGray;
          text-decoration: line-through;
          padding-right: 4px;
        }
        &__regular {
          font-size: $text-big;
          font-weight: $fw-chubby;
          line-height: $lh-base;
          &__line-break {
            font-size: $text-big;
            font-weight: bold;
            line-height: $lh-base;
            @include media-small {
             display: inline;
            }
          }
        }
      }
      &__subtotal {
        font-family: Raleway;
        font-size: $text-base;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: $lh-base;
        letter-spacing: normal;
        text-align: right;
        color: $sn-primary-paragraph;
        padding-top: 8px;
        @include media-small{
          text-align: left;
        }
      }
    }
    &__error {
      justify-content: unset;
      margin-left: 12px;
      @include media-small{
        margin-left: unset;
      }
    }
  }
  &__discount {
    padding-top: 24px;
    // padding-left: 8px;
    // @include media-small{
    //   padding-left: unset; 
    // }
    &__save {
      font-family: Raleway;
      font-size: $text-base;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: $lh-base;
      letter-spacing: normal;
      text-align: left;
      color: $sn-primary-paragraph;
      &__bold {
        font-weight: $fw-chubby;
      }
    }
    &__more {
      font-family: Raleway;
      font-size: $text-base;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: $lh-base;
      letter-spacing: normal;
      text-align: left;
      color: $sn-primary-paragraph;
      padding-top: 30px;
      &__link {
        color: #0066c4;
        text-decoration: underline;
        &:hover, &:focus {
          color: #004371; 
          text-decoration: underline;
        }
      }
    }
  }
  &__quantity-errormsg {
    margin-left: 7px;
  }
}

.quantity-number {
  height: 42px;
  width: 63px;
  padding: 12px 10px;
  text-align: center;
}

.quantity-number input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.quantity-number input[type=number] {
  -moz-appearance: textfield;
}

.space {
  display: none;
  @include media-small{
    display: inline-block;
  }
}

//group discount payment summary
.payment-summary {
  margin-top: 24px;
  margin-bottom: 25px;
  &__container {
    background-color: $color-teacherSoftGray;
    padding: 16px;
    &__details {
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      padding-bottom: 23px;
      &__plan {
        font-family: Raleway;
        font-size: $text-small;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        color: $sn-primary-paragraph;
        &__discount {
          font-weight: bold;
        }
      }
      &__qty {
        font-family: Raleway;
        font-size: $text-small;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        color: $sn-primary-paragraph;
        padding-top: 8px;
      }
    }
    &__sub-Container {
      display: flex;
      justify-content: space-between;
      padding-top: 23px;
      &__subtotal {
        font-family: Raleway;
        font-size: $text-small;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: $lh-base;
        letter-spacing: 0.13px;
        color: $sn-primary-paragraph;
      }
      &__price {
        font-family: Raleway;
        font-size: $text-small;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: $lh-base;
        letter-spacing: 0.13px;
        color: $sn-primary-paragraph;
      }
    }
    &__discount-Container {
      display: flex;
      justify-content: space-between;
      padding-top: 8px;
      &__discount{
        font-family: Raleway;
        font-size: $text-small;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: $lh-base;
        letter-spacing: 0.13px;
        color: $sn-primary-paragraph;
      }
      &__price {
        font-family: Raleway;
        font-size: $text-small;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: $lh-base;
        letter-spacing: 0.13px;
        color: $sn-primary-paragraph;
      }
    }
    &__tax-Container {
      display: flex;
      justify-content: space-between;
      padding-top: 8px;
      &__tax{
        font-family: Raleway;
        font-size: $text-small;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: $lh-base;
        letter-spacing: 0.13px;
        color: $sn-primary-paragraph;
      }
      &__price {
        font-family: Raleway;
        font-size: $text-small;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: $lh-base;
        letter-spacing: 0.13px;
        color: $sn-primary-paragraph;
      }
    }
    &__due-Container {
      display: flex;
      justify-content: space-between;
      padding-top: 8px;
      &__due {
        font-family: Raleway;
        font-size: $text-slightly-big;
        font-weight: $fw-chubby;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        color: $sn-primary-paragraph;
      }
      &__price {
        font-family: Raleway;
        font-size: $text-slightly-big;
        font-weight: $fw-chubby;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        color: $sn-primary-paragraph;
      }
    }
  }
}

//Thank you modal for Group Discount
.signin-group-discount {
  font-family: Raleway;
  font-size: $text-big;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: $lh-base;
  letter-spacing: normal;
  text-align: center;
  color: #008262;
}

.gd-activation-link {
  font-family: Raleway;
  font-size: $text-base;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $lh-base;
  letter-spacing: normal;
  text-align: left;
  color: $sn-primary-paragraph;
  padding-bottom: 20px;
  @include media-small{
    padding-right: 14px !important;
    padding-left: 14px !important;
  }
}

.gd-members {
  font-family: Raleway;
  font-size: $text-base;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $lh-base;
  letter-spacing: normal;
  text-align: left;
  color: $sn-primary-paragraph;
  @include media-small{
    padding-right: 14px !important;
    padding-left: 14px !important;
  }
}

.gd-link {
  display: flex;
  &__url {
    font-family: Raleway;
    font-size: $text-base;
    font-weight: $fw-chubby;
    font-stretch: normal;
    font-style: normal;
    line-height: $lh-base;
    letter-spacing: normal;
    text-align: left;
    color: $sn-primary-paragraph;
    padding-bottom: 20px;
    word-break: break-word;
    width: 82%;
    @include media-small{
      padding-right: 10px !important;
      padding-left: 14px !important;
      width: 70%;
    }
  }
  &__copy-icon {
    display: flex;
    padding-left: 10px;
    @include media-small{
      padding-left: unset;
    }
    cursor: pointer;
    &:hover {
      path {
        fill: #004371;
      }
    }
  }
}

.copy {
  visibility: hidden;
  padding-top: 2px;
  padding-left: 5px;
  font-family: Raleway;
  font-size: $text-tiny;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: $lh-base;
  letter-spacing: normal;
  text-align: left;
  color: #004371;
  @include media-small{
    padding-right: 10px;
  }
}

.gd-link__copy-icon:hover + .copy {
  visibility: visible;
}

.copied-text {
  visibility: visible;
  color: #008262;
}

.gd-link__copy-icon:hover + .copied-text {
  color: #004371;
}

.redeem-code {
  width: 300px;
  height: 46px;
  background-color: #0066c4;
  color: $white;
  font-size: $text-base;
  font-weight: $fw-chubby;
}

.manage-your-purchase {
  border: 1px solid $color-brandBlue2020;
  color: $color-brandBlue2020;
  width: 300px;
  height: 46px;
  font-size: $text-base;
  font-weight: $fw-chubby;
  margin-top: 20px;
  margin-bottom: 50px;
}

.manage-your-purchase:hover {
  border: 1px solid #004371 !important;
}

wbr:before { 
  /* Unicode zero width space */ 
  @include media-small {
    content: "\200B"; 
    white-space: normal;
  } 
}

.hide-full-payment-details {
  display: none;
}

.new-continue-payment {
  &__container {
    flex-direction: column;
    align-items: center;
  }
  &__text {
    flex-grow: 0;
    font-family: Raleway;
    font-size: $text-small;
    line-height: $lh-base;
    text-align: center;
    color: $sn-primary-paragraph;
    padding-top: 24px;
    @include media-small {
      padding: 24px 24px 0 24px;
    }
  }
  &__stripe-image {
    margin-bottom: -7px;
  }
}

.new-update-button {
  padding: 16px 20px !important;
  width: 170px;
  display: block !important;
}

.new-update-payment__text {
  font-family: Raleway;
  font-size: $text-small;
  font-weight: $fw-base;
  line-height: $lh-base;
  text-align: center;
  color: $sn-primary-paragraph;
}

.hide-full-payment-details {
  display: none;
}

.gd-text-color {
  color: #292c2e !important;
}

.countryNotSupported {
  margin-top: 92px;
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    padding: 16px 16px 16px 24px;
    border-radius: 4px;
    border: solid 2px $color-form-error;
    background-color: #fbefed;
    position: absolute;
    top: 0;
    left: 0;

    &__span {
      flex-grow: 1;
      font-family: Raleway;
      font-size: $text-base;
      line-height: $lh-base;
      text-align: left;
      color: $color-form-error;
    }
  }
}

.loginModalSuccess {
  margin-top: 82px;
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    padding: 16px 16px 16px 24px;
    border-radius: 4px;
    background-color: #e1faef;
    position: absolute;
    top: 0;
    left: 0;

    &__span {
      flex-grow: 1;
      font-family: Raleway;
      font-size: $text-base;
      line-height: $lh-base;
      text-align: left;
      color: $plus-green-dark;
    }
  }
}

.billingCountryMismatch {
  margin-top: 92px;
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    padding: 16px 16px 16px 24px;
    border-radius: 4px;
    border: solid 2px $color-form-error;
    background-color: #fbefed;
    position: absolute;
    top: 0;
    left: 0;

    &__span {
      flex-grow: 1;
      font-family: Raleway;
      font-size: $text-base;
      line-height: $lh-base;
      text-align: left;
      color: $color-form-error;
    }
  }
}

.updateCountryNotSupported__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start !important;
  padding: 16px 16px 16px 20px !important;
  
  .subscription-error-message__title {
    font-size: $text-base !important;
    line-height: $lh-base !important;
    text-align: left !important;
  }
}

.remove-bodyContent {
  padding-top: 20px;
}
.form--create-account {
  .fe-buttons .choose-plan-btn.fe-button {
    background-color: #0066c4;

    &:hover, &:focus {
      background-color: #004371;
      color: $white;
    }
  }
  .fe-label--wrap-checkbox {
    input:checked + .fe-checkbox-text{
      &:before{
        background-color: #0066c4;
      }
    }
  }
}
.group-discount-tab {
    .plan-description {
      .new-continue-payment__container {
          .plan-description__continue-payment {
            background-color: #0066c4;
            &:hover, &:focus {
              background-color: #004371;
              color: $white;
            }
          }
      }
    }
} 
.group-discounts-success {
  .fe-buttons__container {
    .redeem-code {
      box-shadow: unset;
      &:hover {
        box-shadow: unset;
      }
    }
    .manage-your-purchase {
      border: 2px solid #0066c4;
      color: #0066c4;
    }
    .manage-your-purchase:hover {
      color: $white !important;
      box-shadow: unset;
    }
  }
}
.gd-checkbox-signin-termsprivacy {
  .gd-checkbox-text::before {
    border: 1px solid #0066c4;
    box-shadow: unset !important;
    border-radius: 2px;
    @media (min-width: 700px) {
      transform: translateY(-9px);
    }
    @media (max-width:468px) and (min-width: 388px) {
      transform: translateY(-70%); 
    }
    @media (max-width: 387px) {
      transform: translateY(-100%);
    }
  }

  input:checked + .fe-checkbox-text{
    &:before{
      background-color: #0066c4;
    }
  }

    &__text {
      padding-top: 16px;
      color: #292c2e;
      font-size: 13px;
    }
}

.choose-your-plan .new-continue-payment__container .plan-description__continue-payment {
  background-color: #0066c4;

  &:hover, &:focus {
    background-color: #004371;
    color: $white;
  }
}

.graphic-novel-expired-user.snplusmodal__header {
  height: 124px;
  @include media-small {
    height: 70px;
  }
}

.graphic-novel-expired-user.snplusmodal__header img {
  top: -46px;
  right: 0 !important;
}

.graphic-novel-expired-user {
  .dashboard-expired__container {
    padding: 0px 92px 0px 92px;
    @include media-small {
      padding: 0px 24px 24px 24px;
    }
  }
  .dashboard-expired__content {
    text-align: center;
    color: #004a3e;
  }

  .dashboard-renew-sub-btn {
    @include media-small {
      margin-bottom: unset;
    }
  }

  .cancel-sub__btncontainer {
    @include media-small {
      margin-bottom: unset;
    }
    @media (min-width: 281px) and  (max-width: 320px) {
      margin-top: 25px;
    }
    @media (max-width: 280px) {
      margin-top: 15px;
    }
  }
  .dashboard-expired.snplusmodal__content__container {
    @include media-small {
      border-radius: 0 0 12px 12px;
    }
  }

  .snplusmodal__container {
    @include media-small {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      margin: unset !important;
    }
  }

  #icon-newGraphic-novel-mobile {
    @include media-small {
      width: 194px;
      top: -56px;
    }
    @media (max-width: 280px) {
      width: 174px;
      top: -46px;
    }
  }

  
  .snplusmodal__content {
    @include media-small {
      // height: 320px;
      // overflow: auto;
      // border-radius: 0px 0px 12px 12px;
      @media (min-width: 281px) and  (max-width: 320px) {
        // height: 280px;
      }
      @media (max-width: 280px) {
        // height: 230px;
      }
    }

  }

  #new-graphicNovel-desktop-copy {
    display: block;
    @include media-small {
      display: none;
    }
  }

  #new-graphicNovel-mobile-copy {
    display: none;
    @include media-small {
      display: block;
    }
  }
}

.newGraphicNovel-mobile {
  .dashboard-expired__title__text {
    @include media-small {
      line-height: 1.4;
    }
    @media (min-width: 281px) and  (max-width: 320px) {
      font-size: 1.2rem;
      line-height: 1.3;
    }
    @media (max-width: 280px) {
      font-size: 1.1rem;
      line-height: 1.2;
      padding-top: 10px;
    }
  }

  .dashboard-expired__content {
    @include media-small {
      letter-spacing: unset;
    }
    @media (min-width: 281px) and  (max-width: 320px) {
      letter-spacing: unset;
      line-height: 1.3;
    }
    @media (max-width: 280px) {
      letter-spacing: unset;
      line-height: 1.2;
      font-size: 1rem;
    }
  }
}

#new-graphicNovel-mobile-copy {
  display: none;
}

#auto-login-error-msg {
  background-color: $white;
  border-radius: 0px 0px 12px 12px;
  .autoLogin-unsupport-msg {
    display: flex;
    padding: 16px 16px 16px 24px;
    border: solid 2px #c51c00;
    background-color: #fbefed;
    &__img {
      padding-right: 12px;
    }
    &__text {
      font-family: Raleway;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: left;
      color: #c51c00;
      &__anchor {
        color: #c51c00;
        text-decoration: underline;
        &:hover {
          color: #004371;
          text-decoration: underline;
        }
      }
    }
  }
  
  .autoLogin-support-msg {
    display: flex;
    padding: 16px 16px 16px 24px;
    border: solid 2px #c51c00;
    background-color: #fbefed;
    &__img {
      padding-right: 12px;
    }
    &__text {
      font-family: Raleway;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: left;
      color: #c51c00;
      &__anchor {
        color: #c51c00;
        text-decoration: underline;
        &:hover {
          color: #004371;
          text-decoration: underline;
        }
      }
    }
  }
  
  .auto-login-error-button {
    .fe-buttons__container--stacked {
      grid-gap: unset;
    }
    .try-again {
      width: 300px;
      height: 46px;
      background-color: #007acd;
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      box-shadow: unset;
      &:hover, &:focus {
        background-color: #004371;;
        box-shadow: unset;
      }
    }
  
    .auto-login-back-to-sparknotes {
      border: 1px solid #007acd;
      color: #007acd;
      width: 300px;
      height: 46px;
      font-size: 1rem;
      font-weight: 600;
      margin-top: 24px;
      margin-bottom: 50px;
      &:hover, &:focus {
        background-color: #004371;
        color: $white;
        box-shadow: unset;
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.recaptcha-privacy {
  font-size: $text-tiny;
  line-height: $lh-base;
  color: $sn-primary-paragraph;
  margin-bottom: 16.5px;
  margin-top: 4px;

  @include media-mobile {
    margin-bottom: 25.5px;
  }
}

.privacy-policy {
  color: $sn-primary-paragraph;
  text-decoration: underline;

  &:hover, &:focus-visible {
    color: #004371;
    text-decoration: underline;
  } 
}

.closeModalFocus {
  &:focus {
    border: 2px solid $sn-black;
  }
}

.redesign-aplit-retake-modal {
  .cancelsub-btn {
    border: 2px solid #0066c4;
    color: #0066c4;
    &:hover, &:focus {
      border: 2px solid #004371;
      box-shadow: unset;
      color: $white;
    }
  }

  .keepsn-btn {
    &:hover, &:focus {
      box-shadow: unset;
      color: $white;
    }
  }
}

.form-container .terms-new-privacy {
  font-size: $text-tiny;
  line-height: $lh-base;
  text-align: center;
  color: $sn-primary-paragraph;
  margin-top: 8px;
}

.terms-privacy-policy{
 text-decoration: underline;
 color:$sn-primary-paragraph;

 &:hover, &:focus {
  color: #004371;
  text-decoration: underline;
 }
}

.modal-content-container-msg{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 40px;
  background-color: #eeeeee;
  @media(max-width:600px){
    padding: 10px 24px;
  }
}

.placeholders-container{
  margin-bottom: 60.5px;
}

.new-recaptcha-privacy{
  margin-top:unset;
  margin-bottom:unset;
}
