.group-discount-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #e1faef;
    &__primary-logo {
        background-color: $white;
        height: 85px;
        display: flex;
        justify-content: center;
        @include media-mobile {
            display: none;
        }
        img {
            height: 50px;
            display: flex;
            align-self: center;
        }
    }
    &__content {
        display: flex;
        justify-content: center;
    }
    &__modal {
        max-width: 576px;
        margin-top: 40px;
        background-color: $white;
        margin-bottom: 100px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}
.group-discount-header {
    height: 120px;
    background-color:#008262;
    display: flex;
    justify-content: center;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}
.group-discount-notes {
    margin-top: 32px;
    background-color: $white;
    &__title {
        font-size: $text-big;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        text-align: center;
        color: #008262;
    }
    &__text {
        font-size: $text-base;
        font-weight: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        text-align: left;
        p {
            color: $plus-green-dark;
        }
        p:nth-child(2) {
            padding-left: 15px;
            padding-top: 20px;
        }
        p:last-child {
            font-size: $text-small;
        }
        margin: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $sn-border-gray;
    }
    &__condition {
        font-size: $text-small;
        line-height: $lh-base;
    }
}
.mandatory-fields-container, .optional-fields-container, .custom-fields-container {
    padding: 0px 40px;
    label:hover .fe-label-text {
        color: #004371 !important;
    }
    .fe-label:focus-within .fe-label-text, .fe-label:hover .fe-label-text {
        color: #004371 !important;
    }
}
.optional-fields-container, .custom-fields-container {
    margin-top: 20px;
}
.group-discount-submit {
    margin: 32px 0 48px 0;
    button {
        &:hover {
            background-color: #004371 !important;
        }    
    }
}
.required-text {
    margin-left: -15px;
    font-size: $text-tiny;
    padding-bottom: 16px;
}
.group-discount-success-screen {
    align-self: center;
    max-width: 576px;
    width: -webkit-fill-available;
}
.submitted-text {
    margin-top: -10px;
    position: relative;
    z-index: 1;
}
.btn-back-to-sparknotes {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
    a {
       &:hover {
        background-color: #004371 !important;;
       }  
    }
}
.success-header {
    @include media-mobile {
        margin-top: -2px !important;
        &::before {
           margin-top: 2px !important; 
        }
    }
}
.success-content {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    @include media-small {
        padding: 12px 20px 20px !important;
    }
}
.form-success-message {
    margin-top: 18px;
    padding-bottom: 20px;
    border-bottom: unset !important;
    @include media-small {
        margin-bottom: 5px;
    }
    p {
        font-size: $text-base !important;
        color:$sn-primary-paragraph;
        a {
            color: $color-brandBlue2020;
            &:hover {
                color: #004371 !important;
            }
        }
    }
}

.group-discount-header .sparknotes-png-logo {
    width: 250px;
    @include media-small {
        width: 240px;
    }
}

.group-discount-header .sparknotes-svg-logo {
    @include media-small {
        width: unset;
    }
}

#payment-form {
    margin-bottom: 100px;
    width: 456px !important;
    flex-direction: column;
    .submit-btn {
        background-color: #00f;
        height: 35px;
        text-align: center;
        border: 2px solid #00f;
        border-radius: 15px;
        margin-left: 5px;
        color: #fff;
        padding: 10px;
    }
    .StripeElement {
        height: unset !important;
    }
}

.radio-btn-options {
    padding-top: 4px;
}

.payable_radio_btn {
    display: flex;
    align-items: center;
}

.payment_option {
    width: 20px;
    height: 20px;
    accent-color: $sn-primary-header !important;
}

.payBy_text {
    padding-left: 8px;
    line-height: $lh-base;
    font-size: $text-base;
    color: $sn-primary-header;
}

.invoice_lable {
    margin-top: 8px;
}

#credit_card_pay {
    margin-bottom: 16px;
}

@include media-notSmall {
    .group-discount-success-screen {
        .snplusmodal__title img {
            width: 260px;
        }
    }
}