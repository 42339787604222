@mixin l-media-landscape-small {
  @media screen and (max-height: 420px) and (orientation: landscape) {
    @content;
  }
}

@mixin media-365 {
  @media screen and (max-width: 365px) {
    @content;
  }
}

@mixin homepageModifier {
  @if (#{&} == '.global-header' ) {
    &--homepage{
      @content
    }
  } @else {
    .global-header--homepage & {
      @content
    }
  }
}

@mixin notHomepageModifier {
  .global-header:not(.global-header--homepage) &{
    @content;
  }
}
.global-header{
  font-size: 1rem;
  font-weight: $fw-chubby;
}

.global-header {
  border-bottom: 1px solid $sn-gray;
  position: relative;
  color: $sn-primary-header;

  @include media-tablet-and-desktop {
    padding: 0 12px;
    background-color: $sn-bg;
  }

  @include homepageModifier{
    background-color: $color-brandBlue2020;
    border-color: $color-brandBlue2020;
    transition: background 0.2s;
    &:hover, &:focus-within {
      background: #00558E;
    }
  }

  &__container {
    @include container;
    display: flex;
    align-items: stretch;
    min-height: 64px;
    @include media-mobile {
      display: grid;
      grid-template-columns: 56px 1fr 56px;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    position: relative;
    &:hover{
      .logo__asterisk,
      .logo__notes{
        fill: $color-linkHover;
      }
      &::before{
        position: absolute;
        display: block;
        content: '';
        bottom: -1px;
        left: 0;
        right: 0;
        height: 6px;
        background: $color-linkHover;
      }
    }
    @include media-tablet-and-desktop {
      $logoWidth: 230px;
      flex: 0 1 $logoWidth;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 16px;
    }
    @include media-mobile {
      $logoWidth: 200px;
      flex: 0 1 $logoWidth;
      justify-self: center;
      max-width: $logoWidth;
    }
    @include homepageModifier {
      display: none;
    }

    svg {
      width: 100%;
      @include media-tablet-and-desktop {
        margin-bottom: 4px; // hack to align text in logo with links.
      }
    }
  }

}
.nav-main {
  @include media-mobile {
    display: none;
  }
  & > ul {
    display: flex;
  }
  display: flex;
  & + .nav-main{
    margin-left: auto;
  }
}

.nav-item {
  line-height: $lh-tight;
  display: flex;
  @include media-mobile-and-tablet{
    font-size: $text-small;
  }
}

.nav-sign-in, .nav-sign-out, .nav-mobile-sign-in, .nav-mobile-sign-out {
  &[hidden]{
    display: none;
  }
}

.nav-link {
  color: $sn-primary-header;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 8px 16px 0;
  font-size: $text-small;
  @include homepageModifier {
    color: $white;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 6px;
    @include homepageModifier {
      background-color: $white;
      height: 0px;
    }
    width: 100%;
    opacity: 0;
  }

  &:hover{
    @include notHomepageModifier {
      color: #004371;
    }
    path{
      fill: $color-linkHover;
    }
    &:after {
      opacity: 1;
    }
  }

  &--button {
    @include removeBtnStyles;
  }
  &--icon-and-text{
    & > * + *{
      margin-left: 8px;
    }
  }
  &__icon{
    display: flex;
    align-items: center;
    @include homepageModifier{
      path{
        fill: $white;
      }
    }
  }
}

.mobile-menu-reveal {
  @include removeBtnStyles;
  padding: 0;
  @include media-tablet-and-desktop {
    display: none;
  }
  @include media-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    &__text{
      @include visuallyhidden;
    }
    @include homepageModifier {
      path,
      rect{
        fill: $white;
      }
    }

  }
}

.mobile-search-reveal {
  @include removeBtnStyles;
  padding: $s-px * 2;
  @include media-tablet-and-desktop {
    display: none;
  }
}

@keyframes slideLeft {
  0% {
    right: 100%;
  }
  100% {
    right: 0;
  }
}
.global-nav-mobile-panel {
  display: none;
  @include media-mobile {
    &:not([hidden]) {
      display: flex;
      animation: slideLeft 0.3s;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  flex-direction: column;
  position: fixed;
  top: 0;
  background-color: $color-brandBlue2020;
  width: 100%;
  height: 100%;
  z-index: 99; //CB: seems higher than needed. Look into this later.
  filter: drop-shadow(4px 0 24px rgba(#000, 0.3));
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 100%;
    width: $radius-big;
    height: 100%;
    background: $color-brandBlue2020;
    border-radius: 0 $radius-big $radius-big 0;
  }

  .global-nav-mobile{
    &__search{
      margin-top: 12px;
      margin-bottom: 12px;
    }
    &__list{
      display: flex;
      flex-direction: column;
      align-items: stretch;
      @include container;
      &__item{
        font-weight: $fw-chubby;
        font-size: $text-base;
      }
    }
    &__link{
      color: $white;
      padding: 16px;
      display: block;
      font-size: 16px;
      border-radius: $radius-base;
      position: relative;
      &--button{
        @include removeBtnStyles;
        width: 100%;
        text-align: left;
      }
      &:before{
        position: absolute;
        display: block;
        content: '';
        bottom: 0;
        left: 16px;
        background-color: $color-linkHover;
        height: 4px;
        width: calc(100% - 32px);
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.2s;
      }
      &:hover:before{
        transform: scaleX(1);

      }
    }
    &__notlink {
      cursor: pointer;
      &:before {
        height: 0px !important;
      }
    }
  }
}

.new-global-nav-mobile-panel {
  display: none;
  @include media-mobile {
    &:not([hidden]) {
      display: flex;
      animation: slideLeft 0.3s;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  flex-direction: column;
  position: fixed;
  top: 0;
  background-color: $white;
  width: 100%;
  height: 100%;
  z-index: 99; //CB: seems higher than needed. Look into this later.
  filter: drop-shadow(4px 0 24px rgba(#000, 0.3));
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 100%;
    width: $radius-big;
    height: 100%;
    background: $color-brandBlue2020;
    border-radius: 0 $radius-big $radius-big 0;
  }

  .global-nav-mobile{
    &__search{
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &__list{
      display: flex;
      flex-direction: column;
      align-items: stretch;
      @include container;
      &__item{
        font-weight: $fw-chubby;
        font-size: $text-base;
      }
    }
    &__link{
      color: $sn-primary-header;
      padding: 18px 16px 18px 16px;
      display: block;
      font-size: $text-base;
      font-weight: $fw-chubby;
      border-radius: $radius-base;
      position: relative;
      &:hover, &:focus {
        color: #004371;
        svg {
          path {
            fill: #004371;
          }
        }
      }
      &__new-plus-content {
        color: #008262;
        &:hover, &:focus {
          color: #004371;
        }
      }
      &--button{
        @include removeBtnStyles;
        width: 100%;
        text-align: left;
      }
      &:before{
        position: absolute;
        display: block;
        content: '';
        bottom: 0;
        left: 16px;
        // background-color: $color-linkHover;
        // height: 4px;
        width: calc(100% - 32px);
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.2s;
      }
      &:hover:before{
        transform: scaleX(1);

      }
    }
    &__notlink {
      cursor: pointer;
      &:before {
        height: 0px !important;
      }
    }
  }
}

.global-header__button{
  @include removeBtnStyles;
}


// search overlay - probably should be a separate file. Assume it has been coupled to make js easier.
#search-overlay {
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba($color-brandBlue2020,1);
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  &:not([hidden]){
    display: flex;
    animation: fadeIn 0.2s;
  }
  .search-overlay{
    &__wrapper {
      align-self: center;
      flex: 1;
      width: 100%;
      max-width: 800px;
      padding-top: 20vh;
      @media (max-width: $mobile-bp) {
        padding-top: 12px;
      }
    }
  }
}

.close-modal-button{
  @include removeBtnStyles;
  align-self: flex-end;
  padding: $s-px * 2;
  flex: none;
  &:hover, &:focus{
    svg{
      filter: drop-shadow(0 0 12px $color-linkHover );
    }
    path{
      @include media-notSmall {
        fill: $sn-primary-paragraph;
      }  
    }
  }
}

.new-close-modal-button{
  @include removeBtnStyles;
  align-self: flex-end;
  padding: $s-px * 2;
  flex: none;
  &:hover, &:focus{
    // svg{
    //   filter: drop-shadow(0 0 12px $color-linkHover );
    // }
    svg {
      circle {
        @include media-notSmall {
          fill: $white;
        }
        @include media-small {
          fill: $white;
        }
      }
      path{
        @include media-notSmall {
          fill: $sn-primary-header;
        }
        @include media-small {
          fill: $sn-primary-header;
        }  
      }
    }
  }
}

.new-sign-up-plus {
  margin: 17px 16px 25px;
}

.ecom-nav {
  border-bottom: 1px solid #ccc;
  margin-bottom: 2rem;
  .ecom-nav__wrapper {
    @media (max-width: $mobile-bp) {
      margin: 20px auto;
      width: 100%;
    }
    width: 20%;
    margin: 25px auto;
    .global-header__logo__img {
      width: 300px !important;
      @media (max-width: $mobile-bp) {
        width: 250px !important;
      }
    }
  }
}

// Desktop design for My Acoount when user is signed-in.
.account-menu-container {
  display: flex;
  line-height: 1.4;
  margin-left: auto;
  align-items: center;
  padding-top: 5px;
  @include media-mobile {
    display: none;
  }
}
.account-menu {
  color: $sn-primary-header;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @include homepageModifier {
    color: $white;
    &:hover {
      color: $white !important;
    }
  }
}
.account-link {
  padding: 0px !important;
  &:after {
    height: 0px;
  }
}
.account-menu-link {
  padding-bottom: 14px !important;
  justify-content: left;
  font-size: $text-small !important;
  color: $sn-primary-header;
  width : 100%;
  @include homepageModifier {
    color: $sn-primary-header;
    &:hover {
      color: #004371;
    }
    &:focus-within {
      color: #004371;
    }
  }
}

.account-details-wrapper {
  visibility: hidden;
  position: absolute;
  top: 65px;
  right: 0px;
  z-index: 5;
  &:hover, &:focus-within {
    visibility: unset !important;
  }
}
.drop-btn {
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  &:hover, &:focus-within {
    .account-icon {
      svg {
        circle {
          stroke: #004371;
          @include homepageModifier {
            stroke: $white;
          }
        }
        ellipse {
          stroke: #004371;
          @include homepageModifier {
            stroke: $white;
          }
        }
        path{
          &:first-of-type {
            fill: #004371;
            stroke: unset !important;
            @include homepageModifier {
              fill: $white;
              stroke: unset !important;
            }
          }
          &:last-of-type {
            stroke: #004371;
            fill: unset !important;
            @include homepageModifier {
              stroke: $white;
              fill: unset !important;
            }
          }
        }
      }
    }
    .myaccount {
      color: $color-linkHover !important;
      @include homepageModifier {
        color:#41ffaa !important;
      }
    }
  }
  span {
    padding-top: 3px !important;
    padding-left: 5px !important;
    color: #008262;
    &:hover {
     color: $color-linkHover;
    }
    @include homepageModifier {
      color: #41ffaa;
    }
  }
}
.account-icon {
  svg {
    ellipse {
      stroke: #00cc9a;
    }
    path{
      &:first-of-type {
        fill: #00cc9a;
        stroke: unset !important;
      }
      &:last-of-type {
        stroke: #00cc9a;
        fill: unset !important;
      }
    }
    circle {
      stroke: #00cc9a;
    }
  }
  @include homepageModifier {
    svg {
      ellipse {
        stroke: $white;
      }
      path{
        &:first-of-type {
          fill: $white;
          stroke: unset !important;
        }
        &:last-of-type {
          stroke: $white;
          fill: unset !important;
        }
      }
      circle {
        stroke: $white;
      }
    }
  }
}

.account-arrow-icon{
  g {
    &:hover {
      fill: $color-linkHover;
    }
  }
  @include homepageModifier {
    g {
      fill: $white;
    }
  }
}

.account-dropdown {
  position: relative;
  height: 65px;
  &:hover,&:focus-within {
    .account-details-wrapper {
      visibility: unset !important;
    }
  }
  &:hover, &:focus-within {
    .account-icon {
      svg {
        circle {
          stroke: #004371;
          @include homepageModifier {
            stroke: $white;
          }
        }
        ellipse {
          stroke: #004371;
          @include homepageModifier {
            stroke: $white;
          }
        }
        path{
          &:first-of-type {
            fill: #004371;
            stroke: unset !important;
            @include homepageModifier {
              fill: $white;
              stroke: unset !important;
            }
          }
          &:last-of-type {
            stroke: #004371;
            fill: unset !important;
            @include homepageModifier {
              stroke: $white;
              fill: unset !important;
            }
          }
        }
      }
    }
  }
}
.account-dropdown-content {
  background-color: $white;
  min-width: 192px;
  border-top: 1px solid $sn-border-gray;
  overflow: auto;
  box-shadow:  0 4px 4px 0 rgba(118, 118, 118, 0.25);;
  z-index: 1;
  border-radius: 0px 0px 3px 3px;
}

.account-dropdown-content li {
  color: $sn-primary-header;
  padding-left: 20px;
  padding-top: 14px;
  padding-right: 6px;
  font-weight: $fw-base;
  &:first-of-type {
    padding-top: 14px;
    padding-right: 16px;
  }
  &:last-of-type {
    padding-top: 14px;
    padding-bottom: 16px;
  }
  &:hover {
    background-color: $color-teacherSoftGray;
  }
  &:focus-within {
    background-color: $color-teacherSoftGray;
  }
}
.signout-menu {
  border-top: 1px solid $sn-border-gray;
  padding-bottom: 2px !important;
}
.account-signout-color {
  color: $color-brandBlue2020;
  @include homepageModifier {
    color: $color-brandBlue2020;
  }
}
.account-plus-text-color {
  color: $color-noFear;
  @include homepageModifier {
    color: $color-noFear;
  }
}
.plus-content-desktop {
  font-weight: $fw-chubby !important;
}
.dashboard-link-disabled {
  opacity: 0.5;
  pointer-events: none;
}
//Desktop design when user has to sign-in for My Account.
.account-signin-box {
  display: flex;
  width: 145px;
  height: 34px;
  padding: 8px 5px;
  border-radius: 2px;
  margin-left: 5px;
  font-weight: bold;
  font-size: 15px;
  background-color: #41ffaa;
  color: #004a3e;
  @include homepageModifier {
    background-color: #41ffaa;
    color: #004a3e !important;
    span{
      color: #004a3e !important;
    }
  }
  &__mobile{
    background-color: $white;
  }
  &:hover {
   background-color: #00cc9a;
   @include homepageModifier {
    color: #004a3e;
    span {
      color: #004a3e;
    }
   }
   color: $white;
   span {
      color:$white;
    }
  }
}
.account-plus-text {
  color: $color-noFear;
  padding-left: 5px;
}

//Mobile design when user is signed-in.
.mobile-dropdown {
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.drop-btn-mobile {
  display: flex;
  border: 1px solid $white;
  border-right: 0px !important;
  border-left: 0px !important;
  cursor: pointer;
  color: $white;
  align-items: center;
  padding: 10px 10px 15px 10px;
  span {
    padding: 8px;
  }
}
.btn-style {
  background-color: $white;
  color: #004371;
}
.account-mobile-arrow-icon {
  margin-left: auto;
}
.account-mobile-icon {
  padding-left: 15px;
}
.hidden-items {
  display: none;
}
.expanded-account-icon{
  display: none;
}
.expanded-arrow-icon{
  display: none;
}
.account-details-wrapper-mobile {
  background-color: $white;
  margin-top: -5px;
  display: none;
}
.account-dropdown-content-mobile {
  overflow: auto;
  border-radius: 3px;
}
.account-dropdown-content-mobile li {
  color: $sn-primary-header;
  padding-left: 11px;
  padding-top: 13px;
  padding-bottom: 14px;
  padding-left: 32px;
  font-size: $text-base;
  &:hover {
    background-color: $color-teacherSoftGray;
  }
  &:focus-within {
    background-color: $color-teacherSoftGray;
  }
}
.account-menu-link-mobile {
  // padding-bottom: 7px !important;
  justify-content: left;
  font-weight: $fw-chubby;
  font-size: $text-base;
  &:hover {
    color: #004371 !important;
  }
}
//Mobile design when user has to sign-in.
.account-signin {
  display: flex;
  width: 170px;
  height: 34px;
  padding: 8px 5px;
  border-radius: 2px;
  color: #004a3e;
  &__mobile {
    background-color: #41ffaa;
    font-size: 15px;
    font-weight: bold;
  }
}

.new-account-signin {
  display: flex;
  width: 170px;
  height: 34px;
  padding: 8px 5px;
  border-radius: 2px;
  color: #004a3e;
  &:hover, &:focus {
    color: $white;
  }
  &__mobile {
    background-color: #41ffaa;
    font-size: 15px;
    font-weight: bold;
    &:hover, &:focus {
      background-color: #004371;
    }
  }
}

// to show the dropdown content for mobile and desktop
.show-account-item {
  display: block !important;
}

//Design for new global nav
.menu{
  @include homepageModifier {
    span {
      polyline {
        stroke: $white;
      }
    }
  }
  &:hover, &:focus-within {
    .menu__items {
      visibility: unset !important;
    }
  }
  &__items {
    left: auto !important;
    z-index: 10 !important;
    border-radius: 0 0 12px 12px !important;
    padding: 5px 0;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  }
  &__title-text {
    &:hover {
      span {
        polyline {
          stroke: $color-linkHover;
          @include homepageModifier {
            stroke: $white;
          }
        }
      }
      .plus-content {
        color: #004371 !important;
        @include homepageModifier {
          color: #41ffaa !important;
        }
      }
    }
    &__test-text {
      @include homepageModifier {
        color: $white;
      }
    }
    &__apPlus-text {
      color: #008262 !important;
      @include homepageModifier {
        color: $white !important;
      }
    }
  }
}
.arrow {
  display: none;
  padding-left: 10px;
}
.hiddenborder {
  border-left: 0px !important;
}
.link-subtitle {
  font-size: $text-small;
  font-weight: $fw-chubby;
}
.active-subtitle {
  color: #292c2e !important;
  text-transform: none !important;
}

//Design for mobile global nav
.menu-container {
  background-color: transparent;
  display: none;
  ul {
    li{
      padding-left: 15px;
      font-size: 1.125rem;
      a {
        color: $white !important;
      }
    }
  }
}

.new-menu-container {
  background-color: transparent;
  display: none;
  ul {
    li{
      padding-left: 15px;
      font-size: 1.125rem;
    }
  }
}

.mobile-arrow {
  float: right;
}
.up-mobile-arrow {
  transform: rotate(180deg);
}

.new-mobile-arrow {
  position: relative;
  bottom: 4px;
}

.plus-content {
  color: #41ffaa;
}

.new-plus-content {
  color: #008262;
}

.global-nav-mobile__link:hover .new-plus-content {
  color: #004371;
}

.global-nav-mobile__link:focus .new-plus-content {
  color: #004371;
}

button {
  &:focus-visible {
    outline: auto;
  }
}

.account-sign-in-btn {
  margin-right: 8px;
  margin-left: 8px;
  @include notHomepageModifier {
    border: 1px solid #004a3e;
  }  
  border-radius: 3px;
  font-size: $text-small;
  font-weight: $fw-chubby;
  line-height: normal;
  width: 76px;
  height: 30px;
  padding: 5px 7px;
  @include homepageModifier {
    background-color: transparent;
    border: 1px solid $white;
    color: $white;
  }
  &:hover {
    @include media-notSmall {
      border:1px solid #004371 !important;
      color: $white;
      background-color: #004371;
      @include homepageModifier {
        background-color: #004371;
        border:1px solid #004371 !important;
      }
    }  
  }
  button{
    color: $plus-green-dark;
  };
}
.signup-account-btn {
  font-size: $text-small;
  width: 142px;
  height: 30px;
  line-height: normal;
  border-radius: 3px;
  font-weight: $fw-chubby;
  align-items: center;
  justify-content: center;
  position: relative;
  span{
    font-weight: bold;
  }
  &:hover {
    @include media-notSmall {
      @include notHomepageModifier {
        background-color: #004371 !important;
      }
    }  
  }
}
.cta-container {
  display: flex;
  align-items: center;
}
.dashboard-btn {
  display: none;
  height: 30px;
  width: 170px;
  padding: 7px 16px;
  border-radius: 3px;
  border: solid 1px #00cc9a;
  background-color: #e1faef;
  font-size: $text-small;
  font-weight: $fw-chubby;
  line-height: normal;
  letter-spacing: -0.06px;
  text-align: center;
  color: $plus-green-dark;
  margin-left: 8px;
  margin-right: 8px;
  span{
    font-weight: bold;
  }
  &:hover {
    @include media-notSmall {
      background-color: #004371;
      color: $white;
      border: 1px solid #004371;
      @include homepageModifier {
        background-color: #004371;
        border: 1px solid #004371 !important;
      }
    }  
  }
}
.user-login-mobile {
  height: 60px;
  border: 1px solid $white;
  width: 100%;
  padding-left: 16px;
  justify-content: left;
  text-align: left;
  color: $white;
  font-size: $text-base;
  font-weight: $fw-chubby;
  border-left: 0;
  border-right: 0;
  margin-top: 10px;
  margin-bottom: 5px;
}
.dashboard-btn-mobile {
  width: 328px;
  height: 45px;
  display: none;
  border: solid 1.5px #00cc9a !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: $text-base;
  margin: 17px 16px 20px;
  padding: 7px 16px;
  border-radius: 3px;
  @include media-365 {
    width: 250px;
  }
}
.signup-account-btn-mobile {
  width: 328px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: $fw-chubby;
  line-height: normal;
  font-size: $text-base;
  margin: 17px 16px 25px;
  padding: 7px 16px;
  border-radius: 3px;
  @include media-365 {
    width: 250px;
  }
  span{
    font-weight: bold;
  }
}

.new-signup-account-btn-mobile {
  width: 328px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  line-height: normal;
  font-size: $text-base;
  margin: 17px 16px 25px;
  padding: 7px 16px;
  border-radius: 3px;
  @include media-365 {
    width: 250px;
  }
  span{
    font-weight: bold;
  }
}

.cross-close-btn{
  padding-bottom: 0;
}

.new-cross-close-btn{
  padding-bottom: 0;
}

.new-feature-link {
  display: none;
}

.my-plus-activity img {
  width: 24px;
  height: 24px;
  margin-left: auto;
}

.plus-arrow {
  margin-left: auto;
  transform: rotate(-90deg);
}

.plus-links:hover .new-feature-link {
  display: block;
  position: absolute;
  top: 0;
  right: 192px;
  z-index: 3;
}

.plus-links:hover .my-plus-activity .plus-arrow svg {
  polyline {
    stroke: #004371;
  }
}

.plus-links:focus-within .my-plus-activity .plus-arrow svg {
  polyline {
    stroke: #004371;
  }
}

.plus-links:focus-within .new-feature-link {
  display: block;
  position: absolute;
  top: 0;
  right: 192px;
  z-index: 3;
}

.plus-feature {
  background-color: $white;
  min-width: 226px;
  border-top: 1px solid $sn-border-gray;
  overflow: auto;
  box-shadow: 0 4px 4px 0 rgb(118 118 118 / 25%);
  z-index: 1;
  border-radius: 0 0 3px 3px;
  padding: 1px;
  &__menu {
    padding: 1px!important;
    &__list {
      display: flex;
      padding: 14px 18px 14px 18px;
      font-family: Raleway;
      font-size: $text-small;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-align: left;
      color: $sn-primary-header;
      &:hover {
        color: #004371;
      }
      &:focus-within {
        color: #004371;
      }
    }
  }
}

.plus-menu {
  padding: unset !important;
  &:hover, &:focus-within {
    background-color: unset !important;
  }
}

.plus-activity {
  display: flex;
  padding: 10px 16px 12px 32px;
  align-items: center;
  &:hover, &:focus-within {
    background-color: $color-teacherSoftGray;
  }
  @include media-small {
    &:hover, &:focus-within {
      background-color: unset;
    }
  }
  &__my-plus {
    color: $color-noFear;
    font-weight: $fw-chubby;
    font-size: $text-base;
  }
  &__my-active-plus {
    color: #004371;
  }
  &__active {
    margin-left: auto;
    cursor: pointer;
    display: flex;
    &__arrow{ }
  }
  &__menu {
    display: none;
    &__container {
      padding-left: 23px;
      &__list {
        padding: 13px 0px 13px 27px !important;
        &:first-child {
          padding: 10px 0px 13px 27px !important;
        }
        &:last-child {
          padding: 13px 0px 20px 27px !important;
        }
        &:hover, &:focus-within {
          background-color: $color-teacherSoftGray !important;
          color: #004371 !important;
        }
        @include media-small {
          &:hover, &:focus {
            background-color: unset !important;
            color: #004371 !important;
          }
        }
        &__links {
          font-family: Raleway;
          font-size: $text-base;
          font-weight: $fw-chubby;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          text-align: left;
          color: $sn-primary-paragraph;
        }
      }
    }
  }
}

.active-container {
  display: block;
}

.expand-arrow {
  display: none;
}

.unexpand-arrow {
  display: none;
}

.btn-style {
  svg {
    circle {
      &:last-of-type {
        stroke: #004371;
      }
    }
    path {
        fill: #004371;
        stroke: #004371;
    }
  }
}

.expired-plus-links .plus-arrow {
  display: none;
}

.mobile-expired-plus-links {
  .plus-activity__active {
    display: none;
  }
  .plus-menu {
    padding-bottom: 4px !important;
  }
}

.global-header--homepage {
  .plus-content-redesign {
    color: #41ffaa !important;
  }
}

.AP-R {
  vertical-align: top;
  font-size: .75rem;
}

.new-sign-up-plus .new-account-signin {
  width: 100%;
  margin: unset;
}

.global-header__container__newMobileGlobalNav {
  @include media-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; 
    padding: 0 12px;
  }
}
.global-header {
  &__newMobileGlobalNav {
    position: relative;
    @media (min-width: 981px) {
      display: none;
    }

    button {
      border: none;
      font-size: $text-small;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }
    
    &__button-signup {
      padding: 8px 12px;
      margin-right: 12px;
      background-color: #41ffaa;
      border-radius: 3px;
      letter-spacing: -0.45px;
      color: $plus-green-dark;

      &:active {
        background-color: #004371;
        color: $white;
      }
    }

    &__button-sigin {
      color: $white;
      background-color: transparent;
      letter-spacing: -0.06px;
      padding: unset;

      @include notHomepageModifier {
        color: $sn-primary-header;
      }

      &:active {
        text-decoration: underline;
        @include notHomepageModifier {
          color: #004371;
        }
      }
    }

    &__button-myPlus {
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8.7px;

      @include notHomepageModifier {
          color: #008262;
      }

      &__text {
        font-size: $text-small;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: $lh-base;
        letter-spacing: normal;
        text-align: center;
      }

      &__img {
        height: 32px;
        width: 32px;

        @include notHomepageModifier {
          svg {
            path {
              fill: #008262;
            }
          }
        }
      }

      &:active, &:focus-within {
        text-decoration: underline;
        @include notHomepageModifier {
          text-decoration: unset;
          .global-header__newMobileGlobalNav__button-myPlus__text {
            color: #004371;
          }
         .global-header__newMobileGlobalNav__button-myPlus__img {
            svg {
              path {
                fill: #004371;
              }
            }
          }
        }
      }
      &:hover {
        color: $white !important;
        @include notHomepageModifier {
          color: #008262 !important;
        }
      }
    }
  }

  &__new-mobile-logo {
    @include homepageModifier {
      display: none;
    }
  }
}

.new-mobile-menu-reveal {
  @media (min-width: 981px) {
    display: none;
  }
  @include media-mobile {
    @include notHomepageModifier {
      display: unset;
      a {
        display: inline-block;
        margin-left: 16px;
        svg {
          path {
            stroke: unset !important;
          }
        }
  
        &:active {
          svg {
            rect {
              fill: #004371;
            }
          }
        }
      }
      svg {
        path {
          stroke: $sn-primary-header;
        }
      }
      &:active {
        svg {
          path {
            stroke: #004371;
          }
        }
      }
    }
  }
}

.signout-logo, .signedin-logo {
  @include media-mobile {
    display: none;
  }
}

.new-account-logo-desktop {
  @include notHomepageModifier {
    svg {
      path {
        fill: #008262;
      }
    }
  }

  &:focus, &:hover {
    @include notHomepageModifier {
      svg {
        path {
          fill: #004371;
        }
      }
    }
  }
}

.test-prep-plus {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 
#plus-activity-arrow{
  &:hover,&:focus, &:active{
   svg {
     path {
       fill: #004371;
     }
   }
  }
}
#test-prep{
  &:hover,&:focus, &:active{
   svg {
     path {
       fill: #004371;
     }
   }
  }
}

.new-mobile-arrow-apTest {
  bottom: 0px !important;
  margin-right: 16px;
}

@media (max-width: 980px) {
  .global-header__newMobileGlobalNav__signIn {
    display: flex;
    gap: 8.7px;
  }
}

.account-details-wrapper-mobile-new-global-nav {
 .plus-activity__menu__container__list__links {
  color: $sn-primary-header;
  padding: 18px 16px 18px 16px;
  display: block;
  font-size: $text-base;
  font-weight: $fw-chubby;
  border-radius: 4px;
  position: relative;
  display: block !important
 }

 .account-menu-link-mobile {
  color: $sn-primary-header;
  padding: 18px 16px 18px 16px;
  display: block;
  font-size: $text-base;
  font-weight: $fw-chubby;
  border-radius: 4px;
  position: relative;
 }
}


@media (max-width: 980px) {
  .new-global-nav-mobile-panel {
    .plus-activity-new {
      display: flex;
    }

    .plus-activity__active {
      padding-right: 16px;
      align-items: center;
    }

    .horizontal-border {
      margin: 16px 0;
      border: solid 1px $sn-border-gray;
    }

    .nav-item--secondary-new-text {
      padding: 16px;
    }
    .plus-activity__menu__container__list-new {
      padding-left: 32px !important;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .plus-activity__menu__container__list__links-new {
      font-size: $text-base;
      font-weight: $fw-chubby;
      letter-spacing: 0.04px;
      color: $sn-primary-paragraph;
      
      &:hover {
        color: #004371;
      }
    }
  }
}

.js-mobile-nav-close-plus {
  &:focus,&:focus-within {
    border: solid 1px #000;
  }
}

.js-mobile-nav-close {
  &:focus,&:focus-within {
    border: solid 1px #000;
  }
}

.ada-class-added {
  &:focus, &:focus-within {
    border: solid 1px #000; 
  }
}

.global-header__new-mobile-logo {
  position: absolute;
  left: 60px;
  @media (min-width: 981px) {
    display: none;
  }
}

.new-mobile-menu-reveal {
  &:focus, &:focus-within {
    border: solid 1px black;
  }
}

.ada-focus-global-nav:focus {
  outline: solid 1px black;
}


.plus-study-tools-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plus-study-tools-mobile-container {
  padding-left: 16px;
  display: none;
}

.all-study-guides-mobile-container {
  padding-left: 16px;
  display: none;
}

.plusToolsMobileArrow {
  svg {
    path {
      &:hover {
        fill: #004371;
      }
    }
  }
}

.redesign-globalsearch {
  background: $white !important;
  padding: 16px 20px 16px 20px;
  overflow-y: auto;
  @include media-mobile {
    padding: 16px 0px 16px 0px;
    overflow-y: unset;
  }
    &::-webkit-scrollbar-track{
      margin: 10px 0;
    }
    &::-webkit-scrollbar{
        width: 10px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: $color-a11yGray;
        border-radius: 10px;
        height: 106px;
    }

  .globalSearch-logo {
    display: block;
    text-align: center;
  }
  
  .close-modal-button {
    display: inline;
    float: right;
    padding: 0;
  }

  .close-modal-button:hover {
    svg {
      filter: unset;
      path {
        fill: #484848;
      }
      circle {
        fill: $white;
      }
    }
  }

  .close-modal-button:focus {
    svg {
      filter: unset;
      path {
        fill: #484848;
      }
      circle {
        fill: $white;
      }
    }
  }

  .search-overlay {
    &__wrapper {
      align-self: center;
      flex: 1;
      width: 100%;
      max-width: 800px;
      padding-top: 80px !important;
      @media (max-width: $mobile-bp) {
        padding-top: 12px;
      }
    }
  }
}

.new-global-nav-mobile-panel .teacherlist .global-nav-mobile__notlink:hover {
  color: $sn-primary-header;
}

.new-global-nav-mobile-panel .allStudyGuidesMobile .global-nav-mobile__notlink:hover {
  color: $sn-primary-header;
}

// .allStudyGuidesMobile .remove-hover-color:hover {
//   color: #004371 !important;
// }

// .teacherlist .remove-hover-color:hover {
//   color: #004371 !important;
// }

.arrow-hover-blue {
  &:hover, &:focus {
    color: #004371 !important;
    svg {
      path {
        fill: #004371 !important;
      }
    }
  }
}

.new-mobile-arrow-apTest {
  &:hover, &:focus {
    color: #004371 !important;
    svg {
      path {
        fill: #004371 !important;
      }
    }
  }
}

.global-search-dropdown-container__recentSearches {
    font-family: Raleway;
    font-size: $text-small;
    font-weight: 700;
    line-height: $lh-base;
    color: $sn-primary-header;
    padding: 16px 0 0 16px;
}

.global-search-dropdown-container__recentSearches__content-items::before {
  content: url(/images/lit-hub-recent-search-icon.svg);
  position: absolute;
  left: 16px;
  top: 12px;
  width: 24px;
  height: 24px;
}

.global-search-dropdown-container__recentSearches__content-items {
  position: relative;
  padding: 11px 16px 11px 48px;
  font-size: 1rem;
  line-height: $lh-base;
  color: $sn-primary-paragraph;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-break: anywhere;

  &:hover {
    background-color: $color-teacherSoftGray;
    color: #004371;
    text-decoration: underline;
    text-underline-offset: 2px;
    cursor: pointer;
  }
}

.global-search-dropdown-container__recentSearches__cross-icons {
  display: none;
}

.global-search-dropdown-container__recentSearches__content-items:hover .global-search-dropdown-container__recentSearches__cross-icons {
  display: block;
  @include media-mobile {
    display: none;
  }
}

.global-search-dropdown-container__mobileRecentSearch {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.global-search-dropdown-container__recentSearches__mobile-clear {
  display: none;
  @include media-mobile {
    display: block;
    font-size: $text-small;
    line-height: $lh-base;
    text-align: left;
    color: #0066c4;
    cursor: pointer;
    padding: 16px 16px 0 0;
    &:hover, &:focus {
      color: #004371;
    }
  }
}

.global-search-dropdown-container__recentSearches__content-text {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
