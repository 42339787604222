$text-mobile-base: 0.8rem;
@mixin mobile-pixel-max {
  @media (min-width: 400px) and (max-width: 414px) {
    @content;
  }
}
@mixin mobile-galaxy-max {
  @media (max-width: 280px) {
    @content;
  }
}
@mixin mobile-iphone5-max {
  @media (max-width: 320px) {
    @content;
  }
}
@include premiumFlag{
    .flashCard-error-message{
      @keyframes bg {
        from{
          background: rgba($color-error,0);
        }
      }
      @keyframes content {
        from{
          opacity: 0;
          transform: translateY(48px);
        }
        to{
          opacity: 1;
          transform: translateY(0);
        }
      }
      margin-top: 170px;
      padding-left: 15px;
      @include media-small {
        margin: 152px 13px 178px 11px;
        padding: 12px 16px;
        text-align: center !important;
      }
      @include mobile-pixel-max {
        padding: 12px 35px;
      }
      @include mobile-galaxy-max {
        margin: 152px -10px;
        padding: 0;
      }
      @include mobile-iphone5-max {
        padding: 0;
      }
      &:not([hidden]){
        display: flex;
        margin-bottom: 16px;
        text-align: left;
      }
      &__icon{
        animation: content 0.4s;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        img{
          transform: translateY(-2px);
        }
      }
      &__text{
        animation: content 0.4s;
        color: $color-form-error;
        font-size: $text-base;
        line-height: $lh-base;
        @include media-small {
          font-size: $text-mobile-base;
        }
      }
      &__title{
        font-size: inherit;
        color: inherit;
        @include media-small {
          font-size: $text-mobile-base;
        }
      }
      &__info{
        color: inherit;
        @include media-small {
          font-size: $text-mobile-base;
        }
      }
    }
    .flashcard_interior__deckfooter-has-error {
      @include media-small {
        visibility: hidden;
      }
    }
  }