$text-mobile-base: 0.8rem;
@include premiumFlag {
  .audio-error-display {
    visibility: hidden;
    width: 540px;
    height: 60px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin: -65px 77px 14px 354px;
    padding: 0;
    border-radius: 4px;
    border: solid 2px #c51c00;
    background-color: #fbefed;
    @media (min-width:699px) and (max-width: 920px) {
      max-width: 390px;
      margin-left: 43%;
    }

    @include media-small { 
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      height: 64px;
      align-items: 0;
      gap: 0;
      margin: 0;
    }
  }

  .audio-error-message {
    display: flex;
    &__icon {
      padding-right: 10px;
      @media (min-width:699px) and (max-width: 920px) {
        padding-left: 7px;
        margin-top: 4px;
      }
      @include media-small {
        display: flex;
        padding-left: 18px;
      }
    }
    &__text {
      padding-top : 8px;
      @include media-small {
        padding-top: 0;
      }
    }
    &__title {
      font-size :15px;
      line-height: 1.2;
      color: $color-form-error;
      @include media-small {
        line-height: 1.6;
      }
    }
  }
}
  