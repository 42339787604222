$tablet-bp-max: 1024px;
$mobile-bp-max: 740px;
.sn-page-notfound{
    overflow: hidden;
}
.sn-page-notfound-container {
    height: 900px;
    max-width: 940px;
    margin: 0 auto;
    position: relative;
    @media (max-width: $mobile-bp-max){
        margin: 20px 12px 0px 12px;
        flex-direction: column-reverse;
        position: unset;
        height: 470px;
        display: flex;
    }
    &__textarea{
        padding-top:90px;
        @media (max-width: $mobile-bp-max){
            margin: 20px auto;
            align-self: center;
            padding: 0px;
        }
        @media (min-width: $mobile-bp-max) and (max-width:$tablet-bp-max){
            padding-left: $pageMargin;
        }
    }
    &:after{
        display: block;
        content:'';
        width: 1090px;
        height: 900px;
        background-image: url(/images/404-sheakespeare-illustration.jpg);
        z-index: -1;
        top: 0;
        left: 20%;
        position: absolute;
        @media (max-width : $tablet-bp-max) {
            width: 800px;
            left: 20%;
        }
        @media (max-width: $mobile-bp-max) {
            background-image: url(/images/404-sheakespeare-illustration-small.jpg);
            @include media-hi-res{
            background-image: url(/images/404-sheakespeare-illustration-small.jpg);
            }
            width: 270px;
            height: 300px;
            background-repeat: no-repeat;
            margin: 0 auto;
            position: unset;
            z-index: 0;
        }
      }

    h2 {
      font-size: $text-extra-big;
      color: $sn-brand-blue;
      width: 400px;
      font-weight: $fw-fat;
      line-height: $lh-tight;
      @media (max-width: $mobile-bp-max) {
        font-size: $text-big;
        text-align: center;
        width: 340px;
      }
    }
    p {
      width: 400px;
      font-size: $text-big-ish;
      font-weight: $fw-base;
      line-height: $lh-tight;
      color: $sn-primary-header;
      text-align: left;
      @media (max-width: $mobile-bp-max){
        font-size: $text-base;
        text-align: center;
        width: 340px;
        margin-top: 20px;
      }
    }
    a {
      color: $color-brandBlue2020;
      text-decoration: underline;
      font-weight: $fw-chubby;
      &:hover, &:focus{
          color:$color-linkHover;
      }
    }
  }
