@include premiumFlag{
  .inline-message{
    @keyframes test {
      from{
        opacity: 0;
        transform: translateY(80px);
      }
      to{
        opacity: 1;
        transform: translateY(0);
      }
    }
    &--success{
      background: $color-success;
    }
    &--fail{
      background: $color-error;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 40px 40px;
    &__icon{
      animation: test 0.4s;
      background: $white;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__text{
      animation: test 0.6s;
      color: $white;
      font-size: $text-base;
      line-height: $lh-base;
      text-align: center;
    }
    &__title{
      font-size: inherit;
      color: inherit;
      font-weight: $fw-chubby;
    }
    &__info{
      color: inherit;
    }
  }
}