.field-error {
    border-radius: 4px;
    border: 2px solid $color-form-error;
    margin-bottom: 16px;
  } 
  .error-title {
    background-color: rgba(197, 28, 0, 0.07);
    padding: 10px;
    text-align: left;
    color: $color-form-error;
    line-height: $lh-base;
  }