@mixin l-media-tablet {
    @media (max-width: 769px) {
      @content;
    }
 }
 
 @mixin l-media-desktop {
    @media (min-width: 770px) {
      @content;
    }
 }
 
 @mixin l-media-big-desktop {
    @media (min-width: 1140px) {
      @content;
    }
 }
 
 
 .TitleHeader {
    &_header {
        background: $color-brandBlue2020;
        padding-top: 16px;
        background-size: 70%, 100%;
        background-repeat: no-repeat;
        background-position: 100% 100%, 0 0;
        &--teachingGuide {
            background-color: $color-teacher;
        }
        &--ctQuizzes, &--flashcard, &--infographic, &--graphicNovel {
            @include premiumFlag {
                background-color: #008262;
            }
            @include premiumFlag {
                @include l-media-tablet {
                    background: #008262;
                }
            }
        }
        &__plus-title {
            width: 50px;
            padding: 1px 8px;
            border-radius: 4px;
            background-color: #41ffaa;
            margin-bottom: 5px;
            &__text {
                font-family: Raleway;
                font-size: $text-tiny;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #004a3e;
            }
        }
        &--plus-header {
            background-image: linear-gradient(84deg, #004635 0%, #008262 50%);
            background-size: unset;
            @include l-media-tablet {
                background-image: linear-gradient(177deg, #014836 -4%, #018262 55%);
            }
        }
        &--nonplus-header {
            background-image: linear-gradient(85deg, #024e82 17%, #017bcd 70%);
            background-size: unset;
            @include l-media-tablet {
                background-image: linear-gradient(177deg, #024e82 -4%, #007acd 65%);
            }
        }
    }
    &_heading {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include l-media-tablet {
            padding-left: 12px;
            padding-right: 12px;
            align-items: center;
            text-align: center;
        }
    }
    &_title {
        color: $white;
        font-weight: $fw-huge;
        font-size: $text-extra-big;
        line-height: $lh-tight;
        margin-bottom: 8px;
    }
    &_container {
        @include container;
        @include l-media-desktop {
            padding-left: 12px;
            padding-right: 12px;
        }
        @include premiumFlag {
            @include l-media-tablet {
                padding-bottom: 47px !important;
            }
        }
        @include l-media-tablet {
            padding-bottom: 43px;
        }
    }
    &_tabs {
        display: flex;
        margin-top: 24px;
        @include l-media-desktop {
            &.mobile-nav {
                display: none;
            }
        }
        @include l-media-tablet {
            &.desktop-nav {
                display: none;
            }
            &.mobile-nav {
                display: block;
            }
 
            &__dropdown {
                width: 94.5%;
                margin: 0 10px;
                position: absolute;
                border: none;
                border-radius: $radius-big $radius-big 0 0;
                background-color: $white;
                text-align: center;
                z-index: 4;
                &.tab-expanded {
                    border-radius: 12px;
                }
                & .tab-selected {
                    color: $sn-primary-header;
                    font-size: $text-small;
                    font-weight: $fw-chubby;
                    padding: 15px 0;
                    span {
                        a {
                            color: $sn-primary-header !important;
                        }
                    }
                    &.select-expanded {
                        border-bottom: 1px solid $sn-border-gray;
                    }
                    & .icon-collapsed {
                        float: right;
                        margin: -5px 16px 0 -40px;
                    }
                    & .icon-expanded {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }
                & .tab-list {
                    color: $color-brandBlue2020;
                    font-size: $text-small;
                    font-weight: $fw-base;
                    padding: 15px 0;
                    &__deactived-link {
                        @include premiumFlag {
                            color: #004a3e;
                        }
                    }
                    &__active-plus-menu {
                        color: $color-brandBlue2020;
                        font-weight: 600;
                        @include premiumFlag {
                            color: #004a3e !important;
                            font-weight: 600;
                        }
                    }
                    &__active-optionTab {
                        color: $color-brandBlue2020;
                        font-weight: 600;
                        &:hover,
                        &:focus {
                            color: $color-linkHover;
                        }
                    }
                    &__optionTab {
                        color: $color-brandBlue2020;
                        &:hover,
                        &:focus {
                            color: $color-linkHover;
                        }
                    }
                    &:hover,
                    &:focus {
                        background-color: $color-teacherSoftGray;
                        color: $color-linkHover;
                        border-radius: 12px;
                        text-decoration: none !important;
                    }
                    &:last-child {
                        padding-bottom: 22px;
                    }
                }
            }
        }
    }
    &_tab {
        display: block;
        background-color: #0064a7;
        color: $white;
        text-decoration: none;
        position: relative;
        line-height: $lh-extra-tight;
        font-weight: $fw-chubby;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        @include l-media-desktop {
            padding: 16px 32px;
        }
        @include l-media-desktop {
            margin-right: 8px;
        }
        &.teachingGuideTab {
            background-image: url("/images/icon-thumb-samples.png");
            background-repeat: no-repeat;
            background-size: 27%;
        }
        & span {
            font-weight: $fw-fat;
        }
        &:not(.TitleHeader_tab--active):hover,
        &:not(.TitleHeader_tab--active):focus {
            background-color: #004878;
            color: $white;
            outline: none;
        }
        @include l-media-tablet {
            padding: 12px 8px;
            flex: 1 1 auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            + .TitleHeader_tab {
                margin-left: 4px;
            }
        }
        &--active {
            background-color: $white;
            color: $color-brandBlue2020;
            &:hover {
                color: $color-brandBlue2020;
            }
        }
    }
 
    &_authorLink {
        display: block;
        color: $white;
        font-size: $text-small;
        line-height: $lh-tight;
        font-weight: $fw-chubby;
        text-decoration: none;
        &__header {
            padding: 6px 16px;
            border-radius: 4px;
            box-shadow: 0 0 4px 0 $white;
            border: solid 1px rgba(255, 255, 255, 0.2);
            font-family: Raleway;
            font-size: $text-base;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: normal;
            color: $white;
            &:hover, &:focus {
                background-color: #004371;
                color: $white;
            }
        }
    }
    &_authorName {
        font-size: $text-small;
        line-height: $lh-tight;
        font-weight: $fw-chubby;
        color: $white;
        &__header {
            font-weight: $fw-chubby;
            font-size: $text-base;
            color: $white;
        }
    }
 }
 @include l-media-tablet {
    .list-expanded {
        background: $sn-border-gray;
        opacity: 0.5;
        pointer-events: none;
        overflow-y: hidden;
        &.teacher {
            padding-top: 36px;
            margin-top: 0;
        }
    }
 }
 
 //apQuiz Tab design
 .aptest-unactive {
    background-color: #008262;
    border-top: 4px solid #00cc9a;
    border-left: 4px solid #00cc9a;
    border-right: 4px solid #00cc9a;
    &:hover, &:focus {
        background-color: #004a3e !important;
        color: $white !important;
        box-shadow: none !important; 
    }
 }
 
 .aptest-active {
    color: #004a3e;
    border-top: 4px solid #00cc9a;
    border-left: 4px solid #00cc9a;
    border-right: 4px solid #00cc9a;
    &:hover {
        background-color: $white !important;
        color: unset !important;
    }
 }
 
 .aptest-signout .aptest-active {
    background-color: #e1faef!important;
 }
 
 .aptest-signin .aptest-active {
    background-color: $white !important;
 }
 
 .apTest-background-color {
    background: #008262;
    padding-top: unset;
    @include l-media-tablet {
        padding-top: 2px;
    }
 }

 .new-apTest-background-color {
    background-image: linear-gradient(84deg, #004635 0, #008262 50%);
    background-size: unset;
    padding-top: unset;
    @include l-media-tablet {
        padding-top: 2px;
    }
 }

 .newAP-apTest-background-color {
    background-image: linear-gradient(84deg, #004635 0, #008262 50%);
    background-size: unset;
    padding-top: unset;
    @include l-media-tablet {
        background-image: linear-gradient(176deg, #004635 0, #008262 50%);
        padding-top: 2px;
    }
 }
 
 .apTest-Tab {
    position: absolute;
    bottom: 0%;
    padding-left: unset;
 
    @include l-media-tablet {
        position: relative;
        bottom: unset;
        padding-left: unset;
        padding-bottom: 47px;
    }
    
 }

.apTest-Tab-hidden { 
    display: none;
}

 
 .apTest-container {
    display: flex;
    width: 100%;
    max-width: 1164px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
 
    @include l-media-tablet {
        flex-direction: column;
    }
 
    &__apText-container {
        width: 50%;
        padding-top: 40px;
        padding-bottom: 60px;
 
        @include l-media-tablet {
            width: unset;
            padding: 20px 10px 0px 10px;
        }
 
        &__prep-text {
            font-size: 31px;
            font-weight: $fw-fat;
            text-align: left;
            color: $white;
            line-height: $lh-loose;
 
            @include l-media-tablet {
                text-align: center;
                font-size: $text-big;
                font-weight: $fw-huge;
                line-height: $lh-base;
                padding-bottom: 10px;
            }
 
            &__prep-plus {
                color: #41ffaa;
            }
 
        }
 
        &__exam-text {
            font-size: $text-base;
            font-weight: normal;
            line-height: 1.75;
            text-align: left;
            color: $white;
 
            @include l-media-tablet {
                text-align: center;
                display: none;
            }
 
        }
 
        &__exam-text-mobile {
            font-size: $text-base;
            font-weight: normal;
            line-height: 1.75;
            text-align: left;
            color: $white;
            display: none;
 
            @include l-media-tablet {
                text-align: center;
                font-size: $text-small;
                font-weight: $fw-chubby;
                line-height: normal;
                display: block;
            }
 
        }
 
    }

    &__new-apText-container {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding-top: 40px;
        // padding-bottom: 60px;
 
        @include l-media-tablet {
            width: unset;
            padding: 20px 10px 0px 10px;
        }
 
        &__prep-text {
            font-size: $text-very-big;
            font-weight: $fw-fat;
            text-align: left;
            color: $white;
            line-height: $lh-loose;
 
            @include l-media-tablet {
                text-align: center;
                font-size: $text-big;
                font-weight: $fw-huge;
                line-height: $lh-base;
                padding-bottom: 10px;
            }

            @include media-small {
                font-size: $text-very-big;
                font-weight: $fw-fat;
            }
 
            &__prep-plus {
                color: #41ffaa;
            }
 
        }

        //add css for ap landing link for landing header of AP
        &__prep-link {
            font-size: 700;
            font-weight: $fw-fat;
            text-align: left;
            color: $white;
            line-height: $lh-loose;
            
            &:hover , &:focus {
                color: $white;
                text-decoration: underline;
                text-decoration-thickness: 2px;
                text-underline-offset: 10px;
            }

            @include l-media-tablet {
                text-align: center;
                font-size: $text-big;
                font-weight: $fw-huge;
                line-height: $lh-base;
                padding-bottom: 10px;
            }

            @include media-small {
                font-size: $text-very-big;
                font-weight: $fw-fat;
            }
 
            &__prep-plus {
                color: #41ffaa;
            }
        }
 
        &__exam-text {
            font-size: $text-base;
            font-weight: normal;
            line-height: 1.75;
            text-align: left;
            color: $white;
 
            @include l-media-tablet {
                text-align: center;
                display: none;
            }
 
        }
 
        &__exam-text-mobile {
            font-size: $text-base;
            font-weight: normal;
            line-height: 1.75;
            text-align: left;
            color: $white;
            display: none;
 
            @include l-media-tablet {
                text-align: center;
                font-size: $text-small;
                font-weight: $fw-chubby;
                line-height: normal;
                display: block;
            }
 
        }
 
    }
 
    &__apImg-container {
        width: 50%;
        
        @include l-media-tablet {
            width: unset;
            display: none;
        }
 
        &__img {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0px 10px;
 
            @media screen and (min-width:1280px){
                margin: 0px 90px;
            }
 
            @include l-media-tablet {
                margin: unset;
                display: none;
            }
 
        }
    }

    // &__new-apImg-container {
    //     width: 50%;
        
    //     @include l-media-tablet {
    //         width: unset;
    //         display: none;
    //     }
 
    //     &__img {
    //         display: block;
    //         width: 100%;
    //         height: 100%;
    //         margin: 0px 10px;
 
    //         @media screen and (min-width:1280px){
    //             margin: 0px 40px;
    //             width: unset;
    //             height: unset;
    //         }
 
    //         @include l-media-tablet {
    //             margin: unset;
    //             display: none;
    //         }
 
    //     }
    // }

    //for subscriber user related css
    &__new-apText-container {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 34px;
        padding-bottom: 34px;
        
        @media screen and (max-width:1145px) {
            width: unset;
            padding: 20px 10px 24px 10px;
        }

        // @include l-media-tablet {
        //     width: unset;
        //     padding: 20px 10px 0px 10px;
        // }
        
        @include media-small {
            padding: 20px 10px 24px 10px;
        }

        &__container {
            display: flex;
            align-items: center;
            padding-bottom: 12px;

            @include l-media-tablet {
                padding-bottom: unset;
                justify-content: center;
            }

            &__prep-text {
                font-size: $text-very-big;
                font-weight: $fw-fat;
                text-align: left;
                color: $white;
                line-height: $lh-loose;
     
                @include l-media-tablet {
                    padding-bottom: 8px;
                    text-align: center;
                    font-size: $text-very-big;
                    font-weight: $fw-huge;
                    line-height: $lh-base;
                    padding-bottom: 10px;  
                    
                }
     
                &__prep-plus {
                    color: #41ffaa;
                }
     
            }

            //add css for ap landing link for interior header of AP
            &__prep-link {
                font-size: $text-very-big;
                font-weight: 700;
                text-align: left;
                color: $white;
                line-height: $lh-loose;

                &:hover , &:focus {
                    color: $white;
                    text-decoration: underline;
                    text-decoration-thickness: 2px;
                    text-underline-offset: 10px;
                }
     
                @include l-media-tablet {
                    text-align: center;
                    font-size: $text-very-big;
                    font-weight: $fw-huge;
                    line-height: $lh-base;
                    padding-bottom: 10px;  
                    
                }
     
                &__prep-plus {
                    color: #41ffaa;
                }
     
            }

            &__dropdown-container {
                position: relative;
                padding-left: 18px;

                @include l-media-tablet {
                    display: none;
                }
                
                &__active {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: $white;
                    padding: 8px 0px 8px 10px;
                    border-radius: 12px;
                    border: solid 1px $white;
                    width: 278px;
                    &__menu {
                        font-family: Raleway;
                        font-size: 18px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.6;
                        letter-spacing: normal;
                        text-align: left;
                        color: #008262;
                    }
                    &__icon {
                        cursor: pointer;
                        position: relative;
                        top: 2px;
                        padding: 0px 11px 0px 11px;
                    }
                }

                &__menu-container {
                    display: none;
                    position: absolute;
                    top: 56px;
                    width: 278px;
                    &__ul-content {
                        display: flex;
                        background-color: $white;
                        flex-direction: column;
                        box-shadow: 0 4px 4px 0 rgba(118, 118, 118, 0.25);
                        border-top: solid 1px #f5f1f5;
                        border-radius: 8px;
                        padding-top: 8px;
                        padding-bottom: 8px;
                        &__li { 
                            padding-left: unset;
                            padding-right: unset;
                            &__unactive {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                font-family: Raleway;
                                font-size: 18px;
                                font-weight: 600;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.6;
                                letter-spacing: normal;
                                text-align: left;
                                color: #008262 !important;
                                padding: 8px 0px 8px 12px;
                                &:hover, &:focus {
                                    color: #004371 !important;
                                    background-color: #f5f1f5;
                                }
                            }
                            &__active {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                font-family: Raleway;
                                font-size: 18px;
                                font-weight: 600;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.6;
                                letter-spacing: normal;
                                text-align: left;
                                color: $white !important;
                                padding: 8px 0px 8px 12px;
                                background-color: #008262;
                                &:hover, &:focus {
                                    color: $white !important;
                                    background-color: #008262;
                                }
                            }
                        }
                    }
                }
            }
        }
        
        &__exam-text {
            font-size: $text-base;
            font-weight: normal;
            line-height: 1.75;
            text-align: left;
            color: $white;
 
            @include l-media-tablet {
                text-align: center;
                display: none;
            }
 
        }
 
        &__exam-text-mobile {
            font-size: $text-base;
            font-weight: normal;
            line-height: 1.75;
            text-align: left;
            color: $white;
            display: none;
 
            @include l-media-tablet {
                text-align: center;
                font-size: $text-slightly-big;
                font-weight: $fw-chubby;
                line-height: normal;
                display: block;
            }

            @include media-small {
                font-size: $text-slightly-big;
            }
 
        }
 
    }

    //new ap interior page css
    &__new-apinterior {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 22px;
        padding-bottom: 23px;
        
        // @media screen and (max-width:1145px) {
        //     width: unset;
        //     padding: 20px 10px 24px 10px;
        // }

        // @include l-media-tablet {
        //     width: unset;
        //     padding: 20px 10px 0px 10px;
        // }
        
        @include media-small {
            padding: 20px 10px 24px 10px;
        }

        &__container {
            display: flex;
            align-items: center;
            
            @include l-media-tablet {
                padding-bottom: unset;
                justify-content: center;
            }

            &__prep-text {
                font-size: $text-very-big;
                font-weight: $fw-fat;
                text-align: left;
                color: $white;
                line-height: $lh-loose;
     
                @include l-media-tablet {
                    padding-bottom: 8px;
                    text-align: center;
                    font-size: $text-very-big;
                    font-weight: $fw-huge;
                    line-height: $lh-base;
                    padding-bottom: 10px; 
                    
                    @include media-small {
                        font-size: $text-slightly-big;
                        font-weight: $fw-chubby;
                    }   
                }
     
                &__prep-plus {
                    color: #41ffaa;
                }
     
            }

            //add css for ap landing link for interior header of AP
            &__prep-link {
                font-size: $text-very-big;
                font-weight: 700;
                text-align: left;
                color: $white;
                line-height: $lh-loose;

                &:hover , &:focus {
                    color: $white;
                    text-decoration: underline;
                    text-decoration-thickness: 2px;
                    text-underline-offset: 10px;
                }
     
                @include l-media-tablet {
                    text-align: center;
                    font-size: $text-very-big;
                    font-weight: $fw-huge;
                    line-height: $lh-base;
                    padding-bottom: 10px;  

                    @include media-small {
                        font-size: $text-slightly-big;
                        font-weight: $fw-chubby;
                    }      
                }
     
                &__prep-plus {
                    color: #41ffaa;
                }
     
            }

            &__dropdown-container {
                position: relative;
                padding-left: 18px;

                @include l-media-tablet {
                    display: none;
                }
                
                &__active {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                    background-color: $white;
                    padding: 8px 16px;
                    border-radius: 12px;
                    border: solid 1px $white;
                    &__menu {
                        font-family: Raleway;
                        font-size: $text-slightly-big;
                        font-weight: $fw-chubby;
                        line-height: $lh-base;
                        letter-spacing: normal;
                        text-align: left;
                        color: #008262;
                        padding-right: 22px;
                    }
                    &__icon {
                        cursor: pointer;
                        position: relative;
                        top: 1px;
                    }
                }

                &__menu-container {
                    display: none;
                    position: absolute;
                    top: 56px;
                    &__ul-content {
                        display: flex;
                        background-color: $white;
                        flex-direction: column;
                        box-shadow: 0 4px 4px 0 rgba(118, 118, 118, 0.25);
                        border-top: solid 1px #f5f1f5;
                        border-radius: 8px;
                        padding-top: 8px;
                        padding-bottom: 8px;
                        &__li { 
                            padding-left: unset;
                            padding-right: unset;
                            &__unactive {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                font-family: Raleway;
                                font-size: $text-slightly-big;
                                font-weight: $fw-chubby;
                                line-height: $lh-base;
                                text-align: left;
                                color: #008262 !important;
                                padding: 8px 0px 8px 12px;
                                &:hover, &:focus {
                                    color: #004371 !important;
                                    background-color: #f5f1f5;
                                }
                            }
                            &__active {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                font-family: Raleway;
                                font-size: $text-slightly-big;
                                font-weight: $fw-chubby;
                                line-height: $lh-base;
                                text-align: left;
                                color: $white !important;
                                padding: 8px 0px 8px 12px;
                                background-color: #008262;
                                &:hover, &:focus {
                                    color: $white !important;
                                    background-color: #008262;
                                }
                            }
                        }
                    }
                }
            }
        }
        
        &__exam-text {
            font-size: 30px;
            font-weight: 700;
            line-height: $lh-base;
            text-align: left;
            color: $white;
 
            @include l-media-tablet {
                text-align: center;
                display: none;
            }
 
        }
 
        &__exam-text-mobile {
            font-size: $text-base;
            font-weight: normal;
            line-height: 1.75;
            text-align: left;
            color: $white;
            display: none;
 
            @include l-media-tablet {
                text-align: center;
                font-size: $text-slightly-big;
                font-weight: $fw-chubby;
                line-height: normal;
                display: block;
            }

            @include media-small {
                font-size: $text-slightly-big;
            }
 
        }
    }

    // //for subscriber user related css
    &__new-apImg-container {
        width: 46%;

        @media screen and (max-width:1145px) {
            width: unset;
        }

        @include media-small {
            display: none;
        }

        &__img {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0px 0px;

            @media screen and (min-width:1280px) {
                margin: 0px 0px;
                width: unset;
                height: unset;
            }

            @media screen and (max-width:1145px) {
                display: none;
            }

        }
    }

    //new ap interior page css
    &__new-apInteriorImg {
        width: auto;
        position: relative;
        top: 4px;

        // @media screen and (max-width:1145px) {
        //     width: unset;
        // }

        @include media-small {
            display: none;
        }

        &__img {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0px 0px;

            @media screen and (min-width:1280px) {
                margin: 0px 0px;
                width: unset;
                height: unset;
            }

            @media screen and (max-width:1145px) {
                display: none;
            }

        }
    }

    &__mobileNew-apText-container {
        display: none;
        @include l-media-tablet {
            display: block; 
            &__active {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: $white;
                margin: 10px 12px 27px 12px;
                border-radius: 12px;
                border: solid 1px $white;
                cursor: pointer;
                &__text {
                    font-family: Raleway;
                    font-size: $text-slightly-big;
                    font-weight: $fw-chubby;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.6;
                    letter-spacing: normal;
                    text-align: left;
                    color: #008262;
                    padding: 10.5px 12px 10.5px 12px;
                }
                &__arrow {
                    padding: 10.5px 12px 10.5px 12px;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
 }
 
 //aptest mobile design
 .ap-test-tab {
    @include l-media-tablet {
        border-top: 4px solid #00cc9a;
        border-left: 4px solid #00cc9a;
        border-right: 4px solid #00cc9a;
        width: 100%;
        margin: unset;
        cursor: auto;
    }
 }
 
 .ap-test-tab.ap-test-tab-signout {
    background-color: #e1faef;
    
    .active-tab.tab-selected {
        border-bottom: unset;
    }
 }
 
 .ap-test-tab.ap-test-tab-signin {
    background-color: $white;
 
    .active-tab.tab-selected {
        border-bottom: unset;
    }
 }
 
 .expand-bottom-border {
    border-bottom: 4px solid #00cc9a;
 }
 
 .active-tab {
    color: #004a3e;
 }
 
 .deactive-tab {
    color: #004a3e;
 }
 
 .aptest-hover {
    &:hover,
    &:focus {
        background-color: #cbeedf !important;
        border-radius: unset !important;
        text-decoration: none !important;
        cursor: auto;;
    }
    &:last-child {
        border-bottom-right-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
        padding-bottom: 25px !important;
    }
 }
 
 .dropdown-expand {
    padding-bottom: 13px !important;
 }
 
 .plustab {
    @include premiumFlag {
        background-color: #008262;
        border: solid 4px $sn-green;
        border-bottom: none;
        &:hover, &:focus {
            background-color: #005d46 !important;
            color: $white !important;
        }
    }
 }
 
 .plustab__panel {
    &__plus-container {
        display: none;
        @include premiumFlag {
            display: unset;
            position: absolute;
            left: -4px;
            top: -4px;
            width: 95px;
            height: 85px;
            overflow: hidden;
            &__plus-text {
                position: absolute;
                transform: translate(23%,-14%) rotate(-45deg);
                background: #41ffaa;
                width: 100%;
                top: 14px;
                left: -48px;
                line-height: 1.5;
                color: #004a3e;
                font-size: 12px;
                font-weight: 700;
                text-align: center;
            }
        }
    }
 }
 
 .plustab-signout .TitleHeader_tab--active.plustab {
    @include premiumFlag {
        background-color: #e1faef !important;
        color: $plus-green-dark !important;
        &:hover {
            color: $plus-green-dark !important;
        }
    }
 }
 
 .plustab-signin .TitleHeader_tab--active.plustab {
    @include premiumFlag {
        background-color: $white !important;
        color: $plus-green-dark !important;
        &:hover {
            color: $plus-green-dark !important;
        }
    }
 }
 
 //main menu mobile design
 .main-menu-border {
    @include premiumFlag {
        @include l-media-tablet {
            border-left: 4px solid #00cc9a;
            border-right: 4px solid #00cc9a;
            &:last-child {
                border-bottom: 4px solid #00cc9a;
                border-left: 4px solid #00cc9a;
                border-right: 4px solid #00cc9a;
            }
        }
    }
 }
 
 .active-plus-menu {
    @include premiumFlag {
        @include l-media-tablet {
            &:first-child {
                border-top: 4px solid #00cc9a;
                border-left: 4px solid #00cc9a;
                border-right: 4px solid #00cc9a;
            }
        }
    }
 }
 
 .mobile-plus-signout .plus-mark {
    @include premiumFlag {
        @include l-media-tablet {
            background-color: #e1faef;
            color: #004a3e;
        }
    }
 }
 
 .plus-mark-hover {
    @include premiumFlag {
        @include l-media-tablet {
            &:hover {
                background-color: #cbeedf !important;
                border-radius: unset !important;
                &:last-child {
                    border-radius: 0 0 12px 12px !important;
                }
            }
        }
    }
 }
 
 .dropdown-round-corner {
    @include premiumFlag {
        @include l-media-tablet {
            &:last-child {
                border-radius: 0 0 12px 12px;
            }
        }
    }
 }
 
 .active-plus-dropdown {
    @include premiumFlag {
        @include l-media-tablet {
            &:first-child {
            border-radius: 12px 12px 0 0;
            }
        }
    }
 }
 
 .active-non-plus {
    @include premiumFlag {
        @include l-media-tablet {
            &:first-child {
                border-top: 4px solid #f5f1f5;
                border-left: 4px solid #f5f1f5;
                border-right: 4px solid #f5f1f5;
            }
        }
    }
 }
 
 .non-plus-mark {
    @include premiumFlag {
        @include l-media-tablet {
            border-left: 4px solid #f5f1f5;
            border-right: 4px solid #f5f1f5;
            &:hover {
                border-left: 4px solid #767676;
                border-right: 4px solid #767676;
            }
            &:last-child {
                border-bottom: 4px solid #f5f1f5;
                border-left: 4px solid #f5f1f5;
                border-right: 4px solid #f5f1f5;
                &:hover {
                    border-bottom: 4px solid #767676;
                    border-left: 4px solid #767676;
                    border-right: 4px solid #767676;   
                }
            }
        }
    }
 }
 
 .non-plus-mark-hover {
    @include premiumFlag {
        @include l-media-tablet {
            border-radius: unset;
            &:last-child {
                border-radius: 0 0 12px 12px !important;
            }
            &:hover {
                border-radius: unset !important;
            }
            &:last-child {
                &:hover {
                    border-radius: 0 0 12px 12px !important;  
                }
            }
        }
    }
 }
 
 .apTest-interior-padding {
    @include  l-media-big-desktop {
        padding-left: unset !important;
        padding-right: unset !important;
    }    
 }
 
 .apTest-interior-height {
    height: 235px;
    @include l-media-tablet {
        height: unset;
    }
    .apTest-container__apText-container__prep-text {
        font-size: 28px !important;
        line-height: 1.2;
        @include l-media-big-desktop {
            font-size: 31px !important;
        }
        @include l-media-tablet {
            font-size: $text-slightly-big !important;
        }
    }
 }
 
 .prep-text-heading {
    color: #41ffaa;
 }
 
 .apTest-interior-image {
    @include l-media-big-desktop {
        padding-left: 100px;
        width: unset; 
    }
    height: 100%;
    width: 100%;
 }
 // @media (min-width: 980px) {
    .redesign_title_header {
        padding: 24px 0 38px;
        @include l-media-tablet {
            padding: 23px 0 35px;
        }
    }
    .redesign_title_header {
        .TitleHeader_authorLink__header {
            box-shadow: none;
            border: none;
            padding: 5px 0;
            font-size: $text-base;
            font-weight: $fw-chubby;
            line-height: 1.3;
            &:hover, &:focus {
                background-color: unset;
                color: $white;
            }
        }
        .TitleHeader_heading {
            // max-width: 441px;
            overflow-wrap: break-word;
            @include l-media-tablet {
                align-items: flex-start;
                padding-left: 30px;
                padding-right: 31px;
                text-align: unset;
            }
        }
        .TitleHeader_title {
            font-size: 30px;
            margin-bottom: 5px;
            font-weight: $fw-fat;
        }
        .TitleHeader_authorLink {
            background: none !important;
            font-size: $text-base;
            font-weight: $fw-chubby;
            line-height: 1.3;
            &:hover, &:focus {
                text-decoration: underline;
                text-decoration-thickness: 2px;
                text-underline-offset: 6px;
                color: $white;
            }
        }
        .TitleHeader_container {
            @include l-media-tablet {
                padding-bottom: unset !important;
            }
        }
    }
 // }
 
 .title-header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    &__container {
        @include l-media-tablet {
            display: none;
        }
        &__plus {
            .title-header__container__dropdown__lists__content__deactive-menu {
                background-color: #008262;
            }
        }
        &__current-menu {
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 43px;
            padding-left: 12px;
            border-radius: 12px;
            box-shadow: inset 0 0 10px 0 rgb(255 255 255 / 35%);
            margin-left: 18px;
            margin-top: -3px;
            position: relative;
            background-color: $white;
            @include l-media-tablet {
                display: none;
                margin-top: 25px;
                margin-left: unset;
                margin-top: unset;
            }
            &__icon {
                padding-top: 2px;
            }
            &__text {
                font-weight: $fw-chubby;
                line-height: 1.6;
                letter-spacing: normal;
                padding: 8px 24px 8px 9px;
                text-align: left;
                color: #0066c4;
                font-size: 18px;
                width: inherit;
                &__noFear {
                    padding-right: 24px;
                }
                &__stydyGuide {
                    padding-right: 83px;
                }
                &__fullText {
                    padding-right: 108px;
                }
                &__arrow {
                    padding: 13.5px 16.5px;
                    background: $white;
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px;
                    cursor: pointer;
                }
            }
            &__plus-text {
                font-weight: $fw-chubby;
                line-height: 1.6;
                letter-spacing: normal;
                text-align: left;
                padding: 8px 0px 8px 10px;
                color:#008262;
                font-size: 18px;
                font-weight: 600;

                &__noFear {
                    padding-right: 24px;
                }
                &__masteryQuizzes {
                    padding-right: 49px;
                }
                &__flashCards {
                    padding-right: 94px;
                }
                &__infoGraphic {
                    padding-right: 90px;
                }
                &__graphicNovel {
                    padding-right: 67px;
                }


                .arrow-active {
                    background-color: red;
                }
                &__arrow {
                    padding: 15px 17px;
                    background: #0066c4;
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px;
                    cursor: pointer;
                }
            }
        }
        &__dropdown {
            display: none;
            position: absolute;
            background-color: $white;
            margin-left: 18px;
            z-index: 9;
            box-shadow: 0 4px 4px 0 rgb(118 118 118 / 25%);
            margin-top: 8px;
            border-radius: 8px;
            width: 278px;
            &__lists {
                padding: 8px 0;
                &__content {
                    padding-left: unset;
                    padding-right: unset;
                    &__active-menu {
                        height: 45px;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;
                        padding: 8px 12px;
                        font-size: $text-slightly-big;
                        font-weight: $fw-chubby;
                        color: #0066c4;
                        &:hover,
                        &:focus {
                            color: #004371 !important;
                            background-color: #f5f1f5;
                            svg {
                                path {
                                fill: #004371;
                                }
                            }
                        }
                    }
                    &__plus-menu {
                        height: 45px;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;
                        padding: 8px 12px;
                        font-size: $text-slightly-big;
                        font-weight: $fw-chubby;
                        color: #008262 !important;
                        &:hover,
                        &:focus {
                            color: #004371 !important;
                            background-color: #f5f1f5;
                            svg {
                                path {
                                fill: #004371;
                                }
                            }
                        }
                    }
                    &__deactive-menu {
                        height: 45px;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;
                        padding: 8px 12px;
                        font-size: $text-slightly-big;
                        font-weight: $fw-chubby;
                        color: $white !important;
                        pointer-events: none;
                        background-color: #0066c4;
                        cursor: default;
                        svg {
                            path {
                            fill: #BFDEF2;
                            }
                        }
                    }
                    &__icons {
                        padding-right: 4px;
                        padding-top: 3px;

                        svg {
                            height: 24px;
                            width: 24px;
                        }
                    }
                }
            }
        }
    }
    &__titleLandingLink {
        color: $white;
        &:hover, &:focus {
            color: $white;
            text-decoration: underline;
            text-decoration-thickness: 2px;
            text-underline-offset: 4px;
        }
    }
 }
 
 .show-dropdown-menu {
    display: block;
 }
 
 .arrow-active {
    img {
        transform: rotate(180deg);
    }
 }
 
 .title-width {
    width: 421px;
    @media (min-width: 980px) {
        width: 441px;
    }
    @include l-media-tablet {
        width: unset;  
    }
 }
 
 .infographic-menu-icon {
    svg {
        path {
            fill: $white;
        }
    }
 }
 
 .redesign_title_header {
    .mobile-title-header {
        display: none;
        @include l-media-tablet {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 24px;
            border-radius: 12px;
            border: solid 1px $white;
            background-color: $white;
            cursor: pointer;
            &__container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                padding: 7px 0 7px 12px;
                &__img {
                    display: flex;
                    padding-right: 8px;
                    padding-bottom: 2px;
                    svg {
                        width: 24px;
                        height: 24px;
                    }
                    &__nonplus-active-menu {
                        font-family: Raleway;
                        font-size: $text-slightly-big;
                        font-weight: $fw-chubby;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: $lh-base;
                        letter-spacing: normal;
                        text-align: left;
                        color: #0066c4;
                    }
                    &__plus-active-menu {
                        font-family: Raleway;
                        font-size: $text-slightly-big;
                        font-weight: $fw-chubby;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: $lh-base;
                        letter-spacing: normal;
                        text-align: left;
                        color: #008262;
                    }
                }
            }
            &__arrow {
                padding: 8px 12px 6px 12px
            }
        }
    }
    
    .hidden-all-menu {
        visibility: hidden;
        height: 0;
        width: 0;
        background-color: #0066c4;
        box-shadow: 0 2px 6px 0 rgba(0,0,0,.1);
    }
 
    @include l-media-tablet {
        .nonPlus-background {
            background-color: #0066c4;
        }
        .plus-background {
            background-color: #007036;
        }
        .titleheader-plus-active {
            background-color: #008262;
        }
        .titleheader-nonplus-active {
            background-color: #0066c4;
        }
        .titletext-active {
            color: $white !important;
        }
        .show-all-menu {
            top: 0;
            left: 0!important;
            position: fixed;
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            visibility: visible;
            display: block;
            z-index: 9;
            background-color: $white;
        }
        .mobile-menu-container {
            margin: 10px 10px 0px 10px;
            &__close {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                cursor: pointer;
            }
            &__all-menu {
                margin-top: 80px;
                &__container {
                    display: flex;
                    flex-direction: column;
                    &__list {
                        margin-bottom: 4px;
                        &__img {
                            display: flex;
                            padding: 0px 8px 0px 12px;
                            svg {
                                width: 24px;
                                height: 24px;
                            }
                            &__nonplus-menu {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                padding-top: 11px;
                                padding-bottom: 10px;
                                font-family: Raleway;
                                font-size: $text-slightly-big;
                                font-weight: $fw-chubby;
                                font-stretch: normal;
                                font-style: normal;
                                letter-spacing: normal;
                                text-align: left;
                                color: #0066c4;
                                &:hover, &:focus {
                                    background-color: #0066c4;
                                    color: $white;
                                    svg {
                                        path {
                                            fill: $white;
                                        }
                                    }
                                }
                            }
                            &__plus-menu {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                padding-top: 11px;
                                padding-bottom: 10px;
                                font-family: Raleway;
                                font-size: $text-slightly-big;
                                font-weight: $fw-chubby;
                                font-stretch: normal;
                                font-style: normal;
                                letter-spacing: normal;
                                text-align: left;
                                color: #008262;
                                &:hover, &:focus {
                                    background-color: #008262;
                                    color: $white;
                                    svg {
                                        path {
                                            fill: $white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
 }
 
 .mobile-menu-container__all-menu__container__list:hover  .mobile-menu-container__all-menu__container__list__menu {
    color: #004371;
 }
 
 .redesign_title_header .mobile-menu-container__close svg {
    width: 40px;
    height: 40px;
    path {
        fill: $sn-black;
    }
 }

 .redesign_title_header .active-img {
    svg {
        path {
            fill: $white;
        }
    }
 }

 .redesign_title_header .active-green-down-arrow {
    svg {
        path {
            fill: #008262;
        }
    }
 }

 .new-plus-nofear {
    color: #008262;
    svg {
        path:last-child {
            fill: #008262;
        }
    }
    &:hover, &:focus {
        svg {
            path:last-child {
                fill: #004371;
            }
        }
    }
 }

 .nofear-plus {
    svg {
        path {
            fill: #008262;
        }
    }
 }

 .new-APTestPrep {
    display: block;
 }

 .rotate-dropdown-icon svg {
    transform: rotate(180deg);
 }

 .mobile-apmenu {
    visibility: hidden;
    height: 0;
    width: 0;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1);
 }

 .show-newAPMenu {
    top: 0;
    left: 0 !important;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    visibility: visible;
    display: block;
    z-index: 9;
    background-color: $white;
 }

 .apMenu-container {
    margin: 10px 10px 0 10px;
    &__close {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        cursor: pointer;
        svg {
            &:hover, &:focus {
                circle {
                    fill: $white;
                }
                path {
                    fill: $sn-black;
                }
            }
        } 
    }
    &__allmenu {
        margin-top: 60px;
        &__container {
            margin-top: 10px;
            &__list {
                margin-top: 10px;
                &__active {
                    padding: 14px 12px 14px 12px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: Raleway;
                    font-size: $text-slightly-big;
                    font-weight: $fw-chubby;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: $white;
                    background-color: #008262;
                    &:hover, &:focus {
                        color: $white;
                    }
                }
                &__unactive {
                    padding: 14px 12px 14px 12px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-family: Raleway;
                    font-size: $text-slightly-big;
                    font-weight: $fw-chubby;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #008262;
                    &:hover, &:focus {
                        color: #004371;
                        background-color: $color-teacherSoftGray;
                    }
                }
            }
        }
    }
 }

 .ap-title-menu-close:focus svg {
    circle {
        fill: $white;
    }
    path {
        fill: $sn-black;
    }
 }
 
 .desktop-image {
    @include l-media-tablet {
        display: none;
    }
 }

 .mobile-image {
    display: none;
    @include l-media-tablet {
        display: block;
    }
 }

 .new-ap-testPrep {
    @include l-media-tablet {
        padding-left: unset;
        padding-right: unset;
    }
 }