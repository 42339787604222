@mixin l-media-bp1 {
  @media (max-width: 640px) {
    @content;
  }
}

.sparkteach-homepage__coming-soon{
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: $color-teacher;
  @include media-small {
    margin-top : 72px;
    padding-bottom: 40px;
  }
  &__container{
    @include container($padding: true);
    //display: flex;
    //flex-direction: row;
    text-align: center;
    @include media-small {
      flex-direction: column;
    }
    align-items: center;
  }
  &__text {
    color : $white;
    font-size: $text-big-ish;
    font-weight: $fw-base;
    line-height : $lh-tight;
    @include media-notSmall {
      margin-left: auto;
      margin-right : 40px;
      font-size: $text-extra-big; 
      font-weight: $fw-slim;
    }
  }
  &__title{
    @include teacherHome-sectionHeading($center: true);
    @include l-media-bp1{
      font-size: $text-big;
      font-weight: $fw-chubby;
    }

    margin-bottom: 24px;
  }
  &__strap{
    line-height: $lh-loose;
    max-width: 720px;
    margin: 0 auto;
    text-align: center;
    strong{
      font-weight: $fw-chubby;
      color: $color-teacher;

    }
  }
}