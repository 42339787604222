//=================================================//=================================================
// UTILITY classes go here
// Anything that's added or removed by JS, or tags added to <body>
//=================================================//=================================================

//=================================================
// (add/remove type classes)
//=================================================

// general show class
.show {
  display: block !important;
}

// show inline block class
.show-inline {
  display: inline-block !important;
}

// for the title page on Mobile
.expanded {
  display: list-item !important;
}

.hide {
  display: none;
}

.desktop-only {
  @media (max-width: $mobile-bp) {
    display: none !important;
  }
}

.no-arrow {
  padding-right: 13px;
}