.modal {
    //display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */    
    &-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 50%;
        text-align: center;
        border-radius: 15px;
        &_header {
            text-align: center;
            margin: 15px 0 15px;
        }
        @media (max-width: $tablet-bp) {
            width: 80%;
          }
        &-block {          
            margin: 1rem auto;
        }
        &_text-block {
            // width: 500px;
            padding: 2rem 0;
            text-align: center;
            font-size: 20px;
            line-height: 1.6;
            letter-spacing: normal;
            text-align: center;
            color: $sn-primary-paragraph;
            border-top: 2px solid #e5e5e5;
            border-bottom: 2px solid #e5e5e5;
        }
        &_link-close {
            display: inline-block; 
            border: none;
            cursor: pointer;
            padding: 10PX;
            background-color: $sn-primary-link;
            border-radius: 4px;
            margin:15px 0;
           color: #fff;
        }
        &_link-subscribe {
            display: inline-block; 
            border: none;
            cursor: pointer;
            padding: 10PX;
            background-color: $sn-primary-link;
            border-radius: 4px;
            margin:35px 10px;
        }
        &_link-subscribe a{
            color: $white;
            padding: 23px;
            font-size: 14px;
        }
        &_link-home-page {
            display: inline-block; 
            border: 1px solid $sn-primary-link;
            cursor: pointer;
            padding: 8px 15px;
            background-color: $white;
            border-radius: 4px;
            margin: 10px;
            color: $sn-primary-link;
        }
        &_link-home-page a{
            color: $sn-primary-link;
            padding: 23px;
            font-size: 14px;
        }
        &_link-close a{           
           color: #fff;
        }    
    }         
}
  
  /* Modal Content */

  .credit-card{
      width: 100px;
      height: auto;     
  }
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }