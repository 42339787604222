// $L-break-pageTurn-small: A breakpoint custom to this component.
$L-break-pageTurn-small: 600px;
@mixin L-media-pageTurn-small {
  @media screen and (max-width: $L-break-pageTurn-small) {
    @content;
  }
}
@mixin L-media-pageTurn-notSmall {
  @media screen and (min-width: ($L-break-pageTurn-small + 1)) {
    @content;
  }
}

// the wrapper element
.page-turn-nav {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $sn-border-gray;
  border-bottom: 1px solid $sn-border-gray;
  margin-top: $s-px * 4;
  margin-bottom: $s-px * 4;
}

// all links
.page-turn-nav__link{
  flex: 1 1 50%;
  line-height: $lh-base;
  display: flex;
  align-items: center;

  // create arrows with pseudo-element.
  // Note the bg-image gets added for each item in individual rules lower down
  &:before{
    content: '';
    display: block;
    $size: $s-px * 3;
    width: $size;
    height: $size;
    border-radius: 50%;
    flex: none;
  }

  // hover states
  // added ':not(.specific-enough)' to get around way too broad rule '.container a:hover, .content-container a:hover'.
  // TODO: Remove thsi broad rule and replace with more specific ones where needed.
  &:not(.specific-enough):hover {
    color: $sn-link-hover;
    background-color: $color-teacherSoftGray;
    text-decoration: none;
  }
  // specific styles for previous button
  &--prev{
    &:before{
      background-image: url("/images/icon-prevPage-heading-24.svg");
      @include L-media-pageTurn-small{
        margin-left: 12px;
      }
    }
    &:hover:before{
      background-image: url("/images/icon-prevPage-linkHover-left.svg");
    }
  }
  
  // specific styles for previous button
  &--next{
    text-align: right;
    // this moves the :before to the right side of the button
    flex-direction: row-reverse;
    &:before{
      background-image: url("/images/icon-nextPage-heading-24.svg");
      @include L-media-pageTurn-small{
        margin-right: 12px;
      }
    }
    &:hover:before{
      background-image: url("/images/icon-nextPage-linkHover-right.svg");
    }
  }

  // span to wrap the label and title for layout purposes
  &__text{
    @include L-media-pageTurn-notSmall{
      padding: 12px;
    }
    @include L-media-pageTurn-small{
      padding: 24px 12px;
    }
    line-height: $lh-tight;
  }
  // the 'Previous/Next section' text
  &__label{
    display: block;
    font-size: $text-small;
    color: $color-a11yGray;
    @include L-media-pageTurn-small{
      .page-turn-nav__link:hover &{
        color: $color-linkHover;
      }
    }
  }
  // The title of the section the link points to
  &__title{
    @include L-media-pageTurn-small{
      @include visuallyhidden;
    }
    font-weight: $fw-fat;
    color: $sn-primary-header;
    .page-turn-nav__link:hover &{
      color: #004371;
    }
    &__page{
      font-weight: $fw-chubby;
    }
  }
}

//redesign pagination add top of the page//
.top-page-turn-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 16px;
  @include media-small {
    margin-top: 0px;
  }
}

.top-page-turn-nav__link__text {
    font-size: $text-small !important;
    font-weight: $fw-chubby !important;
    text-decoration: none !important;
    color: #0066c4;
    line-height: $lh-tight !important;
}

.top-page-turn-nav .pw-in-article-container {
  display: none;
}

.top-page-turn-nav__link--prev {
  display: flex;
  &:before {
    content: " ";
    background: url("/images/icon-top-page-prev.svg") no-repeat;
    height: 20px;
    width: 20px;
  }
  &:hover {
    .pagination_prev {
      color : #004371;
    }
    &:before {
      filter: invert(41%) sepia(78%) saturate(3853%) hue-rotate(182deg) brightness(45%) contrast(107%) !important;
    }
  }
}

.top-page-turn-nav__link--next {
  display: flex;
  text-align: right;
  flex-direction: row-reverse;
  &:before {
    content: " ";
    background: url("/images/icon-top-page-next.svg") no-repeat;
    height: 20px;
    width: 20px;
  }
  &:hover {
    .pagination_next {
      color : #004371;
    }
    &:before {
      filter: invert(41%) sepia(78%) saturate(3853%) hue-rotate(182deg) brightness(45%) contrast(107%)!important;
    }
  }
}

.top-page-turn-nav__link {
  text-decoration: none !important;
  display: flex;
  flex: 1 1 50%;
  align-items: center;
}

.quiz-interior-container {
  .top-page-turn-nav__link {
    flex: unset;
  }
}

.pagination_next {
  padding: 2px 6px 0 0;
}

.pagination_prev {
  padding: 2px 0 0 6px;
}