@include premiumFlag{
  .minimal-nav{
    padding: 0 $pageMargin;
    display: flex;
    justify-content: center;
    background-color: $white;
    &__homepage-link{
      padding: 16px 0;
    }
  }
}