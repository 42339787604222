// stylelint-disable at-rule-no-vendor-prefix, declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix

a {
  color:unset ;
  text-decoration: unset;
  background-color: unset;
}

  a:hover {
    color: unset;
    text-decoration: unset;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top:unset ;
    margin-bottom:unset ;
  }

  p {
    margin-top:unset ;
    margin-bottom:unset ;
  }

  img {
    vertical-align: unset ;
    border-style: unset ;
  }
  svg {
    overflow: unset;
    vertical-align: unset;
  }


// body{
//   display: none
// }
// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css


// Document
//
// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 2. Change the default font family in all browsers.
// 3. Correct the line height in all browsers.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. Change the default tap highlight to be completely transparent in iOS.

*,
*::before,
*::after {
  box-sizing: unset; // 1
}

html {
  font-family: unset; // 2
  line-height: unset; // 3
  -webkit-text-size-adjust: unset // 4
}

// Shim for "new" HTML5 structural elements to display correctly (IE10, older browsers)
// TODO: remove in v5
// stylelint-disable-next-line selector-list-comma-newline-after
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: unset;
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Set an explicit initial text-align value so that we can later use
//    the `inherit` value on things like `<th>` elements.

body {
  margin: 0; // 1
  font-family: unset;

  font-weight: unset ;
  line-height:unset ;
  color:unset ;
  text-align:unset ;
  background-color:unset ;
}

// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
//
// Credit: https://github.com/suitcss/base
[tabindex="-1"]:focus {
  outline:unset ;
}


// Content grouping
//
// 1. Add the correct box sizing in Firefox.
// 2. Show the overflow in Edge and IE.

hr {
  box-sizing:unset ;
  height: unset ;
  overflow:unset ;
}


//
// Typography
//

// Remove top margins from headings
//
// By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
// margin for easier control within type scales as it avoids margin collapsing.
// stylelint-disable-next-line selector-list-comma-newline-after


// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.


// Abbreviations
//
// 1. Duplicate behavior to the data-* attribute for our tooltip plugin
// 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
// 3. Add explicit cursor to indicate changed behavior.
// 4. Remove the bottom border in Firefox 39-.
// 5. Prevent the text-decoration to be skipped.

abbr[title],
abbr[data-original-title] { // 1
  text-decoration:unset ;
  text-decoration:unset ;
  cursor:unset ;
  border-bottom:unset ;
  text-decoration-skip-ink:unset ;
}

address {
  margin-bottom:unset ;
  font-style:unset ;
  line-height:unset ;
}

ol,
ul,
dl {
  margin-top:unset ;
  margin-bottom:unset ;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom:unset ;
}

dt {
  font-weight:unset ;
}

dd {
  margin-bottom:unset ;
  margin-left:unset ;
}

blockquote {
  margin:unset ;
}

b,
strong {
  font-weight:unset ;
}

//// small {
//   @include font-size(80%); // Add the correct font size in all browsers
// }

//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.
//

sub,
sup {
  position: relative;
  //// @include font-size(75%);
  line-height: 0;
  vertical-align: baseline;
}

sub { bottom: -.25em; }
sup { top: -.5em; }


//
// Links
//



// And undo these styles for placeholder links/named anchors (without href)
// which have not been made explicitly keyboard-focusable (without tabindex).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402

a:not([href]):not([tabindex]) {
  color: unset ;
  text-decoration: unset ;

  // @include hover-focus {
  //   color: unset ;
  //   text-decoration: unset ;
  // }

  // &:focus {
  //   outline: unset ;
  // }
}


//
// Code
//

pre,
code,
kbd,
samp {
  // font-family: $font-family-monospace;
  // @include font-size(1em); // Correct the odd `em` font sizing in all browsers.
}

pre {
  // Remove browser default top margin
  margin-top: 0;
  // Reset browser default of `1em` to use `rem`s
  margin-bottom: 1rem;
  // Don't allow content to break outside
  overflow: auto;
}


//
// Figures
//

figure {
  // Apply a consistent margin strategy (matches our type styles).
  margin: unset ;
}


//
// Images and content
//




//
// Tables
//

table {
  border-collapse: unset ;
}

caption {
  padding-top: unset ;
  padding-bottom: unset ;
  color: unset ;
  text-align: left;
  caption-side: bottom;
}

th {
  // Matches default `<td>` alignment by inheriting from the `<body>`, or the
  // closest parent with a set `text-align`.
  text-align: unset ;
}


//
// Forms
//

label {
  // Allow labels to use `margin` for spacing.
  display: unset ;
  margin-bottom: unset ;
}

// Remove the default `border-radius` that macOS Chrome adds.
//
// Details at https://github.com/twbs/bootstrap/issues/24093
button {
  // stylelint-disable-next-line property-blacklist
  border-radius: unset ;
}

// Work around a Firefox/IE bug where the transparent `button` background
// results in a loss of the default `button` focus styles.
//
// Credit: https://github.com/suitcss/base/
button:focus {
  outline: unset ;
  outline: unset ;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0; // Remove the margin in Firefox and Safari
  font-family: unset ;
  //// @include font-size(inherit);
  line-height: unset ;
}

button,
input {
  overflow: unset ;
}

button,
select {
  text-transform: unset ;
}

// Remove the inheritance of word-wrap in Safari.
//
// Details at https://github.com/twbs/bootstrap/issues/24990
select {
  word-wrap: unset ;
}


// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
  -webkit-appearance: unset ;
}

// Opinionated: add "hand" cursor to non-disabled button elements.
// @if $enable-pointer-cursor-for-buttons {
//   button,
//   [type="button"],
//   [type="reset"],
//   [type="submit"] {
//     &:not(:disabled) {
//       cursor: unset ;
//     }
//   }
// }

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: unset ;
  border-style: unset ;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: unset ;
  padding: unset ;
}


input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  // Remove the default appearance of temporal inputs to avoid a Mobile Safari
  // bug where setting a custom line-height prevents text from being vertically
  // centered within the input.
  // See https://bugs.webkit.org/show_bug.cgi?id=139848
  // and https://github.com/twbs/bootstrap/issues/11266
  -webkit-appearance: unset ;
}

textarea {
  overflow: unset ;
  // Textareas should really only resize vertically so they don't break their (horizontal) containers.
  resize: unset ;
}

fieldset {
  // Browsers set a default `min-width: min-content;` on fieldsets,
  // unlike e.g. `<div>`s, which have `min-width: 0;` by default.
  // So we reset that to ensure fieldsets behave more like a standard block element.
  // See https://github.com/twbs/bootstrap/issues/12359
  // and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
  min-width: 0;
  // Reset the default outline behavior of fieldsets so they don't affect page layout.
  padding: 0;
  margin: 0;
  border: 0;
}

// 1. Correct the text wrapping in Edge and IE.
// 2. Correct the color inheritance from `fieldset` elements in IE.
legend {
  display: block;
  width: 100%;
  max-width: 100%; // 1
  padding: 0;
  margin-bottom: .5rem;
  // @include font-size(1.5rem);
  line-height: inherit;
  color: inherit; // 2
  white-space: normal; // 1
}

progress {
  vertical-align: baseline; // Add the correct vertical alignment in Chrome, Firefox, and Opera.
}

// Correct the cursor style of increment and decrement buttons in Chrome.
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: unset ;
}

[type="search"] {
  // This overrides the extra rounded corners on search inputs in iOS so that our
  // `.form-control` class can properly style them. Note that this cannot simply
  // be added to `.form-control` as it's not specific enough. For details, see
  // https://github.com/twbs/bootstrap/issues/11586.
  outline-offset: unset ;
  -webkit-appearance: unset ;
}

//
// Remove the inner padding in Chrome and Safari on macOS.
//

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

//
// 1. Correct the inability to style clickable types in iOS and Safari.
// 2. Change font properties to `inherit` in Safari.
//

::-webkit-file-upload-button {
  font: unset ;
  -webkit-appearance: unset ;
}

//
// Correct element displays
//

output {
  display: inline-block;
}

summary {
  display: unset ;
  cursor: unset ;
}

template {
  display: none; // Add the correct display in IE
}

// Always hide an element with the `hidden` HTML attribute (from PureCSS).
// Needed for proper display in IE 10-.
[hidden] {
  display: none ;
}


//////////////////////////Custom//////////////////////////
.custom-select {
  display: block;
  width: unset;
  height: unset;
  padding: unset;
  font-family: unset;
  font-weight: unset;
  line-height: unset;
  color: unset;
  vertical-align: unset;
  background: unset;
  background-color: unset;
  border: unset;
  appearance: unset;
  position: unset;
  margin-top: 20px;

  .select-selected{
    position: relative;
  }
  .select-items{
     width: 100%!important;
    top: auto!important;
  }
  &:focus {
    border-color: unset;
    outline: 0;

    &::-ms-value {
      // For visual consistency with other platforms/browsers,
      // suppress the default white text on blue background highlight given to
      // the selected option text when the (still closed) <select> receives focus
      // in IE and (under certain conditions) Edge.
      // See https://github.com/twbs/bootstrap/issues/19398.
      color: unset;
      background-color: unset;
    }
  }

  &[multiple],
  &[size]:not([size="1"]) {
    height: auto;
    padding-right: unset;
    // background-image: none;
  }

  &:disabled {
    color: unset;
    background-color: unset;
  }

  // Hides the default caret in IE11
  &::-ms-expand {
    display: none;
  }
}
svg:not(:root) {
  overflow: hidden!important;
}

