.study-guide-video-wrapper{
  position: relative;
  padding-bottom: 56.25% !important;
  margin-bottom: 100px !important;
}
.study-guide-video{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.no-fear-thumbs{
	display: grid;
	grid-gap: 24px;
	grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
	margin-bottom: 48px;
	
	&__thumb{
		display: flex;
		
		&__link{
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			color: $color-brandBlue2020-onSoftGray;
			background-color: $color-teacherSoftGray;
			border-radius: $radius-base;
			overflow: hidden;
			padding: 12px;
			transition: all 0.2s;
			&:hover{
				box-shadow: 0 8px 16px 0 rgba(0,0,0,.2), inset 0 3px 0 0 #004371;
				color: #004371;
        transform: translateY(-2px);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }    
		}
		&__image{
			width: 96px;
			border-radius: 50%;
			filter: saturate(0.75);
		}
		&__text{
			margin-top: 8px;
			color: inherit;
			text-align: center;
			font-size: $text-small;
			font-weight: $fw-chubby;
			line-height: $lh-tight;
		}
	}
}

.hubPage{
	&__section{
		&__heading{
			font-size: $text-big;
			font-weight: $fw-fat;
			line-height: $lh-tight;
			margin-bottom: 0.75em;
		}
		&__blurb{
			margin-bottom: 1rem;
		}
	}
}

.custom_table {
	margin-bottom: 20px;
	border: 1px solid $sn-primary-header !important;
	padding: 2px !important;
	caption {
		margin-bottom: 20px;
		font-weight: $fw-chubby;
	}

	th, tr, td {	
		padding: 5px !important;
		@include media-notSmall {
			padding: 10px !important;
		}
		border: 1px solid $sn-primary-header !important;
		
	}
}

.custom_table_without_border {
	margin-bottom: 20px;
	padding: 2px !important;
	caption {
		margin-bottom: 20px;
		font-weight: $fw-chubby;
	}

	th, tr, td {
		padding: 5px !important;
		@include media-notSmall {
			padding: 10px !important;
		}
	}
}

.audio-triangle {
    position: absolute;
    width: 46px;
    height: 46px;
    top: 133px;
    right: 0;
	@include media-small { 
		top: 122px;
	}
}
.nofear_audio_triangle {
	.no-fear-thumbs {
		&__thumb {
			&__link {
				position: relative;
			}
			&__text {
				@media (max-width: 1024px) {
					font-size: $text-tiny;					
				}
				@include media-small { 
					font-size: $text-tiny !important;
				}
			}
		}
	}
	height: 178px;
	@include media-small { 
		height: 168px;
	}
}