.mobile-apps-content {
    @media (max-width: $mobile-bp) {
        width: 100%;
        height: 625px;
        display: block;
        background-position-x: $mobile-bp;
        background-size: cover;
        overflow-y: hidden;
	}
    display: flex;
    width: $sn-max-width;
    height: 600px;
    margin: 0 auto;
    background-image: url('/images/bg_mobile_apps.jpg');
    .app-title {
        @media (max-width: $mobile-bp) {
            font-size: 28px;
            width: 250px;
            margin: 0 auto;
            padding-bottom: 10px;
            line-height: 35px;
        }
        font-weight: $fw-huge;
        font-size: 40px;
        color: #fff;
        padding-bottom: 15px;
        line-height: 50px;
    }
    .app-features {
        padding-bottom: 30px;
        p{
            color: #fff;
        }
        @media (max-width: $mobile-bp) {
            padding-bottom: 15px;
        }
    }
}

.mobile-apps-content--border {
    width: 100%;
    border-bottom: 1px solid $sn-gray;
    margin-bottom: 30px;
}

.mobile-apps-content-inner {
    @media (max-width: $mobile-bp) {
        width: 100%;
        padding-top: 30px;
        margin: 0 auto;
    }
    width: 44%;
    margin: 150px 50px;
    text-align: center;
}

.iphone-section {
    @media (max-width: $mobile-bp) {
       text-align: center;
       padding-top: 20px;
    }
    overflow: hidden;
    padding-top: 50px;
    margin-left: -40px;
    img {
        @media (max-width: $mobile-bp) {
            width: 335px;
            padding-left: 45px;
        }
    }
}