.sparkteach-homepage__title-tiles {
    margin-left: auto;
    margin-right: auto;
    &__top-note {
        margin-top: 36px;
        margin-left: 7%;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 4px 12px;
        @include media-notSmall{
            border-bottom: 1px solid $sn-border-gray; 
            padding-bottom: 24px;
        }
        
        @include media-small {
            display: grid;
        }
        &__icon {
            grid-row: 1/3;
            align-self: flex-start;
            border: 2px solid $color-teacher;
            border-radius: 50%;
            padding: 7px 8.5px 7px 8.5px;
            @include media-small {
                grid-row: 1/3;
                svg {
                    width: 36px;
                    height: 41px;
                }
            }
            svg {
                width: 56px;
                height: 56px;
                path {
                    fill: $color-teacher;
                }
            }
        }
        &__header {
            @include media-small {
                grid-row: 1/3;
                font-size: $text-big-ish;
                line-height: $lh-extra-tight;
                font-weight: $fw-fat;
                align-self: center;
            }
            &__title {
                font-weight: $fw-fat;
            }
        }
        &__text {
            @include media-small {
                margin-top: 16px;
                grid-column: 1/3;
            }
            grid-column: 2/3;
            max-width: 600px;
            line-height: $lh-loose;
        }
    }
    &__list {
        @include media-small {
            margin-top: 36px;
            flex-direction: column;
        }
        margin-top: 36px;
        display: flex;
        flex-wrap: wrap;
        margin-left: 7%;
        &__container {
            @include media-small {
                margin-right: 12px;
                padding-bottom: 24px;
                box-shadow: none;
            }
           background-color: $white;
           position: relative;
           display: flex;
            flex-direction: column;
            margin-right: 35px;
            padding-bottom: 10px;
            @include media-notSmall {
                margin-bottom : 34px;
                border-radius: $radius-big;
            }
            &:focus-within, &:hover {
                box-shadow: none;
                background-color: $white;
            }
            &:focus-within &__top__desc ,&:hover &__top__desc {
                @include media-notSmall {
                    margin-top: -45px;
                }    
            }
            &:hover &__bn{
                visibility: visible;
            }
            &:focus-within &__bn{
                visibility: visible;
            }
            &__top {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @include media-notSmall{
                &:before{
                    // This is the shadow
                    position: absolute;
                    content:'';
                    display: block;
                    width: 100%;  // same width as actual element
                    top: 10px;  // starts slightly lower
                    height: calc(100% - 60px); //ends 50px before bottom of card
                    background-color: $sn-off-black;  //shadow color
                    filter: blur(20px); // use the blur filter to create shadow
                    z-index: -1; // place behind the card content
                  }
                  &:hover,&:focus {
                      background-color: $white;
                      border-radius : 12px;
                  }
                }
                &__cover {
                    @include media-small {
                        font-size: 16px;
                        align-self: center;
                    }

                    @include media-notSmall {  
                        max-height: 220px;
                        overflow: hidden;
                    }

                    &__image {
                        @include media-small {
                            display: none;
                        }
                        display: block;
                        border-radius: $radius-big $radius-big 0 0;
                    }
                    &__icon {
                        @include media-small {
                            display: block;
                            width: 100%;
                            height: 97px;
                            object-fit: cover;
                            border-radius: 12px 12px 0px 0px;
                        }
                        display: none;
                    }
                }
                &__desc {
                    background-color: $white;
                    padding-bottom: 36px;
                    &:hover{
                        border: none;
                    }
                    @include media-small {
                        align-self: center;
                        width: 100%;
                        border-radius: 0 0 12px 12px;
                        padding-bottom: 20px;
                        background-color: #f5f1f5;
                        padding-left: 16px;
                        padding-right: 16px;
                    }
                    &__name {
                        margin-top: 20px;
                        padding-left: 10px;
                        font-weight: $fw-fat;
                        font-size: $text-base;
                        line-height: $lh-tight;
                        color : $sn-primary-header;
                        @include media-small {
                            padding-left: 0px;
                        }
                    }
                    &__author {
                        padding-left: 10px;
                        font-size: $text-small;
                        line-height: $lh-base;
                        color : $sn-primary-header;
                        @include media-small {
                            padding-left: 0px;
                        }
                    }
                }
            }
            &__bn {
                background-color: $white;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @include media-small {
                    padding-top: 16px;
                    visibility: visible;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                }
                visibility: hidden;
                &__buy-on-bn {
                    @include media-small {
                        flex: 1;
                        margin-left: 10px;
                        margin-right: auto;
                        white-space: nowrap;
                        font-weight: $fw-base;
                        font-size: $text-base;
                        background-color: #0066c4;
                        &:hover {
                            background-color: #004371;
                            color: $white;
                        }
                    }
                    text-align: center;
                    background-color: #0066c4;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin-left: 10px;
                    margin-right: 10px;
                    font-weight: $fw-chubby;
                    border-radius: $radius-base;
                    color: $white;
                    &:hover {
                        background-color: #004371;
                        color: $white;
                    }
                    &.desktop{
                            display: block;
                            @include media-small {
                                display: none;
                            }
                        }
                    &.mobile{
                        display: none;
                        @include media-small {
                            display: block;
                            margin-left:0;
                            margin-right: 0;
                            margin-top: 20px;
                        }
                    }
                }
                &__view-on-sn {
                    &__mobile {
                        display: none;
                        @include media-small {
                            flex: 1;
                            display: block;
                            margin-left: 0px;
                            margin-right: 0px;
                            text-align: center;
                            padding: 10px;
                            border-radius: $radius-base;
                            border: 1px solid $color-brandBlue2020;
                            font-weight: $fw-base;
                            font-size: $text-base;
                            background-color: $white;
                            color: $color-brandBlue2020;
                            &:hover {
                                background-color: $color-linkHover;
                                color: $white;
                            }
                        }
                    }
                    &__desktop {
                        display: block;
                        text-align: center;
                        margin: 10px;
                        background-color: $white;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        font-weight: $fw-chubby;
                        border-radius: $radius-base;
                        color: $color-brandBlue2020;
                        border: 1px solid $color-brandBlue2020;
                        &:hover {
                            background-color: $color-linkHover;
                            color: $white;
                        }
                        @include media-small {
                            display: none;
                        }
                    }
                }
            }
        }

        :last-child {
            @include media-small {   
                border : none;
            }
        }
    }
}