@mixin l-media-930 {
    @media (max-width: 930px) {
        @content;
    }
}

.new-help-page {
    margin-bottom: 25px !important;
    margin-top: 25px;
}
.help-header {
    @include media-small {
        display: unset;
    }
    @include media-notSmall {
        display: none;
    }    
    background: $color-brandBlue2020;
    padding-top: 16px;
    background-size: 70%,100%;
    background-repeat: no-repeat;
    background-position: 100% 100%,0 0;
    &__container {
        max-width: 1164px;
        background-color: $color-brandBlue2020;
        padding-top: 20px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 47px;
        &__heading {
            padding-left: 12px;
            padding-right: 12px;
            align-items: center;
            text-align: center;
            display: flex;
            flex-direction: column;
            &__title {
                color: $white;
                font-weight: bold;
                font-size: $text-big;
                line-height: $lh-loose;
                margin-bottom: 15px;
            }
        }
    }
}

.help-headermenu-wrapper {
    width: 94.5%;
    margin: 0 10px;
    position: absolute;
    border: none;
    border-radius: 12px 12px 0 0;
    background-color: $white;
    text-align: center;
    z-index: 4;
    border-top: 4px solid $color-teacherSoftGray;
    border-left: 4px solid $color-teacherSoftGray;
    border-right: 4px solid $color-teacherSoftGray;
}
.active-help-menu {
    width: 100%;
    color: $sn-primary-header;
    font-size: $text-small;
    font-weight: $fw-chubby;
    align-items: center;
    border-bottom: 1px solid $sn-border-gray;
    padding: 11px 0;
    &__text {
        font-size: $text-small;
        font-weight: $fw-chubby;
        color: $color-brandBlue2020;
    }
    &__icon {
        float: right;
        margin: -5px 16px 0 -40px;
    }
}
.adding-helpmenu-border {
    border-radius: 12px !important;
    border-bottom: 4px solid $color-teacherSoftGray;
    .active-help-menu {
        border-bottom: 1px solid $sn-border-gray;
    }
    .active-help-menu__icon svg {
        transform: rotate(180deg) !important;
    }
}
.display-sub-menu {
    display: unset !important;
}
.for-hiding-menu {
    display: none;
}
.default-help-menu-container {
    display: none;
    li {
        button {
            color: $color-brandBlue2020;
            font-size: $text-small;
            font-weight: $fw-base;
            padding: 15px 0;
            width: 100%;
            @include media-small {
                &:hover {
                    background-color: #eeeeee;
                }
            }
        }
    }
}
.help-wrapper {
    .layout-wrapper-2018 {
        @include media-small {
            margin-top: unset !important;
        }
    }
    .layout-wrapper-2018__column--main {
        @include media-small {
            margin-bottom: unset !important;
        }
    }
    &__header {
        @include media-small {
            display: none;
        }
        text-align: center;
        padding: 20px 0;
        background-color: $color-brandBlue2020;
        &__content {
            margin: 25px 0;
            font-size: $text-extra-big;
            font-weight: $fw-fat;
            line-height: $lh-extra-tight;
            text-align: center;
            color: $white;
        }
    }
    &__body {
        background-color: $white;
        display: flex;
        &__left {
            @include media-small {
                display: none;
                width: unset;
            }
            display: flex;
            width: 24%;
            flex-direction: column;
            &__wrapper {
                padding-bottom: 20px;
                margin-top: 20px;
                border-bottom: 1px solid $sn-border-gray;
                &:last-child {
                    border-bottom: unset;
                }
                &__title {
                    height: 26px;
                    font-size: $text-base;
                    font-weight: bold;
                    line-height: $lh-base;
                    letter-spacing: normal;
                    text-align: left;
                }
                &__content {
                    margin-top: 15px;
                    border-radius: 4px;
                    cursor: pointer;
                    button {
                        @include l-media-930 {
                            padding-top: 5px;
                            padding-bottom: 5px;
                        }    
                    }
                    span {
                        min-height: 42px;
                        padding-left: 8px;
                        padding-right: 8px;
                        align-items: center;
                        display: flex;
                        font-size: $text-base;
                        font-weight: normal;
                        text-align: left;
                        @include l-media-930 {
                            line-height: 1.4;
                        }
                    }
                    &:hover {
                        background-color: #d9ebf7;
                    }
                }
            }
        }
        &__right {
            width: 76%;
            margin-left: 35px;
            padding-left: 25px;
            @include media-small {
                width: 100%;
                padding: unset;
                margin-left: auto;
                margin-right: auto;
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 30px;
            }
        }
    }
    &__dropdown {
        width: 100%;
        height: 84px;
        border-radius: 12px;
        background-color: $color-teacherSoftGray;
        margin-top: 20px;
        @include media-small {
            min-height: 60px;
            height: unset;
        }
        &:hover {
            background-color: #d9ebf7;
        }
    }
    &__dropdown-select {
        display: flex;
        justify-content: space-between;
        border: unset;
        width: 100%;
        height: 84px;
        padding-top: 17px;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 12px;
        background-color: $color-teacherSoftGray;
        text-align: left;
        @include l-media-930 {
            padding-top: 12px;
        }
        @include media-small {
            padding-bottom: 10px;
            min-height: 60px;
            height: unset;
        }
        .notetaking__extra {
            transform: unset !important;
            @include media-small {
                left: -2px !important;
            }    
            img{
                @include media-small {
                    display: unset !important;
                }
            }
        }
    }
    &__dropdown-menu {
        width: 100%;
        height: auto;
        display: none;
        border: 2px solid $color-teacherSoftGray;
        padding: 16px 16px 25px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        @include media-small {
            padding: 12px 15px 24px;
            overflow-y: auto;
            max-height: 350px;
        }
        p {
            font-size: $text-base;
            font-weight: normal;
            line-height: $lh-base;
            text-align: left;
            color: $sn-black;
        }
    }
}

.dropdown-active {
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
    .notetaking__expand-icon {
        transform: rotate(180deg) !important;
    }
}

.active-category-item {
    background-color: #d9ebf7;
    span {
        font-weight: $fw-chubby !important;
    }
}

.show-helpmenu {
    display: block !important;
    .notetaking__extra{
        transform: rotate(180deg) !important;
    }
    .help-wrapper__dropdown {
        border-bottom-right-radius: unset !important;
        border-bottom-left-radius: unset !important;
    }
}

.help-main {
    &__content {
        margin-top: 20px;
    }
    &__subtitle {
        font-size: 21px;
        font-weight: bold;
        @include media-small {
            display: none;
        }
    }
}
.help-questions {
    padding-right: 5px;
}