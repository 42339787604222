// currently this file includes overrides to fix problems with ad code.
// eventually we should take over more ad responsibility and have code in here for positioning slots.


// partner__pw__header doesn't really do anything. <div data-pw-desk="leaderboard_atf"> is what Playwire uses to inject their ad.
// We use the class to target this CSS at it.
.partner__pw__header {
  [data-pw-desk] {
    margin-bottom: 0;
    height: 2px; // not sure if this is needed or not, but in informal testing the ad fired more often when this wasn't 0
    overflow: hidden;
    display: flex !important; // use flex to center the ad vertically and horizontally
    align-items: center;
    justify-content: center;
    transition: height 0.2s; // transitioning height isn't great, but without running JS to detect changes we don't have another way to animate this AND the content after it in the DOM.
    transition-delay: 0.2s; // Sometimes Playwire stick in a div but then don't load an ad and remove it again. This delay gives time for that to happen. Should work with Playwire to resolve this better. Ideally they would add a class rather than us relying on 'not empty'.

    background-color: $color-teacherSoftGray !important;
    background-image: linear-gradient(0deg, rgba(0,0,0,0.18), transparent 20px, transparent);
    height: 90px; // 90px height + 12px padding top and bottom

    @include media-mobile {
      height: 50px;
      background-color: $white !important;
      background-image: none;
    }
  }

  [data-pw-mobi] {
    margin-bottom: 0;
    height: 2px; // not sure if this is needed or not, but in informal testing the ad fired more often when this wasn't 0
    overflow: hidden;
    display: flex !important; // use flex to center the ad vertically and horizontally
    align-items: center;
    justify-content: center;
    transition: height 0.2s; // transitioning height isn't great, but without running JS to detect changes we don't have another way to animate this AND the content after it in the DOM.
    transition-delay: 0.2s; // Sometimes Playwire stick in a div but then don't load an ad and remove it again. This delay gives time for that to happen. Should work with Playwire to resolve this better. Ideally they would add a class rather than us relying on 'not empty'.

    background-color: $color-teacherSoftGray !important;
    background-image: linear-gradient(0deg, rgba(0,0,0,0.18), transparent 20px, transparent);
    height: 114px; // 90px height + 12px padding top and bottom

    @include media-mobile {
      height: 50px;
      background-color: $white !important;
      background-image: none;
    }
  }
}

// a temporary class to adjust Distroscale behavior on /shakespeare/ hub until we redo layout
.shakespeare-hub--ds-overrides{
  position: relative;
  margin-bottom: 64px;
  .ds_cpp{
    .ds_cpp_inner{
      top: 64px !important;
    }

    @include media-mobile-and-tablet{
      display: none;
    }
  }
}

// Distroscale right rail thing appears under main content below 1020px and because it is sticky it breaks the layout
// I'm hiding it temporarily. DS should adjust their code to not show it in this situation.
@include media-under-1020{
  .ds_rightbar{
    display: none !important;
  }
}

@include media-under-1020{
  // #pwContainerRightRail {
  //   display: none !important;
  // }
}

// #pwContainerRightRail {
//   @include media-small {
//     max-width : 405px;
//   }
//   @include media-notSmall {
//     width: 405px;
//   }
//    position: relative;
//    min-height: 254px;
//    //min-height: 230px;
//    //max-height: 316px;
//    margin-bottom : 56px;
//   &:empty {
//       @include media-small {
//         max-width : 405px;
//       }
//       @include media-notSmall {
//         width: 405px;
//       }
//       background-color: $white !important;
//       height: 254px;;
//       min-height: 254px;
//       //min-height: 230px;
//       //max-height: 316px;
//       margin-bottom : 56px;
//   }
// }

#pwTopDockContainer {
  width: 100vw;
  height: calc(100vw / (16/9));
  min-height : 220px;
  position: relative;
  @include media-notSmall {
    display : none;
  }
}

.distroscale_dock_avoid_right_rail {
  &:empty {
      max-width: 405px;
      background-color: $white !important;
      height: 225px;
      min-height: 230px;
      max-height: 316px;
      margin-top : -60px;
  }
}

.distroscale-adjust {
  margin-top : -22px;
}

.incontent-element {
  z-index: -1;
  top: 148px;
  left: 50%;
  transform: translate(-50%,-50%);
  position: absolute;
  height: 255px;
  padding : 8px 0;
  img {
    max-width: 100%;
    max-height: 100%;
   }
}

.distroscale-element{
  z-index : 0;
  position: absolute;
  height: 220px;
  transform: translate(25%, -50%);
  top: 111px;
  z-index: -1;
  img{
    max-width: 100%;
    max-height: 100%;
  }  
}

.mobile-doc-element {
  z-index: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  position: absolute;
  top: 10px;
  height : 200px;
  width: 240px;
    img{
      height : 200px;
      width: 240px;
    }
    a {
      padding-top: 10px;
    }
}
.incontent-ads-container, .incontent-ads-container2, .pw-in-article-container {
  position: relative;
  width: 100%;
  //max-height: 600px;
  //min-height: 300px;
  z-index: 2;
  @include media-mobile {
    min-width: 351px;
  }
  &:empty {
    margin: 15px auto 30px;
    //max-height: 600px;
    //min-height: 300px;
    width: 100%;
    background-color: $white;
  }
}

.hide-mobile-ad {
  @include media-small {
    display : none !important;
  }
}

.hide-desktop-ad {
  @include media-notSmall {
    display : none !important;
  }
}

.pw--right-rail {
  @include media-small {
    display : none !important;
  }
  
  &:empty {
    background-color: $white;
    padding-bottom: $s-px;
    padding-left: $s-px;
    padding-right: $s-px;
    border-radius: $radius-big;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    @include media-notSmall {
      height : 637px;
    }
  }
}
.leaderboard-element, .leaderboard-element-mobile {
  position: absolute;
  @include media-mobile {
    min-height: 105px;
  }
  @include media-small {
    min-height: unset !important;
    object-fit: cover;
    width: 100%;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    @include media-notSmall {
      max-width : 728px;
    }
  }
}

.sticky-ad {
  width: 100%;
  height : 96px;
  background : transparent;
  position : fixed;
  bottom : 0px;
  z-index: 100;
}

#pwDeskLbAtf, #pwDeskLbAtf1, #pwDeskLbAtf2, #pwDeskLbAtf3, #pwDeskLbAtf5, #pwDeskMedRectAtf, #pwDeskMedRectAtf1 {
  @include media-small {
    display : none !important;
  }
}

#pwMobiLbAtf, #pwMobiMedRectAtf {
  @include media-notSmall {
    display : none !important;
  }
}


/* Start - CSS by playwire Team */

 /* SN House img element behind our videos need to be stick flush to nav too. This is generic rule above specifics */
//  #pwContainerRightRail > .distroscale-element img {
//   position: absolute;
//   bottom: 224px;
//   /*TODO: Remove bottom: 0px remove duplicate*/
//   bottom: 0px;
//   left: 68px;/*(400-264)/2, container width - image width / 2 to center*/
//   z-index: -1;
// }
/* General rule for sticky nav */
// #pwContainerRightRail{
//   position: sticky;
//   top: 0px;
//   z-index: 2 !important;
// }
// #pwContainerRightRail .distroscale-element {
//   right: 100px;
// }
// #pwContainerRightRail.no-sticky-nav > .distroscale-element img {
//   left: 68px;
//   bottom: 0px;
// }
// #pwContainerRightRail.sticky-nav-sg-landing > .distroscale-element img {
// left: 50px;
// }

.preloaded {
  @include media-small {
    display: none;
  }
}

.preloaded-image-mobile {
  @include media-notSmall {
    display: none;
  }
}

/*For pages that have no sticky nav but no class, apply same rules*/
// #pwContainerRightRail:not(.sticky-nav-sg, .sticky-nav-sg-landing, .sticky-nav-nofear, .sticky-nav-nofear-tall, .sticky-nav-jumpto) > .distroscale-element img {
//   left: 68px;
//   bottom: 0px;
// }

/* Mobile z-index needs to be above their text on page, below slide menu*/
#pwTopDockContainer {
  z-index: 4;
}

/* End - CSS by playwire Team */