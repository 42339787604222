@use 'sass:map';
$fileTypes: (
  'biography': 'png',
  'philosophy': 'png',
  'sociology': 'png',
  'us-government-and-politics': 'png',
  );

.subject-hub-header{
  background-color: $color-brandBlue2020;
  color: $white;
  &--physics{
    background-color: #F0ECE3;
    color: $sn-primary-paragraph;
    &:after{
      background: #292c2e;  //coincidence that this matches an existing variable. Do not replace with $sn-primary-paragraph as if we change the text color we don't want this color to change.
    }
  }
  &--psychology{
    background-color: #31415B;
  }
  &--us-government-and-politics{
    box-shadow: inset 0 -19px 0 0 #157C4C;
  }
  @include media-notSmall{
    position: relative;
    &:after{
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: auto 100%;
      right: 0;
      top: 0;
      width: calc((100vw - 1164px) / 2 + 405px);
      min-width: 405px;
    }
    @each $channel in $channels {
      &--#{$channel}:after{
        @if map.get($fileTypes, $channel){
          $type: map.get($fileTypes, $channel);
          background-image: url(/images/banner-images/banner-#{$channel}-big.#{$type});

        } @else {
          background-image: url(/images/banner-images/banner-#{$channel}-big.svg);
        }
      }
    }
    &--drama{
      &:after{
        background-color: #003255;
      }
    }
    &--psychology{
      &:after{
        background-color: #E8E2E4;
      }
    }
  }
  &__container{
    @include container;
    position: relative;
    display: flex;
    @include media-small{
      flex-direction: column;
    }
    &:after{
      content: '';
      display: block;
      @include media-notSmall{
        width: 405px;
        height: 270px;
      }
      @include media-small{
        width: 100%;
        height: 0;
        padding-bottom: 40%;
        order: -1;
        background-repeat: no-repeat;
        background-size: cover;
        @each $channel in $channels {
          .subject-hub-header--#{$channel} &{
            @if map.get($fileTypes, $channel){
              $type: map.get($fileTypes, $channel);
              background-image: url(/images/banner-images/banner-#{$channel}-small.#{$type});
    
            } @else {
              background-image: url(/images/banner-images/banner-#{$channel}-small.svg);
            }            
          }
          
        }
      }
    }
  }
  &__text{
    flex: 1;
    padding: 12px $pageMargin 48px;
    @include media-notSmall{
      align-self: center;
    }
    @include media-small{
      padding: 16px $pageMargin 48px;
      background-color: $sn-primary-paragraph;
      .subject-hub-header--film &{
        background-color: #C13A3A;
      }
      .subject-hub-header--physics &{
        background-color: transparent;
      }
    }
  }
  &__title{
    font-size: $text-very-big;
    color: inherit;
    line-height: $lh-base;
    font-weight: $fw-fat;
  }
  &__slogan{
    color: inherit;
    line-height: $lh-loose;
    @include media-under-1020{
      font-size: $text-small;
    }
    margin-top: 0.3em;
  }
}