// interior pages styling
.studyGuideText,
.content_txt,
.authorPageText {
  margin: 0 auto;
  h2 {
    padding-bottom: 20px;
  }
  h3 {
    font-weight: $fw-fat;
    font-size: 16px;
  }
  h4 {
    font-weight: $fw-chubby;
    font-size: 24px;
    padding-bottom: 40px;
  }

  a:not([class]) {
    color: $sn-primary-link;
  }

  //Handle Bold and italic tags from strapi
  strong {
    font-weight: $fw-chubby;
  }
  em {
    font-style: italic;
  }
  img {
    max-width: 100%;
  }
}
.content_txt {
  @media (max-width: $mobile-bp) {
    width: 90%;
  }
}
.authorPageText {
  padding-bottom: 4rem;
  @media (max-width: $mobile-bp) {
    width: 90%;
  }
}

#keyfacts {
  b {
    font-weight: $fw-chubby;
    text-transform: capitalize;
     margin-top: -5px;
  }
}

.sitemap {
  h1 {
    color: $sn-primary-header;
  }
  li,
  h3 {
    padding-bottom: 2rem;
  }
}

#table-of-contents {
  // margin-top: 50px;
  padding-bottom: 3rem;
  @media (max-width: $mobile-bp) {
    //padding-top: 3rem;
    margin-top: 0;
    padding-bottom: 0rem;
  }
  h4 {
    @media (max-width: $mobile-bp) {
      padding-bottom: 2rem;
    }
  }
}

.quote-number {
  position: absolute;
  left: 0;
  font-weight: $fw-slim;
  font-size: 30px;
  @media (max-width: $mobile-bp) {
    left: 10px;
  }
}

.clearfix {
  padding-bottom: 1rem;
}

.unit-container {
  @media (max-width: $mobile-bp) {
    width: 100% !important;
  }
}

// links from summary pages to no fear pages (eg /shakespeare/romeojuliet/section2/):
.nfs-nfl-link {
  /*
  display: inline-block;
  font-size: $text-small;
  font-weight: $fw-chubby;
  margin-bottom: 50px;
  padding: 12px 16px;
  background-color: $color-teacherSoftGray;
  color: $color-brandBlue2020;
  border-radius: $radius-base;
  box-shadow: inset 0 -3px 0 0 transparent;
  transition: all 0.2s;
  &:focus,
  &:hover {
    color: $color-linkHover;
    text-decoration: none;
    box-shadow: inset 0 -2px 0 0 $color-linkHover;
  }
  */
}

.quote-line {
  border-left: 2px solid $sn-gray;
  padding-left: 60px;
  margin-left: 32px;
  margin-bottom: 50px;
  @media (max-width: $mobile-bp) {
    padding-left: 30px;
    margin-bottom: 100px;
    margin-top: 80px;
  }
}

.buy-it-now {
  font-size: 11px;
  font-weight: $fw-chubby;
  text-transform: uppercase;
  background-color: $white;
  color: $color-bnGreen;
  padding: 12px;
  margin-left: 15px;
  border-radius: 5px;
  border: none;
  &.buy-it-now:hover {
    //TODO: this uses hacks to overwrite too broadly applied style elsewhere. Investigate and refactor.
    background-color: darken($color-buyNow, 6);
    text-decoration: none !important;
    color: $sn-primary-paragraph;
  }
}

#problems {
  b {
    font-weight: $fw-chubby;
  }
  .content_txt {
    width: 100%;
  }
}

// I added this hack because it seems that the first heading in full-texts is actually in the XML itself.
// Need Will to check and find elegant way to remove
.hack-to-hide-first-h2 > h2:first-child {
  display: none;
}

// styles specifically for main text contents
.mainTextContent {
  line-height: $lh-loose;
  h2 {
    padding-bottom: 0.75em;
  }
  h3:not([class]) {
    font-size: $text-slightly-big;
    padding-bottom: 1em;
    line-height: $lh-base;
  }
  h4:not([class]) {
    padding-bottom: 1em;
  }
  p {
    margin-bottom: 1.5em;
    strong {
      font-weight: $fw-chubby;
    }
  }
  a:not([class]) {
    &:hover {
      text-decoration: underline;
    }
  }
  
  &__studyQuestion {
    $pseudoSize: 48px;
    // a div that wraps individual quotes in the quotes chapter
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: 6px;
    &__icon {
      width: 56px;
      height: 56px;
    }
    p {
      font-size: $text-base;
      font-weight: $fw-chubby;
      line-height: $lh-base;
      text-align: left;
      color: $sn-primary-header;
      margin-bottom: 0;
    }
    p + & {
      // add spacing between quote explanation and next quote
      margin-top: $s-px * 6;
    }
  }
  &__quote {
    position: relative;
    border-radius: 12px;
    box-shadow: 0 0 6px 0 rgba(0, 122, 205, 0.35);
    background-color: rgba(237, 247, 254, 0.25);
    max-width: 652px;

    @include media-small {
      margin: 0 12px 16px;
      padding: 52px 24px 12px 24px;
    }
  
    @include media-notSmall {
      margin: 46px auto 86px;
      padding: 51px 32px 24px 68px;
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 56px;
      height: 56px;
      object-fit: contain;
      left: 0px;
      top: 0px;
      margin: 6px 0 0 12px;
    }
  }
  &__quote:before {
    background-image: url("/images/link-styling/quotes-link.svg");
  }
  &__image {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
  &__image--inline {
    // this class gets added whenever the image has a parent with a class of 'MATH' in the XML.
    // it is used for math/science equations where we stack multiple images to create a single equation.
    vertical-align: middle;
  }
  &__figure {
    padding-top: 12px;
    padding-bottom: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__figcaption {
    text-align: center;
    font-weight: $fw-base;
    font-style: italic;
    line-height: $lh-base;
    padding: 4px 16px;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: $color-teacherSoftGray;
    color: $sn-primary-header;
    display: block;
  }
  &__list-content {
    &__item {
      margin-bottom: 2em;
      &__heading {
        margin-bottom: 0.25em;
      }
    }
  }
}

.questionsMiniEssays-title-icon {
  width: 56px;
  height: 56px;
}

// used for quotes within other pages - eg: http://localhost:3000/lit/catcher/section1/ or http://localhost:3000/lit/mocking/section1/
.interior-quotation {
  position: relative;
  border-radius: 12px;
  box-shadow: 0 0 6px 0 rgba(0, 122, 205, 0.35);
  background-color: rgba(237, 247, 254, 0.25);
  max-width: 652px;
  font-size: $text-base;
  font-weight: $fw-chubby;
  line-height: $lh-base;
  text-align: left;
  color: $sn-primary-header;
  display: flex;
  flex-direction: column;

  @include media-small {
    margin: 0 12px 16px;
    padding: 52px 24px 12px 24px;
  }

  @include media-notSmall {
    margin: 46px auto 86px;
    padding: 51px 32px 24px 68px;
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 56px;
    height: 56px;
    object-fit: contain;
    left: 0px;
    top: 0px;
    margin: 6px 0 0 12px;
  }

  &__attribution {
    text-decoration: none !important;
    position: relative;
    font-family: 'Raleway'!important;
    font-size: $text-base !important;
    text-align: right;
    padding-top: 24px;
    color: #0066c4 !important;
    &:hover {
      color: #004371 !important;
    }
  }

  p {
    font-family: 'Raleway';
    margin: 0;
    font-size: $text-base;
    font-weight: $fw-chubby;
    line-height: $lh-base;
    text-align: left;
    color: $sn-primary-header;
  }
}

.interior-quotation:not(:has(a)), .interior-quotation:not(:has(.interior-quotation__attribution)) {
  padding: 51px 32px 42px 68px;
}

.interior-quotation:before {
  background-image: url("/images/link-styling/quotes-link.svg");
}

.mainTextContent__quote__line {
  margin-bottom: 0px !important;
  font-family: 'Raleway';
  margin: 0;
  font-size: $text-base;
  font-weight: $fw-chubby;
  line-height: $lh-base;
  text-align: left;
  color: $sn-primary-header;

  p {
    margin-bottom: 0px !important;
    font-family: 'Raleway';
    margin: 0;
    font-size: $text-base !important;
    font-weight: $fw-chubby;
    line-height: $lh-base !important;
    text-align: left;
    color: $sn-primary-header !important;
  }

  p {
    a {
      text-decoration: none !important;
      position: relative;
      font-family: 'Raleway' !important;
      font-size: $text-base !important;
      text-align: right;
      padding-top: 24px;
      color: #0066c4 !important;

      &:hover {
        color: #004371 !important;
      }
    }
  }

  p:last-child:has(a) {
    padding-top: 24px;
  }
}

.mainTextContent__quote:not(:has(a)) {
  padding: 51px 32px 42px 68px;
}

p+.mainTextContent__quote {
  margin-top: 48px;
}

.studyGuideText.hack-to-hide-first-h2:not(:has(h3)) {
  blockquote.interior-quotation {
    background-color: $color-teacherSoftGray;
    padding: 12px;
    font-style: italic;
    display: flex;
    flex-direction: column;
    box-shadow: none;
    margin: 0 0 1.5rem;
    font-weight: unset;
    max-width: unset;
    border-radius: unset;

    p {
      margin-bottom: 0.75rem;
    }

    &::before {
      content: unset;
    }
  }
}

span.interior-quotation {
  background-color: $color-teacherSoftGray;
  padding: 12px !important;
  font-style: italic;
  margin: 0 0 1.5rem;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  font-weight: unset;
  max-width: unset;
  border-radius: unset;

  p {
    margin-bottom: 0.75rem;
  }

  &::before {
    content: unset;
  }
}

.keyfact_content {
    @include media-notSmall {
        display : flex;
    }
    background-color: $color-teacherSoftGray;
    padding: 25px;
    border-radius: 5px; 
    margin-bottom: 0.5em !important;
    b {
      display: flex;
      font-size: $text-slightly-big;
      @include media-notSmall {
        max-width: 20%;
        min-width: 20%;
        margin-right: 20px;
        font-size: $text-slightly-big;
      }
    }    
}

.indepth_content {
  padding: 25px 30px;
  margin-bottom : unset !important;
  @include media-notSmall {
    display : flex;
  }
  padding-left : 0px !important;
  border-top: 2px solid $color-teacherSoftGray;
  b {
    display: flex;
    font-size: $text-slightly-big;
    @include media-notSmall {
      min-width: 20%;
      max-width: 20%;
      margin-right: 35px;
      font-size: $text-slightly-big;
    }
  }
}

.facts-header__title, .facts-header__title2 {
  font-size: $text-slightly-big;
  font-weight: $fw-fat;
  line-height: $lh-tight;
  color: $sn-primary-header;
  margin-top: 35px;
  &__top-title{
    margin-top: 20px;
  }
}

.studyQuestion-spacing {
    scroll-margin-top: 120px;
}

.tooltipMessage{
  position: relative;
  width: 300px;
  height: 62px;
  display: flex;
  border-radius: $radius-base;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #00cc9a;
  background-color: #e1faef;

  &[hidden] {
    visibility: hidden;
  }

  &__icon{
    content: url("/images/notetaking/icon_tooltip.svg");
    width: 29px;
    height: 24px;
    margin: 12px 6px;
  }

  &__text{
    margin: 8px 3px;
    font-size: $text-base;
    font-weight: $fw-base;
    line-height: $lh-tight;
    color: $plus-green-dark;
  }

  &__close{
    margin: -3px 0px 0px 0px;
    color: $color-a11yGray;
    cursor: default;
    padding-right: 8px;
    cursor: pointer;
  }

  &__triangle-down {
    height: 16px;
    width: 16px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    bottom: -8px;
    left: calc(50% - 2px);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    border-radius: 0 0 0 $radius-base;
  }
}

.mainTextTooltip{
  position: relative;
}

.mainTextSummary {
  position: absolute;
  left: 90px;
  z-index: 2;
  top: -20px;
}

.mainTextInteriorQuote{
  position: absolute;
  left: 90px;
  z-index: 2;
  top: -56px;
}

.mainTextQuestion{
  position: absolute;
  left: 90px;
  z-index: 2;
  top: -53px;
}

.mainTextQuote{
  position: absolute;
  left: 90px;
  z-index: 2;
  top: -53px;
}

.mainTextFurtherStudy{
  z-index: 2;
  position: absolute;
  left: 90px;
  top: -57px;
}

.mainTextNoFear{
  position: absolute;
  left: 60px;
  top: -8px;
  z-index: 2;
}

.closeButtonPosition{
  margin-top: 2px;
}

.tooltipHighlight {
  z-index: 3 !important;
  @include media-small{
    display: none;
  }
}

.mainTextContent {
  @include media-small {
    margin-bottom: 90px;
  }
}

.signle-full-text {
  border-top: transparent !important;
  border-bottom: transparent !important;
}

.footnotes {
    margin-top: 32px;
    padding: 16px 0 0px 0;
    border-top: solid 1px $sn-border-gray;
    color: $sn-primary-paragraph;
  p {
    margin-bottom: 8px;
    font-size: $text-base;
    &:last-child {
      margin: 0;
    }
  }
} 

.footnotes+.page-turn-nav {
  margin-top: 16px;
}

.footnotes-script {
  .footnote-links {
    font-size: $text-base !important;
    color: #0066c4 !important;
    cursor: pointer;
    &:hover {
      color: #004371 !important;
    }
  }
}