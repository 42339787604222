.myaccount-mainpage {
    overflow-x: hidden;
    &__myaccount {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 11px;
        background-color: #008262;

        @include media-mobile {
            margin-bottom: -1px;
            padding-bottom: 65px;
            display: block;
            text-align: center;
        }

        &__text {
            font-weight: $fw-fat;
            font-size: $text-very-big;
            padding-top: 23px;
            padding-bottom: 23px;
            color: $white;

            @include media-mobile {
                font-size: $text-big;
                font-weight: bold;
                padding-top: 13px;
                padding-bottom: 5px;
            }

        }
    }
    &__accountDetails {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        max-width: $sn-max-width;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 31px;

        @include media-mobile {
            display: flex;
            flex-direction: column;
            margin-top: unset;
        }

        &__account {
            align-items: stretch;
            flex-grow: 1;
            flex-basis: 0;
            min-width: 260px;
            margin-top: 42px;
            margin-left: 20px;

            @include media-mobile {
                margin-top: unset;
                margin-left: unset;
                width: 100%;
                position: absolute;
                border: none;
                text-align: center;
                z-index: 2;
            }

            &__account-mobile {
                display: flex;
                flex-direction: column;

                @include media-mobile {
                    margin-left: 13px;
                    margin-right: 12px;
                    align-items: center;
                    background-color: $white;
                    border-radius: 12px 12px 0 0;
                    margin-bottom: -1px;
                }

                p {
                    &:first-child {
                        margin-right: 12px;
                    }
                }
            }

            &__account-details-color {
                color: $color-brandBlue2020;
                line-height: 35px;
                font-size: $text-base;
                font-weight: bold;
                margin-bottom: 7px;
                padding: 5px 5px 5px 20px;
                cursor: pointer;
                &:hover {
                    text-decoration: none !important;
                }
                @include media-mobile {
                    color: $color-brandBlue2020;
                    flex-direction: row;
                    padding : 5px 12px;
                    margin-bottom: 0px;
                }

            }
        }
        &__currentPlan {
            flex-grow: 3;
            padding-bottom: 60px;

            @include media-mobile {
                margin-right: 30px;
                margin-top: 30px;
                margin-left: 30px;
                margin-bottom: 20px;
                justify-content: center;
            }

            @include media-small {
                margin-right: 20px;
                margin-top: 30px;
                margin-left: 20px;
                margin-bottom: 20px;
                justify-content: left;
            }

            &__subscription {
                margin-top: 73px;

                @include media-mobile {
                    margin-top: 20px;
                }

            }

            &__current-box-border {
                background-color: $color-teacherSoftGray;
                flex-direction: row;
                border: 1px solid $sn-border-gray;
                max-width: 642px;
                width: 100%;
                margin-bottom: 10px;
                padding: 20px 20px 20px 30px;
                border-radius: 12px;

                @include media-mobile {
                    padding: 0px;
                    max-width : unset;
                }

                &__current-status {
                    display: flex;
                    flex-direction: row;

                    @include media-mobile {
                        display: flex;
                        flex-direction: column;
                        padding-left: 16px;
                        padding-right: 16px;
                        &:focus-within {
                            background: $sn-back-gray;
                        }
                        &:focus-within:first-child {
                            background: $sn-back-gray;
                            border-radius: 6px 6px 0px 0px;
                        }
                        &:focus-within:last-child {
                            background: $sn-back-gray;
                            border-radius: 0px 0px 6px 6px;
                        }
                    }

                    
                        &__name-top-space {
                            display: flex;
                            flex-direction: row;
                            @include media-mobile {
                                flex-direction: column;
                                padding-top: 10px;
                                padding-left: 16px;
                                padding-right: 16px;
                            }
                        }
                        &__notification-top-space {
                            display: flex;
                            flex-direction: row;
                            @include media-mobile {
                                padding-bottom: 10px;
                                flex-direction: column;
                                padding-left: 16px;
                                padding-right: 16px;
                            }
                        }

                    &__current-status-text {
                        flex: 0.25;
                        padding: 10px;
                        margin-top: 21px;

                        @include media-mobile {
                            padding: 10px 10px 10px 0px;
                            margin-top : unset;
                        }

                        &__text-right-details {
                            text-align: right;
                            width: 97%;
                            font-size: $text-base;
                            font-weight: bold;
                            color: $sn-primary-header;

                            @include media-mobile {
                                width: 100%;
                                text-align: left;
                            }

                        }
                    }

                    &__current-status-notes {
                        flex: 1.1;
                        padding: 10px 20px 10px 20px;
                        margin-top: 23px;

                            @include media-mobile {
                                padding: 0px 0px 5px 0px;
                                margin-top: 0px;
                            }

                        &__current-password {
                            display: flex;
                            margin-bottom : 10px;
                            &__password-header {
                               font-size: $text-small;
                               font-weight: $fw-base;
                               margin-bottom: 10px;
                            }
                            &__password-header-tooltip {
                                font-size: $text-small;
                                font-weight: $fw-base;
                             }
                        }

                        &__checkbox-header {
                            margin-top: -15px;
                            margin-left: -21px;
                        }

                        &__notification-details {
                            margin-left: 37px;
                            margin-top: -4px;
                        }

                        &__form-group {
                            position: relative;
                            bottom: 1px;
                            width: 100%;
                            margin-bottom: 16px;

                            &__error-name {
                                color: $color-error;
                                font-size: $text-super;
                                position: relative;
                                margin-top: -21px;
                                top: 22px;   
                            }
                        }

                        &__form-group-password {
                            position: relative;
                            bottom: 1px;
                            width: 100%;
                            margin-bottom: 16px;

                            &__error-password {
                                position: absolute;
                                color: $color-error;
                                font-size: $text-super;
                            }

                            &__error-email {
                                color: $color-error;
                                font-size: $text-super;
                            }

                        }

                        &__text-right {
                            width: 97%;
                            
                            &__text-right-hidden {
                                flex-direction: row;
                                display: flex;
                                justify-content: flex-end;
                                padding-bottom: 16px;

                                @include media-mobile {
                                    justify-content: space-between;
                                    padding-bottom: 12px;
                                    width: 100%;
                                }

                            }
                            
                            &__remove-card {
                                border: none;
                                color: $sn-primary-header;
                                padding: 8px 25px;
                                text-align: center;
                                text-decoration: none;
                                display: inline-block;
                                font-size: $text-small;
                                font-weight: $fw-chubby;
                                margin: 4px 14px;
                                cursor: pointer;
                                border-radius: $radius-base;
                                border: 2px solid $sn-primary-header;
                                background-color: $white;
                                &:hover, &:focus {
                                    background-color: $color-linkHover;
                                    color: $white;
                                }

                                @include media-mobile {
                                    margin: 4px 0px 4px 0px;
                                }

                            }

                            &__change-card {
                                border: none;
                                color: $white;
                                padding: 8px 32px;
                                text-align: center;
                                text-decoration: none;
                                display: inline-block;
                                font-size: $text-small;
                                font-weight: $fw-chubby;
                                margin: 4px 2px;
                                cursor: pointer;
                                background-color: $color-brandBlue2020;
                                border-radius: $radius-base;
                                &:hover, &:focus {
                                    background-color: $color-linkHover;
                                    color: $white;
                                }

                                @include media-mobile {
                                    float: right;
                                }

                            }

                        }

                        &__text-right-password {
                            width: 97%;

                            @include media-mobile {
                                width: 100%;
                            }
                            
                            &__text-right-password-hidden {
                                flex-direction: row;
                                display: flex;
                                justify-content: flex-end;
                                padding-bottom: 16px;

                                @include media-mobile {
                                    justify-content: space-between;
                                    padding-bottom: 12px;
                                }

                            }

                            &__remove-card-password {
                                border: none;
                                color: $sn-primary-header;
                                padding: 8px 25px;
                                text-align: center;
                                text-decoration: none;
                                display: inline-block;
                                font-size: $text-small;
                                font-weight: $fw-chubby;
                                margin: 4px 14px;
                                cursor: pointer;
                                border-radius: $radius-base;
                                border: 2px solid  $sn-primary-header;
                                background-color: $white;
                                &:hover, &:focus {
                                    background-color: $color-linkHover;
                                    color: $white;
                                }

                                @include media-mobile {
                                    margin: 4px 0px 4px 0px;
                                }

                            }

                            &__change-card-password {
                                border: none;
                                color: $white;
                                padding: 8px 32px;
                                text-align: center;
                                text-decoration: none;
                                display: inline-block;
                                font-size: $text-small;
                                font-weight: $fw-chubby;
                                margin: 4px 2px;
                                cursor: pointer;
                                background-color: $color-brandBlue2020;
                                border-radius: $radius-base;
                                &:hover, &:focus {
                                    background-color: $color-linkHover;
                                    color: $white;
                                }

                                @include media-mobile {
                                    float: right;
                                }

                            }

                        }

                        &__text-right-email {
                            width: 97%;

                            @include media-mobile {
                                width : 100%;
                            }

                            &__text-right-email-hidden {
                                flex-direction: row;
                                display: flex;
                                justify-content: flex-end;
                                padding-bottom: 16px;

                                @include media-mobile {
                                    justify-content: space-between;
                                    padding-bottom: 12px;
                                }

                            }

                            &__remove-card-email {
                                border: none;
                                color: $sn-primary-header;
                                padding: 8px 25px;
                                text-align: center;
                                text-decoration: none;
                                display: inline-block;
                                font-size: $text-small;
                                font-weight: $fw-chubby;
                                margin: 4px 14px;
                                cursor: pointer;
                                border-radius: $radius-base;
                                border: 2px solid $sn-primary-header;
                                background-color: $white;
                                &:hover, &:focus {
                                    background-color: $color-linkHover;
                                    color: $white;
                                }

                                @include media-mobile {
                                    margin: 4px 0px 4px 0px;
                                }

                            }

                            &__change-card-email {
                                border: none;
                                color: $white;
                                padding: 8px 32px;
                                text-align: center;
                                text-decoration: none;
                                display: inline-block;
                                font-size: $text-small;
                                font-weight: $fw-chubby;
                                margin: 4px 2px;
                                cursor: pointer;
                                background-color: $color-brandBlue2020;
                                border-radius: $radius-base;
                                &:hover, &:focus {
                                    background-color: $color-linkHover;
                                    color: $white;
                                }

                                @include media-mobile {
                                    float: right;
                                }
                            }
                        }
                    }
                }

                &__current-main-subscription, &__upcoming-main-subscription {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 10px;
                    padding-top: 10px;

                    @include media-mobile {
                        display: flex;
                        flex-direction: column;
                        padding-left: 16px;
                        padding-right: 16px;
                    }

                    &__current-subscription {
                        flex: 0.23;
                        padding: 10px 30px 10px 10px;
                        margin-top: 21px;

                        @include media-mobile {
                            padding: 10px 1px 5px 0px;
                            margin-top : unset;
                        }

                        &__text-right-details {
                            text-align: right;
                            width: 97%;
                            font-size: $text-base;
                            font-weight: bold;
                            color: $sn-primary-header;

                            @include media-mobile {
                                width: 100%;
                                text-align: left;
                            }
                        }
                    }

                    &__current-status-subscription {
                        flex: 0.9;
                        padding: 10px 10px 10px 0px;
                        margin-top: 23px;
                        border-bottom: 1px solid $sn-border-gray;

                        @include media-mobile {
                            padding: 0px;
                            margin-top: 0px;
                        }

                        &__saved-current {
                            display: flex;
                        }

                        &__card {
                            display: flex;
                        }

                        &__text-right-subscription {
                            width: 97%;

                            &__change-plan-subscription {
                                border: none;
                                color: $white;
                                padding: 14px 47px;
                                text-align: center;
                                text-decoration: none;
                                display: inline-block;
                                font-size: $text-base;
                                font-weight: $fw-chubby;
                                margin: 16px 2px;
                                cursor: pointer;
                                background-color: $color-brandBlue2020;
                                border-radius: $radius-base;
                                &:hover, &:focus {
                                    background-color: $color-linkHover;
                                    color: $white;
                                }

                                @include media-mobile {
                                    width: 100%;
                                }
                            }

                            &__renew-plan-subscription {
                                border: none;
                                color: $white;
                                padding: 14px 20px;
                                text-align: center;
                                text-decoration: none;
                                display: inline-block;
                                font-size: $text-base;
                                font-weight: $fw-chubby;
                                margin: 16px 2px;
                                cursor: pointer;
                                background-color: $color-brandBlue2020;
                                border-radius: $radius-base;
                                &:hover, &:focus {
                                    background-color: $color-linkHover;
                                    color: $white;
                                }

                                @include media-mobile {
                                    width: 100%;
                                }

                            }
                        }
                    }

                    &__current-subscription-billing {
                        flex: 0.23;
                        padding: 10px 30px 10px 10px;
                        
                        @include media-mobile {
                            padding: 10px 10px 5px 0px;
                        }

                        &__text-right-details {
                            text-align: right;
                            width: 97%;
                            font-size: $text-base;
                            font-weight: bold;
                            color: $sn-primary-header;

                            @include media-mobile {
                                width: 100%;
                                text-align: left;
                            }
                        }
                    }

                    &__current-status-subscription-billing {
                        flex: 0.9;
                        padding: 12px 10px 10px 0px;
                        border-bottom: 1px solid $sn-border-gray;

                        @include media-mobile {
                            padding: 0px;
                        }

                        &__card {
                            display: flex;
                        }

                        &__text-right-subscription {
                            width: 97%;

                            &__change-plan-subscription {
                                border: none;
                                color: $white;
                                padding: 8px 26px;
                                text-align: center;
                                text-decoration: none;
                                display: inline-block;
                                font-size: $text-base;
                                margin: 16px 2px;
                                cursor: pointer;
                                background-color: $color-brandBlue2020;
                                border-radius: $radius-base;
                                &:hover, &:focus {
                                    background-color: $color-linkHover;
                                    color: $white;
                                }

                                @include media-mobile {
                                    float: right;
                                }
                            }

                            &__remove-plan-subscription {
                                border: none;
                                color: $color-brandBlue2020;
                                padding: 8px 32px;
                                text-align: center;
                                text-decoration: none;
                                display: inline-block;
                                font-size: $text-base;
                                margin: 16px 14px;
                                cursor: pointer;
                                border-radius: $radius-base;
                                &:hover, &:focus {
                                    background-color: $color-linkHover;
                                    color: $white;
                                }

                                @include media-mobile {
                                    margin: 4px 0px 4px 0px;
                                }
                            }
                        }
                    }

                    &__current-subscription-method {
                        flex: 0.23;
                        padding: 10px 30px 10px 10px;

                        @include media-mobile {
                            padding: 10px 10px 5px 0px;
                            margin-top : unset;
                        }

                        &__text-right-details {
                            text-align: right;
                            width: 97%;
                            font-size: $text-base;
                            font-weight: bold;
                            color: $sn-primary-header;

                            @include media-mobile {
                                width: 100%;
                                text-align: left;
                            }
                        }
                    }

                    &__current-status-subscription-method {
                        flex: 0.9;
                        padding: 10px 10px 10px 0px;
                        margin-top: 5px;
                        border-bottom: none;

                        @include media-mobile {
                            padding: 0px;
                            margin-top: 0px;
                            border-bottom: 1px solid $sn-border-gray;
                        }

                        &__card {
                            display: flex;
                            @include media-small {
                                position: relative;
                            }
                        }

                        &__text-right-subscription {
                            width: 97%;

                            &__update-card-subscription {
                                border: none;
                                color: $white;
                                padding: 14px 49px;
                                text-align: center;
                                text-decoration: none;
                                display: inline-block;
                                font-size: $text-base;
                                font-weight: $fw-chubby;
                                margin: 16px 2px;
                                cursor: pointer;
                                background-color: $color-brandBlue2020;
                                border-radius: $radius-base;
                                &:hover, &:focus {
                                    background-color: $color-linkHover;
                                    color: $white;
                                }

                                @include media-mobile {
                                    width: 100%;
                                }
                            }

                            &__remove-card-subscription {
                                border: none;
                                color: $white;
                                padding: 14px 43px;
                                text-align: center;
                                text-decoration: none;
                                display: inline-block;
                                font-size: $text-base;
                                font-weight: $fw-chubby;
                                margin: 16px 2px;
                                cursor: pointer;
                                border-radius: $radius-base;
                                background-color: $color-brandBlue2020;
                                &:hover, &:focus {
                                    background-color: $color-linkHover;
                                    color: $white;
                                }

                                @include media-mobile {
                                    width: 100%;
                                }
                            }
                        }
                    }
                                                           
                    &__current-subscription-history {
                        flex: 0.23;
                        padding: 10px;

                        @include media-mobile {
                            padding: 10px 10px 5px 0px;
                        }

                        &__text-right-details {
                            text-align: right;
                            width: 97%;
                            font-size: $text-base;
                            font-weight: bold;
                            color: $sn-primary-header;

                            @include media-mobile {
                                width: 100%;
                                text-align: left;
                            }
                        }
                    }

                    &__current-status-subscription-history {
                        flex: 0.9;
                        padding: 0px 0px 10px 20px;
                        margin-top: 16px;

                        @include media-mobile {
                            padding: 0px;
                            margin-top: 0px;
                        }

                        &__see-all{
                            margin-top: 10px;
                            @include media-mobile {
                                padding-bottom: 15px;
                            }
                        }

                        &__payment-history {
                            display: flex;
                            flex-direction: column;
                            border-bottom: 1px solid $sn-border-gray;
                            padding: 10px 5px 10px 5px;

                            &:first-child {
                                border-top: 1px solid $sn-border-gray;
                                padding: 10px 5px 10px 5px;
                            }

                            &__details {
                                display: inline-flex;
                            }
                        }
                    }

                    &__current-status-subscription-cancel {
                        flex: 0.9;
                        padding: 10px 10px 10px 150px;

                        @include media-mobile {
                            padding: 0px;
                        }

                        &__text-right-subscription {
                            width: 97%;

                            &__cancel-subscription {
                                border: none;
                                color: $white;
                                padding: 14px 35px;
                                text-align: center;
                                text-decoration: none;
                                display: inline-block;
                                font-size: $text-base;
                                font-weight: $fw-chubby;
                                margin: 16px 2px;
                                cursor: pointer;
                                background-color: $color-brandBlue2020;
                                border-radius: $radius-base;
                                &:hover, &:focus {
                                    background-color: $color-linkHover;
                                    color: $white;
                                }

                                @include media-mobile {
                                    width: 100%;
                                    margin: 5px 0px 32px 0px;
                                }
                            }
                        }
                    }

                    &__current-subscription-cancel {
                        flex: 0.23;
                        padding: 10px;
                        margin-top: 20px;

                        @include media-mobile {
                            padding: 10px 10px 5px 0px;
                            margin-top : unset;
                        }

                        &__text-right-details {
                            text-align: right;
                            width: 97%;
                            font-size: $text-base;
                            font-weight: bold;
                            color: $sn-primary-header;

                            @include media-mobile {
                                width: 100%;
                                text-align: left;
                            }
                        }
                    }
                }

                &__manage-group-discount {
                    display: flex;
                    flex-direction: row;
                    // gap: 24px;
                    @include media-mobile {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                    }
                    &__current-plan {
                        flex: 0.27;
                        padding: 0 24px 10px 0;
                        @include media-mobile {
                            padding: 0;
                            margin-top : unset;
                        }
                        &__text-right-details {
                            text-align: right;
                            width: 97%;
                            font-size: $text-base;
                            font-weight: bold;
                            color: $sn-primary-header;
                            @include media-mobile {
                                width: 100%;
                                text-align: left;
                            }
                        }
                    }
                    &__plan-details {
                        flex: 0.9;
                        padding: 0 10px 23px 0px;
                        border-bottom: 1px solid $sn-border-gray;
                        @include media-mobile {
                            padding: 0 0 24px 0;
                            margin-top: 0px;
                        }
                        &__text {
                            font-size: $text-small;
                            color: $sn-primary-header;
                            @include media-mobile {
                                width: 100%;
                                text-align: left;
                            }
                        }
                    }
                    &__joining-link, &__joining-code, &__reedemed-code {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        justify-content: flex-end;
                        padding: 23px 24px 10px 0;
                        flex: 0.23;
                        
                        @include media-mobile {
                            padding: 24px 0 4px 0;
                            justify-content: flex-start;
                        }
                        &__text-right-details {
                            padding-right: 8px;
                            text-align: right;
                            width: 97%;
                            font-size: $text-base;
                            font-weight: bold;
                            color: $sn-primary-header;
                            line-height: $lh-base;
                            @include media-mobile {
                                text-align: left;
                                width: unset;
                                justify-content: flex-start;
                            }
                        }
                        &__info-image {
                            padding-top: 5px;
                        }
                    }
                    &__reedemed-code {
                        display: unset;
                        padding-top: 35px;
                        flex: 0.25;
                        @include media-mobile {
                            padding: 24px 0 4px 0;
                        }
                        &__text-right-details {
                            padding-right: 0px !important;
                            @media (min-width: 980px) {
                                transform: translateX(8px);
                            } 
                        }
                    }
                    &__joining-code {
                        padding: 33px 24px 0 0;
                        flex: 0.26;
                        @include media-mobile {
                            padding: 24px 0 4px 0;
                        }
                    }
                    &__link {
                        display: flex;
                        flex: 0.8;
                        padding: 26px 0 24px 0;
                        border-bottom: 1px solid $sn-border-gray;
                        @include media-mobile {
                            padding: 0px;
                            flex-direction: column;
                        }
                        &__text {
                            font-size: $text-small;
                            color: $sn-primary-header;
                            line-height: $lh-base;
                            padding-right: 8px;
                            @include media-mobile {
                                padding: 0 0 16px 0;
                            }
                        }
                        &__buttons {
                            display: flex;
                            flex-direction: row;
                            gap: 8px;
                            
                            @include media-mobile {
                                gap: 16px;
                                padding-bottom: 24px;
                            }
                        }
                        &__copy-joining-link, &__email-joining-link {
                            width: 80px;
                            height: 46px;
                            padding: 16px 20px;
                            border-radius: 4px;
                            color: $white;
                            background: #0066c4;
                            border: none;
                            font-size: $text-base;
                            font-weight: $fw-chubby;
                            @include media-mobile {
                                width: 100%;
                            }
                            &:hover {
                                background-color: #004371;
                            }
                        }
                        &__copy-joining-link-notification {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            @include media-mobile {
                                width: 100%;
                            }
                        }
                        &__email-joining-link {
                            margin-left: 8px;
                        }
                    }
                    &__join-code {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        flex: 0.9;
                        padding: 24px 0px;
                        border-bottom: 1px solid $sn-border-gray;
                        @include media-mobile {
                            display: unset;
                            padding: 0px;
                            padding-bottom: 24px;
                        }
                        &__text {
                            font-size: $text-small;
                            font-weight: $fw-chubby;
                            color: $sn-primary-header;
                            line-height: $lh-base;
                            padding-right: 16px;
                            @include media-mobile {
                                padding: 0 0 16px 0;
                            }
                        }
                        &__copy-joining-code-notification {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            @include media-mobile {
                                width: 100%;
                            }
                        }
                        &__copy {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            @include media-mobile {
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                            }
                        }
                        &__copy-joining-code {
                            width: 80px;
                            height: 46px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            padding: 16px 20px;
                            border-radius: 4px;
                            color: $white;
                            background: #0066c4;
                            border: none;
                            font-size: $text-base;
                            font-weight: $fw-chubby;
                            @include media-mobile {
                                width: 100%;
                                padding: 16px;
                            }
                            &:hover {
                                background-color: #004371;
                            }
                        }
                        &__link {
                            padding-top: 24px;
                            @include media-mobile {
                                padding-bottom: 24px 0;
                            }
                            &__text-link {
                                text-decoration: underline;
                                color: #0066c4;
                                &:hover {
                                    color: #004371 !important;
                                    text-decoration: underline;
                                }
                            }
                            
                            &__text {
                                font-size: $text-small;
                                color: $sn-primary-header;
                                line-height: $lh-base; 
                            }
                        }
                    }
                    &__reedem-code-accounts-count {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        flex: 0.9;
                        // padding: 12px 10px 24px 0px;
                        border-bottom: 1px solid $sn-border-gray;
                        @include media-mobile {
                            padding: 0px;
                            flex-direction: column;
                            align-items: flex-start;
                        }
                        &__text {
                            font-size: $text-small;
                            color: $sn-primary-header;
                            line-height: $lh-base;
                            padding: 35.5px 16px 35.5px 0;
                            @include media-mobile{
                                padding: 0 0 16px 0;
                            }
                        }
                        &__redeem-code-now {
                            width: 189px;
                            height: 46px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            padding: 16px 20px;
                            border-radius: 4px;
                            background: #0066c4;
                            border: none;
                            font-size: $text-base;
                            font-weight: $fw-chubby;
                            color: $white;
                            
                            &:hover {
                                background-color: #004371;
                            }
                            @include media-mobile {
                                width: 100%;
                                margin-bottom: 24px;
                            }

                            &:disabled {
                                opacity: 0.5;
                            }
                        }
                        &__redemption-limit-text {
                            font-size: 14px;
                            line-height: 1.6;
                            padding-bottom: 24px;
                            margin-top: -27.5px;
                            @include media-mobile {
                                margin-top: -8px;
                            }

                            a {
                                text-decoration: underline;
                            }
                        }
                    }
                    &__current-subscription-history {
                        flex: 0.23;
                        @include media-mobile {
                            padding: 10px 10px 5px 0px;
                        }
                        &__text-right-details {
                            font-size: $text-base;
                            font-weight: bold;
                            color: $sn-primary-header;
                            @include media-mobile {
                                width: 100%;
                                text-align: left;
                            }
                        }
                    }
                    &__current-status-subscription-history {
                        flex: 0.7;
                        margin-top: 12px;
                        @include media-mobile {
                            padding: 0px;
                            margin-top: 0px;
                        }
                        &__see-all{
                            margin-top: 10px;
                            @include media-mobile {
                                padding-bottom: 15px;
                            }
                        }
                        &__payment-history {
                            display: flex;
                            flex-direction: column;
                            // border-bottom: 1px solid $sn-border-gray;
                            padding: 0 5px 10px 5px;
                            &__details {
                                display: inline-flex;
                            }
                        }
                    }
                }
            }
        }
    } 

    .hidden {
        @include media-mobile {
            display: none;
        } 
    }

    .details-design {
        background-color: $color-teacherSoftGray;
        margin-right: 16px;

        @include media-mobile {
            background-color: unset;
            margin-right: unset;
            text-decoration: none !important;
        }
    }

    .menu-highlight {
        @include media-mobile {
            padding: 5px 12px;
            font-size: 1rem;
        }
    }

    .form-field {
        width: 97%;

        @include media-mobile {
            width: 100%;
        }

        border: 0;
        border: 1px solid rgba(0, 0, 0, 0.25);
        outline: 0;
        font-size: $text-base;
        color: $sn-primary-paragraph;
        padding: 29px 16px 12px 16px;
        transition: border-color 0.2s;
        border-radius: $radius-base;
        background-color: $white;
        &[aria-invalid='true']{
            color: $color-form-error !important;
            border: 1px solid $color-form-error;
        }
    }
    
    .form-field:focus {
        outline: none;
        border-radius: $radius-base;
        box-shadow: 0 0 3px 2px $color-brandBlue2020;
        &[aria-invalid='true']{
            box-shadow: 0 0 3px 2px $color-form-error;
        }
    }
    
    .form-field::placeholder {
        color: transparent;
    }
    
    .form-label {
        position: absolute;
        display: block;
        transition: unset;
        font-size: 12px;
        color: $color-a11yGray;
        top: 12px;
        left: 18px;
        font-weight: $fw-slim;
        [aria-invalid='true'] {
            color: $color-form-error;
        }
    }

    .fe-label-text {
        &[aria-invalid='true'] {
            color: $color-form-error;  
        }
    }
    
    .form-field:placeholder-shown~.form-label {
        font-size: $text-base;
        cursor: text;
        top: 23px;
        left: 17px;
    }
    
    .form-field:focus~.form-label {
        position: absolute;
        top: 12px;
        left: 18px;
        display: block;
        transition: 0.2s;
        font-size: 12px;
        color: $color-a11yGray;
        &[aria-invalid='true'] {
            color: $color-form-error;  
        }
    }

    .showpassword-eye {
        position: relative;
        right: 37px;
        bottom: 37px;
        cursor: pointer;
        float: right;
        &[aria-invalid='true']{
            color: $color-form-error;
        }
        @include media-mobile {
            right: 25px;
        }
    }

    .tooltip-header {
        font-size: $text-super;
        font-weight: $fw-chubby;
    }

    .tooltip-text {
        font-size: $text-super;  
        color: $sn-black;
    }

    .tooltip-password {
        position: relative;
        top: 1px;
        left: 13px;
        box-shadow: 0 0 0 1px $color-a11yGray;
        transform: translateY(.15em);
        transition: box-shadow .2s;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        path {
            fill: $color-a11yGray;
        }
        @include media-mobile {
            left: 7px;
        }
    }

    .tooltip-password:hover {
        background-color: $color-brandBlue2020;
        box-shadow: 0 0 0 1px $color-brandBlue2020;
        path {
            fill: $white;
        }
    }
    
    .tooltip-password .tooltiptext {
        visibility: hidden;
        width: 245px;
        background-color: $white;
        color: $sn-black;
        border-radius: 6px;
        padding: 15px 14px;
        border: 1px solid #d6d4d4;
        position: absolute;
        z-index: 1;
        font-size: $text-small;
        line-height: 19px;
    }
    
    .tooltip-password .tooltiptext::after {
        content: '';
        position: absolute;
        width: 21px;
        height: 21px;
        left: 45%;

        @include media-mobile {
            left : 91%;
            right : 15px;
        }

        transform: translate(-50%, 21%) rotate(45deg);
        background-color: $white;
        box-shadow: 0 1px 8px rgb(0 0 0 / 0%);
        border-bottom: 1px solid #d6d4d4;
        border-right: 1px solid #d6d4d4;
    }
    
    .tooltip-password:hover .tooltiptext {
        visibility: visible;
        left: -104px;
        bottom: 35px;

        @include media-mobile {
            left: -215px;
        }

    }

    .input-checkmark {
        top: 13px;
        opacity: 0;
        position: relative;
        left: 21px;
    }

    .input-checkmark + .checkmark {
        position: relative;
        cursor: pointer;
        display: inline-block;
        line-height: 25px;
    }

    .input-checkmark + .checkmark::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        box-shadow: 0 0 0 2px $color-brandBlue2020;
        background: $white;
        border-radius: 2px;
    }

    .input-checkmark:checked + .checkmark::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        box-shadow: 0 0 0 2px $color-brandBlue2020;
        background: $white;
        background: $color-brandBlue2020;
        border-radius: 2px;
    }

    .input-checkmark:checked + .checkmark::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 16px;
        border-right: 1px solid $white;
        border-bottom: 1px solid $white;
        top: 13%;
        left: 2%;
        transform: translate(69%, -1%) rotateZ(39deg);
    } 

    .loader1, .loader2, .loader3 {
        border: 3px solid #f3f3f3;
        border-radius: 50%;
        border-top: 3px solid #3498db;
        width: 33px;
        height: 33px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        margin: 7px 16px 0px 5px;
    }

    @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
    }
      
    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }

    .tick-subscription {
        position: relative;
        display: inline-block;
        border-right: 2px solid #007036;
        border-bottom: 2px solid #007036;
        width: 8px;
        height: 16px;
        transform: translate(69%,-1%) rotateZ(39deg);
        right: 15px;
    }

    .tick {
        position: relative;
        display: inline-block;
        border-right: 2px solid #007036;
        border-bottom: 2px solid #007036;
        width: 8px;
        height: 15px;
        transform: translate(69%,-1%) rotateZ(41deg);
        right: 15px;
    }

    .changes {
        font-size: $text-small;
        color: #007036;
    }

    .changes-current {
        font-size: $text-small;
        font-weight: $fw-chubby;
        color: $color-noFear;
        padding-left: 30px;
    }

    .changes-method {
        font-size: $text-small;
        font-weight: $fw-chubby;
        color: $color-noFear;
        margin-top: 4px;
        margin-left: 60px;
        @include media-mobile {
            margin-left: unset;
            float: right;
        }
    }

    .monthy-acivate {
        font-size: $text-super;
        color: $color-a11yGray;  
    }

    .expired-current {
        color: $color-form-error;
        font-size: $text-small;
        font-weight: bold;
        padding-left: 21px;
    }

    .card-expired-current {
        display: flex;
        align-items: center;
        color: $color-form-error;
        font-size: $text-small;
        font-weight: bold;
        padding-left: 21px;
        @include media-small {
            position: absolute;
            right: 0;
        }
    }

    .expired-method {
        color: $color-form-error;
        font-size: $text-small;
        padding-top: 6px;
        font-weight: bold;
        margin-left: 10px;
        @include media-mobile {
            display: none;
        }
    }
    
    .expired-method-mobile {
        display: none;
        @include media-mobile {
            display: block;
            color: $color-form-error;
            font-size: $text-small;
            margin-top: -22px;
            margin-left: 172px;
            font-weight: bold;
            position: relative;
            top: -6px;
        }
    }

    .changes-billing {
        font-size: $text-small;
        font-weight: $fw-chubby;
        color: $color-noFear;
    }

        .remove-card-container .remove-card-button .update-card-button .change-plan .renew-sub .new-name .new-password .email-save .new-password-error .select-plan .exiting-password-error.email-error .loader .changes-saved .changes-saved-email .changes-saved-password .subscription .my-account .changes-saved-notification .remove-credit .offer-on .offer-off .changes-billing .current-error-desktop .current-error-mobile .changes-method .subscription-error {
        &[hidden]{
            display: none;

            @include media-mobile {
                display: none;
            }
            
        }
    }
    .changes-saved, .changes-saved-notification, .changes-saved-password, .changes-saved-email {
        float: right;
        margin-right: 14px;
    }

    .toggle {
        display: flex;
        flex-direction: row;
        margin-top: 22px;
        margin-bottom: 10px;
         &[hidden]{
            display: none !important;

            @include media-mobile {
                display: none;
            }
            
        }
    }

    .toggle-checkbox {
        width: 114px;
        margin-top: -4px;
        margin-left: -28px;

        @include media-mobile {
            width: unset;
            margin-left: -4px;
        }

    }

    .toggle-checkbox input {
        position: relative;
        height: 31px;
        width: 58px;
        background: $sn-primary-header;
        -webkit-appearance: none;
        border-radius: 50px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        left: 30px;
        bottom: 3px;
        cursor: pointer;
        
        @include media-mobile {
            float: right;
            left: 0px;
        }
        
    }

    input[type="checkbox"]:checked {
        background: #008262;
    }

    .toggle-checkbox input[type="checkbox"]::before {
        height: 95%;
        width: 29px;
        left: 2px;
        position: absolute;
        content: '';
        background: linear-gradient(#fff, #f2f2f2, #e6e6e6, #d9d9d9);
        border-radius: 50px;
        transform: scale(0.85);
        top: 1px
    }

    input[type="checkbox"]:checked::before {
        left: 28px;
    }
    
    .renew-text {
        font-size: $text-small;
        color: $sn-primary-header;
        margin-bottom: 15px;
        font-weight: normal;
    }

    .renew-text-current {
        font-size: $text-small;
        color: $sn-primary-header;
        @include media-mobile {
            flex: 1;
        }
    }

    .current-text {
        font-size: $text-base;
        color: $sn-primary-header;
    }

    .current-error-mobile {
        @include media-mobile {
            float: right;
            margin-top: -29px;
            margin-right: unset;
        }
    }

    .autorenew-text {
        font-size: $text-small;
        color: $sn-primary-header;
        width: 142px;
        font-weight: bold;

        @include media-mobile {
            width: unset;
            padding-left: 10px;
            flex: 1;
        }

    }

    .renew-bold {
        font-weight: normal;
    }

    .bill-text-renews {
        font-weight: bold;
        height: 23px;
        flex-grow: 0;
        margin: 0 12px 0 0;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: #41ffaa;
        color: #007036;
    }

    .bill-text-expires {
        font-weight: bold;
        height: 23px;
        flex-grow: 0;
        margin: 0 12px 0 0;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: #f7b500;
        color: $sn-primary-header;
    }

    .bill-text-expired {
        font-weight: 600;
        height: 23px;
        flex-grow: 0;
        margin: 0 12px 0 0;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: $color-form-error;
        color: $white;
    }

    .bubble-indicator {
        position: relative;
        top: 1px;
    }

    .card-number {
        font-size: $text-base;
        color: $sn-primary-header;
        position: relative;
        left: 9px;
        top: 3px;

        @include media-mobile {
            width: 125px;
        }
    }

    .table-date {
        font-size: $text-tiny;
        color: $color-a11yGray;
    }

    .table-details {
        flex: 1;
        font-size: $text-small;
        color: $sn-primary-paragraph;
    }

    .price-details {
        flex: 0.2;
        font-size: $text-small;
        color: $sn-primary-paragraph;
        font-weight: bold;
        @include media-mobile {
            flex: 0.3;
        }
    }

    .icon-download {
        margin-top: -3px;
    }

    .hide-icon {
        visibility : hidden
    }
    
    .discount {
        font-size: $text-super;
        color: $color-brandBlue2020;
    }

    .show-all-payments {
        font-size: $text-super;
        color: $color-brandBlue2020;
        font-weight: bold;
        border: none;
        background: none;
        height: 30px;
        cursor: pointer;
        padding: 1px 5px;
    }

    .bold-text {
        font-weight: $fw-chubby;
    }

    .changes-mobile {
        @include media-mobile {
            display: block;
            flex: 1;
        }  
    }

    .subscription-error {
        &__error-msg {
          background-color: $color-form-error;
          color: $white;
          text-align: center;
          display: flex;
          flex-direction: row;
          padding: 15px 30px;
          margin-right: -20px;
          margin-top: -20px;
          margin-left: -20px;
          &__logo {
            padding-top: 5px;
          }
          &__text {
            text-align: left;
            margin-left: 15px;
            line-height: 1.5;
            margin-top: 10px;
            @include media-mobile {
                margin-top: unset;
            }
          }
        }
    }
}

.container-background {
    background: #eeeaee;
}

li {
    list-style-type: none;
}

.tab-background {
    display: unset !important;
    @include media-mobile {
        display: none !important;
        // background-color: #e1faef;
        // border-bottom-left-radius: 12px;
        // border-bottom-right-radius: 12px;
        // &:hover, &:focus {
        //     background: rgba(0,204,154,.2);
        // }
    }
}

// a:last-child {
//     border-bottom-left-radius: 12px;
//     border-bottom-right-radius: 12px;  
// }

.tab-background .anchor-text {
    @include media-mobile {
        font-weight: 400;
    }
}

.menu-hide {
    display: unset !important;
    @include media-mobile {
        display: unset !important; 
        width: 100%;
    }
}

.tab-section {
    @include media-mobile {
        flex: 1;
    }  
}

.box-shade {
    @include media-mobile {
        box-shadow: 0 2px 6px 0 $color-a11yGray;
        border-radius: 12px;
    }  
}

.didive-line {
    @include media-mobile {
        width: 100%;
        // border-bottom: 1px solid $sn-border-gray;
    }
}

.anchor-text {
    color: $color-brandBlue2020;
    @include media-mobile {
        color: #008262;
        font-size: $text-small;
        font-weight: $fw-chubby;
    }
}

.cancel-container, .no-card-text, .user-card-details, .remove-user-card {
    &[hidden]{
        display: none;    
    }
}

.no-card-text {
	font-style: italic;
	color: $sn-primary-header;
    margin-bottom: 10px;
	span {
	  font-weight: $fw-chubby;
	}
}

.payment-list {
    &:nth-of-type(n+6) {
        display:none;
    }
}

#newTogglePassword {
    &[hidden]{
        display: none;

        @include media-mobile {
            display: none;
        }
        
    }
}

#existingTogglePassword {
    &[hidden]{
        display: none;

        @include media-mobile {
            display: none;
        }
        
    }
}

.dot {
    position: relative;
    top: -1px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: inline-block;
    background-color: black;
}

.auto-changing {
    display: flex;
    flex-direction: row;
    @include media-mobile {
        flex: 1;
    }
}

.drop-down-rotate {
    img {
        transform: rotate(180deg);
    }
}

.mobile-menu {
    @include media-mobile {
        position: absolute;
        width: 96%;
        top: -49px;
        margin: 0px 15px;
    }
    @include media-small {
        position: absolute;
        width: auto;
        // top: -42px;
        top: -48px;
        margin: unset;
        right: 16px;
        left: 16px;
    }
}

.hide-desktop {
    display: none;
    @include media-mobile {
        display: block;
    }   
}

.drop-down {
    display: none;
    @include media-mobile {
        display: unset;
        float: right;
        position: relative;
        bottom: 32px;
        right: 25px;
        cursor: pointer;
    }  
}

.anchor-text-color {
    color: $color-brandBlue2020;
    @include media-mobile {
        color: #004a3e;
    }
}

.menu-rotate-subscription {
    flex-direction: column;
    @include media-mobile {
        flex-direction: column-reverse;
    }
}

.tooltip-password svg {
    vertical-align: unset !important;
}

.test:focus-within {
    visibility : hidden !important;
}

.myaccount-coupon-details, .upcoming-coupon {
    margin-top: 10px;
    // display: flex;
    // flex-direction: row;
}

.coupon-discount {
    font-size: $text-small;
    color: $sn-primary-paragraph;
    line-height: $lh-base;
}

.coupon-name, .upcoming-coupon-name {
    font-size: $text-small;
    color: $sn-primary-paragraph;
    line-height: $lh-base;
    font-weight: $fw-chubby;
    &:after {
        content: "|";
        padding: 5px;
    }

    @include media-mobile {
        max-width : 100%;
    }
}

.upcoming-invoice-amount {
    font-size: $text-small;
    line-height: $lh-base;
    &:before {
        content: "|";
        padding: 0px 4px 0 1px;
    }
}

.group-discount-bold-text {
    font-weight: 600;
}

.change-plan-text1, .change-plan-text2{
    position: absolute;
    bottom : -34px;
    width : 199px;

    @include media-small {
        width : 136px;  
    }
}

.plan-inner-text {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align : center;
}

.plan-text-color {
    color : #007acd !important;
}

.upcoming-subscription {
    padding-top: 14px !important;
    &__text{
        margin-top : unset !important;
        padding-top: unset !important;

        @include media-tablet-and-desktop {
            flex: .3 !important;
            padding-left: 0px;
            margin-left: -22px;   
            padding-bottom : 24px !important;
        }
    }

    &__plan {
        margin-top : unset !important;
        padding-top: unset !important;
        padding-bottom : 24px !important;
    }
}
.gd-on-focus:focus {
    outline: none;
    border-radius: $radius-base;
    box-shadow: 0 0 3px 2px #004371;
  }

.gd-container {
    padding: 20px 20px 20px 24px;
    @include media-mobile {
        padding: unset;
    }
    &__details-plan {
        padding-bottom: 23px;
    }
    &__current-plan {
        flex: .3;
        padding: 10px 24px 10px 0px;
        @include media-mobile {
            padding: 14px 0px 0px 0px;
        }
    }
    &__upcoming-plan {
        padding-left: 0;
        margin-left: unset;
        padding: 10px 24px 10px 0px;
        @include media-mobile {
            padding: unset;
        }
    }
    &__start-plan {
        flex: .3;
        padding: 10px 24px 10px 0px;
        @include media-mobile {
            padding: unset;
        }
    }
    &__plan-status {
        @include media-mobile {
            padding-bottom: 14px;
            padding-top: 2px;
        }
    }
    &__payment-details {
        flex: .3;
        padding: 10px 24px 10px 0px;
        @include media-mobile {
            padding: unset;
        }
    }
    &__payment-history {
        flex: .3;
        padding: 10px 20px 10px 0px;
        @include media-mobile {
            padding: unset;
            padding-bottom: 8px;
        }
    }
    &__remove-button {
        padding: 14px 20px;
    }
    &__payment-detailCard {
        padding: 5px 10px 5px 0px;
        @include media-mobile {
            padding: 10px 10px 10px 0px;
        }
    }
    &__history-container {
        padding-top: unset;
        @include media-mobile {
            padding-top: 24px;
            padding-bottom: 40px;
        }
    }
    &__payment-container {
        margin-bottom: unset;
        padding-top: 4px;@include media-mobile {
            padding-top: 24px;
        }
    }
    &__start-plan-container {
        padding-top: 4px;
        @include media-mobile {
            margin-bottom: unset;
            padding-top: 14px;
        } 
    }
    &__history-detail {
        padding: 0px 0px 10px 0px;
        margin-top: 7px;
        @include media-mobile {
            margin-top: unset;
        }
    }
}

.gd-container__payment-detailCard .remove-user-card {
    margin: 16px 0px 5px 0px;
    @include media-mobile {
        margin: 16px 0px 14px 0px;
    }
}

.gd-container__details-plan .stripe-renew-sub button {
    margin: 16px 0px 0px 0px;
}

.gd-renew-plan {
    @include media-mobile {
        flex-direction: column;
    }
}

.gd-renew-plan .expired-current {
    @include media-mobile {
        padding-left: unset;
    }
}

.gd-container__plan-status .bill-text {
    margin: 0 6px 0 0;
}

.removeBorder {
    padding: 12px 10px 9px 0;
    border-bottom: unset;
    @include media-mobile {
        border-bottom: 1px solid #e5e5e5;
        padding: 12px 10px 11px 0;
    }
}

.removerBottomMargin {
    margin-bottom: unset;
}

.group-discount-box-border {
    padding: 56px 24px 48px 24px;

    @include media-mobile { 
        padding: 24px 16px 40px;
    }
}

.manage-group-discount {
    .subscription-error-message:not([hidden]) {
        display: flex;
        align-items: center;
        max-height: 68px;
        margin: -56px -24px 48px -24px;
        @include media-mobile {
            margin: -24px -16px 40px;
            max-height: 76px;
        }
    }
}
.copied-notification, .copied-notification-code {
    visibility: hidden;
    padding: 16px;
    width: 80px;
    height: 53px;
    background-color: $white;
    border: 1px solid $sn-border-gray;
    position: absolute;

    &::after {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        top: 80%;
        left: 49%;
        transform: translate(-50%,21%) rotate(45deg);
        background-color: $white;
        box-shadow: 0 1px 8px transparent;
        border-bottom: 1px solid $sn-border-gray;
        border-right: 1px solid $sn-border-gray;
    }

    &__text {
        font-size: $text-small;
        line-height: $lh-base;
        color: $sn-primary-paragraph;
    }
}
.groupDiscountCopiedNotification {
    visibility: visible;
    bottom: 56px;
}

.groupDiscountCodeCopiedNotification {
    visibility: visible;
    bottom: 56px;
}

.menu-rotate-tab {
    flex-direction: column;
}

@include media-mobile { 

    #acc-details {
        display: flex;
        flex-direction: column;
        #account-details-page {
            order: 0;
            border-top: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 12px 12px 0 0;
        }
        #subscription-and-billing-page {
            order: 1;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            background-color: #e1faef;
            &:hover, &:focus {
                border-left: solid 4px #01a87f;
                border-right: solid 4px #01a87f;
                background: rgba(0,204,154,.2); 
            }
        }
        #manageGroup-discount-page {
            order: 2;
            border-bottom: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 0 0 12px 12px;
            background-color: #e1faef;
            &:hover, &:focus {
                border-left: solid 4px #01a87f;
                border-right: solid 4px #01a87f;
                border-bottom: solid 4px #01a87f;
                background: rgba(0,204,154,.2); 
            }
        }
    }

    #sub-bill {
        display: flex;
        flex-direction: column;
        #account-details-page {
            order: 1;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            background-color: #e1faef;
            &:hover, &:focus {
                border-left: solid 4px #01a87f;
                border-right: solid 4px #01a87f;
                background: rgba(0,204,154,.2); 
            }
        }
        #subscription-and-billing-page {
            order: 0;
            border-top: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 12px 12px 0 0;
        }
        #manageGroup-discount-page {
            order: 2;
            border-bottom: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 0 0 12px 12px;
            background-color: #e1faef;
            &:hover, &:focus {
                border-left: solid 4px #01a87f;
                border-right: solid 4px #01a87f;
                border-bottom: solid 4px #01a87f;
                background: rgba(0,204,154,.2); 
            }
        }
    }

    #ind-sub-bill {
        display: flex;
        flex-direction: column;
        #account-details-page {
            order: 1;
            border-bottom: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 0 0 12px 12px;
            background-color: #e1faef;
            &:hover, &:focus {
                border-left: solid 4px #01a87f;
                border-right: solid 4px #01a87f;
                border-bottom: solid 4px #01a87f;
                background: rgba(0,204,154,.2); 
            }
        }
        #subscription-and-billing-page {
            order: 0;
            border-top: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 12px 12px 0 0;
        }
    }

    #ind-acc-details {
        display: flex;
        flex-direction: column;
        #account-details-page {
            order: 0;
            border-top: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 12px 12px 0 0;
        }
        #subscription-and-billing-page {
            order: 1;
            border-bottom: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 0 0 12px 12px;
            background-color: #e1faef;
            &:hover, &:focus {
                border-left: solid 4px #01a87f;
                border-right: solid 4px #01a87f;
                border-bottom: solid 4px #01a87f;
                background: rgba(0,204,154,.2); 
            }
        }
    }

    #two-acc-details {
        display: flex;
        flex-direction: column;
        #account-details-page {
            order: 0;
            border-top: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 12px 12px 0 0;
        }
        #manageGroup-discount-page {
            order: 1;
            border-bottom: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 0 0 12px 12px;
            background-color: #e1faef;
            &:hover, &:focus {
                border-left: solid 4px #01a87f;
                border-right: solid 4px #01a87f;
                border-bottom: solid 4px #01a87f;
                background: rgba(0,204,154,.2); 
            }
        }
    }
    
    #manage-gd {
        display: flex;
        flex-direction: column;
        #account-details-page {
            order: 1;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            background-color: #e1faef;
            &:hover, &:focus {
                border-left: solid 4px #01a87f;
                border-right: solid 4px #01a87f;
                background: rgba(0,204,154,.2); 
            }
        }
        #subscription-and-billing-page {
            order: 2;
            border-bottom: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 0 0 12px 12px;
            background-color: #e1faef;
            &:hover, &:focus {
                border-left: solid 4px #01a87f;
                border-right: solid 4px #01a87f;
                border-bottom: solid 4px #01a87f;
                background: rgba(0,204,154,.2); 
            }
        }
        #manageGroup-discount-page {
            order: 0;
            border-top: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 12px 12px 0 0;
        }
    }

    #manage-gd-not-reedmed {
        display: flex;
        flex-direction: column;
        #account-details-page {
            order: 1;
            border-bottom: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 0 0 12px 12px;
            background-color: #e1faef;
            &:hover, &:focus {
                border-left: solid 4px #01a87f;
                border-right: solid 4px #01a87f;
                border-bottom: solid 4px #01a87f;
                background: rgba(0,204,154,.2); 
            }
        }
        #manageGroup-discount-page {
            order: 0;
            border-top: solid 4px #00cc9a;
            border-left: solid 4px #00cc9a;
            border-right: solid 4px #00cc9a;
            border-radius: 12px 12px 0 0;
        }
    }
}

.group-discount-emailCopy, .group-discount-email-body {
    display: none;
}

//manage GD for join by link tooltip
.join-link {
    position: relative;
}

.join-link .tooltip-join-link {
    visibility: hidden;
    width: 290px;
    height: auto;
    background-color: $white;
    color: $sn-black;
    border-radius: 6px;
    padding: 15px 14px;
    border: 1px solid $sn-border-gray;
    position: absolute;
    z-index: 1;
    font-size: $text-small;
    line-height: 19px;

    @include media-small {
        width: 260px;
    }
}

.join-link .tooltip-join-link::after {
    content: '';
    position: absolute;
    width: 21px;
    height: 21px;
    left: 48%;

    @include media-mobile {
        left : 36%;
    }

    @include media-small {
        left : 40%;
    }

    transform: translate(-50%, 21%) rotate(45deg);
    background-color: $white;
    box-shadow: 0 1px 8px rgb(0 0 0 / 0%);
    border-bottom: 1px solid $sn-border-gray;
    border-right: 1px solid $sn-border-gray;
}

.join-link:hover .tooltip-join-link {
    visibility: visible;
    left: -133px;
    bottom: 33px;

    @include media-mobile {
        left: -97px;
    }

}

.tooltip-join-link__text {
    font-size: $text-small;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: $lh-base;
    letter-spacing: normal;
    text-align: left;
    color: $sn-primary-paragraph;
}

//manage GD for join by code tooltip
.join-code {
    position: relative;
}

.join-code .tooltip-join-code {
    visibility: hidden;
    width: 270px;
    height: auto;
    background-color: $white;
    color: $sn-black;
    border-radius: 6px;
    padding: 15px 14px;
    border: 1px solid $sn-border-gray;
    position: absolute;
    z-index: 1;
    font-size: $text-small;
    line-height: 19px;

    @include media-small {
        width: 260px;
    }
}

.join-code .tooltip-join-code::after {
    content: '';
    position: absolute;
    width: 21px;
    height: 21px;
    left: 52%;

    @include media-mobile {
        left : 42%;
    }

    @include media-small {
        left : 44%;
    }

    transform: translate(-50%, 21%) rotate(45deg);
    background-color: $white;
    box-shadow: 0 1px 8px rgb(0 0 0 / 0%);
    border-bottom: 1px solid $sn-border-gray;
    border-right: 1px solid $sn-border-gray;
}

.join-code:hover .tooltip-join-code {
    visibility: visible;
    left: -133px;
    bottom: 33px;

    @include media-mobile {
        left: -107px;
    }

}

.tooltip-join-code__text {
    font-size: $text-small;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: $lh-base;
    letter-spacing: normal;
    text-align: left;
    color: $sn-primary-paragraph;
}

.group_expired {
    padding-left: 16px !important;
}

.remove-payment-biling {
    margin-bottom: unset;
    @include media-mobile {
        margin-bottom: 10px;
    }
}

.remove-payment-history {
    padding-top: unset;
}

.remove-border-billing {
    border-bottom: unset;
    @include media-mobile {
        border-bottom: 1px solid #e5e5e5;
    }
}

.redemption-limit-text {
    flex-direction: column;
    align-items: unset;
}

.germany-unsubscribe {
    &__fullpage {
        display: flex;
        justify-content: center;
        background-color: #e1faef;
    }

    &__cont {
        max-width: 576px;
        padding-top: 40px;
        padding-bottom: 29px;

        .fe-field{
            & + .fe-field{
              margin-top: 24px;
            }
        }

        @include media-mobile {
            padding: 0px;
        }
        
        &__form {
            background-color: $white;
            padding: 0 40px;
            border-bottom-right-radius: 12px;
            border-bottom-left-radius: 12px;
            @include media-mobile {
                padding: 0 24px;
            }

            &__error-container {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: stretch;
                gap: 12px;
                padding: 16px 16px 16px 24px;
                margin: 0 -40px;
                background-color: #fbefed;
                border: solid 2px $color-form-error;
                border-radius: 4px;

                @include media-small {
                    margin: 0 -24px;
                }

                &__text {
                    color: $color-form-error;
                    font-size: $text-base;
                    line-height: $lh-base;
                }
            }

            &__header {
                font-size: $text-big;
                font-weight: $fw-chubby;
                line-height: $lh-base;
                text-align: center;
                color: #008262;
                padding-bottom: 24px;
                padding-top: 32px;
                @include media-mobile {
                    padding: 24px 0;
                }
            }

            &__headerInfo {
                font-size: $text-base;
                line-height: $lh-base;
                text-align: left;
                border-bottom: 1px solid $sn-border-gray;
                padding-bottom: 24px;
                width: 100% !important;
            }

            &__required {
                padding-top: 24px;
                padding-bottom: 16px;
            }
        }
    } 
}

.germany-unsubscribe-notes{
    color:$sn-primary-paragraph;
    font-size: $text-small;
    line-height: $lh-base;
    padding-top: 24px;
}

.germany-cancellation-btn-cont {
    padding-bottom: 48px;

    .germany-cancellation-btn {
        background-color:#0066c4;
        padding: 13.5px 92px;
        line-height: normal;
        letter-spacing: 0.13px;
        box-shadow: unset;
        @include media-mobile {
            padding: 13.5px 98px;
        }

        &:hover{
            background-color: #004371;
            color: white;
            box-shadow: 0 20px 15px -15px rgba(0, 0, 0, 0.25);
        }
    }
    
}

.germany-cancellation-btn{
    &:disabled {
        opacity: 0.39;
    }
}

.germanyConfirmationPage {
        display: flex;
        justify-content: center;
        background-color: #e1faef;
    
    &__cont {
        max-width: 576px;
        padding-top: 40px;
        padding-bottom: 165px;
        @include media-mobile {
           padding-top: 0px;
           padding-bottom: 51px;
        }

        &__info {
            background-color: $white;
            padding-left: 40px;
            padding-right: 40px;
            border-bottom-right-radius: 12px;
            border-bottom-left-radius: 12px;
            @include media-mobile {
                padding: 0 24px;
            }

            &__header {
                font-size: $text-big;
                font-weight: $fw-chubby;
                line-height: $lh-base;
                text-align: center;
                color: #008262;
                padding-bottom: 24px;
                padding-top: 32px;
                @include media-mobile {
                    padding: 24px 0;
                }

                &__date {
                    flex-grow: 0;
                    font-size: $text-base;
                    line-height: $lh-base;
                    text-align: left;
                    color: $sn-primary-paragraph;
                    border-bottom: 1px solid $sn-border-gray;
                    padding-bottom: 24px;
    
                    span {
                        font-weight: $fw-chubby;
                    }
                }
            }   

            &__subscription {
                padding-top: 24px;

                &__details {
                    font-size: $text-base;
                    line-height: $lh-base;
                    text-align: left;
                    color:$sn-primary-paragraph;
                    font-weight: $fw-chubby;
                }

                &__printer {
                    font-size: $text-base;
                    line-height:$lh-base;
                    text-align: left;
                    color: #0066c4;
                    text-decoration: underline;
                }
               &__printerdiv {
                   display: flex;

                   p {
                    margin-right: 8px;
                   }
               }
            }
        }
    }
}

.germanyConfirmationPage__backToSparknotes {
    margin-bottom: 40px;
    padding: 13.5px 74px;
    letter-spacing: 0.13px;
    box-shadow: unset;
    background-color: #0066c4;

    @include media-mobile {
        padding: 13.5px 71px;
    }
}

.hidden-warning {
    display: none !important;
}

.germany-unsubscribe-error-msg {
    display: flex;
    gap: 12px;
    padding: 16px 16px 16px 24px;
    border-radius: 4px;
    border: solid 2px $color-form-error;
    background-color:#fbefed;
}

.germany-unsubscribe-error-msg-text {
    font-size: $text-base;
    line-height: $lh-base;
    color: $color-form-error;

}

.germany-cancellation-btn:disabled {
    opacity: 0.5;
    &:hover {
        background-color: #0066c4 !important;
        box-shadow: unset !important;
    }
}