.memes_home {
    &__title {
      display : flex;
      padding-top : 40px;
      &__text {
        font-size : $text-big;
        margin-left : $s-px;
        font-weight: $fw-chubby;
        color : $sn-primary-header;

        @include media-small {
          margin-left: auto;
          margin-right : auto;
        }
      }
    }
    &__section {
      display        : flex;
      flex-wrap      : wrap;
      padding-top    : $pageMargin;
      padding-bottom : 40px;
      @include media-small {
        justify-content: center;
      }
      &__meme {
        display      : block;
        width        : fit-content;
        margin-top   : 24px;
        margin-right : 20px;

        @include media-small {
          margin-top   : 24px;
          margin-right : $pageMargin;
        }
        &__memeLink {
          @include previewHover;
          background-color: $color-teacherSoftGray;
          display: block;
          border-radius: $radius-big;
          overflow: hidden;
          justify-content: center;
          margin: 0;
          padding: 5px;
          border: none;
          cursor: pointer;
          &:hover,
          &:focus {
            background-color: $sn-bg;
            box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.21);
            transform : scale(1.05);
          }
          &__img {
            mix-blend-mode: multiply;
            display: flex;
          }
        }
      }
    }
} 

.meme__modal__content{
  display: flex;
  @include media-small{
    flex-direction: column;
  }
  margin: 12px;
}
.meme__modal__image-container {
  background-color: $color-teacherSoftGray;
  justify-content : center;
  flex : 1;
  @include media-small{
    flex-direction: column;
  }
  &--loading{

  }
}


.meme__modal__image{
  @include media-notSmall {
    padding : 20px 60px 0px 60px;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
  width : 100%;
  display: block;
  .meme__modal__image-container--loading &{
    opacity: 0;
  }
}

.meme__modal__page-number{
  font-weight: $fw-chubby;
}

.meme__modal__ad {
  [data-pw-desk] {
    margin : 24px 0px 32px 0px;
    @include media-under-1020 {
      display : none;
    }
    max-width        : 728px;
    height           : 90px;
    margin-left      : auto;
    margin-right     : auto;
    background-color : $white;
  } 
}
.meme__modal__more-content {
  text-align : center;
  margin-top: 24px;
  &__button {
    display: flex;
    width: fit-content;
    height : 42px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    padding : 8px 24px;
    font-size : $text-base;
    font-weight: $fw-chubby;
    background-color: $color-brandBlue2020;
    color: $white;
    border-radius: $radius-base;
    line-height: $lh-base;
    &:hover,
    &:focus{
      background-color: $color-linkHover;
    }

    &__icon {
      display : flex;
    }
  }

  &__text {
    margin-top: 16px;
    margin-bottom : 24px;
  }
}