@mixin focusing {
  @if (#{&} == ".searchbar") {
    &--open,
    #search-overlay & {
      @content;
    }
  } @else {
    .searchbar--open &,
    #search-overlay & {
      @content;
    }
  }
}

#searchbar--a11yMessage, #heroArrow, #overlayArrow, #mobileArrow, #resultArrow {
  @include visuallyhidden;
}

.searchbar {
  &__wrapper {
    padding-left: $pageMargin;
    padding-right: $pageMargin;
    &__titleRedesignHub {
      background-image: unset !important;
    }
    &--abovePopularTitles {
      background-image: linear-gradient(to bottom, transparent 26px, $color-teacherSoftGray 26px);
    }
    &--searchResults {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 24px;
    }
  }
  @include container;
  background-color: $white;
  border-radius: $radius-base;
  box-shadow: 0 4px 24px rgba(#000, 0.2);
  position: relative;
  z-index: 4;
  @include media-small {
    z-index: 3;
  }
  @include focusing {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  &--belowHero {
    margin-top: -26px;
  }
  &--searching {
    @keyframes loader-animation {
      0% {
        transform: scaleX(0) translateX(0);
      }
      50% {
        transform: scaleX(0.5) translateX(50%);
      }
      100% {
        transform: scaleX(1) translateX(100%);
      }
    }
    overflow: hidden;
    &:before {
      animation: loader-animation 2s infinite;
      transform-origin: left;
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 4px;
      background: $color-linkHover;
      top: 0;
      left: 0;
    }
  }
  &__form {
    display: flex;
  }
  &__label {
    @include visuallyhidden;
  }
  &__input {
    @include removeInputStyles($removeFocus: true);
    flex: 1;
    padding: 16px;
    font-weight: $fw-chubby;
    color: $sn-primary-header;
  }
  &__button {
    @include removeBtnStyles;
    padding: 0;
    width: 52px;
    min-height: 52px;
    &__text {
      @include visuallyhidden;
    }
    path {
      fill: $sn-primary-header;
    }
    &:hover,
    &:focus {
      path {
        fill: $color-linkHover;
      }
    }
  }
  &__suggestions {
    display: none;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    background-color: $color-teacherSoftGray;
    border-radius: 0 0 $radius-base $radius-base;
    flex-direction: column;
    transition: height 0.2s;
    overflow: hidden;
    box-shadow: 0 16px 24px rgba(#000, 0.3);
    @include focusing {
      &:not([hidden]) {
        display: flex;
      }
    }
    &__list {
      flex-wrap: wrap;
      &:not([hidden]) {
        display: flex;
        padding-left: 16px;
      }
    }
    &__title {
      padding: 16px;
      color: $sn-primary-header;
      font-size: $text-small;
      font-weight: $fw-chubby;
      line-height: 1;
    }
    &__item {
      @include removeBtnStyles($removeFocus: true);
      border-radius: 500px;
      background-color: $white;
      color: $sn-primary-header;
      padding: 12px 16px;
      font-weight: $fw-chubby;
      margin-bottom: 16px;
      margin-right: 16px;
      &:hover,
      &:focus {
        background-color: #004371;
        color: $white;
      }
    }
  }
}


.redesignGlobalSearch {
  #searchbar--a11yMessage, #heroArrow, #overlayArrow, #mobileArrow, #resultArrow {
    @include visuallyhidden;
  }
  
  .searchbar {
    &__wrapper {
      padding-left: $pageMargin;
      padding-right: $pageMargin;
      &__titleRedesignHub {
        background-image: unset !important;
      }
      &--abovePopularTitles {
        background-image: linear-gradient(to bottom, transparent 26px, $color-teacherSoftGray 26px);
      }
      &--searchResults {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 24px;
      }
    }
    @include container;
    background-color: $white;
    border-radius: $radius-base;
    box-shadow: 0 4px 24px rgba(#000, 0.2);
    position: relative;
    z-index: 4;
    @include media-small {
      z-index: 3;
    }
    @include focusing {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    &--belowHero {
      margin-top: -26px;
    }
    &--searching {
      @keyframes loader-animation {
        0% {
          transform: scaleX(0) translateX(0);
        }
        50% {
          transform: scaleX(0.5) translateX(50%);
        }
        100% {
          transform: scaleX(1) translateX(100%);
        }
      }
      overflow: hidden;
      &:before {
        animation: loader-animation 2s infinite;
        transform-origin: left;
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 4px;
        background: $color-linkHover;
        top: 0;
        left: 0;
      }
    }
    &__form {
      display: flex;
    }
    &__label {
      @include visuallyhidden;
    }
    &__input {
      @include removeInputStyles($removeFocus: true);
      flex: 1;
      padding: 16px;
      font-weight: $fw-chubby;
      color: $sn-primary-header;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      border-left: 2px solid transparent;
      &:focus {
        border-top: 2px solid #484848;
        border-bottom: 2px solid #484848;
        border-left: 2px solid #484848;
      }
    }
    &__button {
      @include removeBtnStyles;
      padding: 0;
      width: 52px;
      min-height: 52px;
      &__text {
        @include visuallyhidden;
      }
      path {
        fill: $sn-primary-header;
      }
      &:hover,
      &:focus {
        path {
          fill: #004371;
        }
      }
    }
    &__suggestions {
      display: none;
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      background-color: $color-teacherSoftGray;
      border-radius: 0 0 $radius-base $radius-base;
      flex-direction: column;
      transition: height 0.2s;
      overflow: hidden;
      box-shadow: 0 16px 24px rgba(#000, 0.3);
      @include focusing {
        &:not([hidden]) {
          display: flex;
        }
      }
      &__list {
        flex-wrap: wrap;
        &:not([hidden]) {
          display: flex;
          padding-left: 16px;
        }
      }
      &__title {
        padding: 16px;
        color: $sn-primary-header;
        font-size: $text-small;
        font-weight: $fw-chubby;
        line-height: 1;
      }
      &__item {
        @include removeBtnStyles($removeFocus: true);
        border-radius: 500px;
        background-color: $white;
        color: $sn-primary-header;
        padding: 12px 16px;
        font-weight: $fw-chubby;
        margin-bottom: 16px;
        margin-right: 16px;
        &:hover,
        &:focus {
          background-color: #004371;
          color: $white;
        }
      }
    }
  }

  .searchbar__input:focus + .searchbar__button {
    border-top: 2px solid #484848;
    border-bottom: 2px solid #484848;
    border-right: 2px solid #484848;
    path {
      fill: #004371;
    }
  }
}

.redesign-globalsearch , .homepage{
  .search-dropdown-container {
    display: none;
    position: absolute;
    background-color: $white;
    padding: 1px;
    width: 100%;
    z-index: 4;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, .2);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    &__topTitle-container {
      &__heading {
        font-family: Raleway;
        font-size: $text-small;
        font-weight: bold;
        font-style: normal;
        line-height: $lh-base;
        text-align: left;
        color: $sn-primary-header;
        padding: 16px 0 0 16px;
      }
      &__topTitle {
        margin: 1px;
        &__list {
          margin-top: 1px;
          margin-bottom: 1px;
          @include media-mobile {
            &:last-child {
              display: none;
            }
          }
          &__text {
            display: block;
            width: 100%;
            font-family: Raleway;
            font-size: $text-base;
            font-weight: normal;
            font-style: normal;
            line-height: $lh-base;
            text-align: left;
            color: $sn-primary-paragraph !important;
            padding: 11px 0 11px 15px;
            &:hover, &:focus {
              background-color: $color-teacherSoftGray;
              color: #004371;
              text-decoration: underline;
              text-underline-offset: 2px;
            }
          }
        }
      }
    }
  }

  .new-searchbar .new-searchbar__wrapper {
    position: relative;
  }

  .show-lithub-dropdown {
    display: block;
  }

  .redesignGlobalSearch .searchbar__input {
    position: relative;
    padding: 16px 45px 16px 16px !important;
    @media (max-width: 980px) { 
      padding : 13px 10px 13px 18px !important;
    }
  }

  .global_auto_suggest_list {
    
    .auto_result_link {
      display: flex;
      line-height: $lh-base;
      align-items: center;
      color: $sn-primary-paragraph;
      cursor: pointer;
      font-weight: 700;
      font-size: $text-base;
      padding: 11px 16px;

      &:hover, &:focus{
        background-color: $color-teacherSoftGray;
        color: #004371;
        text-decoration: underline;
        text-underline-offset: 2px;
        h4 {
          background-color: $color-teacherSoftGray;
          color: #004371;
          text-decoration: underline;
          text-underline-offset: 2px;
        }
        span {
          background-color: $color-teacherSoftGray;
          color: #004371;
          text-decoration: underline;
          text-underline-offset: 2px;
        }
        color: #004371;
      }

      .nolink_title{
        font-weight: 700;
        font-size: $text-base;
      }
      
      .check_author_title {
        color: $sn-primary-paragraph;
        font-size: $text-base;
        font-weight: $fw-base;
        font-style: italic;
      }

      .highlight {
        font-weight: $fw-base;
      }

      .auto_result_heading {
        padding: 0;
      }
      
    }

    .auto_result_heading {
      display: flex;
      line-height: $lh-base;
      align-items: center;
      color: $sn-primary-paragraph;
      font-weight: 700;
      padding: 11px 16px;

      .nolink_title{
        font-weight: 700;
        font-size: $text-base;
      }
      
      .check_author_title {
        color: $sn-primary-paragraph;
        font-size: $text-base;
        font-weight: $fw-base;
        font-style: italic;
      }

      .highlight {
        font-weight: $fw-base;
      }
    }

    .title_text {
      color: $sn-primary-paragraph;
      font-size: $text-base;
      font-weight: 700;
      font-style: normal;
    }

    .hide_shakespeare_author {
      display: none;
    }
  }

  // auto suggest global search
  .globalHiddenUl {
    display: none !important;
  }

  .global_search_clear_btn {
    position: absolute;
    right: 61px;
    top: 12px;
    width: 32px;
    height: 32px;

    a {
      color: #0066c4 !important;
      font-size: $text-base;
      line-height: $lh-base;
      cursor: pointer;
      display: none;
    }

    @media (max-width: 980px) {
      right: 11px;
    }
  }

  .global_clear_all_btn.showClearAllBtn {
    display: block;
  }

  .show-global-search-dropdown {
    display: block;
  }

  .hideGlobalSearchIcon svg {
    @media (max-width: 980px) {
      display: none;
    }
  }

  .global_clear_all_btn.showClearAllBtn {
    display: block;
    margin-top: 2px;
    @media (max-width: 980px) {
      margin-top: 0;
      .global_clear_text {
        display: none !important;
      }
      .global_cross_icon {
        display: block !important;
      }
    }
    .global_cross_icon {
      display: none;
    }
    .global_clear_text {
      display: block ;
    }
  }
}