// ============== // ==============// ==============
// Core Scss Import - includes reset
// ============== // ==============// ==============// sizes for breakpoints, max width etc

//mixins for easier writing media quieries
@mixin media-mobile {
  @media (max-width: $mobile-bp) {
    @content;
  }
}

@mixin media-tablet {
  @media (min-width: $mobile-bp+1) and (max-width: $tablet-bp) {
    @content;
  }
}
@mixin media-mobile-and-tablet {
  @media (max-width: $tablet-bp) {
    @content;
  }
}

@mixin media-desktop {
  @media (min-width: $tablet-bp+1) {
    @content;
  }
}

@mixin media-tablet-and-desktop {
  @media (min-width: $mobile-bp+1) {
    @content;
  }
}

html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  box-sizing: border-box;
  font-size: 1rem;
  scroll-behavior: smooth;
  // users who have set prefers-reduced-motion:
  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
}

input[type="text"]::-ms-clear {
  display: none;
}

// playwire padding
[data-pw-desk] {
  margin-bottom: 2rem;
}
[data-pw-mobi] {
  &:not(:empty){
    margin-bottom: 2rem;
  }
}

// ============== // ==============// ==============
// common extends
// ============== // ==============// ==============
%form-submit-button-style {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: $sn-primary-link;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  padding-top: 0;
  font-family: inherit;
  font-weight: 400;
}

%form-submit-button-style-disabled {
  background-color: #e5e5e5;
  color: #6d7474;
  width: 100%;
}

%form-style {
  background-color: #fdfdfd;
  width: 456px;
  margin: 4rem auto 0;
  padding: 30px 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e5e5e5;
  border-radius: 5px;
}

%input-style {
  width: 85%;
  height: 55px;
  font-size: 16px;
  margin: 0.5rem 0;
  padding-left: 13px;
  padding-top: 15px;
  border-radius: 3px;
  border: solid 1px #b9b9b9;
  color: $sn-primary-paragraph;
  font-weight: $fw-slim;
}

%label-style {
  @media (max-width: $mobile-bp) {
      left: 2%;
  }
  position: absolute;
  z-index: 1;
  color: #292c2e;
  font-weight: 300;
  top: 30px;
  left: 10%;
  transition: 0.2s ease;
}

    // so this happens twice because stripe adds in the focused class on everything
    // in the field. !important needs to override the current 11% to keep it in place. 
%show-password {
  position: absolute;
  right: 0px;  
  top: 8px;
}
// ============== // ==============// ==============
// content classes
// ============== // ==============// ==============

.content:not(.homepage__container) {
  display: flex;
  max-width: $sn-max-width;
  padding-left: 12px;
  padding-right: 12px;
  margin: 0 auto;
  @media (max-width: $tablet-bp) {
    display: block;
  }
}

.content--interior {
  max-width: 937px;
  @media (max-width: $tablet-bp) {
    width: 100%;
    display: block;
  }
}

.legal-pages-navigation {
  margin-top: 32px;
  &__item {
    display: inline-block;
    margin-right: 1em;
  }
}



.content-container,
.container {
  order: 1;
  width: 752px;
  margin-top: 50px;
  padding-bottom: 4rem;
  @media (max-width: $tablet-bp) {
    max-width: 620px;
    margin: 0 auto;
  }
  @media (max-width: $mobile-bp) {
    padding: 0;
    width: 90%;
  }
  h2,
  h4 {
    padding-bottom: 2rem;
    @media (max-width: $tablet-bp) {
      font-size: 20px;
      padding-bottom: 16px;
    }
  }
  h4 {
    @media (max-width: $tablet-bp) {
      font-size: 18px;
    }
  }
  h5 {
    font-weight: $fw-chubby;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  hr {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  li:not([class]) {
    font-weight: $fw-base;
    padding-bottom: 2rem;
    padding-top: 0.5rem;
  }
  p {
    padding-bottom: 2rem;
  }
  a {
    color: $sn-primary-link;
    &:hover {
      text-decoration: underline;
      color: $sn-link-hover;
    }
  }
  pre,
  xmp,
  plaintext,
  listing {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0px;
    padding: 1em;
    background-color: #f8f8f8;
  }
}

.container > h1 {
  margin-top: 50px;
}

.advertising {
  margin-top: 50px;
}

.hub-container {
  // padding-right: 24px;
}

.lit-container {
  position: relative;
  padding-top: 36px;
  padding-bottom: 0;
  margin-bottom: 8rem;
}

.container {
  margin: 0 auto;
  p {
    padding-bottom: 1rem;
  }
  a {
    font-weight: $fw-chubby;
    color: $sn-primary-link;
  }
  h1 {
    padding-bottom: 0.2rem;
  }
  h3 {
    padding-bottom: 0.2rem;
    font-size: 18px;
  }
}

#content-container--expander {
  text-align: left;
  margin-top: 0;
  @media (max-width: $mobile-bp) {
    padding: 0;
    width: 100%;
    max-width: none;
  }
}

.right_rail {
  //align-self: flex-start;
  order: 2;
  padding-left: 2rem !important;
  margin-right: -2rem !important;
  box-sizing: content-box;
  width: 405px;
  //padding-top: 5px;
  box-sizing: border-box;
  @media (max-width: $tablet-bp) {
    width: 100%;
    // padding-top: 125px;
  }
}

.unit-container {
  position: relative;
  margin: 0 auto;
  width: 1000px;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid $sn-border-gray;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -100%;
    bottom: -1px;
    border-bottom: 1px solid $sn-border-gray;
    width: 100%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 100%;
    bottom: -1px;
    border-bottom: 1px solid $sn-border-gray;
    width: 100%;
  }
  @media (max-width: $mobile-bp) {
    padding: 2rem;
    width: 100%;
    img {
      width: 100%;
    }
  }
}

.mobile-line-break {
  display: none;
  @media (max-width: $mobile-bp) {
    display: block;
  }
}

// homepage



.homepage-subheader {
  text-align: center;
  width: 570px;
  margin: 0 auto;
  color: $sn-primary-header;
  @media (max-width: $mobile-bp) {
    width: 100%;
  }
}

.container {
  .navigation {
    li {
      display: block;
      padding-bottom: 0.5rem;
    }
  }
}

.upperAlpha {
  li {
    list-style-type: disc;
    padding-bottom: 20px;
  }
}

.homepage-slider {
  display: inline-flex;
  overflow: visible;
  margin-top: 20px;
  margin-bottom: 60px;
  padding: 20px 41px 20px 40px;
  border-top: 1px solid $sn-border-gray;
  border-bottom: 1px solid $sn-border-gray;
  @media (max-width: $mobile-bp) {
    width: 100%;
    padding-left: 0;
    padding-right: 0px;
  }
  img {
    width: 175px;
    height: 100%;
  }
}

.homepage-slider--side-header {
  padding-top: 2rem;
  font-size: 15px;
}

.owl-prev {
  position: absolute;
  left: 0;
  border: none;
  top: 45%;
  width: 25px;
  height: 30px;
  background-image: url("/images/sprites.png");
  background-position: 411px 520px;
}

.owl-next {
  position: absolute;
  right: 0;
  border: none;
  top: 45%;
  width: 25px;
  height: 30px;
  background-image: url("/images/sprites.png");
  background-position: 322px 520px;
}

.owl-prev.disabled {
  background-position: 348px 520px;
  width: 23px;
  background-color: transparent !important;
  cursor: default !important;
}

.owl-next.disabled {
  background-position: 260px 520px;
  width: 25px;
  background-color: transparent !important;
  cursor: default !important;
}
 
.layout-wrapper-2018__column--main {
  a:not(.more-like-this__link):not(.page-turn-nav__link):not(.texts-landing-page__toc__section__link):not(.ctQuizzes__landingPage__link):not(.ctQuizzes__feedback__retake-quiz):not(.flashcard_interior__readmore):not(.link-flashcard):not(.landing-page__umbrella__link):not(.bn-purchase-module__link):not(.howTo-textStyles__toc__link):not(.graphicNovels_toc__act__link):not(.slide-up-modal__step):not(.nofear__tabbed__tabs__tab):not(.promo_login_link):not(.bn-purchase-module__redirect):not(.left-rail-color):not(.carousel-item):not(.page-title-icon):not(.atoz-jump__link):not(.hub-AZ-list__card__title__link):not(.tab-list__deactived-link):not(.no-link) {
        font-family: Raleway;
        font-size: 16px;
        text-decoration: underline;
        font-weight: normal;
        color: #0066c4;
        &:hover {
          color: #004371;
        }
        &:visited {
          color : #5e57ad;
        }
  }
}

.custom-link {
  color: #0066c4 !important;
  text-decoration: unset !important;
  padding: 12px 16px;
  opacity: 0.8;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  display: inline-block;
  //text-align: left;
  &:hover {
    color: #004371 !important;
    /* height: 56px; */
    //margin: 0 0 0 16px;
    opacity: 0.8;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
    background-color: var(--white-grey);
    path {
      fill : #004371 !important;
    }
  }
}

.in-title-links, .out-title-links, .nfs-nfl-link, .quotes-links, .character-links, .aptest-links {
  display: flex;
  align-items: center;

  .custom-link {
    line-height: $lh-base !important;
  }

  .in-title-icon, .out-title-icon, .quotes-title-icon, .ap-link-icon {
    padding-top: 6px;
  }

  .ap-link-icon {
    @include media-notSmall {
      margin-right: 6px;
    }
  }

  .nfs-title-icon {
    height: 56px;
    width: 56px;
  }

  &:hover {
    .custom-link {
      color: #004371 !important;
      box-shadow: none;
    }

    path:last-child {
      fill: #004371 !important;
    }
  }
}

.aptest-links {
  .custom-link {
    color: #008262 !important;
    padding-left: 5px;
    opacity: 1;
  }

  &__plus-title {
    width: 46px;
    border-radius: 12px;
    background-color: #41ffaa;
    font-size: 12px;
    font-weight: 800;
    line-height: 1.5;
    text-align: center;
    color: #004a3e;
    margin-bottom: 0 !important;
    padding: 2px 0;
    display: inline-block;
    margin-right: 6px;
  }
}

.nfs-nfl-link {
  .custom-link {
    font-weight: $fw-base !important;
  }

  a {
    color: #0066c4 !important;
    text-decoration: unset !important;
    padding: 12px 16px;
    opacity: 0.8;
    font-family: Raleway;
    font-size: $text-base;
    font-weight: $fw-chubby !important;
    line-height: $lh-base;
    display: inline-block;
  }

  &:hover{
    a {
      color: #004371 !important;
    }
  }
}

.character-links {
  padding-left: 12px;
}

.in-title-links, .out-title-links, .character-links, .aptest-links {

  font-weight: $fw-chubby;
  color: #0066c4;

  a {
    font-weight: $fw-chubby !important;
    text-decoration: none !important;
  }
}

.titleRedesignHub-hub-container {
  max-width: 723px;
  margin-bottom: unset;
  &__signedin {
    max-width: 884px;
    .hub-AZ-list__section__list__redesign {
      column-gap: 14.7px;
    }
  }
  @include media-small {
    .hub-AZ-list__section {
      margin-left: unset;
      margin-right: unset;
    }
  }
}

.titleRedesignHub-hub-content {
  @include media-small {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }
}

@include media-notSmall {
  .titleRedesignHub-hub-right-rail {
    max-width: 280px !important;
  }
}

.main-ideas-links {
  display: flex;
  align-items: center;
}