.landing-page__umbrella {
  $iconSize: 80px;
  $iconMargin: 12px;
  &:not(:last-child){
    border-bottom: 1px solid $sn-border-gray;
  }
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  scroll-margin-top: 56px;
  @include media-small{
    padding-left: $pageMargin;
    padding-right: $pageMargin;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  &__header{
    display: flex;
    margin-bottom: 16px;
    @include media-small{
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    &__icon{
      @include interior-page-icon;
      background-position: 50% 50%;
      $icons: "summary", "characters", "ideas", "quotes", "study", "help","handbook", "terms", "keypeople","keyquestion","quiz","qa","essays";
      $lifeAndTimesIcons: "biography", "historical-context", "social-context", "top-10-quotes", "plays", "narrative-poems", "glossary", "language";
      @each $icon in $icons{
        &--#{$icon}{
          background-image: url('/images/icon-#{$icon}-72-studyGuide.svg');
         
        }
      }
      
      @each $icon in $lifeAndTimesIcons{
        &--#{$icon}{
          background-image: url('/images/icon-#{$icon}-72-grays.svg');
        }
      }
      &--life-and-times{
        border-color: $sn-primary-header;
      }
    }
    &__text{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__title{
      font-size: $text-big;
      font-weight: $fw-chubby;
      line-height: $lh-tight;
      color: $sn-primary-header;
      &--is-link{
        color: $color-brandBlue2020;
        &:hover{
          color: $color-linkHover;
          text-decoration: underline;
        }
      }
      @include media-small{
        margin-bottom: 8px;
      }
    }
    &__blurb{
      line-height: $lh-loose;
      @include media-small{
        font-size: $text-small;
      }
    }
  }
  &__sections{
    @include media-notSmall{
      margin-left: $iconSize + $iconMargin;
    }
    
  }
  
  &__section{
    @include media-notSmall{
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    &:not(:first-child){
      padding-top: 24px;
    }
    &__writing-help-list {
      grid-template-rows: repeat(3,auto) !important;
    }
    &__list{
      flex: 1 0 auto;
      @include media-notSmall{
        display: grid;
        grid-template-rows: repeat(8, auto);
        grid-auto-flow: column;
        grid-gap: 0 12px;
        max-width: 100%;
        &--no-grid{
          display: block;
        }
        &:not(:last-child){
          margin-right: 32px;
        }
      }
      @include media-small{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &--quizzes{
        width: 100%;
        margin-right: 0;
        padding-top: 24px;
        padding-bottom: 24px;
        @include media-notSmall{
          &__sublists{
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
          }
          &__sublist{
            flex: 1 1 0;
            &:not(:last-child){
              margin-right: 32px;
            }
          }
        }
        @include media-small{
          display: flex;
          flex-direction: column;
          align-items: center;
          &__sublist{
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }
  &__list-item{
    padding-top: 2px;
    padding-bottom: 2px;
    display: block;
    &--title{
    }
  }
  &__list-subcategory{
    display: inline-block;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &__link{
    display: inline-block;
    padding-top: 12px;
    padding-bottom: 12px;
    color: $color-brandBlue2020;
    line-height: $lh-base;
    font-weight: $fw-chubby;
    @include media-small{
      text-align: center;
    }
    &:hover{
      color: #004371;
      text-decoration: underline;
    }
  }
  // life and times specific:
  &__content{
    @include media-notSmall{
      margin-left: $iconSize + $iconMargin;
      display: flex;
    }
  }
  &__list{
      flex: 1;
      @include media-small{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &:not(:first-child){
        @include media-notSmall{
          margin-left: 32px;
        }
      }
      &__title{
        padding-top: 12px;
        padding-bottom: 12px;
        @include media-small{
          margin-top: 16px;
        }
      }
      &_item{

      }
  }
}


.landing-page__umbrella:last-of-type {
  border-bottom : 1px solid red !important;
}


.jump-nav-hook--studyGuide{
  $jump-nav-height: 56px;
  position: absolute;
  height: $jump-nav-height;
  width: 1px;
  top: -$jump-nav-height;
}

// used on stub pages only
.stub-blurb {
  line-height: $lh-loose;
  margin-bottom: 60px;
}

.vote-sparknote-container {
  text-align: center;
  padding: 36px;
  background-color: $color-teacherSoftGray;

  .vote-sparknote {
    margin-bottom: 15px;
  }
  .sparknote-vote {
    @include removeBtnStyles;
    display: block;
    background-color: $color-brandBlue2020;
    color: $white;
    border-radius: $radius-base;
    padding: 16px;
    max-width: 360px;
    margin: 0 auto;
    line-height: $lh-base;
    &:not(.voted ):hover,
    &:not(.voted ):focus{
      background-color: $color-linkHover;
    }
  }
  .voted {
    font-size: 24px;
    background: none;
    padding: 0;
    color: $sn-primary-header;
  }
}


// still needed by Life and Times and Quick Quiz headings (though Quick Quiz should get new Title Header added)
.letter-list__headline {
  h1 {
    color: $sn-primary-header;
    text-transform: uppercase;
  }
  text-align: center;
  max-width: $sn-max-width;
  margin: 0 auto;
  .buy-it-now {
    margin-left: 0;
  }
}

.custom_sublist {
  @include media-notSmall {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto auto auto;
    grid-column-gap: 24px;
    grid-template-rows: repeat(8, 60px);

  }
}