// BASE COLORS
// basecolors should not be used directly and are just used to group different conceptual things that share the same hex value.
$sn-green: #2ed091; //TODO: Rename to $colorBase-green

// Colors to use - note anything not prefixed with 'color-' will likely be renamed, adjusted or removed during updates in late 2019 / early 2020
$sn-border-gray: #e5e5e5; //TODO: rename (pending discussion with Tom)
$sn-gray: $sn-border-gray; //TODO: remove alias and use just one variable.
$sn-off-gray: #8f8f8f; //TODO: rename (pending discussion with Tom)
$sn-brand-blue: #00adef; //TODO: Eventually refactor out. The brand blue has been updated recently and will almost excliusively be used for links.
$lit-blue: $sn-brand-blue; //TODO: remove alias and use just one variable.
$sn-shakespeare-purple: #891eff; //TODO: Rename to $color-shakespearePurple.
$color-buyNow: $sn-green;
$color-correct: $sn-green;
$color-otherSubjects: $sn-green;
$sn-primary-header: #484848; //TODO: Rename to $color-header
$sn-primary-paragraph: #292c2e; //TODO: Rename to $color-base
$sn-primary-link: #217cb5; //TODO: Eventually refactor out. Default link color will now be $color-brandBlue2020
$sn-link-hover: #0B466B; //TODO: Eventually refactor out. Hover link color will now be $color-linkHover
$color-background: #f8f8f8;
$color-incorrect: #ff3f1f;
$color-blog: #df5a00;
$color-form-error: #c51c00;
$color-lifeAndTimes: #bb0627;
$color-teacherSoftGray: #F5F1F5;
$color-brandBlue2020: #007ACD;
$color-brandBlue2020-onSoftGray: #0271bd;
$color-bnGreen: #478966;
$color-a11yGray: #767676;
$color-a11yGray-onSoftGray: #6d6d6d;
$color-linkHover: #00adef;
$color-studyGuide: #00929A;
$color-teacher: #98348B;
$color-noFear:#00A970;
$color-disabledButton: #6d7474;
$color-error : #D93D25;
$color-success: #00B07B;
$sn-off-black: rgba(0,0,0,0.21);
$color-author-image-shadow: #005D9C;
//TODO:  not addressed below here yet. Also still need to refactor a lot of hex codes used diretcly in the sass files
$homepage-hero: #01acf1;
$sn-bg: #fff;
$white: #fff;
$light-bg: #fafafa;
$dark-blue: #001727;
$footer-link: #8a9aa7;
$footer-legal-link: #5c6f7c;
$sn-lite-dark-gray: #eae8e8;
$sn-back-gray: #f0f0f0;
$sn-black: #000;
$sn-dark-hash: #b5b3b3;
$plus-green-dark: #004a3e;