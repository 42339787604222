.interior-subnav{
  position: relative;
  &__nav{
    @include scrollShadows($dir: 'horizontal');
    display: flex;
    background-color: $white;
    border-bottom: 1px solid $sn-border-gray;
    overflow: auto;
    z-index: 1;
    &__container{
      @include container($padding: true)
    }
    &__list{
      display: flex;
      line-height: $lh-base;
      align-items: center;
    }
    &__button{
      @include removeBtnStyles;
      color: $color-a11yGray;
      padding: 16px 12px;
      flex: none;
      position: relative;
      cursor: pointer;
      text-decoration: none;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
      }
      &:hover,
      &:focus {
        color: $color-linkHover;
        &:after {
          background-color: $color-linkHover;
        }
      }
      &[aria-expanded="true"]{
        color: $sn-primary-header;
        &:after {
          background-color: $sn-primary-header;
        }
      }
    }
  }
  &__panel{
    [data-panelstate="closed"] & {
      display: none;
    }
    overflow: hidden;
    background: $white;
    position: absolute;
    width: 100%;
    z-index: 5;
    display: flex;
    flex-direction: column;
    @include media-notSmall {
      top: 100%;
      border-radius: 0 0 $radius-big $radius-big;
      box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.2);
      transform-origin: top;
    }
    @include media-small {
      position: fixed;
      top: 64px;
      height: calc(100vh - 64px);
      border-radius: $radius-big $radius-big 0 0;
      overflow: hidden;
      transform-origin: bottom;
      box-shadow: 0 -10px 30px 0 rgba(0,0,0,0.20);
    }
    &__container{
      @include media-small {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }
      @include scrollShadows;
      .interior-subnav__panel--animating & {
        &:before,
        &:after{
          display: none;
        }
      }
    }
    &__header{
      @include media-notSmall {
        display: none;
      }
      @include media-small {
        display: flex;
        align-self: stretch;
        align-items: flex-start;
        border-bottom: 1px solid $sn-border-gray;
        padding-left: $pageMargin;
        padding-right: $pageMargin;
        font-size: $text-slightly-big;
        font-weight: $fw-fat;
        line-height: $lh-tight;
        color: $sn-primary-header;
      }
    }
    &__title{
      padding-top: 12px;
      padding-bottom: 12px;
      margin-right: auto;
      font: inherit;
    }
    &__controls{
      display: flex;
      align-items: center;
      flex: none;
    }
    &__button{
      @include removeBtnStyles;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      path {
        fill: $sn-primary-header;
      }
      &--previous {
        svg {
          transform: rotate(180deg);
        }
      }
      &__a11y-label{
        @include visuallyhidden;
      }
    }
    &__close{
      @include removeBtnStyles;
      width: 36px;
      height: 36px;
      margin: 8px 0 8px 12px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      path{
        fill: $sn-primary-header;
      }
      &:hover,
      &:focus{
        path{
          fill: $color-linkHover;
        }
      }        
    }
  }
  &__content{
    &[hidden]{
      display: none;
    }
    @include container($padding: true);    
    &--teacher{
      display: grid;
      grid-gap: 24px;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
    &--graphicNovel{
      display: flex;
      @include media-notSmall{
        align-items: stretch;
      }      
      @include media-small{
        flex-direction: column;
      }      
    }
    @include media-notSmall{
      padding-top: 24px;
      padding-bottom: 24px;
    }
    @include media-small{
      padding-top: 12px;
      margin-bottom: 12px;
    }
  }

  &__overlay {
    &[hidden]{
      display: none;
    }    
    --overlayHeight: 100vh;
    background-color: rgba($sn-primary-header, 0.75);
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    @include media-notSmall {
      height: var(--overlayHeight);
    }
    @include media-small {
      position: fixed;
      top: 0;
      height: 100vh;
    }
  }
}

.interior-subnav-teacher-item{
  background: $color-teacherSoftGray;
  padding: 24px;
  text-decoration: none;
  line-height: $lh-base;
  border-radius: $radius-base;
  transition: all 0.14s;
  &:hover,
  &:focus {
    box-shadow: inset 0 3px 0 0 $color-teacher;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &__title {
    font-weight: $fw-chubby;
    color: $color-teacher;
    margin-bottom: 12px;
  }
  
  &__blurb {
    margin-bottom: 24px;
  }
  
  &__prompt {
    display: flex;
    color: $color-brandBlue2020;
    align-items: center;
    .interior-subnav-teacher-item:hover &,
    .interior-subnav-teacher-item:focus & {
      color: $color-linkHover;
    }
    svg {
      display: block;
      margin-left: 4px;
      path {
        fill: $color-brandBlue2020;
      }
  
      .interior-subnav-teacher-item:hover &,
      .interior-subnav-teacher-item:focus & {
        transform: translateX(4px);
        path {
          fill: $color-linkHover;
        }
      }
      transition: transform 0.2s;
    }
  }
}

.interior-subnav-graphicNovel-item{
  display: block;
  @include softGrayCardLink;
  padding: 16px 12px;
  @include media-notSmall{
    margin-right: 12px;
    margin-bottom: 12px;
  }
  @include media-small{
    &:not(:last-child){
      margin-bottom: 12px;
    }
  }
}

// adding styles for subnav search btn and home link icon
.subnav-search-btn {
  margin-left: auto;
  padding-right: 70px;
  display: none;
  @media (min-width: $mobile-bp) and (max-width: $sn-max-width){
    margin-right: 10px;
  }
}
.subnav-btn {
  padding: 15px !important;
  &:after {
    height: 3px !important;
    bottom: 0px !important;
  }
}
.subnav-home-link {
  padding: 13px;
  display: none;
  &:hover {
    color: $color-brandBlue2020 !important;
    background-color: $color-teacherSoftGray;
  }
}
.show-homelink-searchbtn {
  display: flex;
  @media (max-width: $mobile-bp) {
    display: none;
  }
}