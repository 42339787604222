@mixin media-over-780 {
  @media (min-width: 781px) {
    @content;
  }
}

@mixin media-under-780 {
  @media (max-width: 780px) {
    @content;
  }
}

#modal-onboarding.snplusmodal {
  .snplusmodal__container {
    align-self: center;
  }

  @include media-small {
    .snplusmodal__container {
      width: auto;
      border-radius: 12px !important;
    }
    .snplusmodal__header {
      display: none 1important;
    }
    .snplusmodal__header__content {
      padding-left: unset !important;
    }
    img {
      width: 240px !important;
      margin-top: -42px !important;
      left: 15% !important;
    } 
    .dashboard-expired__title {
      margin-top: 24px !important;
    }
    .dashboard-expired__content {
      margin-top: 20px !important;
    }   
  }

  @include media-under-780 {
    .snplusmodal__container {
      width: auto;
      height: fit-content;
      background-color: $white;
      overflow: visible;
    }
    .snplusmodal__header {
      display: none !important;
    }
    .snplusmodal__content {
      margin-top: -20px;
    }
    img {
      position: absolute;
      left: 0;
      right: 0;
      width: 335px;
      margin-top: -50px;
    }
    .dashboard-expired__container {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
    }
    .dashboard-expired__title__text {
      font-size: $text-big;
      font-weight: bold;
    }
    .dashboard-expired__content {
      text-align: center;
      padding-left: unset;
      padding-right: unset;
      font-size: $text-slightly-big;
      font-weight: normal;
    }
    .intro-content__button {
      display: flex;
      margin-top: 26px;
      margin-bottom: 40px;
      align-items: center;
      justify-content: center;
      button {
        width: 231px;
        height: 46px;
        border-radius: 4px;
        background-color: $color-brandBlue2020;
        color: $white;
        font-size: $text-base;
        font-weight: $fw-chubby;
        letter-spacing: 0.13px;
        &:hover {
          background-color: $color-linkHover;
        }
      }
    }  
  }
}

#modal-onboarding {
  @include media-over-780 {
    .snplusmodal__container {
      width : 768px !important;
      max-width : 768px !important;
      position : relative;
      height : 432px !important;
    }
    .snplusmodal__content, .snplusmodal__content__container, .video-container {
      height: -webkit-fill-available;
    }
    .snplusmodal__header__content {
      display: none;
    }
    
    .fe-buttons {
      position: absolute;
      bottom: 70px;
      right: 10px;
      margin-bottom : 0px !important;
    }
    .snplusmodal__header {
      position: absolute;
      right: 0px;
      z-index: 90;
      width: 50%;
      background-color: transparent;
      padding-bottom: 0px !important;
      border-top-left-radius: 0px !important;
      &__container {
        justify-content: flex-end;
      }
    }
  }
  
  .continue-pay {
    display: none;
  }

  .video-container {
    position : relative;
    @include media-under-780 {
      margin-bottom : -4px;
    }
    iframe{
      @include media-over-780 {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
      }
    }
  }
  .owl-stage {
    transition: unset !important;
  } 

  .owl-dots {
    margin-left: 50%;
    padding-left: 50px;
    position: absolute;
    bottom: 55px;
  }

  button.owl-dot {
    border-radius: 50%;
    width: 12px !important;
    height: 12px;
    margin: 0 12px 0 0;
    background-color: $plus-green-dark;
    border : unset !important;
  }

  .owl-dot.active {
    background-color: #41ffaa ;
  }

  .owl-next {
    background-image : unset!important;
    position: absolute;
    top: 81%;
    height: 36px;
    width: 170px;
    cursor: pointer;
    font-size: $text-small;
    color: $color-brandBlue2020;
    font-weight: $fw-chubby;
    right: 3%;
    border-radius: 4px;
    background-color: $white;
    &:hover{
      background-color: $color-linkHover;
      color: $white;
    }
  }

  .owl-prev {
    display : none;
  }

  .start-over {
    background-image : unset!important;
    position: absolute;
    top: 81%;
    height: 36px;
    width: 170px;
    cursor: pointer;
    font-size: $text-base;
    color: $white;
    font-weight: normal;
    border: 1px solid $white;
    right: 3%;
    border-radius: 5px;
    background-color: transparent;
    z-index: 1;
    &:hover{
      background-color: $color-linkHover;
      border: 1px solid $color-linkHover;
      color: $white;
    }
  }
}

.intro-container {
  @include media-under-780 {
    display: none;
  }
  display: flex;
  background: #008262;
  height: 432px;
  .intro-content{
    &__plus-logo {
      margin-top: 38px;
      margin-left: 77px;
    }
    &__container {
      flex-direction: column;
      margin-top: 29px;
      margin-left: 41px;
      color: $white;
      text-align: left;
      display: flex;
    }
    &__title{
      font-size: 30px;
      margin-bottom: 16px;
      font-weight: 800;
    }
    &__text {
      color: $white;
      font-size: $text-slightly-big;
      line-height: $lh-base;
      font-weight: normal;
      padding-right: 24px;
    }
    &__button {
      padding-right: 24px;
      margin-top: 106px;
      button {
        height: 36px;
        width: 170px;
        float: right;
        background: $white;
        border-radius: 4px;
        font-size: $text-small;
        font-weight: $fw-chubby;
        color: $color-brandBlue2020;
        &:hover{
          background: $color-linkHover;
          color: $white;
        }
      }
    }
  }  
}
.intro-image {
  height: 100%;
  width: 100%;
  img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
  }
}

.intro-container-mobile {
  @include media-over-780 {
    display: none;
  }
}  