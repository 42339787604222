.atoz-jump{
  &__sentinel{
    height: 1px;
  }
  position: sticky;
  top: 8px;
  z-index: 1;
  background-color: $white;
  padding: 6px;
  border-radius: $radius-base;
  transition: box-shadow 0.2s;
  margin-bottom: calc(0px - var(--atozHeight) + 8px);  
  @include media-small{
    border-radius: 0 0 $radius-big $radius-big;
    top: 0;
    margin-left: -$pageMargin;
    margin-right: -$pageMargin; 
    padding: 8px $pageMargin;   
  }
  .hub-container--hasJumpNav &{
    top: 60px;
    @include media-small{
      top: 56px;
    }
  }

  &--isSticky{
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
  }
  &__list{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(32px, 1fr));
    grid-gap: 4px;
    &__item{
      display: flex;
      &:before{
        content: "";
        display: inline-block;
        width: 1px;
        height: 0;
        padding-bottom: calc(100%);
      }
    }
  }
  &__link{
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
    background-color: $color-teacherSoftGray;
    color: #0066c4;
    border-radius: 2px;
    &:hover,
    &:focus{
      color: $white;
      background-color: #004371;
    }
    &--active{
      .atoz-jump--isSticky &{
        background-color: $sn-primary-header;
        color: $white;
      }
    }
  }
  &__notLink{
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
    color: $color-a11yGray;
    cursor: not-allowed;
  }
}