.author {
    &__header {
        background-image: linear-gradient(90deg, $color-brandBlue2020 15%, #005995 100%);
        &__container {
            flex-direction: row;
            @include container($padding: true);
            display: flex;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
            @include media-small {
                flex-direction: column;
                text-align: center;
                padding: 32px 0;
            }
        }
        &__text {
            padding: 0;
            text-align: left;
            width: auto;
            @include media-small {
                text-align: center;
            }
        }
        &__image {
            width: 122px;
            height: 122px;
            background-color: #fff;
            border: 4px solid #fff;
            border-radius: 50%;
            box-shadow: 0 10px 30px 0 $color-author-image-shadow;
            margin: 0 20px 0 0;
            object-fit: cover;
        }
        &__headline {
            font-size: $text-extra-big;
            color: #fff;
            font-weight: $fw-huge;
            line-height: $lh-tight;
            @media (max-width: $mobile-bp) {
                padding: 16px 0 8px 0;
            }
            @include media-small {
                font-size: $text-big;
            }
        }
        &__subheadline {
            font-size: $text-base;
            color: #fff;
            font-weight: $fw-base;
            line-height: $lh-tight;
        }
    }
}
.author-pages {
    &__other-blocks {
        display: grid;
        padding-top: 40px;
        padding-bottom: 40px;
        scroll-margin-top: 56px;
        @include media-small {
            padding-top: 24px;
            padding-bottom: 24px;
        }
        border-top: 1px solid $sn-border-gray;

        &:last-child {
            @include media-small {
                border-bottom: 1px solid $sn-border-gray;
            }
        }
        &.sec-biography {
            border-top: 0;
            padding-top: 16px !important;
            div {
                @include media-notSmall {
                    padding-left: 88px !important;
                }
            }
        }
        p {
            font-size: $text-base;
            padding-bottom: 32px;
            &:nth-child(2){
                font-weight: $fw-chubby;
            }
            &:last-child {
                padding-bottom: 0px;
            }
        }
        h2 {
            margin-top: 27px;
            margin-bottom: 32px;
            text-transform: capitalize;
            width: 100%;
            grid-row: 1;
            grid-column: 1;
            padding-left: 90px;
            @include media-small {
                margin-bottom: 24px;
                margin-top: 15px;
                padding-left: 68px;
            }
        }
        & .data {
            grid-row: 2;
            padding-left: 70px;
            width: 100%;
            @include media-small {
                padding-left: 0;
            }
        }
        & .icon {
            width: 80px;
            height: 80px;
            flex: none;
            border: 2px solid #007acd;
            border-radius: 50%;
            margin-right: 12px;
            grid-row: 1;
            grid-column: 1;
            @include media-small {
                width: 56px;
                height: 56px;
            }

            //To make as default
            background: url(/images/author-icons/72px/icon-Biography-72-brandBlue.svg) no-repeat 50% 50%;
            @include media-small {
                background: url(/images/author-icons/32px/icon-Biography-32-brandBlue.svg) no-repeat 50% 50%;
            }

            &.Biography, &.Autobiography {
                background: url(/images/author-icons/72px/icon-Biography-72-brandBlue.svg) no-repeat 50% 50%;
                @include media-small {
                    background: url(/images/author-icons/32px/icon-Biography-32-brandBlue.svg) no-repeat 50% 50%;
                }
            }
            &.Studyguide {
                background: url(/images/author-icons/72px/icon-StudyGuide-72-brandBlue.svg) no-repeat;
                @include media-small {
                    background: url(/images/author-icons/32px/icon-StudyGuide-32-brandBlue.svg) no-repeat 50% 50%;
                }
            }
            &.Quotes {
                background: url(/images/author-icons/72px/icon-Quotes-72-brandBlue.svg) no-repeat;
                background-position: 50% 50%;
                @include media-small {
                    background: url(/images/author-icons/32px/icon-Quotes-32-brandBlue.svg) no-repeat 50% 50%;
                }
            }
            &.Novels, &.EssayCollections {
                background: url(/images/author-icons/72px/icon-Novels-72-brandBlue.svg) no-repeat 50% 50%;
                @include media-small {
                    background: url(/images/author-icons/32px/icon-Novels-32-brandBlue.svg) no-repeat 50% 50%;
                }
            }
            &.Shortstories {
                background: url(/images/author-icons/72px/icon-ShortStories-72-brandBlue.svg) no-repeat;
                @include media-small {
                    background: url(/images/author-icons/32px/icon-ShortStories-32-brandBlue.svg) no-repeat 50% 50%;
                }
            }
            &.Nonfiction, &.Fiction {
                background: url(/images/author-icons/72px/icon-Nonfiction-72-brandBlue.svg) no-repeat;
                @include media-small {
                    background: url(/images/author-icons/32px/icon-Nonfiction-32-brandBlue.svg) no-repeat 50% 50%;
                }
            }
            &.Poetry {
                background: url(/images/author-icons/72px/icon-Poetry-72-brandBlue.svg) no-repeat;
                @include media-small {
                    background: url(/images/author-icons/32px/icon-Poetry-32-brandBlue.svg) no-repeat 50% 50%;
                }
            }
            &.Novellas {
                background: url(/images/author-icons/72px/icon-Novels-72-brandBlue.svg) no-repeat 50% 50%;
                @include media-small {
                    background: url(/images/author-icons/32px/icon-Novels-32-brandBlue.svg) no-repeat 50% 50%;
                }
            }
            &.Plays {
                background: url(/images/author-icons/72px/icon-Poetry-72-brandBlue.svg) no-repeat 50% 50%;
                @include media-small {
                    background: url(/images/author-icons/32px/icon-Poetry-32-brandBlue.svg) no-repeat 50% 50%;
                }
            }
            &.Shortfiction {
                background: url(/images/author-icons/72px/icon-ShortStories-72-brandBlue.svg) no-repeat;
                @include media-small {
                    background: url(/images/author-icons/32px/icon-ShortStories-32-brandBlue.svg) no-repeat 50% 50%;
                }
            }
            &.GraphicNovels {
                background: url(/images/author-icons/72px/icon-graphicNovel-72-brandBlue.svg) no-repeat;
                @include media-small {
                    background: url(/images/author-icons/32px/icon-graphicNovel-32-brandBlue.svg) no-repeat 50% 50%;
                }
            }
        }
    }
    &__book-list {
        display: flex;
        flex-wrap: wrap;
        grid-row: 2;
        padding-left: 80px;
        @include media-small {
            padding-left: 0;
        }
        .block-right {
            margin: 16px 12px;
            @include media-small {
                margin-left: 0px;
            }
            background-color: $color-teacherSoftGray;
            width: 100%;
            padding: 16px 20px;
            .author-book__title {
                display: inline-block;
            }
            a {
                line-height: 50px;
                margin: 0;
                &.view-guide {
                    background-color: $color-brandBlue2020;
                    color: #fff;
                    border-radius: $radius-base;
                    font-weight: $fw-chubby;
                    font-size: $text-small;
                    padding: 8px 24px;
                    @include media-small {
                        display: none;
                    }
                    &.arrow {
                        display: none;
                        @include media-small {
                            display: block;
                            border-radius: 50%;
                            height: 32px;
                            padding: 0;
                            width: 32px;
                        }
                        svg {
                            margin: -12px 0 0 -12px;
                            path {
                                fill: $white;
                            }
                        }
                    }
                    &:hover {
                        background-color: $color-linkHover;
                    }
                }
            }
        }
        .book-title {
            font-weight: $fw-fat;
            font-size: $text-base;
            line-height: 30px !important;
            @include media-small {
                line-height: 18px !important;
            }
            padding-bottom: 4px;
            @include media-small {
                padding-bottom: 8px;
            }
        }
        .book-year {
            color: $sn-primary-header;
            font-weight: $fw-base;
            font-size: $text-base;
        }
        li {
            padding-right: 2rem;
        }
        a {
            font-weight: $fw-chubby;
            color: $sn-primary-link;
        }
    }
    &__book {
        width: 50%;
        display: flex;
        flex-direction: row;
        &.studyGuide {
            flex: 1 1 100%;
            .block-right {
                display: grid;
                margin: 16px 8px;
                padding-right: 0px !important;
                @include media-small {
                    margin-left: 0px;
                }
                &:hover, &:focus-within {
                    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), inset 0 3px 0 0 #004371;
                    transform: translateY(-2px);
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    & .view-guide {
                        background-color: $color-linkHover;
                    }
                    & .book-title {
                        color: #004371;
                    }
                }
            }
            .book-title {
                color: #0066c4;
                font-weight: $fw-chubby !important;
            }
            .title-section {
                grid-column: 1;
                h3 {
                    padding-bottom: 4px;
                    @include media-small {
                        padding-bottom: 8px;
                    }
                }
            }
            .button-section {
                grid-column: 20;
            }
        }
        @media (max-width: $mobile-bp) {
            flex: 1 1 100%;
        }
    }
    & .author-quotes {
        width: max-content;
        width: 100%;
        float: left;
        font-weight: $fw-chubby;
        font-style: italic;
        line-height: 30px;
        padding-left: 94px;
        position: relative;
        @include media-small {
            padding-left: 0;
        }
        &:before {
            content: "";
        }
        &:after {
            content: "";
        }
        p {
            &:before {
                content: '"';
            }
            &:after {
                content: '"';
            }
        }
        &__block {
            padding: 40px 0;
            margin: 0;
        }
        &__para {
            padding: 0;
            p {
                font-style: normal;
                padding-bottom: 30px;
            }
            &:last-child {
                p {
                    padding-bottom: 0;
                }
            }
        }
        @media (max-width: $mobile-bp) {
            width: 100%;
        }
    }
}
.author-quotes-para-text{
    &:last-child {
        p {
            padding-bottom: 30px !important;
        }
        p {
            &:nth-child(2) {
                font-weight: $fw-base !important;
            }
        }
    }
}