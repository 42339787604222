$linegap: 8px;
$linewidth: 1px;

.title-preview {
    @media (max-width: $mobile-bp) {
        padding: 0;
        text-align: center;
    }
    a {
        display: flex;
        align-items: center;
        padding: 16px;
        border-radius: 4px;
        &:hover {
            background-color: $color-teacherSoftGray;
            .title-preview__thumb {
                box-shadow: 0 0 0 $s-px * 0.25 $color-linkHover;
            }
            .title-preview__title {
                color: $color-linkHover;
            }
        }
    }
    &__thumb {
        width: 76px;
        height: 76px;
        border-radius: 50%;
        overflow: hidden;
        flex: none;
        margin-right: 12px;
        img {
            width: auto;
            display: block;
            height: 76px;
            transform: translate(-50%);
            margin-left: 38px;
        }
    }
    span {
        color: $sn-primary-header;
        display: block;
        font-size: $text-base;
        font-weight: $fw-chubby;
        line-height: $lh-tight;
    }
    &__center {
        text-align: center;
        &__viewMoreLink {
            display: inline-block;
            font-size: $text-base;
            padding: 8px 16px;
            align-self: flex-start;
            @include media-small {
                align-self: center;
            }
            text-align: center;
            background-color: $color-brandBlue2020;
            color: $white;
            border-radius: $radius-base;
            line-height: $lh-tight;
            &:hover,
            &:focus {
                background-color: $color-linkHover;
            }
        }
    }
}

.title-preview .nf-title--shakespeare {
    @media (max-width: $mobile-bp) {
        padding-right: 8px;
    }
}

.nf-title,
.nf-title--shakespeare {
    position: relative;
}

.nf-title--shakespeare:nth-of-type(n + 13) {
    display: none;
}

.title-preview:blank {
    display: none;
}

.title-preview:blank + hr {
    display: none;
}

.title-preview--evergreen {
    width: 300px;
    padding: 20px 0 30px;
    @media (max-width: $mobile-bp) {
        text-align: left;
    }
}

.right_rail--evergreen {
    @media (max-width: $mobile-bp) {
        width: 150%;
        padding-left: 1rem !important;
    }
    .homepage-slider--side {
        margin-top: 0;
    }
}

.evergreen-shakespreare-header {
    font-size: $text-base;
    color: $sn-primary-header;
    font-weight: $fw-fat;
    margin-bottom: 12px;
    padding: 0.2em 16px 0 16px;
    line-height: $lh-tight;
}

.evergreen-shakespreare-sidebar {
    h3 {
        font-size: 13px;
    }
    .link-title {
        padding-top: 3px;
        font-size: 13px;
        line-height: 20px;
    }
    .link-author {
        a {
            font-size: 13px;
        }
    }
    .homepage-slider__item {
        padding-bottom: 30px;
    }
    @media (max-width: $mobile-bp) {
        margin-top: 0;
    }
}

#shakespeare-header,
#study-guides {
    padding-top: 40px;
    margin-top: -40px;
}

.link-content {
    padding-left: 20px;
}

.homepage-slider--side-header span a {
    font-weight: $fw-slim;
    font-size: 14px;
}

.homepage-slider--side a {
    font-weight: $fw-slim;
    font-size: 14px;
    padding-top: 5px;
}

.evergreen-shakespreare-sidebar img {
    width: 70px;
}

.link-author {
    padding-top: 5px;
}

#top-10-quotes .toc__blurb {
    margin-bottom: 0;
}

.life-and-times {
    &__landing {
        &__header {
            background-color: $color-teacherSoftGray;

            &__container {
                @include container($padding: true);
                display: flex;
                @include media-small {
                    flex-direction: column;
                }
            }
            &__text {
                flex: 0 2 auto;
                align-self: center;
                @include media-notSmall {
                    padding: 36px 24px 48px 0;
                }
                @include media-small {
                    padding: 36px;
                    text-align: center;
                    margin-top: -32px;
                }
            }
            &__heading {
                mix-blend-mode: multiply;
                font-weight: $fw-slim;
                font-size: $text-extra-big;
                @include media-small {
                    font-size: $text-big;
                }
                line-height: $lh-tight;
                margin-bottom: 8px;
                span {
                    font-weight: $fw-huge;
                    @include media-small {
                        display: block;
                    }
                }
            }
            &__dates {
                mix-blend-mode: multiply;
                display: block;
                font-weight: $fw-slim;
                font-size: $text-big;
                @include media-small {
                    font-size: $text-big-ish;
                }
                margin-bottom: 16px;
            }
            &__description {
                font-size: $text-slightly-big;
                @include media-small {
                    font-size: $text-base;
                }
                a {
                    color: $color-brandBlue2020;
                    &:hover {
                        color: $color-linkHover;
                    }
                }
            }
            &__image-container {
                @include media-notSmall {
                    flex: none;
                    width: 264px;
                    position: relative;
                }
                @include media-small {
                    width: auto;
                    order: -1;
                    align-self: center;
                    margin-top: -24px;
                }
                // image relies on mix-blend-mode.
                // Edge 18 doesn't support it so we just hide the image
                @supports not (mix-blend-mode: multiply) {
                    display: none;
                }
            }
            &__image {
                @include media-notSmall {
                    position: absolute;
                    display: block;
                    top: 0;
                    width: 170%;
                    height: 100%;
                    object-fit: cover;
                }
                @include media-small {
                    max-width: 240px;
                }
                mix-blend-mode: multiply;
                filter: saturate(1.5);
            }
        }
    }
    &__interior {
        &__header {
            padding: 16px 0 24px;
            background-color: $color-teacherSoftGray;
            &__container {
                @include container($padding: true);
            }
            &__heading {
                display: flex;
                flex-direction: column;
                line-height: $lh-tight;
                align-items: flex-start;
                @include media-small {
                    align-items: center;
                    text-align: center;
                }
                &__eyebrow {
                    order: 1;
                    margin-top: 8px;
                    flex: none;
                    background-color: $white;
                    color: $color-brandBlue2020;
                    font-size: $text-small;
                    line-height: $lh-tight;
                    font-weight: $fw-chubby;
                    text-decoration: none;
                    padding: 4px 16px;
                    border-radius: 50px;
                    // transition: background .2s;
                    &:hover,
                    &:focus {
                        background-color: rgba(0, 0, 0, 0.75);
                        color: $white;
                    }
                    &__name {
                        font-weight: $fw-fat;
                    }
                }
                &__main {
                    font-size: $text-extra-big;
                    font-weight: $fw-huge;
                    mix-blend-mode: multiply;
                }
                &__hidden {
                    opacity: 0;
                }
            }
        }
    }
}
