// mixin to apply styles to dropdown elements only when the dropdown is open
@mixin L-whenOpen(){
  .interior-sticky-nav--modal &{
    @content;
  }
}

// mixin to apply styles to dropdown elements only when the dropdown is closed
@mixin L-whenClosed(){
  .interior-sticky-nav:not(.interior-sticky-nav--modal) &{
    @content;
  }
}

// component that houses pagination step links (previous and next) and page selection dropdown
.interior-pagination-long {
  $l-stepSize: 40px;
  display: grid;
  grid-template-columns: $l-stepSize 1fr $l-stepSize;
  grid-template-rows: $l-stepSize auto;
  grid-template-areas: "a b c" "d d d";
  border-radius: $radius-big;
  font-size: $text-small;
  align-self: start;
  @include L-whenSticky {
    margin: 0;
  }

  @include L-whenOpen {
    filter: drop-shadow(0 0 15px rgba(#000, 0.2));
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    @include media-small {
      border-bottom-right-radius: $radius-big;
      border-bottom-left-radius: $radius-big;
      filter: drop-shadow(
        0 0 15px rgba(mix($color-brandBlue2020, #000, 25), 0.5)
      );
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: fixed;
      width: calc(100vw - (24px));
      z-index: 4;
    }
  }
  // the previous and next buttons
  &__step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    padding: 8px;
    background-color: $color-teacherSoftGray;
    &--disabled {
      background-color: $color-teacherSoftGray;
      flex: none;
      padding: 8px;
      path {
        fill: darken($color-teacherSoftGray, 20);
      }
      &:first-child {
        border-top-left-radius: $radius-big;
        @include L-whenClosed {
          border-bottom-left-radius: $radius-big;
        }
      }
      &:not(:first-child) {
        border-top-right-radius: $radius-big;
        @include L-whenClosed {
          border-bottom-right-radius: $radius-big;
        }
      }
    }
    &--next {
      border-top-right-radius: $radius-big;
      @include L-whenClosed {
        border-bottom-right-radius: $radius-big;
      }
    }
    &--prev {
      border-top-left-radius: $radius-big;
      @include L-whenClosed {
        border-bottom-left-radius: $radius-big;
      }
    }

    &:hover,
    &:focus {
      background-color: darken($color-teacherSoftGray, 5);
      path {
        fill: #004371;
      }
    }
    &--hide {
      @include media-small {
        display: none;
      }
    }
  }
  // div that wraps the dropdown trigger and panel
  &__dropdown {
    display: flex;
    justify-content: center;
  }
  // button that activates the dropdown panel
  &__trigger {
    @include removeBtnStyles;
    -webkit-appearance: none;
    height: $l-stepSize;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $sn-primary-header;
    padding: 4px 8px;
    position: relative;
    flex-grow: 1;
    line-height: $lh-tight;
    background-color: $color-teacherSoftGray;
    &:hover,
    &:focus {
      background-color: darken($color-teacherSoftGray, 5);
      path {
        stroke: #004371;
      }
    }

    svg {
      flex: none;
      @include L-whenOpen {
        transform: rotate(180deg);
      }
    }

    // span within the button
    &__text {
      max-width: calc(
        100vw - (80px + 24px + 16px + 32px)
      ); //couldn't do this elegantly so went with this. Values are: width of 2 arrow buttons, width of page padding, padding of the trigger, width and margin of the carat.
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 8px;
    }
  }
  // the dropdown panel
  &__list {
    grid-area: d;
    top: 100%;
    min-width: 12em;
    max-width: 30em;
    @include L-whenOpen {
      @include media-small {
        max-width: 100%;
      }
    }
    height: max-content;
    max-height: 60vh;
    overflow-y: auto;
    z-index: 1;
    background-color: #fff;
    @include media-small {
      max-height: calc(100vh - 112px);
    }
    border-bottom-left-radius: $radius-big;
    border-bottom-right-radius: $radius-big;
  }
  // individual links within the panel
  &__link {
    display: block;
    padding: 8px 48px;
    color: $color-brandBlue2020;
    line-height: $lh-base;
    &:focus,
    &:hover {
      background-color: $color-teacherSoftGray;
      color: #004371;
    }
    &--active {
      color: $sn-primary-header;
    }
    &--long-active {
      color: $sn-primary-header !important;
      cursor: default;
      pointer-events: none;
      background-color: unset !important;
    }
  }
}
.paging{
  @media (min-width: $mobile-bp) {
    @include L-whenSticky {
      padding: 3px !important;
      margin-left: 48px;
      a{
        padding: 4px !important
      }
      button{
        padding: 4px 12px;
        span{
          font-weight: $fw-chubby;
        }
      }
    } 
  }  
}